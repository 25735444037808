import React, { Component } from "react";
import Contact from "./ContactFooter";
import Disclaimer from "./DisclaimerFooter";
import "./Footer.scss";

class Footer extends Component {
    render() {
        const yearNow = new Date().getFullYear();
        return (
            <div>
                <footer>
                    <div className="container">
                        <Contact />
                        <Disclaimer />
                        <div className="copyright">
                            <div>
                                <p className="text-center mb-xl-0">
                                    Copyright &copy; 2017 - {yearNow} PT. Bursa Akselerasi Indonesia All rights reserved.
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}
export default Footer;
