import React, { Component } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import DatadiriPersonal from "./AkunPersonal/DatadiriPersonal";
import DatadiriEntity from "./AkunEntity/DatadiriEntity";
import EditPersonalLender from "./EditPersonal/EditPersonal";
import EditDatadiriEntity from "./EditEntity/EditEntity";
import Rekening from "../../userAkun/akun/TambahRekening";
import Password from "../../userAkun/akun/GantiPassword";
import Dipay from "../../userAkun/akun/Dipay";
import { connect } from "react-redux";
import { Master } from "../../../redux/action/Master";
import { NavbarTitle, NavbarContent } from "../../../library/components/NavbarComponent";
import UpdateDataYearly from "./UpdateDataYearly/UpdateDataYearly";
import ListTiketing from "../../tiketing/ListTiketing/ListTiketing";
import { getListTiketing } from "../../../redux/action/Tiketing";

class Akun extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 0,
            loading: true,
            jeniskelaminList: [],
            agamaList: [],
            status_perkawinanList: [],
            negara_domisiliList: [],
            kewarganegaraanList: [],
            provinsiList: [],
            kotaList: [],
            pendidikanList: [],
            pekerjaanList: [],
            bidang_pekerjaanList: [],
            pengalamanList: [],
            pendapatanList: [],
            bankList: [],
            jenis_buList: [],
            list_tiketing: [],
            page_list_tiketing: 0,
            total_data_list_tiketing: 0
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.getListTiketing(1);
        await this.props.dispatch(Master());
        this.setState({
            jeniskelaminList: this.props.master.masterList.master_jenis_kelamin,
            agamaList: this.props.master.masterList.master_agama,
            status_perkawinanList: this.props.master.masterList.master_status_perkawinan,
            negara_domisiliList: this.props.master.masterList.master_negara,
            kewarganegaraanList: this.props.master.masterList.master_negara,
            provinsiList: this.props.master.masterList.master_provinsi,
            kotaList: this.props.master.masterList.master_kota_kabupaten,
            pendidikanList: this.props.master.masterList.master_pendidikan,
            pekerjaanList: this.props.master.masterList.master_pekerjaan,
            bidang_pekerjaanList: this.props.master.masterList.master_bidang_pekerjaan,
            pengalamanList: this.props.master.masterList.master_pengalaman_kerja,
            pendapatanList: this.props.master.masterList.master_pendapatan,
            bankList: this.props.master.masterList.master_escrow,
            jenis_buList: this.props.master.masterList.master_jenis_badan_hukum,
            asetList: this.props.master.masterList.master_aset,
            adminVerifLog: this.props.master.masterList
        });
    }

    async getListTiketing(page) {
        await this.props.dispatch(getListTiketing({ page: page }));
        this.setState({
            list_tiketing: this.props.tiketing.listTiketing,
            page_list_tiketing: page,
            total_data_list_tiketing: this.props.tiketing.totalData
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    authDataDiri = (jenis, jenisMaster, userDigisign, adminVerifLog) => {
        const lenLog = this.props.adminVerifLog.length;
        let isRejetedVerif = false;
        if (lenLog > 0) {
            isRejetedVerif = this.props.adminVerifLog[lenLog - 1].status === "failed";
        }

        if (jenis === "1" || jenisMaster === "1") {
            if (isRejetedVerif && !this.props.update_lender) {
                return (
                    <EditPersonalLender
                        id={this.props.match.params.id}
                        no_hp={this.props.no_hp}
                        email={this.props.email}
                        jeniskelaminList={this.state.jeniskelaminList}
                        agamaList={this.state.agamaList}
                        status_perkawinanList={this.state.status_perkawinanList}
                        negara_domisiliList={this.state.negara_domisiliList}
                        kewarganegaraanList={this.state.kewarganegaraanList}
                        provinsiList={this.state.provinsiList}
                        kotaList={this.state.kotaList}
                        pendidikanList={this.state.pendidikanList}
                        pekerjaanList={this.state.pekerjaanList}
                        bidang_pekerjaanList={this.state.bidang_pekerjaanList}
                        pengalamanList={this.state.pengalamanList}
                        pendapatanList={this.state.pendapatanList}
                        userDigisign={userDigisign}
                        bank={this.state.bankList}
                    />
                );
            } else {
                if (this.props.statusCheckYearly && this.props.admin_kyc) {
                  return (
                    <UpdateDataYearly
                      id={this.props.match.params.id}
                      no_hp={this.props.no_hp}
                      email={this.props.email}
                      jeniskelaminList={this.state.jeniskelaminList}
                      agamaList={this.state.agamaList}
                      status_perkawinanList={this.state.status_perkawinanList}
                      negara_domisiliList={this.state.negara_domisiliList}
                      kewarganegaraanList={this.state.kewarganegaraanList}
                      provinsiList={this.state.provinsiList}
                      kotaList={this.state.kotaList}
                      pendidikanList={this.state.pendidikanList}
                      pekerjaanList={this.state.pekerjaanList}
                      bidang_pekerjaanList={this.state.bidang_pekerjaanList}
                      pengalamanList={this.state.pengalamanList}
                      pendapatanList={this.state.pendapatanList}
                      userDigisign={userDigisign}
                    />
                  );
                } else {
                return (
                    <DatadiriPersonal
                        id={this.props.match.params.id}
                        no_hp={this.props.no_hp}
                        email={this.props.email}
                        jeniskelaminList={this.state.jeniskelaminList}
                        agamaList={this.state.agamaList}
                        status_perkawinanList={this.state.status_perkawinanList}
                        negara_domisiliList={this.state.negara_domisiliList}
                        kewarganegaraanList={this.state.kewarganegaraanList}
                        provinsiList={this.state.provinsiList}
                        kotaList={this.state.kotaList}
                        pendidikanList={this.state.pendidikanList}
                        pekerjaanList={this.state.pekerjaanList}
                        bidang_pekerjaanList={this.state.bidang_pekerjaanList}
                        pengalamanList={this.state.pengalamanList}
                        pendapatanList={this.state.pendapatanList}
                        userDigisign={userDigisign}
                    />
                );
                }
            }
        } else {
            if (isRejetedVerif && !this.props.update_lender) {
                return (
                    <EditDatadiriEntity
                        id={this.props.match.params.id}
                        no_hp={this.props.no_hp}
                        email={this.props.email}
                        jeniskelaminList={this.state.jeniskelaminList}
                        jenis_buList={this.state.jenis_buList}
                        negara_domisiliList={this.state.negara_domisiliList}
                        provinsiList={this.state.provinsiList}
                        kotaList={this.state.kotaList}
                        bidang_pekerjaanList={this.state.bidang_pekerjaanList}
                        asetList={this.state.asetList}
                        bankList={this.state.bankList}
                        userDigisign={userDigisign}
                        bank={this.state.bankList}
                    />
                );
            } else {
                return (
                    <DatadiriEntity
                        id={this.props.match.params.id}
                        no_hp={this.props.no_hp}
                        email={this.props.email}
                        jeniskelaminList={this.state.jeniskelaminList}
                        jenis_buList={this.state.jenis_buList}
                        negara_domisiliList={this.state.negara_domisiliList}
                        provinsiList={this.state.provinsiList}
                        kotaList={this.state.kotaList}
                        bidang_pekerjaanList={this.state.bidang_pekerjaanList}
                        asetList={this.state.asetList}
                        bankList={this.state.bankList}
                        userDigisign={userDigisign}
                    />
                );
            }
        }
    };

    render() {
        const { jenis } = this.props.location.state;
        const { jenisMaster } = this.props.location.state;
        const title = ["Data Diri", "Rekening Bank", "Ubah Kata Sandi", this.props.dipay_key ? "Dipay" : null, "Laporan Pengaduan"].filter(Boolean);
        const children = [
            this.authDataDiri(jenis, jenisMaster, this.props.userDigisign, this.props.adminVerifLog),
            <Rekening
                id={this.props.match.params.id}
                bank={this.state.bankList}
                userBank={this.props.userBank}
                nama_pengguna={this.props.nama_pengguna}
            />,
            <Password />,
            this.props.dipay_key ? <Dipay /> : null,
            <ListTiketing
                list_tiketing={this.state.list_tiketing}
                onChange={this.getListTiketing.bind(this)}
                page={this.state.page_list_tiketing}
                totalData={this.state.total_data_list_tiketing}
            />
        ].filter(Boolean);
        return (
            <div>
                <div className="akun-wrapper">
                    <div className="card border-0 shadow">
                        <NavbarTitle
                            title={title}
                            activeTab={this.state.activeTab}
                            toggle={this.toggle}
                            dipay_key={this.props.dipay_key}
                            akunLender={true}
                        />
                        <NavbarContent activeTab={this.state.activeTab} components={children} />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        master: state.master,
        tiketing: state.tiketing
    };
};
export default connect(mapStateToProps)(Akun);
