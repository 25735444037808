import React, { Component } from "react";
import LendingTotal from "../dashboard/DasborTotal";
import { NavbarTitle, NavbarContent } from "../../../library/components/NavbarComponent";
import Pendanaan from "./DasborContentPendanaan";
import Statistik from "./DasborContentStatistik";
import Laporan from "./Laporan";
import "./dasbor.scss";
class Dasbor extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 0,
            info: [],
            project: [],
            saldo: props.saldo
        };
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const title = ["Project pendanaan", "Statistik", "Laporan"];
        const children = [<Pendanaan />, <Statistik />, <Laporan />];

        return (
            <div>
                <div className="col-12 col-lg-12">
                    <div className="dasbor-wrapper">
                        <LendingTotal />
                        <NavbarTitle title={title} activeTab={this.state.activeTab} toggle={this.toggle} />
                        <NavbarContent activeTab={this.state.activeTab} components={children} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Dasbor;
