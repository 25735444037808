import React, { Component } from "react";

class EmailForgotPassword extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <section id="email-sent" className="with-pad bg-grey">
                <div className="container no-lg">
                    <div className="card email-sent-card border-0">
                        <div className="card-body">
                            <div className="text-center">
                                <div className="my-5">
                                    <img
                                        className="ojk img-fluid mb-4"
                                        src={process.env.PUBLIC_URL + "images/partners-icon/sendemail.svg"}
                                        alt="logo-send"
                                    />
                                </div>
                                <div className="mb-4">
                                    <h4 className="font-weight-bold mb-3">Email Atur Ulang Kata Sandi Anda telah terkirim</h4>
                                    <p className="fs-1 mb-1 text-secondary">
                                        Silahkan melakukan atur ulang kata sandi Anda melalui email yang kami kirimkan ke:
                                    </p>
                                    <span className="text-primary">{localStorage.email}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default EmailForgotPassword;
