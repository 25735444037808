import React, { Component } from "react";
import "./InfoPinjam.scss";
import Login from "../../template/guest/login modal/Login";
import Swal from "sweetalert2";

class InfoPinjam extends Component {
    state = {
        modal: false,
        setModal: false,
        isOpen: false,
        setIsOpen: false
    };

    togglemodal() {
        this.setState({
            modal: !this.state.modal,
            setModal: !this.state.setModal
        });
        // console.log(localStorage.justOnce)
    }
    infolender() {
        Swal.fire("Sudah punya akun pemberi dana?", "Anda masuk sebagai Borrower, ingin menjadi pemberi dana ?", "question");
    }
    infoborrower() {
        Swal.fire("Sudah punya akun penerima dana?", "Anda masuk sebagai Pemberi Dana, ingin menjadi Penerima Dana ?", "question");
    }
    render() {
        // console.log(localStorage.kode_pengguna.slice(2, 3))
        return (
            <div>
                <section id="info-pinjam" className="bg-white">
                    <div className="container no-lg mb-n5">
                        <div className="row justify-content-center floating-card">
                            <div className="d-flex col-12 col-md-6 col-lg-5 col-xl-5 mb-4 mb-md-0">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body" style={{ padding: "40px" }}>
                                        <h5 className="font-weight-bold text-center">Berikan Pendanaan</h5>
                                        <p className="text-secondary pt-2 pb-2">
                                            Dapatkan imbal hasil menguntungkan tanpa ribet, hanya butuh 10 menit
                                        </p>
                                        {localStorage.justOnce === "true" && localStorage.masuk === "1" ? (
                                            <a href="/lender/dasbor" className="btn btn-primary btn-block btn-lg fs-1 test">
                                                Berikan pendanaan
                                            </a>
                                        ) : localStorage.justOnce === "true" && localStorage.masuk === "1" ? (
                                            <a href="/lender/dasbor" className="btn btn-primary btn-block btn-lg fs-1 test">
                                                Berikan pendanaan
                                            </a>
                                        ) : localStorage.justOnce === "true" && localStorage.masuk !== "1" ? (
                                            <a href="/#" className="btn btn-primary btn-block btn-lg fs-1 test" onClick={this.infolender}>
                                                Berikan pendanaan
                                            </a>
                                        ) : (
                                            <a
                                                href="/daftar/1"
                                                className="btn btn-primary btn-block btn-lg fs-1 test"
                                                // onClick={this.togglemodal.bind(this)}
                                            >
                                                Berikan pendanaan
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5 col-xl-5 mb-4 mb-md-0">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body " style={{ padding: "40px" }}>
                                        <h5 className="font-weight-bold text-center ">Ajukan Pendanaan</h5>
                                        <p className="text-secondary pt-2 pb-2">
                                            Dapatkan pendanaan dengan biaya terjangkau dan jangka waktu fleksibel sesuai kebutuhan usahamu.
                                        </p>
                                        {localStorage.justOnce === "true" && localStorage.masuk === "2" ? (
                                            <a href="/borrower/dasbor" className="btn btn-primary btn-block btn-lg fs-1 test">
                                                Ajukan pendanaan
                                            </a>
                                        ) : localStorage.justOnce === "true" && localStorage.masuk === "2" ? (
                                            <a href="/borrower/dasbor" className="btn btn-primary btn-block btn-lg fs-1 test">
                                                Ajukan pendanaan
                                            </a>
                                        ) : localStorage.justOnce === "true" && localStorage.masuk !== "2" ? (
                                            <a href="/#" className="btn btn-primary btn-block btn-lg fs-1 test" onClick={this.infoborrower}>
                                                Ajukan pendanaan
                                            </a>
                                        ) : (
                                            <a
                                                href="/daftar/2"
                                                className="btn btn-primary btn-block btn-lg fs-1 test"
                                                // onClick={this.togglemodal.bind(this)}
                                            >
                                                Ajukan pendanaan
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Login isOpen={this.state.modal} toggle={this.togglemodal.bind(this)} />

                        {/* <div className="row justify-content-center info-list d-none d-sm-block">
                            <div className="row justify-content-center info-list">
                                <div className="col-12 col-md-4 col-lg text-center mb-3 mb-md-0">
                                    <h3 className="text-primary font-weight-bold">Rp. 5.60 M</h3>
                                    <p>Pendanaan yang telah disalurkan</p>
                                </div>
                                <div className="col-12 col-md-4 col-lg text-center mb-3 mb-md-0">
                                    <h3 className="text-primary font-weight-bold">Rp. 3.87 M</h3>
                                    <p>Total Outstanding Pinjaman</p>
                                </div>
                                <div className="col-12 col-md-4 col-lg text-center mb-3 mb-md-0">
                                    <h3 className="text-primary font-weight-bold">Rp. 1.73 M</h3>
                                    <p>Pinjaman yang telah selesai</p>
                                </div>
                                <div className="col-12 col-md-4 col-lg text-center mb-3 mb-md-0">
                                    <h3 className="text-primary font-weight-bold">10.000</h3>
                                    <p>Pengusaha mikro telah diberdayakan</p>
                                </div>
                                <div className="col-12 col-md-4 col-lg text-center mb-3 mb-md-0">
                                    <h3 className="text-primary font-weight-bold">100%</h3>
                                    <p>Tingkat Keberhasilan Bayar dalam 90 Hari</p>
                                </div>
                                <div className="col-12 col-md-4 col-lg text-center mb-3 mb-md-0">
                                    <h3 className="text-primary font-weight-bold">22.375</h3>
                                    <p>Pemodal yang terdaftar</p>
                                </div>
                                <div className="col-12 col-md-4 col-lg text-center">
                                    <h3 className="text-primary font-weight-bold">15.67%</h3>
                                    <p>Rata-rata Tingkat Pengembalian</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>
            </div>
        );
    }
}
export default InfoPinjam;
