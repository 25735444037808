import React, { Component } from "react";
import { Link } from "react-router-dom";
import InfoItem from "./InfoItemProyek";
import { namaSektor, colorGrade, satuanJWP, sukuBunga, bilanganNominal } from "../../../../library/helper/helper";

function ItemProduktif(project) {
    const progressBar = (project.nilai_total_peminjam / project.nilai_pengajuan_pinjaman) * 100;
    const jangka_waktu = project.rencana_jangka_waktu_publikasi;
    const periode_akhir =
        project.tgl_mulai_publikasi_pinjaman === undefined
            ? ""
            : new Date(new Date(project.tgl_mulai_publikasi_pinjaman).getTime() + jangka_waktu * 24 * 60 * 60 * 1000);
    const date1 = Date.now();
    const date2 = new Date(periode_akhir);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const sisaPendanaan = project.nilai_pengajuan_pinjaman - project.nilai_total_peminjam;
    const sum_lender = project.pemberian_pinjaman ? `${project.pemberian_pinjaman.length} lender` : null;
    const bannerTag = () => {
        if (project.nilai_total_peminjam === project.nilai_pengajuan_pinjaman) {
            return (
                <div>
                    <div className="back-banner-fanded"></div>
                    <p className="banner-fanded">fully funded</p>
                </div>
            );
        } else if (project.status_proyek === "Terdanai sebagian") {
            return (
                <div>
                    <div className="back-banner-fanded"></div>
                    <p className="banner-fanded">partial funded</p>
                </div>
            );
        } else {
            return (
                <div style={{ visibility: "hidden" }}>
                    <div className="back-banner-fanded"></div>
                    <p className="banner-fanded"></p>
                </div>
            );
        }
    };
    return (
        <div className="col-11 col-md-6 col-xl-4 pl-4" key={project._id} style={{ marginBottom: "20px" }}>
            {bannerTag()}

            <Link to={"/proyek/" + project._id} className="card project-card">
                <img className="card-img-top banner-img" src={project.gambar_proyek} alt="1" />
                <p className="banner-grade" style={{ backgroundColor: colorGrade(project.grade_proyek) }}>
                    Grade {project.grade_proyek} |{" "}
                    {sukuBunga(
                        project.tipe_pinjaman,
                        project.jenis_pinjaman,
                        project.suku_bunga_pinjaman,
                        project.suku_bunga_konsumtif,
                        project.suku_bunga_micro,
                        project.spread_bunga,
                        true
                    )}{" "}{project.tipe_pinjaman.toLowerCase().includes('micro')?<span className="fs-n3">/ Bln</span>:<span className="fs-n3">/ Thn</span>}
                </p>
                <div className="card-body">
                    <div className="card-label text-center">
                        <div className="card-title">{project.nama_pinjaman}</div>
                        <p className="text-secondary">{project.tipe_pinjaman}</p>
                    </div>
                    <hr />
                    <div className="card-label">
                        {sisaPendanaan <= 0 ? (
                            <p>
                                Dana <b>100%</b> telah terkumpul
                            </p>
                        ) : project.status_proyek === "Terdanai sebagian" ? (
                            <p>
                                Dana terkumpul <b>{"Rp " + project.nilai_total_peminjam.toLocaleString("IN")}</b>
                            </p>
                        ) : (
                            <p>
                                Sisa pendanaan <b>{"Rp " + bilanganNominal(sisaPendanaan)} lagi</b>
                            </p>
                        )}
                    </div>
                    <div className="progress">
                        <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{
                                width: `${parseInt(progressBar)}%`
                            }}></div>
                    </div>
                    <div className="row text-information pt-2">
                        <InfoItem judul={"Terkumpul"} content={"Rp " + project.nilai_total_peminjam.toLocaleString("IN")} />
                        <InfoItem judul={"Plafon"} content={"Rp " + project.nilai_pengajuan_pinjaman.toLocaleString("IN")} />
                        <InfoItem judul={"Terdanai oleh"} content={sum_lender} />
                        <InfoItem judul={"Industri"} content={namaSektor(project.penggunaan_pinjaman)} />

                        <InfoItem
                            judul={"Durasi peminjaman"}
                            content={project.jangka_waktu_pinjaman + " " + satuanJWP(project.satuan_jangka_waktu_pinjaman)}
                        />
                        <InfoItem judul={"Masa pendanaan"} content={isNaN(diffDays) ? "0" : diffDays + " Hari"} />
                    </div>
                </div>
            </Link>
        </div>
    );
}

export { ItemProduktif };
