import React, { Component } from "react";
import { connect } from "react-redux";
import Withdraw from "./DompetWithdraw";
import History from "./DompetHistory";
import { BalanceTransaction } from "../../../redux/action/User";
import { NavbarTitle, NavbarContent } from "../../../library/components/NavbarComponent";
import DompetInfo from "./DompetInfo";
class Dompet extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 0,
            modaltarik: false,
            setModal: false,
            userTransaksi: "",
            textFilter: "Semua",
            minDate: "",
            maxDate: ""
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.fetchListTransaction(this.state.textFilter);
    }

    async fetchListTransaction(type, minDate, maxDate) {
        await this.props.dispatch(
            BalanceTransaction({
                type: type === "Semua" ? "" : type,
                minDate: !minDate ? "" : minDate,
                maxDate: !maxDate ? "" : maxDate
            })
        );
        this.setState({
            userTransaksi: this.props.user.userDompet
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleChangeFilter(value) {
        this.setState({ textFilter: value });
        this.fetchListTransaction(value, this.state.minDate, this.state.maxDate);
    }

    handleChangeDateFilter(minDate, maxDate) {
        this.setState({ minDate: minDate, maxDate: maxDate });
        this.fetchListTransaction(this.state.textFilter, minDate, maxDate);
    }

    render() {
        const title = ["Transaksi Pending", "Riwayat Transaksi"];
        const children = [
            <Withdraw userTransaksi={this.state.userTransaksi} />,
            <History
                userTransaksi={this.state.userTransaksi}
                textFilter={this.state.textFilter}
                handleChangeFilter={this.handleChangeFilter.bind(this)}
                handleChangeDateFilter={this.handleChangeDateFilter.bind(this)}
            />
        ];

        return (
            <div>
                <div className="dompet-wrapper">
                    <DompetInfo
                        saldo={this.props.saldo}
                        nama_pengguna={this.props.nama_pengguna}
                        userNameDipay={this.props.userNameDipay}
                        userTlpDipay={this.props.userTlpDipay}
                        loadingUserDipay={this.props.loadingUserDipay}
                    />
                    <NavbarTitle title={title} activeTab={this.state.activeTab} toggle={this.toggle} />
                    <NavbarContent activeTab={this.state.activeTab} components={children} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(Dompet);
