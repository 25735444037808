import React, { Component } from "react";
import "./OurTeam.scss";
import Card from "./CardTeam";
class OurTeam extends Component {
    render() {
        return (
            <div>
                <section id="our-team">
                    <div className="container">
                        <div className="sec-header">
                            <h4 className="text-center sec-title">Expert & Solid Team</h4>
                            <p className="text-center sec-desc">
                                Tim utama Indofund.id terdiri dari individu-individu yang telah berpengalaman dalam industri keuangan
                            </p>
                        </div>
                        <div className="team-wrapper">
                            <div className="row m-n2 justify-content-center">
                                <Card
                                    gambar={"Ryan.png"}
                                    nama={"Ryan Filbert"}
                                    jabatan={"CEO & Founder"}
                                    content={
                                        "Terkenal sebagai salah seorang praktisi dan inspirator investasi di Indonesia, Ryan Filbert berpengalaman lebih dari 10 tahun dalam bidang investasi, khususnya saham dan derivative. Selain sebagai praktisi dan inspirator, Ryan juga dikenal sebagai seorang mentor dan penulis buku-buku tentang investasi dan analis saham, baik teknikal maupun secara fundamental. Kemampuan analisanya merupakan asset yang berharga bagi perusahaan."
                                    }
                                />
                                <Card
                                    gambar={"Arwani.png"}
                                    nama={"Arwani Pranajaya"}
                                    jabatan={"Commisioner"}
                                    content={
                                        "Dengan pengalaman lebih dari 20 tahun di bidang keuangan khususnya di bidang investasi, Bapak Arwani membantu memberikan insight dan arahan dalam penentuan arah kebijakan dari PT BAI."
                                    }
                                />
                                <Card
                                    gambar={"Marianto.png"}
                                    nama={"Marianto"}
                                    jabatan={"Commisioner"}
                                    content={
                                        "Dengan pengalaman lebih dari 20 tahun di bidang dunia keuangan serta bertugas di bagian compliance & Risk Management sebelum bergabung dengan grup Surya Fajar, Bpk Marianto diharapkan dapat memberikan pengawasan yang lebih mendetail terhadap compliance dan risk management di Indofund."
                                    }
                                />
                                <Card
                                    gambar={"Fandy.png"}
                                    nama={"Fandy Gunawan"}
                                    jabatan={"COO"}
                                    content={
                                        "Berpengalaman lebih dari 15 tahun di bidang keuangan, baik di sektor riil maupun di sektor industry keuangan khususnya pada perbankan.Pengalaman Fandy Gunawan dalam menganalisa potensi dan kelayakan usaha serta memitigasi resiko merupakan aset yang berharga bagi perusahaan."
                                    }
                                />
                                <Card
                                    gambar={"Benny.png"}
                                    nama={"Benny"}
                                    jabatan={"Head of Business Development"}
                                    content={
                                        "Benny merupakan lulusan dari Universitas Binus. Memiliki pengalaman lebih dari 11 tahun di industri keuangan khususnya di industry perbankan, Benny sangat memahami proses lending maupun funding. Networking yang luas juga merupakan salah satu kelebihan Benny yang dibawa ke Indofund untuk memperkenalkan Indofund tanpa melupakan prinsip kehati – hatian dan akuntabilitas."
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default OurTeam;
