const initialState = {
    listCategory: [],
    listBlog: [],
    detailBlog: null,

    isLoadingCategory: false,
    isLoadingBlog: false,
    isLoadingDetail: false
};

const Blog = (state = initialState, action) => {
    switch (action.type) {
        case "CATEGORY_BLOG_PENDING":
            return {
                ...state,
                isLoadingCategory: true
            };
        case "CATEGORY_BLOG_REJECTED":
            return {
                ...state,
                isLoadingCategory: false,
                isError: true
            };
        case "CATEGORY_BLOG_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoadingCategory: false,
                listCategory: action.payload.data.data
            };
        case "LIST_BLOG_PENDING":
            return {
                ...state,
                isLoadingBlog: true
            };
        case "LIST_BLOG_REJECTED":
            return {
                ...state,
                isLoadingBlog: false,
                isError: true
            };
        case "LIST_BLOG_FULFILLED":
            return {
                ...state,
                isLoadingBlog: false,
                listBlog: action.payload.data.result
            };
        case "DETAIL_BLOG_PENDING":
            return {
                ...state,
                isLoadingDetail: true
            };
        case "DETAIL_BLOG_REJECTED":
            return {
                ...state,
                isLoadingDetail: false,
                isError: true
            };
        case "DETAIL_BLOG_FULFILLED":
            // console.log(action.payload)
            return {
                ...state,
                isLoadingDetail: false,
                detailBlog: action.payload.data.data
            };
        default:
            return state;
    }
};
export default Blog;
