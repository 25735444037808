import React, { Component } from "react";
import { Button } from "reactstrap";

class SuccessDownloadFile extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <section id="email-sent" className="with-pad bg-grey">
                <div className="container no-lg">
                    <div className="card email-sent-card border-0">
                        <div className="card-body">
                            <div className="text-center">
                                <div className="my-5">
                                    {/* <img className="ojk img-fluid mb-4" src={process.env.PUBLIC_URL + 'images/partners-icon/sendemail.svg'} alt="logo-send" /> */}
                                </div>
                                <div className="mb-4">
                                    <h4 className="font-weight-bold mb-3">Berhasil Download Dokumen</h4>
                                    {/* <p className="fs-1 mb-1 text-secondary">Email anda telah di verifikasi, silahkan login dengan email dan password Anda</p> */}
                                    <Button size="lg" color="primary" className="pr-4 pl-4 mt-4" onClick={() => window.close()}>
                                        Kembali
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default SuccessDownloadFile;
