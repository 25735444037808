import React, { Component } from "react";
// import ProyekSidebar from './ProyekSidebar'
import ProyekItem from "./ProyekItem";
import ProyekItemKonsumtif from "./ProyekItemK";
import "./Proyek.scss";
import { AllProject } from "../../../redux/action/Project";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { NavbarTitle, NavbarContent } from "../../../library/components/NavbarComponent";
import { getTopikTiketing } from "../../../redux/action/Tiketing";
import { LenderId } from "../../../redux/action/Lender";
import ProyekItemPremium from "./ProyekItemPremium";

class AllProyek extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            project: [],
            currentPage: 1,
            todosPerPage: 9,
            activeTab: 0,
            isPremiumLender: false
        };
    }

    handleClick(pageNumber) {
        this.setState({
            currentPage: pageNumber
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.props.dispatch(AllProject());
        await this.props.dispatch(LenderId(localStorage.getItem("lenderId")));

        this.setState({
            project: this.props.project.projectAll,
            isPremiumLender: this.props.lender.lenderDetail.lender_type === 2
        });
    }

    render() {
        const { isPremiumLender } = this.state;
        const title = isPremiumLender ? ["Premium Lender Project", "Produktif", "Employee Loan"] : ["Produktif", "Employee Loan"];
        const children = isPremiumLender
            ? [
                  <ProyekItemPremium project={this.state.project} />,
                  <ProyekItem project={this.state.project} />,
                  <ProyekItemKonsumtif projects={this.state.project} />
              ]
            : [<ProyekItem project={this.state.project} />, <ProyekItemKonsumtif projects={this.state.project} />];
        return (
            <div>
                {this.props.project.isLoading === true ? (
                    <Loader type="ThreeDots" color="#FF7A00" height={100} width={100} className="loading-dots" />
                ) : (
                    <section id="proyek-page" className="with-pad">
                        <div className="container">
                            <div className="row">
                                {/* <ProyekSidebar /> */}
                                <div className="col-12 col-lg-9 col-xl-12">
                                    <div className="row align-items-center sec-header">
                                        <div className="col-12 col-lg-8 mb-4 mb-lg-0">
                                            <h4 className="sec-title">Proyek Berjalan Saat Ini</h4>
                                            <p className="sec-desc">Temukan peluang pendanaan yang menguntungkan pada hari ini</p>
                                        </div>
                                    </div>
                                    <NavbarTitle title={title} activeTab={this.state.activeTab} toggle={this.toggle} />
                                    <div className="projects-wrapper">
                                        <NavbarContent activeTab={this.state.activeTab} components={children} className={"pt-5"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        project: state.project,
        lender: state.lender
    };
};
export default connect(mapStateToProps)(AllProyek);
