import React, { Component } from "react";
import { ItemProduktif } from "../welcome/proyek/ItemProduktif";
import { arrayItemProject } from "../../../library/helper/helper";
import Pagination from "react-js-pagination";
import NoData from "../../assets/no data/NoData";
import Airplane from "../../assets/svg/Airplane";
class ProyekItemPremium extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: [],
            currentPage: 1,
            todosPerPage: 9
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(pageNumber) {
        this.setState({
            currentPage: pageNumber
        });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { currentPage, todosPerPage } = this.state;
        const length = this.props.project.filter((item) => item.premium_lender).length;
        const proyek = (currentPage, todosPerPage) => {
            const indexOfLastTodo = currentPage * todosPerPage;
            const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
            const currentTodos = this.props.project
                .filter((item) => item.premium_lender)
                .sort(compare)
                .slice(indexOfFirstTodo, indexOfLastTodo);
            //sorting descending
            function compare(a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }

            const hasil = arrayItemProject(currentTodos).map((project) => {
                return ItemProduktif(project);
            });
            return hasil;
            //   console.log(arrayItemProject(currentTodos));
        };
        return (
            <div>
                <div className="row">
                    {length !== 0 ? (
                        proyek(currentPage, todosPerPage)
                    ) : (
                        <NoData symbol={<Airplane />} keterangan={"Maaf saat ini belum ada project tersedia "} />
                    )}
                </div>

                <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        hideDisabled
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.currentPage}
                        itemsCountPerPage={this.state.todosPerPage}
                        totalItemsCount={parseInt(length)}
                        onChange={this.handleClick.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

export default ProyekItemPremium;
