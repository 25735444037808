import React, { Component } from "react";
import { Pagination, PaginationItem, PaginationLink, Button } from "reactstrap";
import { CancelWithdraw } from "../../../redux/action/User";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import Loading from "./Loading";
import NoData from "../../assets/no data/NoData";
import ChartPie from "../../assets/svg/ChartPie";

import { colorCurrency, ballanceCurrency } from "../../../library/helper/helper";

class DompetTarik extends Component {
    constructor() {
        super();
        this.state = {
            currentPageTarik: 1,
            todosPerPageTarik: 15,
            hidden: true,
            filter: ""
        };
        this.handleClickTarik = this.handleClickTarik.bind(this);
    }

    handleClickTarik(event) {
        window.scrollTo(0, 0);
        this.setState({
            currentPageTarik: Number(event.target.id)
        });
    }

    handleClickCancel = async (id) => {
        Swal.fire({
            title: "Cancel penarikan",
            text: "Yakin melakukan cancel penarikan?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yakin"
        })
            .then(async (result) => {
                if (result.value) {
                    // console.log(id)
                    await this.props.dispatch(CancelWithdraw(id));
                    Swal.fire({
                        title: "Berhasil ",
                        text: "Melakukan cancel penarikan saldo. Silahkan cek di riwayat saldo Anda",
                        icon: "success",
                        button: "ok"
                    }).then(() => {
                        window.location.reload();
                    });
                }
            })
            .catch((err) => {
                // console.log(err.response)
                if (err) {
                    if (err.response.status === 404) {
                        Swal.fire({
                            title: "Gagal ",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    }
                }
            });
    };

    DompetTarik = (currentPageTarik, todosPerPageTarik, lengthTarik) => {
        if (this.props.userTransaksi && this.props.userTransaksi.length > 0 && lengthTarik > 0) {
            const withdraw = this.props.userTransaksi;
            //sorting descending
            function compare(a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }
            //page
            const indexOfLastTodo = currentPageTarik * todosPerPageTarik;
            const indexOfFirstTodo = indexOfLastTodo - todosPerPageTarik;
            const currentTodos = withdraw
                .filter((item) => item.tipe_pembayaran === "Tarik saldo" && item.status_tarik_saldo === "Pengajuan")
                .sort(compare)
                .slice(indexOfFirstTodo, indexOfLastTodo);
            return currentTodos.map((item, index) => {
                return (
                    <div key={index}>
                        <div className="transaction p-3">
                            <div className="card border-0 shadow-sm">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                            <p className="fs-n1 text-secondary mb-1">Tanggal Transaksi</p>
                                            <p className="mb-0 text-warning">{new Date(item.createdAt).toLocaleDateString("IN")}</p>
                                            {/* <p className="mb-0 text-warning">{new Date(item.createdAt).toLocaleDateString()}</p> */}
                                        </div>
                                        <div className="col-6 col-md-4 mb-3 mb-xl-0">
                                            <p className="fs-n1 text-secondary mb-1">Deskripsi</p>
                                            <p className="mb-0">{item.deskripsi_pembayaran}</p>
                                        </div>
                                        <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                            <p className="fs-n1 text-secondary mb-1">Status</p>
                                            <p className="mb-0">{item.status_tarik_saldo}</p>
                                        </div>
                                        <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                            <p className="fs-n1 text-secondary mb-1">Nominal</p>
                                            <p className={colorCurrency(item.tipe_pembayaran)}>
                                                {ballanceCurrency(item.tipe_pembayaran) + item.nilai_transaksi.toLocaleString("IN")}
                                            </p>
                                        </div>
                                        {item.status_tarik_saldo === "Pengajuan" ? (
                                            <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                                <Button
                                                    color="outline-primary"
                                                    className="btn btn-long"
                                                    onClick={() => this.handleClickCancel(item._id)}>
                                                    Cancel Tarik{" "}
                                                </Button>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        } else {
            return <NoData symbol={<ChartPie />} keterangan={"Belum ada Transaksi"} />;
        }
    };

    render() {
        const { currentPageTarik, todosPerPageTarik } = this.state;
        //pagination tariksaldo
        const lengthTarik =
            this.props.userTransaksi &&
            this.props.userTransaksi.filter((item) => item.tipe_pembayaran === "Tarik saldo" && item.status_tarik_saldo === "Pengajuan").length;
        const pageTarik = [];
        for (let i = 1; i <= Math.round(lengthTarik / todosPerPageTarik); i++) {
            pageTarik.push(i);
        }

        return (
            <div>
                {this.props.user.isLoadingBalance === true ? (
                    <div>
                        <Loading />
                        <Loading />
                    </div>
                ) : (
                    this.DompetTarik(currentPageTarik, todosPerPageTarik, lengthTarik)
                )}

                <Pagination hidden={!this.state.hidden} aria-label="Page navigation example" className="d-flex justify-content-center pt-5 ">
                    {pageTarik.map((number) => (
                        <PaginationItem key={number} active={currentPageTarik === number ? true : false}>
                            <PaginationLink key={number} id={number} onClick={this.handleClickTarik}>
                                {number}
                            </PaginationLink>
                        </PaginationItem>
                    ))}
                </Pagination>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps)(DompetTarik);
