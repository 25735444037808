import React from "react";

const Instagram = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0001 7.20343C8.2798 7.20343 6.87589 8.60734 6.87589 10.3277C6.87589 12.048 8.2798 13.4519 10.0001 13.4519C11.7204 13.4519 13.1243 12.048 13.1243 10.3277C13.1243 8.60734 11.7204 7.20343 10.0001 7.20343ZM19.3704 10.3277C19.3704 9.0339 19.3821 7.75187 19.3095 6.46046C19.2368 4.96046 18.8946 3.62921 17.7978 2.53234C16.6985 1.43312 15.3696 1.09328 13.8696 1.02062C12.5759 0.947963 11.2939 0.959682 10.0025 0.959682C8.7087 0.959682 7.42667 0.947963 6.13527 1.02062C4.63527 1.09328 3.30402 1.43546 2.20714 2.53234C1.10792 3.63156 0.76808 4.96046 0.695424 6.46046C0.622767 7.75421 0.634486 9.03624 0.634486 10.3277C0.634486 11.6191 0.622767 12.9034 0.695424 14.1948C0.76808 15.6948 1.11027 17.0261 2.20714 18.123C3.30636 19.2222 4.63527 19.562 6.13527 19.6347C7.42902 19.7073 8.71105 19.6956 10.0025 19.6956C11.2962 19.6956 12.5782 19.7073 13.8696 19.6347C15.3696 19.562 16.7009 19.2198 17.7978 18.123C18.897 17.0237 19.2368 15.6948 19.3095 14.1948C19.3845 12.9034 19.3704 11.6214 19.3704 10.3277ZM10.0001 15.1347C7.33995 15.1347 5.19308 12.9878 5.19308 10.3277C5.19308 7.66749 7.33995 5.52062 10.0001 5.52062C12.6603 5.52062 14.8071 7.66749 14.8071 10.3277C14.8071 12.9878 12.6603 15.1347 10.0001 15.1347ZM15.004 6.4464C14.3829 6.4464 13.8814 5.94484 13.8814 5.32374C13.8814 4.70265 14.3829 4.20109 15.004 4.20109C15.6251 4.20109 16.1267 4.70265 16.1267 5.32374C16.1269 5.47123 16.0979 5.61729 16.0416 5.75358C15.9852 5.88987 15.9026 6.01371 15.7983 6.11799C15.694 6.22228 15.5701 6.30497 15.4339 6.36132C15.2976 6.41767 15.1515 6.44659 15.004 6.4464Z"
                fill="white"
            />
        </svg>
    );
};

export default Instagram;
