import React, { Component } from "react";
import { TabContent, TabPane, Form, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { LoginUser } from "../../../../redux/action/User";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import "../../../../sass/loader.css";
import axios from "axios";
import { InputIcon } from "../../../../library/components/Components";
import { validate } from "../../../../library/validation/validate";
import { encryptedData } from "../../../../library/helper/Crypto";
// import './LoginContent.scss'
const url = process.env.REACT_APP_BASSE_API;

class LoginContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errEmail: "",
      errPassword: "",
      hidden: true
    };
    this.showHide = this.showHide.bind(this);
  }

  showHide() {
    this.setState({ hidden: !this.state.hidden });
  }

  handleChange = (e, type, changeError) => {
    // console.log(e.target.name)
    let value = e.target.value;
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState({
        [changeError]: validate(type, value, this.state.password, this.state.passwordConfirm)
      });
    });
  };

  handleLoginLender = async () => {
    if (this.state.errEmail === 0 && this.state.errPassword === 0) {
      await this.props
        .dispatch(
          LoginUser({
            email: encryptedData(this.state.email),
            password: encryptedData(this.state.password),
            kode_pengguna: encryptedData("1")
          })
        )
        .then((res) => {
          if (res.value.data.data.kode_pengguna[0] === 1 || res.value.data.data.kode_pengguna[1] === 1) {
            this.props.history.push("/lender/dasbor");
            window.location.reload();
          }
        })
        .catch((err) => {
          // console.log(err)
          // console.log(err.response)
          if (err.response.status === 404) {
            Swal.fire({
              title: "Tidak Dapat Masuk",
              text: `${err.response.data.message}`,
              icon: "warning",
              button: "ok"
            });
          } else if (err.response.status === 401) {
            Swal.fire({
              title: "Tidak Dapat Masuk",
              text: `${err.response.data.message.message}`,
              icon: "warning",
              button: "ok"
            }).then(() => {
              this.props.history.push("/emailverif/1");
              window.location.reload();
            });
          } else if (err.response.status === 400) {
            if (err.response.data.message.borrowerLogin === true) {
              localStorage.removeItem("jwtToken");
              localStorage.removeItem("email");
              localStorage.removeItem("no_handphone");
              localStorage.removeItem("namaUser");
              localStorage.removeItem("verif");
              Swal.fire({
                title: "Login Gagal",
                text: `${err.response.data.message.message}`,
                icon: "warning",
                button: "ok"
              });
            } else if (err.response.data.message.inputPassword === true) {
              Swal.fire({
                title: "Login Gagal",
                text: `${err.response.data.message.message}`,
                icon: "warning",
                button: "ok"
              }).then(() => {
                window.location.href = "/new-password";
              });
            } else if (err.response.data.message.retryLogin === true) {
              //setelah percobaan 5 kali
              if (err.response.data.message.nextAttempt !== undefined) {
                const now = new Date().getTime();
                const nextAttempt = new Date(err.response.data.message.nextAttempt).getTime();
                var diffTime = nextAttempt - now;
                //hitung jam
                var hoursDiff = Math.floor(diffTime / (1000 * 3600));
                // hitung menit
                let diffInMilliSeconds = Math.abs(nextAttempt - now) / 1000;
                const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
                diffInMilliSeconds -= minutes * 60;

                var time = hoursDiff + " jam " + minutes + " menit";
                Swal.fire({
                  title: "Gagal login",
                  text: `Anda dapat login kembali setelah ${time}, karena percobaan login gagal selama 5 kali`,
                  icon: "warning",
                  button: "ok"
                });
              } else {
                Swal.fire({
                  title: "Gagal login",
                  text: `${err.response.data.message.message}`,
                  icon: "warning",
                  button: "ok"
                });
              }
            }
          }
        });
    } else {
      Swal.fire({
        title: "Gagal Masuk",
        text: "Mohon untuk mengisi Email dan Password",
        icon: "warning",
        buttons: "ok"
      });
    }
  };
  handleLoginBorrower = async () => {
    if (this.state.errEmail === 0 && this.state.errPassword === 0) {
      await this.props
        .dispatch(
          LoginUser({
            email: encryptedData(this.state.email),
            password: encryptedData(this.state.password),
            kode_pengguna: 2
          })
        )
        .then((res) => {
          if (res.value.data.data.borrower_id.admin_kyc === false) {
            Swal.fire({
              title: "Gagal Masuk",
              text: "Data anda sedang di review oleh tim Indofund. Proses verifikasi membutuhkan 1x24 jam hari kerja",
              icon: "warning",
              buttons: "ok"
            }).then(() => {
              axios.post(
                `${url}/user/logout`,
                { accessToken: localStorage.accessToken },
                {
                  headers: { "x-auth-token": localStorage.jwtToken }
                }
              );
              localStorage.removeItem("id");
              localStorage.removeItem("jwtToken");
              localStorage.removeItem("email");
              localStorage.removeItem("kode_pengguna");
              localStorage.removeItem("nama_pengguna");
              localStorage.removeItem("justOnce");
              localStorage.removeItem("masuk");
              localStorage.removeItem("nama");
              localStorage.removeItem("kyc");
              localStorage.removeItem("no_hp");
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("no_handphone");
              localStorage.removeItem("namaUser");
              window.location.reload();
              window.location.href = "/";
            });
          } else if (res.value.data.data.kode_pengguna.length === 0) {
            this.props.history.push("/pilihuser");
          } else if (
            res.value.data.data.kode_pengguna[0] === 2 ||
            res.value.data.data.kode_pengguna[1] === 2
            // res.value.data.data.kode_pengguna[0] === 2
          ) {
            this.props.history.push("/borrower/dasbor");
            window.location.reload();
          }
          else if (res.value.data.data.kode_pengguna[0] !== 2 ||
            res.value.data.data.kode_pengguna[1] !== 2) {
            Swal.fire({
              title: "Tidak Dapat Masuk",
              text: `Akun belum terdaftar sebagai penerima dana`,
              icon: "warning",
              button: "ok"
            });
          }
        })
        .catch((err) => {
          // console.log(err.response)
          // console.log(err.response.data)

          if (err.response.status === 404) {
            Swal.fire({
              title: "Tidak Dapat Masuk",
              text: `${err.response.data.message}`,
              icon: "warning",
              button: "ok"
            });
          } else if (err.response.status === 401) {
            Swal.fire({
              title: "Tidak dapat masuk",
              text: `${err.response.data.message.message}`,
              icon: "warning",
              button: "ok"
            }).then(() => {
              this.props.history.push("/emailverif/2");
              window.location.reload();
            });
          } else if (err.response.status === 400) {
            //jika sudah punya akun lender tapi login borrower
            if (err.response.data.borrowerRegister === true) {
              Swal.fire({
                title: "Login Gagal",
                text: `${err.response.data.message}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Daftar borrower"
              }).then((result) => {
                if (result.value) {
                  this.props.history.push("/pilihborrower");
                  this.props.toggle();
                }
              });
            } else if (err.response.data.message.inputPassword === true) {
              Swal.fire({
                title: "",
                text: `${err.response.data.message.message}`,
                icon: "warning",
                button: "ok"
              }).then(() => {
                window.location.href = "/new-password";
              });
            } else if (err.response.data.message.continueRegister === true) {
              Swal.fire({
                title: "Mengisi data penerima dana ",
                text: `Anda belum melanjutkan mengisi data borrower. Harap melanjutkan pendaftaran`,
                icon: "warning",
                button: "ok"
              }).then(() => {
                this.props.history.push("/pilihborrower");
                this.props.toggle();
              });
            } else if (err.response.data.message.lenderLogin === true) {
              localStorage.removeItem("jwtToken");
              localStorage.removeItem("email");
              localStorage.removeItem("no_handphone");
              localStorage.removeItem("namaUser");
              localStorage.removeItem("verif");
              Swal.fire({
                title: "Tidak Dapat Login",
                text: `${err.response.data.message.message}`,
                icon: "warning",
                button: "ok"
              });
            } else if (err.response.data.message.retryLogin === true) {
              //setelah percobaan 5 kali
              if (err.response.data.message.nextAttempt !== undefined) {
                const now = new Date().getTime();
                const nextAttempt = new Date(err.response.data.message.nextAttempt).getTime();
                var diffTime = nextAttempt - now;
                //hitung jam
                var hoursDiff = Math.floor(diffTime / (1000 * 3600));
                // hitung menit
                let diffInMilliSeconds = Math.abs(nextAttempt - now) / 1000;
                const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
                diffInMilliSeconds -= minutes * 60;

                var time = hoursDiff + " jam " + minutes + " menit";
                Swal.fire({
                  title: "Gagal login",
                  text: `Anda dapat login kembali setelah ${time}, karena percobaan login gagal selama 5 kali`,
                  icon: "warning",
                  button: "ok"
                });
              } else {
                Swal.fire({
                  title: "Gagal login",
                  text: `${err.response.data.message.message}`,
                  icon: "warning",
                  button: "ok"
                });
              }
            }
          }
        });
    } else {
      Swal.fire({
        title: "Gagal Masuk",
        text: "Mohon untuk mengisi Email dan Password",
        icon: "warning",
        buttons: "ok"
      });
    }
  };
  onKeyPressLender = (e) => {
    if (e.which === 13) {
      this.handleLoginLender();
    }
  };
  onKeyPressBorrower = (e) => {
    if (e.which === 13) {
      this.handleLoginBorrower();
    }
  };

  render() {
    // console.log(this.props.activeTab)

    return (
      <div>
        <div className="row py-4">
          <div className="col-12">
            <TabContent activeTab={this.props.activeTab}>
              <TabPane tabId="1">
                <Form>
                  <InputIcon
                    forLabel={"email"}
                    label={"Email"}
                    icon={<i className="material-icons-outlined">mail</i>}
                    type={"email"}
                    name={"email"}
                    onChange={(e) => this.handleChange(e, "email", "errEmail")}
                    placeholder={"Contoh: example@email.com"}
                    maxLength={""}
                    error={this.state.errEmail}
                    onKeyPress={this.onKeyPressLender}
                  />
                  <InputIcon
                    forLabel={"password"}
                    label={"Kata Sandi"}
                    icon={<i className="material-icons-outlined">lock</i>}
                    type={this.state.hidden ? "password" : "text"}
                    name={"password"}
                    onChange={(e) => this.handleChange(e, "passwordLogin", "errPassword")}
                    placeholder={"Kata sandi Anda"}
                    error={this.state.errPassword}
                    onKeyPress={this.onKeyPressLender}
                    className={"password-form"}
                    password={true}
                    clickShow={this.showHide}
                    hidden={this.state.hidden}
                  />
                  <div className="text-right" style={{ marginBottom: "40px" }}>
                    <Link to="/forgot-password" onClick={this.props.toggle}>
                      Lupa Kata Sandi?
                    </Link>
                  </div>
                  {this.props.user.isLoadingLogin ? (
                    <Button
                      color="primary"
                      className="btn btn-primary btn-block btn-lg"
                      disabled
                      onClick={this.handleLoginLender}>
                      <span className="loader"></span>
                    </Button>
                  ) : (
                    <Button color="primary" className="btn btn-primary btn-block btn-lg" onClick={this.handleLoginLender}>
                      Masuk sebagai pemberi dana
                    </Button>
                  )}
                </Form>
              </TabPane>
              <TabPane tabId="2">
                <Form>
                  <InputIcon
                    forLabel={"email"}
                    label={"Email"}
                    icon={<i className="material-icons-outlined">mail</i>}
                    type={"email"}
                    name={"email"}
                    onChange={(e) => this.handleChange(e, "email", "errEmail")}
                    placeholder={"Contoh: example@email.com"}
                    error={this.state.errEmail}
                    onKeyPress={this.onKeyPressBorrower}
                  />
                  <InputIcon
                    forLabel={"password"}
                    label={"Kata Sandi"}
                    icon={<i className="material-icons-outlined">lock</i>}
                    type={this.state.hidden ? "password" : "text"}
                    name={"password"}
                    onChange={(e) => this.handleChange(e, "passwordLogin", "errPassword")}
                    placeholder={"Kata sandi Anda"}
                    error={this.state.errPassword}
                    onKeyPress={this.onKeyPressBorrower}
                    className={"password-form"}
                    password={true}
                    clickShow={this.showHide}
                    hidden={this.state.hidden}
                  />

                  <div className="text-right" style={{ marginBottom: "40px" }}>
                    <Link to="/forgot-password" onClick={this.props.toggle}>
                      Lupa Kata Sandi?
                    </Link>
                  </div>
                  {this.props.user.isLoadingLogin ? (
                    <Button
                      disabled
                      color="primary"
                      className="btn btn-primary btn-block btn-lg"
                      onClick={this.handleLoginBorrower}>
                      <span className="loader"></span>
                    </Button>
                  ) : (
                    <Button color="primary" className="btn btn-primary btn-block btn-lg" onClick={this.handleLoginBorrower}>
                      Masuk sebagai penerima dana
                    </Button>
                  )}
                </Form>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};
export default withRouter(connect(mapStateToProps)(LoginContent));
