import React, { Component } from "react";
const queryString = require("query-string");
const axios = require("axios").default;
const url = process.env.REACT_APP_BASSE_API;
class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: []
        };
    }
    async componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        const { aktivasi } = values;
        const data = await axios.post(`${url}/user/verification`, { token: aktivasi });

        // console.log(data.data)
        if (data.data.status === 200) {
            this.props.history.push("/success-email"); //success
        } else {
            this.props.history.push("/failed-email"); //failed normal
        }
    }
    render() {
        return <div className="app flex-row align-items-center"></div>;
    }
}

export default Register;
