import React, { Component } from "react";
import "./blog.scss";
import Main from "./BlogMain";
import { CategoryBlog } from "../../../redux/action/Blog";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: []
        };
    }
    componentDidMount = async () => {
        await this.props.dispatch(CategoryBlog());
        this.setState({
            category: this.props.blog.listCategory
        });
    };

    listCategory() {
        const hasil = this.state.category.map((val) => {
            return (
                <li className="menu" key={val._id}>
                    <Link to={"/blog-category/" + val.name}>{val.name}</Link>
                </li>
            );
        });
        return hasil;
    }
    render() {
        return (
            <section className="section-blog with-pad">
                <div className="container">
                    <div className="blog-header">
                        <h2>Blog</h2>
                    </div>
                    <div className="row justify-content-center floating-card category-card">
                        <div className="col-12 col-md-8 col-lg-8 col-xl-8 mb-4 mb-md-0">
                            <div className="card border-0 shadow">
                                <div className="card-body text-center">
                                    <ul className="category-menu">
                                        <li className="menu">
                                            <Link to={"/blog"}>Semua Artikel</Link>
                                        </li>
                                        {this.listCategory()}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Main />
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        blog: state.blog
    };
};
export default connect(mapStateToProps)(Blog);
