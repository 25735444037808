import React, { Component } from "react";
import { Button, TabPane } from "reactstrap";
import { connect } from "react-redux";
import { SendID, customerInquiry } from "../../../../redux/action/User";
import Accordion from "./Accordion";
import { NavLink } from "react-router-dom";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Swal from "sweetalert2";

class PilihToup extends Component {
    //MAYBANK ENGGA DI INPUT
    //KE HANA GA ADA MOBILE BANKING
    //DANAMON ENGGA ADA INTERNET BANKING
    constructor(props) {
        super(props);
        this.state = {
            nilai_transaksi: "",
            fee: "",
            total_nilai_transaksi: "",
            saldo: "",
            va: "",
            tipe: "",
            nicepay_register: "",
            isOpen: false,
            setIsOpen: false,
            button: false,
            errNominal: "",
            showVAl: false
        };
    }
    toggle() {
        this.setState({
            setIsOpen: !this.state.setIsOpen,
            isOpen: !this.state.isOpen
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.nicepay();
        // console.log(this.props.va);
    }
    onClickCopyHandler(text) {
        navigator.clipboard.writeText(`${text}`)

        Swal.fire({
            title: "",
            text: `Nomor VA Berhasil Di Copy`,
            showCancelButton: false,
            showConfirmButton: false,
            icon: "success",
            timer:2000,
        });
    }

    async nicepay() {
        const regist = this.props.nicepay_register;
        if (regist === false) {
            await this.props.dispatch(SendID());
        } else {
            await this.props.dispatch(customerInquiry());
        }
        // console.log(this.props.user.NicepayVA)
    }

    render() {
        const tampil = () => {
            if (this.props.user.NicepayVA.vacctInfoList === undefined) {
            } else {
                const hasil = this.props.user.NicepayVA.vacctInfoList.map((val, index) => {
                    // console.log(val)
                    const nama =
                        val.bankCd === "BMRI"
                            ? "Bank Mandiri"
                            : val.bankCd === "BDIN"
                                ? "Bank Danamon Indonesia"
                                : val.bankCd === "BNIA"
                                    ? "Bank CIMB Niaga"
                                    : val.bankCd === "BNIN"
                                        ? "BNI"
                                        : val.bankCd === "IBBK"
                                            ? "BII"
                                            : val.bankCd === "BBBA"
                                                ? "Bank Permata"
                                                : val.bankCd === "BRIN"
                                                    ? "BRI"
                                                    : val.bankCd === "HNBN"
                                                        ? "Bank Hana"
                                                        : "";
                    return (
                        <div key={index}>
                            <TabPane tabId="1" className="mb-5">
                                <Accordion
                                    panel="panel1"
                                    title={nama}
                                    nama_bank={nama}
                                    body={
                                        <div style={{ width: "100%" }}>
                                            <h6 className="text-center">{nama} Nomor Virtual dd</h6>
                                            <div className="d-flex flex-col gap-2">
                                                <h4 className="text-center text-primary" style={{ fontWeight: 700 }}>
                                                    {val.vacctNo}
                                                </h4>
                                                <Button
                                                    disabled={this.state.disabled}
                                                    color="primary"
                                                    onClick={()=>this.onClickCopyHandler(val.vacctNo)}
                                                    className="btn btn-lg col-12 col-md-6">
                                                    Copy
                                                </Button>
                                            </div>
                                            <hr />
                                            <p className="text-secondary text-center"> Biaya Admin Rp. 3.330</p>
                                            <p className="text-secondary text-center"> Minimal transfer Rp. 50.000</p>
                                        </div>
                                    }
                                    panels="panel1-1"
                                    title1="ATM"
                                    body1={
                                        <div>
                                            {val.bankCd === "" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Input kartu <b>ATM</b> dan <b>PIN</b> Anda BCA
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Transaksi Lainnya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Transfer</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Ke rekening BCA Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Benar</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Ya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Ambil <b>bukti bayar Anda</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "IBBK" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Input kartu <b>ATM</b> dan <b>PIN</b> Anda
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Pembayaran/Top Up Pulsa</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input Nomor Virtual Account <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Benar</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Ya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Ambil <b>bukti bayar Anda</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BMRI" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Input kartu <b>ATM</b> dan <b>PIN</b> Anda MANDIRI
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Bayar/Beli</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Lainnya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Multi Payment</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>88049</b> sebagai <b>Kode Institusi</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Benar</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukan jumlah <b>nominal</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Lanjut</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Ambil bukti bayar Anda</p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BNIN" ? (
                                                <ol>
                                                    <li>
                                                        <p>Masukkan Kartu Anda. BNI</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Bahasa.</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>PIN ATM</b> Anda.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>"Menu Lainnya"</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>"Transfer"</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Jenis rekening</b> yang akan Anda gunakan (Contoh; "Dari Rekening Tabungan").
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>“Virtual Account Billing”</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>{val.vacctNo}</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Konfirmasi</b>, apabila telah sesuai, lanjutkan transaksi.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Transaksi Anda telah selesai.</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BBBA" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Input kartu <b>ATM</b> dan <b>PIN</b> Anda PERMATA
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Transaksi Lainnya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Pembayaran</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Pembayaran Lain-lain</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Select <b>Benar</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Select <b>Ya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Ambil <b>bukti bayar</b> anda
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "HNBN" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Input kartu <b>ATM</b> dan <b>PIN</b> Anda KEB HANA
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Pembayaran</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Lainnya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Benar</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Ya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Ambil <b>bukti bayar</b> Anda
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Input kartu <b>ATM</b> dan <b>PIN</b> Anda BRI
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Transaksi Lain</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Pembayaran</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Lain-lain</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>BRIVA</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Ya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Ambil <b>bukti bayar</b> Anda
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BNIA" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Input kartu <b>ATM</b> dan <b>PIN</b> Anda
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Pembayaran</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Lanjut</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Proses</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Data Virtual Account</b>akan ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Proses</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Ambil <b>bukti bayar</b> Anda
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Input <b>PIN ATM</b> Anda DANAMON
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Pembayaran</b> <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukan nomor <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>Nominal</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pada layar konfirmasi pembayaran, pastikan transaksi sudah benar -> pilih <b>Ya</b> untuk
                                                            memproses transaksi
                                                        </p>
                                                    </li>
                                                </ol>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    }
                                    panels="panel1-2"
                                    title2="Mobile Banking "
                                    body2={
                                        <div>
                                            {val.bankCd === "" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Mobile Banking BCA</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>m-Transfer</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>BCA Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Send</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Informasi Virtual Account</b> akan ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>OK</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>PIN Mobile Banking</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BMRI" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Mobile Banking MANDIRI</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Bayar</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Buat Pembayaran Baru</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Multi Payment</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>NICEPAY</b> sebagai penyedia jasa
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Tambah Sebagai Nomor Baru</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Lanjut</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Konfirmasi</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>Nominal</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Lanjut</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input<b>MPIN</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BNIN" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Akses BNI Mobile Banking dari handphone kemudian masukkan <b>user ID</b> dan{" "}
                                                            <b>password.</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih menu <b>“Transfer”</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih menu <b>“Virtual Account Billing”</b> kemudian pilih rekening debet.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>{val.vacctNo}</b> pada menu <b>“input baru”</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.</p>
                                                    </li>
                                                    <li>
                                                        <p>Konfirmasi transaksi dan masukkan Password Transaksi.</p>
                                                    </li>
                                                    <li>
                                                        <p>Pembayaran Anda Telah Berhasil.</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BBBA" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Mobile Banking PERMATA</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Pembayaran Tagihan</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>Nominal</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Kirim</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>Token</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Kirim</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> akan ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>BRI Mobile </b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Mobile Banking BRI</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Pembayaran</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>BRIVA</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>Nomor Virtual Account</b>, misal. 88788XXXXXXXXXXX
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Kirim</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>PIN Mobile</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Kirim</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> akan dikirim melalui sms
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BNIA" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Go Mobile</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Transfer</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Transfer ke CIMB Niaga Lain</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Pilih Sumber Dana yang akan digunakan</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>Nominal</b> misal. 10000
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Lanjut</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Data Virtual Account akan ditampilkan</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>PIN Mobile</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Konfirmasi</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> akan dikirim melalui sms
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "CENA" ? (
                                                <ol>
                                                    <li>
                                                        <p>Login pada Aplikasi D-Mobile DANAMON</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih menu <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukan <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukan <b>Nominal</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pada layar konfirmasi pembayaran, pastikan transaksi sudah benar -> pilih <b>Ya</b> untuk
                                                            memproses transaksi.
                                                        </p>
                                                    </li>
                                                </ol>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    }
                                    panels="panel1-3"
                                    title3="Internet Banking "
                                    body3={
                                        <div>
                                            {val.bankCd === "" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Internet Banking BCA</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Transaksi Dana</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Transfer Ke BCA Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            {" "}
                                                            Klik <b>Lanjutkan</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>Respon KeyBCA Appli 1</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Kirim</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar ditampilkan</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "IBBK" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Internet Banking</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Rekening dan Transaksi</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Maybank Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Sumber Tabungan</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Input Nominal </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Submit</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>SMS Token</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BMRI" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Internet Banking MANDIRI</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Pembayaran</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Multi Payment</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>NICEPAY</b> sebagai <b>Penyedia Jasa</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Tambah Sebagai Nomor Baru</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>Nominal</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Ceklist</b> pada kolom jumlah
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Konfirmasi</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>Kode Challenge</b> yang dikirim ke ponsel anda ke dalam "Token"
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>OK</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BNIN" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Ketik alamat <b>https://ibank.bni.co.id</b> kemudian klik <b>“Enter”</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>User ID</b> dan <b>Password.</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih menu <b>“Transfer”</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>“Virtual Account Billing”</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Kemudian masukan <b>{val.vacctNo}</b>. Lalu pilih rekening debet yang akan digunakan.
                                                            Kemudian tekan <b>‘’lanjut’’.</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Kemudin tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>Kode Otentikasi Token</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Pembayaran Anda telah berhasil.</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BBBA" ? (
                                                <ol>
                                                    <li>
                                                        <p>Login Internet Banking PERMATA</p>
                                                    </li>
                                                    <li>
                                                        <p>Pilih Pembayaran Tagihan</p>
                                                    </li>
                                                    <li>
                                                        <p>Pilih Virtual Account</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Input Nominal misal. 10000</p>
                                                    </li>
                                                    <li>
                                                        <p>Klik Kirim</p>
                                                    </li>
                                                    <li>
                                                        <p>Input Token</p>
                                                    </li>
                                                    <li>
                                                        <p>Klik Kirim</p>
                                                    </li>
                                                    <li>
                                                        <p>Bukti bayar akan ditampilkan</p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "HNBN" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Internet Banking KEB HANA</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih menu <b>Transfer</b> kemudian Pilih <b>Withdrawal Account Information</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih<b> Account Number anda</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>Nominal</b>, misal. 10000
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Click <b>Submit</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>SMS Pin</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> akan ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Internet Banking BRI</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Pembayaran</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>BRIVA</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Klik Kirim</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>Password</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>mToken</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Kirim</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> akan ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BNIA" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Internet Banking</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Bayar Tagihan</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Rekening Sumber - Pilih yang akan Anda digunakan</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Jenis Pembayaran - Pilih <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Untuk Pembayaran - Pilih <b>Masukkan {val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Isi <b>Remark</b> Jika diperlukan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Lanjut</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Data Virtual Accoun</b>t akan ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>mPIN</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Kirim</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> akan ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                    <b></b>
                                                </ol>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    }
                                />
                            </TabPane>
                        </div>
                    );
                });
                // console.log(hasil)
                return (
                    <div>
                        <div className="mt-5 mb-5">
                            <p className="text-secondary mb-0 text-center" style={{ padding: "4px 4px 0 0" }}>
                                Sekarang Top Up lebih mudah dengan cara berikut :{" "}
                            </p>
                        </div>
                        <div>
                            <TabPane tabId="2" className="mb-5">
                                <Accordion
                                    panel="panel7"
                                    title="Bank BCA"
                                    nama_bank="BCA"
                                    body={
                                        <div style={{ width: "100%" }}>
                                            <h6 className="text-center">Bank BCA Nomor Virtual Akun</h6>
                                            <div className="flex-row ">
                                                <h4 className="text-center text-primary" style={{ fontWeight: 700 }}>
                                                    {"11324 " + " " + this.props.va}
                                                </h4>
                                                <Button
                                                    disabled={this.state.disabled}
                                                    color="primary"
                                                    onClick={() => this.onClickCopyHandler(`11324${this.props.va}`)}
                                                    className="btn btn-lg col-12 col-md-6">
                                                    Copy
                                                </Button>
                                            </div>
                                            <hr />
                                            <p className="text-secondary text-center"> Biaya Admin Rp. 3.000</p>
                                            <p className="text-secondary text-center"> Minimal transfer Rp. 10.000</p>
                                        </div>
                                    }
                                    panels="panel1-1"
                                    title1="ATM"
                                    body1={
                                        <div>
                                            <ol>
                                                <li>
                                                    <p>Masukkan kartu ATM dan PIN BCA anda</p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Pilih menu <b>TRANSAKSI LAINNYA</b> > <b>TRANSFER</b> > <b>KE REKENING BCA VIRTUAL ACCOUNT</b>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Masukkan <b> {"11324" + this.props.va} </b> sebagai rekening tujuan
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Masukkan jumlah transfer sesuai detail transaksi + <b> Rp. 3000</b>.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>Ikuti instruksi untuk menyelesaikan transaksi</p>
                                                </li>
                                            </ol>
                                        </div>
                                    }
                                    panels="panel1-2"
                                    title2="KLIK BCA"
                                    body2={
                                        <div>
                                            <ol>
                                                <li>
                                                    <p>
                                                        Masuk ke website <a href="https://www.klikbca.com/">KLIK BCA</a>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Pilih menu <b>TRANSFER DANA</b> > <b>TRANSFER KE BCA VIRTUAL ACCOUNT</b>{" "}
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Masukkan <b> {"11324" + this.props.va} </b> sebagai rekening tujuan
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Masukkan jumlah transfer sesuai detail transaksi + <b> Rp. 3000</b>.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>Ikuti instruksi untuk menyelesaikan transaksi</p>
                                                </li>
                                            </ol>
                                        </div>
                                    }
                                    panels="panel1-3"
                                    title3="m-BCA (BCA MOBILE)"
                                    body3={
                                        <div>
                                            <ol>
                                                <li>
                                                    <p>
                                                        Masuk ke aplikasi mobile <b>m-BCA</b>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Pilih menu <b>M-TRANSFER</b> > <b>BCA VIRTUAL ACCOUNT</b>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Masukkan <b> {"11324" + this.props.va} </b> sebagai rekening tujuan
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Masukkan jumlah transfer sesuai detail transaksi + <b> Rp. 3000</b>.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Masukkan <b>PIN m-BCA Anda</b>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>Ikuti instruksi untuk menyelesaikan transaksi</p>
                                                </li>
                                            </ol>
                                        </div>
                                    }
                                />
                            </TabPane>
                            {hasil}
                        </div>
                    </div>
                );
            }

            if (this.props.user.NicepayCI.vacctInfoList === undefined) {
                // console.log(this.state.jenis_kelaminList)
            } else {
                const hasil = this.props.user.NicepayCI.vacctInfoList.map((val, index) => {
                    const nama =
                        val.bankCd === "BMRI"
                            ? "Bank Mandiri"
                            : val.bankCd === "BDIN"
                                ? "Bank Danamon Indonesia"
                                : val.bankCd === "BNIA"
                                    ? "Bank CIMB Niaga"
                                    : val.bankCd === "BNIN"
                                        ? "BNI"
                                        : val.bankCd === "IBBK"
                                            ? "BII"
                                            : val.bankCd === "BBBA"
                                                ? "Bank Permata"
                                                : val.bankCd === "BRIN"
                                                    ? "BRI"
                                                    : val.bankCd === "HNBN"
                                                        ? "Bank Hana"
                                                        : "";
                    return (
                        <div key={index}>
                            <TabPane tabId="1" className="mb-5">
                                <Accordion
                                    panel="panel1"
                                    title={nama}
                                    nama_bank={nama}
                                    body={
                                        <div style={{ width: "100%" }}>
                                            <h6 className="text-center">{nama} Nomor Virtual Akun</h6>
                                            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                                <h4 className="text-center text-primary" style={{ fontWeight: 700 }}>
                                                    {val.vacctNo}
                                                </h4>
                                                <Button
                                                    disabled={this.state.disabled}
                                                    color="primary"

                                                    style={{ paddingInline: '16px', paddingBlock: '6px', fontSize: '0.875rem' }}
                                                    onClick={() => {
                                                        this.onClickCopyHandler(val.vacctNo)
                                                    }}
                                                    className="btn btn-lg d-flex gap-1 justify-content-center align-items-center">
                                                    <FileCopyIcon style={{ width: '16px', height: '16px' }} />
                                                    Copy
                                                </Button>
                                            </div>
                                            <hr />
                                            <p className="text-secondary text-center"> Biaya Admin Rp. 3.330</p>
                                            <p className="text-secondary text-center"> Minimal transfer Rp. 50.000</p>
                                        </div>
                                    }
                                    panels="panel1-1"
                                    title1="ATM"
                                    body1={
                                        <div>
                                            {val.bankCd === "IBBK" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Input kartu <b>ATM</b> dan <b>PIN</b> Anda
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Pembayaran/Top Up Pulsa</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input Nomor Virtual Account <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Benar</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Ya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Ambil <b>bukti bayar Anda</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BMRI" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Input kartu <b>ATM</b> dan <b>PIN</b> Anda MANDIRI
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Bayar/Beli</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Lainnya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Multi Payment</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>88049</b> sebagai <b>Kode Institusi</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Benar</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukan jumlah <b>nominal</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Lanjut</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Ambil bukti bayar Anda</p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BNIN" ? (
                                                <ol>
                                                    <li>
                                                        <p>Masukkan Kartu Anda. BNI</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Bahasa.</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>PIN ATM</b> Anda.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>"Menu Lainnya"</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>"Transfer"</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Jenis rekening</b> yang akan Anda gunakan (Contoh; "Dari Rekening Tabungan").
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>“Virtual Account Billing”</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>{val.vacctNo}</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Konfirmasi</b>, apabila telah sesuai, lanjutkan transaksi.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Transaksi Anda telah selesai.</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BBBA" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Input kartu <b>ATM</b> dan <b>PIN</b> Anda PERMATA
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Transaksi Lainnya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Pembayaran</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Pembayaran Lain-lain</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Select <b>Benar</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Select <b>Ya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Ambil <b>bukti bayar</b> anda
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "HNBN" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Input kartu <b>ATM</b> dan <b>PIN</b> Anda KEB HANA
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Pembayaran</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Lainnya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Benar</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Ya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Ambil <b>bukti bayar</b> Anda
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BRIN" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Input kartu <b>ATM</b> dan <b>PIN</b> Anda BRI
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Transaksi Lain</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Pembayaran</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Lain-lain</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>BRIVA</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Ya</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Ambil <b>bukti bayar</b> Anda
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BNIA" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Input kartu <b>ATM</b> dan <b>PIN</b> Anda
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Pembayaran</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Lanjut</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Proses</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Data Virtual Account</b>akan ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Proses</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Ambil <b>bukti bayar</b> Anda
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BDIN" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Input <b>PIN ATM</b> Anda DANAMON
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Pembayaran</b> >>> <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukan nomor <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>Nominal</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pada layar konfirmasi pembayaran, pastikan transaksi sudah benar -> pilih <b>Ya</b> untuk
                                                            memproses transaksi
                                                        </p>
                                                    </li>
                                                </ol>
                                            ) : (
                                                "Maaf belum tersedia, pilih cara lainnya"
                                            )}
                                        </div>
                                    }
                                    panels="panel1-2"
                                    title2="Mobile Banking "
                                    body2={
                                        <div>
                                            {val.bankCd === "BMRI" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Mobile Banking MANDIRI</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Bayar</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Buat Pembayaran Baru</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Multi Payment</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>NICEPAY</b> sebagai penyedia jasa
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Tambah Sebagai Nomor Baru</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Lanjut</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Konfirmasi</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>Nominal</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Lanjut</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input<b>MPIN</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BNIN" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Akses BNI Mobile Banking dari handphone kemudian masukkan <b>user ID</b> dan{" "}
                                                            <b>password.</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih menu <b>“Transfer”</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih menu <b>“Virtual Account Billing”</b> kemudian pilih rekening debet.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>{val.vacctNo}</b> pada menu <b>“input baru”</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.</p>
                                                    </li>
                                                    <li>
                                                        <p>Konfirmasi transaksi dan masukkan Password Transaksi.</p>
                                                    </li>
                                                    <li>
                                                        <p>Pembayaran Anda Telah Berhasil.</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BBBA" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Mobile Banking PERMATA</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Pembayaran Tagihan</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>Nominal</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Kirim</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>Token</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Kirim</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> akan ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BRIN" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>BRI Mobile </b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Mobile Banking BRI</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Pembayaran</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>BRIVA</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Masukkan Nominal </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Kirim</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>PIN Mobile</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Kirim</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> akan dikirim melalui sms
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BNIA" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Go Mobile</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Transfer</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih Menu <b>Transfer ke CIMB Niaga Lain</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Pilih Sumber Dana yang akan digunakan</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>Nominal</b> misal. 10000
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Lanjut</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Data Virtual Account akan ditampilkan</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>PIN Mobile</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Konfirmasi</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> akan dikirim melalui sms
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BDIN" ? (
                                                <ol>
                                                    <li>
                                                        <p>Login pada Aplikasi D-Mobile DANAMON</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih menu <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukan <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukan <b>Nominal</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pada layar konfirmasi pembayaran, pastikan transaksi sudah benar -> pilih <b>Ya</b> untuk
                                                            memproses transaksi.
                                                        </p>
                                                    </li>
                                                </ol>
                                            ) : (
                                                "Maaf belum tersedia, pilih cara lainnya"
                                            )}
                                        </div>
                                    }
                                    panels="panel1-3"
                                    title3="Internet Banking "
                                    body3={
                                        <div>
                                            {val.bankCd === "IBBK" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Internet Banking</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Rekening dan Transaksi</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Maybank Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Sumber Tabungan</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Input Nominal </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Submit</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>SMS Token</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BMRI" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Internet Banking MANDIRI</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Pembayaran</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Multi Payment</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>NICEPAY</b> sebagai <b>Penyedia Jasa</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Tambah Sebagai Nomor Baru</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>Nominal</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Ceklist</b> pada kolom jumlah
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Konfirmasi</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>Kode Challenge</b> yang dikirim ke ponsel anda ke dalam "Token"
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>OK</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BNIN" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Ketik alamat <b>https://ibank.bni.co.id</b> kemudian klik <b>“Enter”</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>User ID</b> dan <b>Password.</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih menu <b>“Transfer”</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>“Virtual Account Billing”</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Kemudian masukan <b>{val.vacctNo}</b>. Lalu pilih rekening debet yang akan digunakan.
                                                            Kemudian tekan <b>‘’lanjut’’.</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Kemudin tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>Kode Otentikasi Token</b>.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Pembayaran Anda telah berhasil.</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BBBA" ? (
                                                <ol>
                                                    <li>
                                                        <p>Login Internet Banking PERMATA</p>
                                                    </li>
                                                    <li>
                                                        <p>Pilih Pembayaran Tagihan</p>
                                                    </li>
                                                    <li>
                                                        <p>Pilih Virtual Account</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Input Nominal misal. 10000</p>
                                                    </li>
                                                    <li>
                                                        <p>Klik Kirim</p>
                                                    </li>
                                                    <li>
                                                        <p>Input Token</p>
                                                    </li>
                                                    <li>
                                                        <p>Klik Kirim</p>
                                                    </li>
                                                    <li>
                                                        <p>Bukti bayar akan ditampilkan</p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "HNBN" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Internet Banking KEB HANA</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih menu <b>Transfer</b> kemudian Pilih <b>Withdrawal Account Information</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih<b> Account Number anda</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>Nominal</b>, misal. 10000
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Click <b>Submit</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Input <b>SMS Pin</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> akan ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BRIN" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Internet Banking BRI</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Pembayaran</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>BRIVA</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>{val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik<b> Kirim</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>Password</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>mToken</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Kirim</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> akan ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                </ol>
                                            ) : val.bankCd === "BNIA" ? (
                                                <ol>
                                                    <li>
                                                        <p>
                                                            Login <b>Internet Banking</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Pilih <b>Bayar Tagihan</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Rekening Sumber - Pilih yang akan Anda digunakan</p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Jenis Pembayaran - Pilih <b>Virtual Account</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Untuk Pembayaran - Pilih <b>Masukkan {val.vacctNo}</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Isi <b>Remark</b> Jika diperlukan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Lanjut</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Data Virtual Accoun</b>t akan ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Masukkan <b>mPIN</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            Klik <b>Kirim</b>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <b>Bukti bayar</b> akan ditampilkan
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>Selesai</p>
                                                    </li>
                                                    <b></b>
                                                </ol>
                                            ) : (
                                                "Maaf belum tersedia, pilih cara lainnya"
                                            )}
                                        </div>
                                    }
                                />
                            </TabPane>
                        </div>
                    );
                });

                // console.log(hasil)
                return (
                    <div>
                        <div className="mt-5 mb-5">
                            <p className="text-secondary mb-0 text-center" style={{ padding: "4px 4px 0 0" }}>
                                Sekarang Top Up lebih mudah dengan cara berikut :{" "}
                            </p>
                        </div>
                        <div>
                            <TabPane tabId="2" className="mb-5">
                                <Accordion
                                    panel="panel7"
                                    title="Bank BCA"
                                    nama_bank="BCA"
                                    body={
                                        <div style={{ width: "100%" }}>
                                            <h6 className="text-center">Bank BCA Nomor Virtual Akun</h6>
                                            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                                <h4 className="text-center text-primary" style={{ fontWeight: 700, margin: 0 }}>
                                                    {"11324 " + " " + this.props.va}
                                                </h4>
                                                <Button
                                                    disabled={this.state.disabled}
                                                    color="primary"

                                                    style={{ paddingInline: '16px', paddingBlock: '6px', fontSize: '0.875rem' }}
                                                    onClick={() => {
                                                        this.onClickCopyHandler(`11324${this.props.va}`)
                                                    }}
                                                    className="btn btn-lg d-flex gap-1 justify-content-center align-items-center">
                                                    <FileCopyIcon style={{ width: '16px', height: '16px' }} />
                                                    Copy
                                                </Button>
                                            </div>
                                            <hr />
                                            <p className="text-secondary text-center"> Biaya Admin Rp. 3.000</p>
                                            <p className="text-secondary text-center"> Minimal transfer Rp. 10.000</p>
                                        </div>
                                    }
                                    panels="panel1-1"
                                    title1="ATM"
                                    body1={
                                        <div>
                                            <ol>
                                                <li>
                                                    <p>Masukkan kartu ATM dan PIN BCA anda</p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Pilih menu <b>TRANSAKSI LAINNYA</b> > <b>TRANSFER</b> > <b>KE REKENING BCA VIRTUAL ACCOUNT</b>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Masukkan <b> {"11324" + this.props.va} </b> sebagai rekening tujuan
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Masukkan jumlah transfer sesuai detail transaksi + <b> Rp. 3000</b>.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>Ikuti instruksi untuk menyelesaikan transaksi</p>
                                                </li>
                                            </ol>
                                        </div>
                                    }
                                    panels="panel1-2"
                                    title2="KLIK BCA"
                                    body2={
                                        <div>
                                            <ol>
                                                <li>
                                                    <p>
                                                        Masuk ke website <a href="https://www.klikbca.com/">KLIK BCA</a>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Pilih menu <b>TRANSFER DANA</b> > <b>TRANSFER KE BCA VIRTUAL ACCOUNT</b>{" "}
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Masukkan <b> {"11324" + this.props.va} </b> sebagai rekening tujuan
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Masukkan jumlah transfer sesuai detail transaksi + <b> Rp. 3000</b>.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>Ikuti instruksi untuk menyelesaikan transaksi</p>
                                                </li>
                                            </ol>
                                        </div>
                                    }
                                    panels="panel1-3"
                                    title3="m-BCA (BCA MOBILE)"
                                    body3={
                                        <div>
                                            <ol>
                                                <li>
                                                    <p>
                                                        Masuk ke aplikasi mobile <b>m-BCA</b>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Pilih menu <b>M-TRANSFER</b> > <b>BCA VIRTUAL ACCOUNT</b>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Masukkan <b> {"11324" + this.props.va} </b> sebagai rekening tujuan
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Masukkan jumlah transfer sesuai detail transaksi + <b> Rp. 3000</b>.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Masukkan <b>PIN m-BCA Anda</b>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>Ikuti instruksi untuk menyelesaikan transaksi</p>
                                                </li>
                                            </ol>
                                        </div>
                                    }
                                />
                            </TabPane>
                            {hasil}
                        </div>
                    </div>
                );
            }
        };
        return (
            <div>
                {this.props.admin_kyc ? (
                    <>
                        <div className="sec-header mt-5">
                            <h4 className="text-center sec-title">Top Up Dompet</h4>
                        </div>
                        <div className="d-flex justify-content-center mb-4">
                            <div className="col-md-5">
                                <div className="card border-0 shadow card-statistic">
                                    <div className="card-body text-center">
                                        <p className="text-secondary mb-0">Total Saldo</p>
                                        <h4 className="font-weight-semi-bold mb-0 text-primary">Rp {this.props.saldo.toLocaleString("IN")}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mb-4">{tampil()}</div>
                    </>
                ) : (
                    <div>
                        <div className="alert-box">
                            <span className="mr-2">Untuk melakukan Topup silakan lengapi data diri terlebih dahulu</span>
                            {this.props.lender && (
                                <NavLink
                                    to={{
                                        pathname: "/lender/akun/" + this.props.lender._id,
                                        state: {
                                            jenis: `${this.props.jenis}`
                                        }
                                    }}
                                    className="btn btn-light ml-auto text-primary px-4 font-weight-semi-bold">
                                    Lengkapi
                                </NavLink>
                            )}
                        </div>
                        <div className="sec-header mt-5">
                            <h4 className="text-center sec-title">Top Up Dompet</h4>
                        </div>
                        <div className="d-flex justify-content-center mb-4">
                            <div className="col-md-5">
                                <div className="card border-0 shadow card-statistic">
                                    <div className="card-body text-center">
                                        <p className="text-secondary mb-0">Total Saldo</p>
                                        <h4 className="font-weight-semi-bold mb-0 text-primary">Rp {this.props.saldo.toLocaleString("IN")}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapToStateProps = (state) => {
    return {
        user: state.user
    };
};
export default connect(mapToStateProps)(PilihToup);
