import React, { Component } from "react";
import { Form, FormGroup, Label, Input, CustomInput } from "reactstrap";

class Employee extends Component {
    render() {
        return (
            <Form>
                <h4 className="text-center mb-4 d-block d-sm-none font-weight-bold">Required Employee</h4>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="kewarganegaraan">Kode Perusahaan</Label>
                            <Input
                                onChange={this.props.onChange}
                                type="text"
                                maxLength="6"
                                name="kode_perusahaan"
                                autoComplete="off"
                                placeholder="Contoh: 123456"
                                value={this.props.kode_perusahaan}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="exampleFile">Upload Surat Keterangan Kerja Dari HRD</Label>
                            <CustomInput
                                type="file"
                                onChange={this.props.handleUploadFile}
                                name="surat_keterangan_kerja"
                                id="exampleFile"
                                label={
                                    this.props.surat_keterangan_kerja.name !== undefined ? this.props.surat_keterangan_kerja.name : "Masukan file PDF"
                                }
                                accept="application/pdf"
                            />
                        </FormGroup>
                    </div>
                </div>
            </Form>
        );
    }
}
export default Employee;
