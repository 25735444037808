import axios from "axios";

const url = process.env.REACT_APP_BASSE_API;

export const createTiketing = (data) => {
    return {
        type: "CREATE_TIKETING",
        payload: axios.post(`${url}/tiket/user-create`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const getTopikTiketing = () => {
    return {
        type: "GET_TOPIK_TIKETING",
        payload: axios.get(`${url}/tiket/list-topik?limit=10&tipe=`, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const getDetailTiketing = (id) => {
    return {
        type: "GET_DETAIL_TIKETING",
        payload: axios.get(`${url}/tiket/user/${id}`, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const postNewMessageTiketing = (tiketId, data) => {
    return {
        type: "CREATE_MESSAGE_TIKETING",
        payload: axios.post(`${url}/tiket/message/${tiketId}`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const patchEndTiketing = (tiketId) => {
    return {
        type: "UPDATE_STATUS_TIKETING",
        payload: axios.patch(
            `${url}/tiket/finish/${tiketId}`,
            {},
            {
                headers: { "x-auth-token": localStorage.jwtToken }
            }
        )
    };
};

export const getListTiketing = (value) => {
    return {
        type: "GET_LIST_TIKETING",
        payload: axios.get(`${url}/tiket/list-user-ticket?page=${value.page}&limit=5`, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};
