const initialState = {
    aktivasiDipay: null,
    transasctionDipay: null,
    updatetransDipay: null,
    unlinkedDipay: null,
    isLoadingAktivasi: false,
    isLoadingCreate: false,
    isLoadingUpdateTransasction: false,
    isLoadingUnlinked: false
};

const Dipay = (state = initialState, action) => {
    switch (action.type) {
        case "LINK_ACCOUNT_PENDING":
            return {
                ...state,
                isLoadingAktivasi: true
            };
        case "LINK_ACCOUNT_REJECTED":
            return {
                ...state,
                isLoadingAktivasi: false,
                isError: true
            };
        case "LINK_ACCOUNT_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoadingAktivasi: false,
                aktivasiDipay: action.payload.data
            };
        case "CREATE_TRANSACTION_PENDING":
            return {
                ...state,
                isLoadingCreate: true
            };
        case "CREATE_TRANSACTION_REJECTED":
            return {
                ...state,
                isLoadingCreate: false,
                isError: true
            };
        case "CREATE_TRANSACTION_FULFILLED":
            return {
                ...state,
                isLoadingCreate: false,
                transasctionDipay: action.payload.data
            };
        case "UNLINK_ACCOUNT_PENDING":
            return {
                ...state,
                isLoadingUnlinked: true
            };
        case "UNLINK_ACCOUNT_REJECTED":
            return {
                ...state,
                isLoadingUnlinked: false,
                isError: true
            };
        case "UNLINK_ACCOUNT_FULFILLED":
            return {
                ...state,
                isLoadingUnlinked: false,
                unlinkedDipay: action.payload.data
            };
        default:
            return state;
    }
};
export default Dipay;
