import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import NoData from "../../assets/no data/NoData";
import Pagination from "react-js-pagination";
import { LoadingDashboard } from "../../assets/loading data/LoadingBorrower";
import Swal from "sweetalert2";
import { Paginations, ShowIdPinjaman, showDataCicilan } from "./FunctionCicilanAktif";

class Caktif extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPageAktif: 1,
            todosPerPageAktif: 15,
            filter: "",
            copyPaste: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    openNotif() {
        Swal.fire({
            width: 300,
            html: "<p style='color: #155724'>Berhasil mencopy id pinjaman</p>",
            background: "#d4edda",
            showConfirmButton: false,
            timer: 1500
        });
    }

    handleClick(pageNumber) {
        window.scrollTo(0, 0);
        this.setState({
            currentPageAktif: pageNumber
        });
    }
    handleChangeSearch = (event) => {
        this.setState({ filter: event.target.value });
    };

    AktifProject = (project, currentPage, todosPerPage, lengthAktif) => {
        if (project === undefined) {
        } else {
            const currentTodos = Paginations(project, currentPage, todosPerPage, this.state.filter);

            if (currentTodos.length !== 0 && lengthAktif !== 0) {
                const hasil = currentTodos.map((val, i) => {
                    const showDataId = ShowIdPinjaman(val.tipe_pinjaman);
                    const judulShow = showDataId === true ? "Id Pinjaman" : "Suku Bunga";
                    const isiShow =
                        showDataId === true ? (
                            <CopyToClipboard onCopy={this.openNotif.bind(this)} text={val.id_pinjaman}>
                                <span className="text-primary" style={{ cursor: "pointer" }}>
                                    {val.id_pinjaman}
                                </span>
                            </CopyToClipboard>
                        ) : (
                            `${val.suku_bunga_pinjaman * 100}%`
                        );
                    const rincianPinjaman = eval("val" + `${showDataCicilan(val)}`);

                    const cicilanTerdekat = () => {
                        // if(rincianPinjaman === undefined){
                        //     return ''
                        // }else{
                        const hasil = rincianPinjaman.map((val, i) => {
                            if (i === 0) {
                                if (val.status_bayar === false) {
                                    return (
                                        <p className="mb-0" key={i}>
                                            {new Date(rincianPinjaman[0].jatuh_tempo).toLocaleDateString("IN")}
                                        </p>
                                    );
                                }
                            } else if (i > 0) {
                                if (rincianPinjaman[i - 1].status_bayar === true && rincianPinjaman[i].status_bayar === false) {
                                    return (
                                        <p className="mb-0" key={i}>
                                            {new Date(rincianPinjaman[i].jatuh_tempo).toLocaleDateString("IN")}
                                        </p>
                                    );
                                }
                            }
                        });
                        return hasil;
                    };
                    // }

                    return (
                        <div key={val._id}>
                            <div className="transaction p-3">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-xl-9 mb-3 mb-xl-0">
                                                <div className="row">
                                                    <div className="col-6 col-md-5 mb-3">
                                                        <p className="fs-n1 text-secondary mb-1">Nama Proyek</p>
                                                        <p className="mb-0">{val.nama_pinjaman}</p>
                                                    </div>
                                                    <div className="col-6 col-md-4 mb-3">
                                                        <p className="fs-n1 text-secondary mb-1">Jenis Pinjaman</p>
                                                        <p className="mb-0">{val.jenis_pinjaman}</p>
                                                    </div>
                                                    <div className="col-6 col-md-3 mb-3">
                                                        <p className="fs-n1 text-secondary mb-1">Nominal</p>
                                                        <p className="mb-0">Rp {val.nilai_total_peminjam.toLocaleString("IN")}</p>
                                                    </div>
                                                    <div className="col-6 col-md-5 mb-3 mb-xl-0">
                                                        <p className="fs-n1 text-secondary mb-1">{judulShow}</p>
                                                        <p className="mb-0">{isiShow}</p>
                                                    </div>
                                                    <div className="col-6 col-md-4 mb-3 mb-xl-0">
                                                        <p className="fs-n1 text-secondary mb-1">Jatuh Tempo</p>
                                                        <div key={rincianPinjaman[rincianPinjaman.length - 1]._id}>
                                                            {new Date(rincianPinjaman[rincianPinjaman.length - 1].jatuh_tempo).toLocaleDateString(
                                                                "IN"
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-3 mb-3 mb-xl-0">
                                                        <p className="fs-n1 text-secondary mb-1">Cicilan Terdekat</p>
                                                        {cicilanTerdekat()}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-xl-3 text-center">
                                                <NavLink to={"/borrower/detail-cicilan/" + val._id} className="btn btn-outline-primary btn-block">
                                                    <span>Detail</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                });
                return hasil;
            } else {
                if (this.props.loading) {
                    return (
                        <div>
                            <LoadingDashboard />
                            <LoadingDashboard />
                        </div>
                    );
                } else {
                    return (
                        <NoData
                            symbol={<i className="material-icons-outlined">assignment</i>}
                            keterangan={project.length !== 0 ? "Proyek Anda belum tahap pembayaran cicilan" : "Belum ada Pinjaman"}
                        />
                    );
                }
            }
        }
    };
    render() {
        const { currentPageAktif, todosPerPageAktif, filter } = this.state;
        //pagination Aktif
        const lengthAktif = this.props.project?.filter((item) =>
            this.state.filter === ""
                ? item.status_proyek === "Pembayaran cicilan"
                : item.nama_pinjaman !== undefined
                ? item.nama_pinjaman.toLowerCase().includes(this.state.filter.toLowerCase()) & (item.status_proyek === "Pembayaran cicilan")
                : ""
        ).length;

        return (
            <div>
                <div className="d-flex align-items-center justify-content-between my-3">
                    {/* <div>
                        <label className="mb-0 mr-2 d-none d-md-inline-block" for="urutkan">Urutkan</label>
                        <select id="urutkan" className="custom-select w-auto mr-2">
                            <option value="">Suku Bunga</option>
                        </select>
                    </div> */}
                    <div>
                        <form>
                            <div className="input-group">
                                <input
                                    className="form-control"
                                    type="text"
                                    id="keyword"
                                    name="keyword"
                                    placeholder="Cari Transaksi"
                                    value={filter}
                                    onChange={this.handleChangeSearch}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <i className="material-icons-outlined">search</i>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {this.props.isLoadingGetUser ? (
                    <div>
                        <LoadingDashboard />
                        <LoadingDashboard />
                    </div>
                ) : (
                    this.AktifProject(this.props.project, currentPageAktif, todosPerPageAktif, lengthAktif)
                )}
                <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        hideDisabled
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.currentPageAktif}
                        itemsCountPerPage={this.state.todosPerPageAktif}
                        totalItemsCount={parseInt(lengthAktif)}
                        onChange={this.handleClick.bind(this)}
                    />
                </div>
            </div>
        );
    }
}
export default Caktif;
