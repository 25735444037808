import React, { Component } from "react";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";

const queryString = require("query-string");
const axios = require("axios").default;
const url = process.env.REACT_APP_BASSE_API;

class DownloadFileDigisign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result: ""
        };
    }

    handleFiledDownload() {
        Swal.fire({
            title: "Opps",
            text: "Download Dokumen gagal silakan coba kembali",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Kembali Ke Home"
        }).then(async (result) => {
            if (result.value) {
                this.props.history.push("/");
            }
        });
    }
    async componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        const { token, id } = values;
        await axios
            .get(`${url}/digisign/download-document/${id}`, { headers: { "x-auth-token": token }, responseType: "blob" })
            .then((file) => {
                // const href = URL.createObjectURL(file.data);
                const link = document.createElement("a");
                // create a blobURI pointing to our Blob
                link.href = URL.createObjectURL(file.data);
                link.download = "perjanjian dokument.pdf";
                // some browser needs the anchor to be in the doc
                document.body.append(link);
                link.click();
                // link.remove();
                // // in case the Blob uses a lot of memory
                setTimeout(() => this.props.history.replace("/succes-download"), 4000);
                // // clean up "a" element & remove ObjectURL
                // // document.body.removeChild(link);
                // // URL.revokeObjectURL(href);
                //
            })
            .catch((err) => {
                console.log(err, "ERR");
                this.handleFiledDownload();
            });
    }
    render() {
        return (
            <div style={{ padding: "8rem 0 4rem 0" }} className="">
                <div className="container" style={{ marginTop: "80px" }}>
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <Spinner animation="grow" variant="primary" style={{ width: "4rem", height: "4rem" }} />
                        <div style={{ paddingBottom: "16px" }} />
                        <p className="text text-center">
                            Mohon Tunggu. <br /> Permintaan anda sedang kami proses
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default DownloadFileDigisign;
