import React, { Component } from "react";
import Route from "./routes/Routes";
import "./component/template/guest/Menu.scss";
import "react-dipay/dist/index.css";
import axios from "axios";
const url = process.env.REACT_APP_BASSE_API;
const core = process.env.REACT_APP_BASSE_CORE;
class App extends Component {
    async componentWillMount() {
        if (localStorage.jwtToken === undefined) {
            return "";
        } else {
            // axios.get(`http:localhost:3000/user/me`, {
            axios
                .get(`${url}/user/me`, {
                    headers: { "x-auth-token": localStorage.jwtToken }
                })
                .then((res) => {
                    // console.log(res)
                })
                .catch((err) => {
                    // console.log(err.response)
                    if (err.response.status === 401) {
                        // axios.post(`http:localhost:3000/user/refresh-token`, { "refreshToken": localStorage.refreshToken }, {
                        axios.post(
                            `${url}/user/logout`,
                            { accessToken: localStorage.accessToken },
                            {
                                headers: { "x-auth-token": localStorage.jwtToken }
                            }
                        );
                        localStorage.removeItem("id");
                        localStorage.removeItem("jwtToken");
                        localStorage.removeItem("email");
                        localStorage.removeItem("id_penyelenggara");
                        localStorage.removeItem("kode_pengguna");
                        localStorage.removeItem("tgl_registrasi");
                        localStorage.removeItem("status_pengguna");
                        localStorage.removeItem("nama_pengguna");
                        localStorage.removeItem("id_pengguna");
                        localStorage.removeItem("bank");
                        localStorage.removeItem("jenis_kelamin");
                        localStorage.removeItem("justOnce");
                        localStorage.removeItem("masuk");
                        localStorage.removeItem("refreshToken");
                        localStorage.removeItem("lenderId");
                        window.location.href = `${core}`;
                    }
                });
        }
    }
    render() {
        return (
            <div className="App">
                <Route />
            </div>
        );
    }
}

export default App;
