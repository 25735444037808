import React, { Component } from "react";
import { Link } from "react-router-dom";
import Whatsapp from "../../../assets/svg/Whatsapp";
import Instagram from "../../../assets/svg/Instagram";
import Facebook from "../../../assets/svg/Facebook";

import Maps from "./Maps";

class ContactFooter extends Component {
    render() {
        return (
            <div className="row mb-5d-flex justify-content-center text-justify" id="contact-us">
                <div className="col col-md-12 col-lg-12">
                    <div className="col-12 mb-5 p-0">
                        <img width="200px" className="img-fluid" src={process.env.PUBLIC_URL + "/images/logo-white-R.png"} alt="logo-white" />
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3" style={{ paddingInline: '20px',display:'flex',flexDirection:'column',gap:'4px' }}>
                            <Link to="/faq">FAQ</Link> 
                            <Link to="/layanan">Layanan Pengaduan</Link>
                            <Link to="/privacy-policy">Kebijakan Privasi</Link>
                           
                            <Link to="/privacy-cookie">Kebijakan Cookie</Link>
                           
                            <a href="https://frontend-picture.s3.ap-southeast-1.amazonaws.com/pdf-file/draft-perjanjian-penerima-dana.pdf">
                                Draf Perjanjian Penerima Dana
                            </a>
                           
                            <a href="https://frontend-picture.s3.ap-southeast-1.amazonaws.com/pdf-file/draft-perjanjian-pemberi-dana.pdf">
                                Draf Perjanjian Pemberi Dana
                            </a>
                          
                            <Link to="/laporan-keuangan">Laporan Keuangan</Link>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mt-5 mt-md-0" style={{ paddingInline: '20px' }}>
                            <h5 className="font-weight-semi-bold mb-2">Kontak kami</h5>
                            <div className="d-flex flex-column gap-1" style={{marginBottom:'14px'}}>
                                <p className="text-second m-0">
                                    Email: cs@indofund.id
                                </p>
                                <p className="text-second m-0">
                                    Telepon:021-25982507
                                </p>
                                <p className="text-second m-0">
                                    Whatsapp: 0896 4344 8118
                                </p>
                            </div>
                            <div className="col-12 col-lg-12 mb-5 mb-lg-0 p-0">
                                <a
                                    href="http://wassmee.us/w/send.aspx?phone=6289643448118&text=Halo%20Indofund.id!%20Saya%20mau%20bertanya%20:"
                                    className="icons pr-4 ">
                                    <Whatsapp />
                                </a>
                                <a href="https://www.instagram.com/indofund.id/" className="icons pr-4 ">
                                    <Instagram />
                                </a>
                                <a href="https://www.facebook.com/Indofund.id/" className="icons pr-4">
                                    <Facebook />
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3" style={{ paddingInline: '20px' }}>
                            <h5 className="font-weight-semi-bold mb-2">Kantor kami</h5>
                            <p className="text-second line-height-medium">
                                PT. Bursa Akselerasi Indonesia <br /> Satrio Tower Lantai 14 unit 6 Jalan Prof. DR. Satrio Kav. 1-4 Blok C4 Kel.
                                Kuningan Timur, Kec. Setiabudi, Jakarta Selatan 12950
                            </p>
                        </div>
                        <div className="col-12 col-lg-3 col-md-6 mt-5 mt-md-0" style={{ paddingInline: '20px' }}>
                            <Maps />
                        </div>
                    </div>
                    <hr style={{ border: "0.5px solid #acacac" }} className="mt-5" />
                </div>
            </div>
        );
    }
}

export default ContactFooter;
