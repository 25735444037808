import React, { Component } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Document } from "../../../redux/action/Digisign";
import { LoadingSignProject } from "../../assets/loading data/LoadingBorrower";
import NoData from "../../assets/no data/NoData";
import Pagination from "react-js-pagination";
import moment from "moment";

const axios = require("axios").default;
const url = process.env.REACT_APP_BASSE_API;
class SignProjectBr extends Component {
    handleClickLagi = async (id, document_id) => {
        const data = await this.props.dispatch(Document(id));
        if (data.value.data.JSONFile.result === "05") {
            if (data.value.data.JSONFile.notif === "user telah menandatangani dokumen ini") {
                await axios.post(`${url}/digisign/aktivasi`, { email_user: this.props.email, document_id: document_id }, {});
                Swal.fire({
                    title: "Berhasil",
                    text: "Dokument telah berhasil ditanda tangan",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Kembali"
                }).then(async (result) => {
                    if (result.value) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    title: "Gagal",
                    text: `${data.value.data.JSONFile.notif}`,
                    icon: "warning",
                    button: "ok"
                });
            }
        } else if (data.value.data.JSONFile.result === "30") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "12") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "28") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "06") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "14") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "28") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "06") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "14") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "12") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else {
            window.location = this.props.digisign.response.JSONFile.link;
        }
    };
    handleClick(pageNumber) {
      if(pageNumber==this.props.pagination.page) return
      
      window.scrollTo(0, 0)
      this.setState({
        currentPage: pageNumber
      }, async () => {
        await this.props.reFetchData({ ...this.props.query, page: pageNumber })
  
      })
      
  }
    signProject = (projects) => {
        // const jumlahProyek = projects.filter(
        //     (item) => (item.status_proyek === "Proyek sedang berjalan" || item.premium_lender) && item.sendDocument === true && item.userTtd === false
        // ).length;
      
        const jumlahProyek = this.props.pagination?.totalData
        if (jumlahProyek !== 0) {
 
           return projects.map((val) => {
               
                  return (
                        <div className="card border-0 shadow-sm" key={val._id}>
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-12 mb-3 mb-xl-0">
                                        <div className="row">
                                            <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">
                                                <p className="fs-n1 text-secondary mb-1 title-sign-card">Tanggal</p>
                                                <p className="fs-n1 mb-0">{moment(new Date(val.createdAt)).format('LL')}</p>
                                            </div>
                                            <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">

                                              <p className="fs-n1 text-secondary mb-1 title-sign-card">Nama Pinjaman</p>
                                              <p className="mb-0">{val.nama_pinjaman}</p>
                                            </div>
                                            <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">

                                                <p className="fs-n1 text-secondary mb-1 title-sign-card">Nominal yg terkumpul</p>
                                                <p className="mb-0">Rp. {val.nilai_total_peminjam.toLocaleString("IN")}</p>
                                            </div>
                                            <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">

                                                <p className="fs-n1 text-secondary mb-1 title-sign-card">Suku Bunga</p>
                                                <p className="mb-0">{val.suku_bunga_pinjaman * 100} %</p>
                                            </div>
                                            <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">
                                                <p className="fs-n1 text-secondary mb-1 title-sign-card">Tenor</p>
                                                <p className="mb-0">{val.jangka_waktu_pinjaman} Bulan</p>
                                            </div>
                                          <div className="col-12 col-xl-2 text-center d-flex flex-column justify-content-center align-items-center">
                                                {val.sendDocument === false ? (
                                                    <Button color="primary" disabled onClick={() => this.handleClickLagi(val._id)}>
                                                        Tanda tangan
                                                    </Button>
                                                ) : (
                                                    <Button color="primary" onClick={() => this.handleClickLagi(val._id, val.document_id)}>
                                                        Tanda tangan
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
            }); 
          
        } else {
            return (
                <NoData symbol={<i className="material-icons-outlined">assignment</i>} keterangan={"Belum ada proyek dalam tahap sedang berjalan"} />
            );
        }
    };

    loading() {
        return (
            <div>
                <LoadingSignProject />
                <LoadingSignProject />
            </div>
        );
    }
    render() {
        return (<div>{this.props.isLoadingProjectSigned ? this.loading() : this.signProject(this.props.projects)}
        {this.props.projects?.length>0 &&
         <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        hideDisabled
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.props.pagination?.page}
                        itemsCountPerPage={this.props.pagination?.limit}
                        totalItemsCount={parseInt(this.props.pagination?.totalData)}
                        onChange={this.handleClick.bind(this)}
                    />
                </div>
                }
        </div>)
    }
}
const mapStateToProps = (state) => {
    return {
        digisign: state.digisign
    };
};
export default connect(mapStateToProps)(SignProjectBr);
