import axios from "axios";

// const url = `http://localhost:3000`
const url = process.env.REACT_APP_BASSE_API;
// const url = `192.168.0.107:3500`;

export const Register = (data) => {
  return {
    type: "REGISTER",
    payload: axios.post(`${url}/user/register`, data, {})
  };
};

export const RegisterLender = (data) => {
  return {
    type: "REGISTERLENDER",
    payload: axios.post(`${url}/lender/register`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};
export const checkPusdafilUpdate = (userType, userId = "") => {
  return {
    type: "CHECK_UPDATE_PUSDAFIL",
    payload: axios.get(`${url}/user/update-data?userType=${userType}&userId=${userId}`, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};
export const postPusdafilUpdate = (userType, userId = "", body) => {
  return {
    type: "POST_UPDATE_PUSDAFIL",
    payload: axios.post(`${url}/user/update-data?userType=${userType}&userId=${userId}`, body, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const RegisterBorrower_BadanHukum = (data) => {
  return {
    type: "REGISTERBORROWER_BADANHUKUM",
    payload: axios.post(`${url}/borrower/register-entity`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const RegisterBorrower_Personal = (data) => {
  return {
    type: "REGISTERBORROWER_PERSONAL",
    payload: axios.post(`${url}/borrower/register-personal`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const LoginUser = (data) => {
  return {
    type: "LOGIN",
    payload: axios.post(`${url}/user/login`, data)
  };
};

export const UserLupaPassword = (data) => {
  return {
    type: "LUPA_PASSWORD",
    payload: axios.post(`${url}/user/forget-password`, data, {})
  };
};
export const getUser = (id) => {
  return {
    type: "GET_USER_ID",
    payload: axios.get(`${url}/user/find/${id}`, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const OTPVerif = (data) => {
  return {
    type: "OTP_VERIF",
    payload: axios.patch(`${url}/otp/otp-verification/`, data, {})
  };
};

export const OTPResend = (data) => {
  return {
    type: "OTP_RESEND",
    payload: axios.post(`${url}/otp/resend-otp/`, data, {})
  };
};

export const keepLogin = (data) => {
  return {
    type: "LOGIN",
    payload: {
      id: data.id,
      email: data.email,
      id_penyelenggara: data.id_penyelenggara,
      kode_pengguna: data.kode_pengguna,
      tgl_registrasi: data.tgl_registrasi,
      status_pengguna: data.status_pengguna,
      nama_pengguna: data.nama_pengguna,
      id_pengguna_bank: data.id_pengguna_bank,
      jenis_kelamin: data.jenis_kelamin
    }
  };
};

export const refreshToken = (data) => {
  return {
    type: "REFRESH_TOKEN",
    payload: axios.post(`${url}/user/refresh-token`, data, {})
  };
};

//kenapa diuser? karena dompet hanya satu dengan 2 tipe yg berbeda
export const BalanceTransaction = (data) => {
  // console.log(data.type);
  return {
    type: "BALANCE_TRANSACTION",
    payload: axios.get(`${url}/balance/balance-transaction-list?type=${data.type}&minDate=${data.minDate}&maxDate=${data.maxDate}`, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const TopUp = (data) => {
  return {
    type: "TOPUP",
    payload: axios.post(`${url}/balance/top-up`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const Withdraw = (data) => {
  return {
    type: "WITHDRAW",
    payload: axios.post(`${url}/balance/withdraw`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const CancelWithdraw = (id, data) => {
  return {
    type: "CANCEL_WITHDRAW",
    payload: axios.patch(`${url}/balance/cancel-withdraw/${id}`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const UpdateRekening = (data) => {
  return {
    type: "REKENING",
    payload: axios.patch(`${url}/user/add-bank/`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const EditRekening = (data) => {
  console.log(data);
  return {
    type: "EDIT_REKENING",
    payload: axios.put(`${url}/user/edit-rekening/${data.index}/`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const DeleteRekening = (id, data) => {
  return {
    type: "DELETE_REKENING",
    payload: axios.patch(`${url}/user/delete-bank/${id}`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const PrimaryRekening = () => {
  return {
    type: "PRIMARY_REKENING",
    payload: axios.get(`${url}/user/primary-bank/`, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const SetAsPrimaryRekening = (id, data) => {
  return {
    type: "SETASPRIMARY_REKENING",
    payload: axios.patch(`${url}/user/bank-set-primary/${id}`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const UbahPassword = (data) => {
  return {
    type: "UBAH_PASSWORD",
    payload: axios.patch(`${url}/user/change-password`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

// home page
export const Statistik = () => {
  return {
    type: "STATISTIK",
    payload: axios.get(`${url}/user/faq`)
  };
};

export const TKB90 = () => {
  return {
    type: "TKB90",
    payload: axios.get(`${url}/user/tkb`)
  };
};

export const LapKeuangan = (data) => {
  return {
    type: "KEUANGAN",
    payload: axios.get(`${url}/laporan-keuangan/list-statement-client?page=${""}&limit=${""}`)
  };
};

export const AcceptTerm = () => {
  console.log("TERMMM");
  return {
    type: "TERM",
    payload: axios.patch(
      `${url}/user/accept-term`,
      {},
      {
        headers: { "x-auth-token": localStorage.jwtToken }
      }
    )
  };
};

//nicepay user
export const SendID = () => {
  return {
    type: "SEND_ID",
    payload: axios.get(`${url}/nicepay/customer-va`, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const customerInquiry = () => {
  return {
    type: "CUSTOMER_INQUIRY",
    payload: axios.get(`${url}/nicepay/customer-inquiry`, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};
