import React, { Component } from "react";

class TabInformasiPeminjam extends Component {
    render() {
        const project = this.props.project;
        return (
            <div className={this.props.className}>
                <div className="card container mt-4 mb-4 p-4">
                    <div className="mb-4">
                        <p className="font-weight-bold">Deskripsi Borrower</p>
                        <p className="fs-1 mb-0" dangerouslySetInnerHTML={{ __html: project.deskripsi_proyek }}></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default TabInformasiPeminjam;
