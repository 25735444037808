import React, { Component } from "react";
import "./BerikanPinjaman.scss";
import Step from "./Step";
import PictureStep from "./PictStep";

class BerikanPinjaman extends Component {
    render() {
        return (
            <div>
                <section id="cara-berikan-pinjaman" className=" pt-5 pb-5 bg-gray">
                    <div className="container no-lg">
                        <div className="sec-header" id="one">
                            <h4 className="text-center sec-title">Cara Berikan Pendanaan</h4>
                            <p className="text-center sec-desc">Langkah mudah memberikan bantuan melalui Indofund</p>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <PictureStep name={"/images/cara-berikan-pinjaman/1.png"} />
                            </div>
                            <div className="col-12 col-lg-h5 col-lg-6">
                                <Step
                                    title1={"Daftar sebagai Pemberi Dana"}
                                    body1={"Daftarkan diri anda melalui form pendaftaran."}
                                    title2={"Pilih pendanaan sesuai resiko Anda"}
                                    body2={"Pemberi dana mencari pendanaan yang sesuai dengan profil resiko dari daftar pendanaan yang tersedia."}
                                    title3={"Dapatkan imbal hasil setiap bulan"}
                                    body3={"Pemberi dana menerima imbal hasil atas pendanaan yang diberikan di Indofund."}
                                    namebutton={"Berikan Pendanaan"}
                                    secondButton={"Simulasi Pendanaan"}
                                    link={"/tentang-sukubunga#calculator"}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default BerikanPinjaman;
