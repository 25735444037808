import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import CreateIcon from "@material-ui/icons/Create";
import Swal from "sweetalert2";
import axios from "axios";
import { Link } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from "reactstrap";
import CompanyInfo from "../../template/guest/CompanyInfo";
import NumberFormat from "react-number-format";
import ShowElement from "../../../library/components/ShowElement";
import TKB from "../guest/MenuTKB";
const useStyles = makeStyles({
    list: {
        width: 330
    },
    fullList: {
        width: "auto"
    },
    flexBetween: {
        display: "flex",
        justifyContent: "space-between"
    }
});

export default function SwipeableTemporaryDrawer(props) {
    const url = process.env.REACT_APP_BASSE_API;
    const classes = useStyles();
    const [state, setState] = React.useState({
        left: false
    });
    const [collapsed, setCollapsed] = React.useState(true);

    const togglenavbar = () => setCollapsed(!collapsed);

    const toggleDrawer = (side, open) => (event) => {
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setState({ ...state, [side]: open });
    };
    const rupiahFormat = (value) => `Rp. ${value || 0}`;
    const changeAccount = () => {
        axios
            .patch(
                `${url}/user/switch-account`,
                {},
                {
                    headers: { "x-auth-token": localStorage.jwtToken }
                }
            )
            .then((res) => {
                const refreshToken = res.data.refreshToken;
                const jwtToken = res.data.token;
                localStorage.setItem("refreshToken", refreshToken);
                localStorage.setItem("jwtToken", jwtToken);
                setTimeout(() => {
                    window.location.href = "/lender/dasbor";
                }, 2000);
            })
            .catch((err) => {
                if (err.response.status === 500) {
                    Swal.fire({
                        title: "Tidak Berhasil",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                }
            });
    };

    const borrowerDetail = () => {
        const borrower = props.borrower;
        if (borrower === undefined) {
            return (
                <ListItem button>
                    <ListItemIcon>
                        <AccountCircleOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Akun" />
                </ListItem>
            );
        } else {
            return (
                <ListItem
                    button
                    component={Link}
                    to={{
                        pathname: "/borrower/akun/" + borrower._id,
                        state: {
                            jenisMaster: `${props.jenis}`
                        }
                    }}>
                    <ListItemIcon>
                        <AccountCircleOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Akun" />
                </ListItem>
            );
        }
    };

    const authAjukanPinjaman = () => {
        if (props.bankVerif.length === 0) {
            return (
                <ListItem>
                    <ListItemText primary="Ajukan pinjaman" className="btn btn-primary btn-block disabled" />
                </ListItem>
            );
        } else {
            return (
                <ListItem component={Link} to="/borrower/ajukan-pinjaman">
                    <ListItemText primary="Ajukan pinjaman" className="btn btn-primary btn-block" />
                </ListItem>
            );
        }
    };

    const borrower = props.borrower;
    const sideList = (side) => (
        <div className={classes.list} role="presentation" onClick={toggleDrawer(side, false)} onKeyDown={toggleDrawer(side, false)}>
            <List>
                <ListItemText
                    primary={borrower.jenis_pengguna === 1 ? "PERSONAL" : "BADAN HUKUM"}
                    className="text-center"
                    style={{ color: "#FF7A00", paddingTop: "30px" }}
                />
                <ListItemText disableTypography primary={props.nama_pengguna} className="text-center font-weight-semi-bold mb-1" />
                <ListItemText primary={props.email} className="text-center text-secondary" />
                <ListItem button className={classes.flexBetween}>
                    <ListItemIcon>
                        <AccountBalanceWalletOutlinedIcon style={{ color: "#FF7A00" }} />

                        <span className="font-weight-semi-bold pl-2" style={{ color: "#000000" }}>
                            Saldo
                        </span>
                    </ListItemIcon>
                    <div className="d-flex gap-1">
                        <NumberFormat
                            value={props.saldo}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={0}
                            renderText={(value) => (
                                <ListItemText
                                    disableTypography
                                    primary={props.isShowSaldo ? rupiahFormat(value) : "*****"}
                                    className="font-weight-semi-bold d-flex justify-content-end"
                                />
                            )}
                        />
                        <div className="d-inline-flex">
                            <ShowElement
                                isShow={props.isShowSaldo}
                                toggleIsShow={(e) => {
                                    e.stopPropagation();
                                    props.toggleIsShowSaldo();
                                }}
                            />
                        </div>
                    </div>
                </ListItem>
                {authAjukanPinjaman()}
                <hr />
                <ListItem button component={Link} to="/borrower/dasbor">
                    <ListItemIcon>
                        <DashboardOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Dashboard" />
                </ListItem>
                <ListItem button component={Link} to="/borrower/dompet">
                    <ListItemIcon>
                        <AccountBalanceWalletOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Dompet" />
                </ListItem>
                {borrowerDetail()}
                <ListItem button component={Link} to="/borrower/sign">
                    <ListItemIcon>
                        <CreateIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Signing" />
                </ListItem>
                <ListItem button onClick={(e) => props.onLogout(e)}>
                    <ListItemIcon>
                        <ExitToAppOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Keluar" />
                </ListItem>
            </List>
        </div>
    );

    const types = JSON.parse("[" + localStorage.kode_pengguna + "]");

    return (
        <div>
            <header>
                <div className="container p-2">
                    <CompanyInfo />
                    <Navbar className="c bg-white navbar-expand-lg" vertical="true">
                        <div className="container">
                            <NavbarToggler onClick={toggleDrawer("left", true)} style={{ marginLeft: "0" }}>
                                <i className="material-icons-round">menu</i>
                            </NavbarToggler>
                            <SwipeableDrawer open={state.left} onClose={toggleDrawer("left", false)} onOpen={toggleDrawer("left", true)}>
                                {sideList("left")}
                            </SwipeableDrawer>
                            <NavbarBrand href="/">
                                <img
                                    src={process.env.PUBLIC_URL + "/images/logo.png"}
                                    width="140"
                                    className="d-inline-block align-top"
                                    alt="logo-indofund"
                                />
                            </NavbarBrand>
                            <NavbarToggler onClick={togglenavbar}>
                                <i className="material-icons-round">more_vert</i>
                            </NavbarToggler>
                            <Collapse isOpen={!collapsed} navbar>
                                <Nav className="mr-auto" navbar>
                                    <NavItem>
                                        <NavLink href="/borrower/dasbor">Dashboard</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="/faq">FAQ</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="/layanan">Layanan Pengaduan</NavLink>
                                    </NavItem>
                                    {/* kalau udah verifikasi digisign dan cuma punya lender, tombol muncul */}
                                    {types.length > 1 ? (
                                        <Nav className="d-lg-none">
                                            <NavItem>{/* <NavLink href="#" onClick={changeAccount}>Akun Borrower</NavLink> */}</NavItem>
                                        </Nav>
                                    ) : props.userDigisign && types.length === 1 ? (
                                        <div />
                                    ) : // <NavItem className="d-lg-none">
                                        //   <NavLink
                                        //     href={
                                        //       props.jenis === 1
                                        //         ? "/regist-personal/1"
                                        //         : "/regist/badanhukum"
                                        //     }
                                        //   >
                                        //     Ajukan Pinjaman
                                        //   </NavLink>
                                        // </NavItem>
                                        null}
                                </Nav>
                                <div className="nav-right d-none d-lg-block">
                                    <div className="tkb">
                                        {types.length > 1 ? (
                                            <Nav>
                                                <NavItem>
                                                    {/* <NavLink href="/borrower/dasbor">
                            Akun Borrower
                          </NavLink> */}
                                                    {/* <NavLink href="#" onClick={changeAccount}>Akun Borrower</NavLink> */}
                                                </NavItem>
                                            </Nav>
                                        ) : props.userDigisign && types.length === 1 ? (
                                            <Nav>
                                                {/* <NavItem>
                          <NavLink
                            href={
                              props.jenis === 1
                                ? "/regist-personal/1"
                                : "/regist/badanhukum"
                            }
                          >
                            Ajukan Pinjaman
                          </NavLink>
                        </NavItem> */}
                                            </Nav>
                                        ) : null}
                                        <TKB
                                            className="d-none d-sm-block"
                                            data={props.tkb}
                                            isOpenModal={props.isOpenTkbModal}
                                            toggle={props.toggle}
                                            popoClass="d-none d-sm-block"
                                            id="Popovers1"
                                        />
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </Navbar>
                </div>
            </header>
        </div>
    );
}
