import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { colorGrade, sukuBunga, satuanJWP, bilanganNominal } from "../../../library/helper/helper";

class ProyekInfo extends Component {
    sisaPenggalanganDana = (sisa) => {
        if (sisa > 0) {
            return (
                <div className="row align-items-center">
                    <div className="col-6">
                        <p className="card-text label-info" title="Terkumpul">
                            Sisa pengumpulan dana
                        </p>
                        <NumberFormat
                            value={sisa}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"Rp "}
                            renderText={(value) => <p className="card-text value-info text-primary">{value}</p>}
                        />
                    </div>
                </div>
            );
        }
    };

    render() {
        const project = this.props.project;
        const diffDays = this.props.diffDays;
        const progressBar = this.props.progressBar;
        const sisa = this.props.sisa;
        const namaSektor = this.props.namaSektor;
        const background = {
            backgroundImage: `url(${project.gambar_proyek})`,
            backgroundRepeat: "no-repeat",
            width: "100%",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "287px",
            borderRadius: "8px",
            marginBottom: "24px"
        };
        const sum_lender = project.pemberian_pinjaman ? `${project.pemberian_pinjaman.length} lender` : null;
        // console.log(project.borrower.employee_loan);
        return (
            // <div></div>
            <div className="project-body">
                {project.tipe_pinjaman !== "Employee Loan" ? <div style={background}></div> : null}
                <div className="row mb-4">
                    <div className="col-8 col-md-6">
                        <h3 className="font-weight-bold">{project.nama_pinjaman}</h3>
                        <h5 className="text-secondary">{project.tipe_pinjaman}</h5>
                    </div>
                    <div className="col-4 col-md-6">
                        <p className="float-right banner-grade-detail" style={{ backgroundColor: colorGrade(project.grade_proyek) }}>
                            {"Grade " + project.grade_proyek}
                        </p>
                    </div>
                </div>
                {sisa <= 0 ? (
                    <p>
                        Dana <b>100%</b> telah terkumpul
                    </p>
                ) : project.status_proyek === "Terdanai sebagian" ? (
                    <p>
                        Dana terkumpul <b>{"Rp " + project.nilai_total_peminjam.toLocaleString("IN")}</b>
                    </p>
                ) : (
                    <p>
                        Sisa pendanaan <b>{"Rp " + bilanganNominal(sisa)} lagi</b>
                    </p>
                )}
                <div className="progress">
                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${parseInt(progressBar)}%` }}></div>
                </div>

                <div className="row">
                    <div className="col-6 col-md-3 pb-3">
                        <p className="card-text label-info pb-1">Terdanai oleh</p>
                        <p className="card-text value-info">{sum_lender}</p>
                    </div>
                    <div className="col-6 col-md-3 pb-3">
                        <p className="card-text label-info pb-1">Sektor usaha</p>
                        <p className="card-text value-info">{namaSektor}</p>
                    </div>
                    <div className="col-6 col-md-3 pb-3">
                        <p className="card-text label-info pb-1">Terkumpul</p>
                        <NumberFormat
                            value={project.nilai_total_peminjam}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"Rp "}
                            renderText={(value) => <p className="card-text value-info">{value}</p>}
                        />
                    </div>
                    <div className="col-6 col-md-3 pb-3">
                        <p className="card-text label-info pb-1">Plafon</p>
                        <NumberFormat
                            value={project.nilai_pengajuan_pinjaman}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"Rp "}
                            renderText={(value) => <p className="card-text value-info">{value}</p>}
                        />{" "}
                    </div>
                    <div className="col-6 col-md-3 pb-3">
                        <p className="card-text label-info pb-1">Durasi peminjaman</p>
                        <p className="card-text value-info">
                            {project.jangka_waktu_pinjaman} {satuanJWP(project.satuan_jangka_waktu_pinjaman)}
                        </p>
                    </div>
                    <div className="col-6 col-md-3 pb-3">
                        <p className="card-text label-info pb-1">Masa pendanaan</p>
                        <p className="card-text value-info"> {isNaN(diffDays) ? "0" : diffDays} hari</p>
                    </div>
                    <div className="col-6 col-md-3 pb-3">
                        <p className="card-text label-info pb-1">Suku bunga</p>
                        <p className="card-text value-info">
                            {sukuBunga(
                                project.tipe_pinjaman,
                                project.jenis_pinjaman,
                                project.suku_bunga_pinjaman,
                                project.suku_bunga_konsumtif,
                                project.suku_bunga_micro,
                                project.spread_bunga
                            )}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
export default ProyekInfo;
