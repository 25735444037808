import React, { Component } from "react";
import { ListBlog } from "../../../redux/action/Blog";
import { connect } from "react-redux";
import NoData from "../../assets/no data/NoData";
class BlogTerkait extends Component {
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        await this.props.dispatch(ListBlog());
    };

    beritaTerkait() {
        if (this.props.blog.listBlog.length !== 0) {
            function compare(a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }
            const hasil = this.props.blog.listBlog
                .slice(0, 3)
                .sort(compare)
                .map((val) => {
                    // console.log(val)
                    const length = 150;
                    const highlight = val.konten.length > length ? val.konten.substring(0, length - 3) + "..." : val.konten;
                    return (
                        <div className="col-12 col-lg-4 p-3" key={val._id}>
                            <a href={"/blog-detail/" + val._id} className="card article-card">
                                <img src={val.gambar_blog} alt="article-1" className="card-img-top" style={{ height: "182px" }} />
                                <div className="card-body">
                                    <div className="poster">
                                        <p>{val.penulis.nama_admin}</p>
                                        <span className="dot">•</span>
                                        <p>9 Juli 2019</p>
                                    </div>
                                    <p className="card-title">{val.judul}</p>
                                    <p className="" style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: highlight }}></p>
                                </div>
                            </a>
                        </div>
                    );
                });
            return hasil;
        } else {
            return <NoData symbol={<i className="material-icons">book</i>} keterangan={"Maaf saat ini belum ada artikel"} />;
        }
    }
    render() {
        return (
            <div>
                <hr />
                <h4 className="font-weight-bold"> Berita Terkait</h4>
                <div className="row">{this.beritaTerkait()}</div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        blog: state.blog
    };
};
export default connect(mapStateToProps)(BlogTerkait);
