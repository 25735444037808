import React, { Component } from "react";
import { connect } from "react-redux";
import { LenderId, updateDataLenderYearly } from "../../../../redux/action/Lender";
import { Form, Button } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../sass/datepicker.css";
import Swal from "sweetalert2";
import { LoadingPersonal } from "../../../assets/loading data/LoadingLender";
import { Inputs, SelectOptions } from "../../../../library/components/Components";
import { validate } from "../../../../library/validation/validate";
import { CheckDigisignData, InfoBrowser } from "../../../../library/helper/helper";
import { addYears } from "date-fns";

class UpdateDataYearly extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id_pendidikan: "",
            id_pekerjaan: "",
            id_bidang_pekerjaan: "",
            pengalaman_kerja: "",
            pendapatan: "",
            sumber_dana: "",
            popoverOpen: false,
            checked: false,
            disabled: true,
            tgl_lahir: addYears(new Date(), -17),
            update_lender: "",
            update_digisign: "",
            kyc: ""
        };
    }

    handleChange = (e, type, changeError) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value) });
        });
    };

    handleUploadFile = (e) => {
        let file = e.target.files[0];
        this.setState({
            [e.target.name]: file
        });
        //    console.log(e.target.files[0])
    };

    handleSelectSearch = (name, e) => {
        // console.log({[name] : e.value})
        this.setState({ [name]: e.value });
    };

    //verif akun popover
    toggletkb() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }
    onHover = () => {
        this.setState({
            popoverOpen: true
        });
    };
    onHoverLeave = () => {
        this.setState({
            popoverOpen: false
        });
    };
    //end verif

    handleCheck = () => {
        this.setState({ checked: !this.state.checked });
        if (!this.state.checked) {
            this.setState({
                disabled: !this.state.disabled
            });
        } else {
            this.setState({
                disabled: !this.state.disabled
            });
        }
    };
    //function
    handleUpdate = async () => {
        const { id_pendidikan, id_pekerjaan, id_bidang_pekerjaan, pengalaman_kerja, pendapatan, sumber_dana } = this.state;

        const updateLender = async () => {
            await this.props
                .dispatch(
                    updateDataLenderYearly(this.props.id, {
                        id_pendidikan: this.state.id_pendidikan,
                        id_pekerjaan: this.state.id_pekerjaan,
                        id_bidang_pekerjaan: this.state.id_bidang_pekerjaan,
                        pengalaman_kerja: this.state.pengalaman_kerja,
                        pendapatan: this.state.pendapatan,
                        sumber_dana: this.state.sumber_dana
                    })
                )
                .then(() => {
                    return Swal.fire({
                        title: `<p style="font-size:20px;">Terimakasih telah Melakukan Pengkinian Data!</p>`,
                        text: "Data anda saat ini berhasil diperbarui",
                        icon: "success",
                        buttons: "ok"
                    }).then(() => {
                        window.location.reload();
                    });
                })
                .catch((err) => {
                    // console.log(err.response)
                    if (err.response.status === 400) {
                        Swal.fire({
                            title: "Gagal",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    }
                });
        };
        // //cek data input
        if (!id_bidang_pekerjaan || id_pendidikan === -1 || id_pekerjaan === -1 || pengalaman_kerja === -1 || pendapatan === -1 || !sumber_dana) {
            Swal.fire({
                title: "Gagal Daftar",
                text: "Mohon untuk periksa kembali data anda",
                icon: "warning",
                buttons: "ok"
            });
        } else {
            updateLender();
            // console.log('TRUEEEE')
        }
    };

    readOnly() {
        if (this.state.update_lender === true) {
            return true;
        } else {
            return false;
        }
    }
    authReadonlyDigisign() {
        if (this.state.update_digisign === true) {
            return false;
        } else {
            return true;
        }
    }

    confirmDataValid() {
        return (
            <div>
                <div className="mt-4 mb-5">
                    <div className="custom-control custom-checkbox filter-input" style={{ zIndex: 0 }}>
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="agreement"
                            name="agreement"
                            onChange={this.handleCheck}
                            defaultChecked={this.state.checked || ""}
                        />
                        <label className="custom-control-label" htmlFor="agreement">
                            Saya menyatakan bahwa data-data yang telah saya isi diatas adalah valid, saya telah melakukan pengecekan atas kebenaran
                            data-data tersebut, dan saya telah membaca ketentuan privacy dan cookies .
                        </label>
                    </div>
                </div>
                <div className="text-center">
                    <Button disabled={this.state.disabled} color="primary" onClick={this.handleUpdate} className="btn btn-lg col-12 col-md-6">
                        {this.props.lender.isLoadingudapteYerly ? <span className="loader"></span> : "Ubah Data"}
                    </Button>
                </div>
            </div>
        );
        // }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.props.dispatch(LenderId(this.props.id));
        this.setState({
            _id: this.props.lender.lenderDetail._id
        });
        this.setState({
            kota: this.props.lender.lenderDetail.ojkJson.kota.keterangan,
            negaraDomisili: this.props.lender.lenderDetail.ojkJson.negaraDomisili.keterangan,
            provinsi: this.props.lender.lenderDetail.ojkJson.provinsi.keterangan,
            id_agama: this.props.lender.lenderDetail.ojkJson.agama.kode,
            id_bidang_pekerjaan: this.props.lender.lenderDetail.ojkJson.bidangPekerjaan.kode,
            id_kota: this.props.lender.lenderDetail.ojkJson.kota.kode,
            id_negara_domisili: this.props.lender.lenderDetail.ojkJson.negaraDomisili.kode,
            id_kewarganegaraan: this.props.lender.lenderDetail.ojkJson.kewarganegaraan.kode,
            id_pekerjaan: this.props.lender.lenderDetail.ojkJson.pekerjaan.kode,
            id_provinsi: this.props.lender.lenderDetail.ojkJson.provinsi.kode,
            pendapatan: this.props.lender.lenderDetail.ojkJson.pendapatan.kode,
            pengalaman_kerja: this.props.lender.lenderDetail.ojkJson.pengalamanKerja.kode,
            status_perkawinan: this.props.lender.lenderDetail.ojkJson.statusPerkawinan.kode,
            id_pendidikan: this.props.lender.lenderDetail.ojkJson.pendidikan.kode,
            id_jenis_kelamin: this.props.lender.lenderDetail.ojkJson.jenisKelamin.kode,
            sumber_dana: this.props.lender.lenderDetail.sumber_dana
        });

        // console.log(this.props.lender.lenderDetail.ojkJson);
        //jika data salah dan camera web lewat browser
        if (this.state.update_lender !== true) {
            InfoBrowser();
        } else {
            CheckDigisignData(this.state.update_digisign, true);
        }
    }
    togglePopover() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }
    onHover = () => {
        this.setState({
            popoverOpen: true
        });
    };
    onHoverLeave = () => {
        this.setState({
            popoverOpen: false
        });
    };
    form = () => {
        return (
            <div className="card-body">
                <Form>
                    <div className="row">
                        <div className="col-md-6">
                            <SelectOptions
                                forLabel={"id_pendidikan"}
                                label={"Pendidikan Terakhir"}
                                name={"id_pendidikan"}
                                value={this.state.id_pendidikan}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih Pendidikan Terakhir"}
                                data={this.props.pendidikanList}
                            />
                        </div>
                        <div className="col-md-6">
                            <SelectOptions
                                forLabel={"id_pekerjaan"}
                                label={"Pekerjaan"}
                                name={"id_pekerjaan"}
                                value={this.state.id_pekerjaan}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih Pekerjaan"}
                                data={this.props.pekerjaanList}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <SelectOptions
                                forLabel={"id_bidang_pekerjaan"}
                                label={"Bidang Pekerjaan"}
                                name={"id_bidang_pekerjaan"}
                                value={this.state.id_bidang_pekerjaan}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih Bidang Pekerjaan"}
                                data={this.props.bidang_pekerjaanList}
                            />
                        </div>
                        <div className="col-md-6">
                            <SelectOptions
                                forLabel={"pengalaman_kerja"}
                                label={"Pengalaman Kerja"}
                                name={"pengalaman_kerja"}
                                value={this.state.pengalaman_kerja}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih Pengalaman Kerja"}
                                data={this.props.pengalamanList}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <SelectOptions
                                forLabel={"pendapatan"}
                                label={"Pendapatan Per Bulan"}
                                name={"pendapatan"}
                                value={this.state.pendapatan}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih pendapatan"}
                                data={this.props.pendapatanList}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"sumber_dana"}
                                label={"Sumber Dana"}
                                onChange={(e) => this.handleChange(e, "string", "errSumber")}
                                type={"text"}
                                name={"sumber_dana"}
                                placeholder={"Contoh : Penghasilan"}
                                error={this.state.errSumber}
                                value={this.state.sumber_dana}
                                readOnly={this.readOnly()}
                            />
                        </div>
                    </div>
                    {this.confirmDataValid()}
                </Form>
            </div>
        );
    };
    render() {
        return (
            <div>
                {this.props.lender.isLoadingLender ? <LoadingPersonal /> : this.form()}
                {/* Modal show ktp */}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        lender: state.lender
    };
};
export default connect(mapStateToProps)(UpdateDataYearly);
