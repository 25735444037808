import React, { Component } from "react";
import Loading from "./Loading";
import { connect } from "react-redux";
import NoData from "../../assets/no data/NoData";
import ChartPie from "../../assets/svg/ChartPie";
import Pagination from "react-js-pagination";
import { colorCurrency, ballanceCurrency } from "../../../library/helper/helper";
import NumberFormat from "react-number-format";
import { DropdownToggle, DropdownMenu, DropdownItem, Collapse, Navbar, NavbarToggler, Nav, NavItem, UncontrolledDropdown } from "reactstrap";
import "./DompetHistory.scss";
import DatePicker from "react-datepicker";
import { addYears } from "date-fns";
import moment from "moment";

class DompetTransaksi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            todosPerPage: 15,
            filter: "",
            dropdownOpen: false,
            selectedFilter: "",
            filterTransaction: [
                "Semua",
                "Auto withdraw",
                "Pembayaran cicilan",
                "Tarik saldo",
                "Koreksi",
                "Penerimaan proyek",
                "Danai proyek",
                "PPH 23",
                "Penerimaan denda",
                "Retur",
                "Asuransi"
            ],
            minDate: new Date().setMonth(new Date().getMonth() - 1),
            maxDate: new Date()
        };
        this.handleClick = this.handleClick.bind(this);
        this.minDateChange = this.minDateChange.bind(this);
        this.maxDateChange = this.maxDateChange.bind(this);
    }

    handleClick(pageNumber) {
        window.scrollTo(0, 0);
        this.setState({
            currentPage: pageNumber
        });
    }

    handleChangeSearch = (event) => {
        this.setState({ filter: event.target.value });
    };

    minDate() {
        return (
            <DatePicker
                className="input-date"
                autoComplete="off"
                dateFormat="dd MMM yy"
                selected={this.state.minDate}
                onChange={this.minDateChange}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                name="minDate"
                maxDate={new Date()}
            />
        );
    }

    maxDate() {
        return (
            <DatePicker
                className="input-date"
                autoComplete="off"
                dateFormat="dd MMM yy"
                selected={this.state.maxDate}
                onChange={this.maxDateChange}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                name="maxDate"
                maxDate={new Date()}
            />
        );
    }

    minDateChange(d) {
        this.setState({ minDate: d });
    }

    maxDateChange(d) {
        this.setState({ maxDate: d });
    }

    handleDateFilter = (event) => {
        switch (event) {
            case "Apply":
                this.props.handleChangeDateFilter(moment(this.state.minDate).format("YYYY-MM-DD"), moment(this.state.maxDate).format("YYYY-MM-DD"));
                break;
            case "Delete":
                this.props.handleChangeDateFilter("", "");
                break;
            default:
                break;
        }
        this.props.handleChangeDateFilter(moment(this.state.minDate).format("YYYY-MM-DD"), moment(this.state.maxDate).format("YYYY-MM-DD"));
    };

    DompetHistory = (currentPage, todosPerPage, lenghtHistory, filter) => {
        if (this.props.userTransaksi && this.props.userTransaksi.length > 0 && lenghtHistory > 0) {
            const history = this.props.userTransaksi;
            //sorting descending
            function compare(a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }
            //page
            const lowercasedFilter = filter.toLowerCase();
            const indexOfLastTodo = currentPage * todosPerPage;
            const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
            const currentTodos = history
                .filter((item) => {
                    if (item.tipe_pembayaran !== undefined) {
                        return this.state.filter === ""
                            ? item.status_tarik_saldo !== "Pengajuan"
                            : item.tipe_pembayaran.toLowerCase().includes(this.state.filter.toLowerCase());
                    }
                })
                .sort(compare)
                .slice(indexOfFirstTodo, indexOfLastTodo);
            // const currentTodos = val.riwayat_transaksi_saldo.sort(compare)

            return currentTodos
                .filter((item) => {
                    return Object.keys(item).some(
                        (key) => typeof item[key] === "string" && item[key].toString().toLowerCase().includes(lowercasedFilter)
                    );
                })
                .map((item, index) => {
                
                    const metodeBayar = item.metode_pembayaran === "Dipay" ? "Dipay" : "Dompet";
                    //   console.log(item.status_tarik_saldo)
                    return (
                        <div key={index}>
                            <div className="transaction p-3">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                                <p className="fs-n1 text-secondary mb-1">Tanggal Transaksi</p>
                                                <p className="mb-0 text-warning">{new Date(item.createdAt).toLocaleDateString("IN")}</p>
                                            </div>
                                            <div className="col-6 col-md-4 mb-3 mb-xl-0">
                                                <p className="fs-n1 text-secondary mb-1">Deskripsi</p>
                                                <p className="mb-0">
                                                    {item.deskripsi_pembayaran} [ {metodeBayar} ]
                                                </p>
                                            </div>
                                            <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                                <p className="fs-n1 text-secondary mb-1">Status</p>
                                                <p className="mb-0">{item.status_tarik_saldo === undefined ? "Berhasil" : item.status_tarik_saldo}</p>
                                            </div>
                                            <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                                <p className="fs-n1 text-secondary mb-1">Nominal</p>

                                                <p className={colorCurrency(item.tipe_pembayaran, item.status_tarik_saldo)}>
                                                    <NumberFormat
                                                        value={item.total_nilai_transaksi}
                                                        displayType={"text"}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        decimalScale={0}
                                                        renderText={(value) => (
                                                            <span>{ballanceCurrency(item.tipe_pembayaran, item.status_tarik_saldo) + value}</span>
                                                        )}
                                                    />
                                                </p>
                                            </div>
                                            <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                                <p className="fs-n1 text-secondary mb-1">Saldo</p>
                                                <p className="mb-0">
                                                    Rp {item.saldo_transaksi === undefined ? "" : item.saldo_transaksi.toLocaleString("IN")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                });
        } else {
            return <NoData symbol={<ChartPie />} keterangan={"Belum ada Transaksi"} />;
        }
    };
    render() {
        const { filter, filterTransaction } = this.state;

        const { currentPage, todosPerPage } = this.state;
        //pagination history transaksi
        const lenghtHistory = () => {
            if (this.state.filter === "") {
                return (
                    this.props.userTransaksi &&
                    this.props.userTransaksi.filter((item) => {
                        if (item.tipe_pembayaran !== undefined) {
                            return item.status_tarik_saldo !== "Pengajuan";
                        }
                    }).length
                );
            } else {
                return (
                    this.props.userTransaksi &&
                    this.props.userTransaksi.filter((item) => {
                        if (item.tipe_pembayaran !== undefined) {
                            return item.tipe_pembayaran.toLowerCase().includes(this.state.filter.toLowerCase());
                        }
                    }).length
                );
            }
        };
        return (
            <div>
                <div className="d-flex align-items-center justify-content-between my-3 p-3">
                    <div>
                        <div className="input-group">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Cari Transaksi"
                                value={filter}
                                onChange={this.handleChangeSearch}
                            />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <i className="material-icons-outlined">search</i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <UncontrolledDropdown className="mr-3 dropdown-container">
                            <DropdownToggle color="#fff" outline={false} caret className="text-filter">
                                Tanggal
                            </DropdownToggle>
                            <DropdownMenu className="menu-dropdown pt-3">
                                <div className="px-3">
                                    <div>
                                        <div className="d-flex">
                                            <p className="title-history">Atur Tanggal</p>
                                        </div>
                                        <div className="d-flex mb-2 justify-content-between">
                                            <div className="mr-3">{this.minDate()}</div>
                                            <div className="mr-3">{this.maxDate()}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <DropdownItem className="filter-text" onClick={() => this.handleDateFilter("Delete")}>
                                        Hapus Filter
                                    </DropdownItem>
                                    <DropdownItem className="filter-text active-item-menu" onClick={() => this.handleDateFilter("Apply")}>
                                        Terapkan
                                    </DropdownItem>
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown className="dropdown-container">
                            <DropdownToggle color="#fff" outline={false} caret className="text-filter">
                                {this.props.textFilter}{" "}
                            </DropdownToggle>
                            <DropdownMenu className="menu-dropdown">
                                {filterTransaction.map((text, i) => {
                                    return (
                                        <DropdownItem
                                            key={i}
                                            onClick={() => this.props.handleChangeFilter(text)}
                                            className={`${text === this.props.textFilter ? "active-item" : ""}`}>
                                            {text}
                                        </DropdownItem>
                                    );
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>

                {this.props.user.isLoadingBalance === true ? (
                    <div>
                        <Loading />
                        <Loading />
                    </div>
                ) : (
                    this.DompetHistory(currentPage, todosPerPage, lenghtHistory(), filter)
                )}
                <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        hideDisabled
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.currentPage}
                        itemsCountPerPage={this.state.todosPerPage}
                        totalItemsCount={parseInt(lenghtHistory())}
                        onChange={this.handleClick.bind(this)}
                    />
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps)(DompetTransaksi);
