import React, { Component } from "react";
import Pendanaan from "./TabPendanaan";
import TabDetailProyek from "../../guest/proyek/TabDetailProyek";
import TabInformasiPeminjam from "../../guest/proyek/TabInformasiPeminjam";
import { NavbarTitle, NavbarContent } from "../../../library/components/NavbarComponent";
class DetailTab extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 0,
            project: []
        };
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    render() {
        const title = ["Informasi Pendanaan", "Detail Proyek", "Informasi Peminjam"];
        const children = [
            <Pendanaan lending={this.props.lending} />,
            <TabDetailProyek project={this.props.project} className={"col-md-7"} />,
            <TabInformasiPeminjam project={this.props.project} className={"col-md-7"} />
        ];

        return (
            <div className="col-12 col-lg-12">
                <div className="dasbor-wrapper">
                    <NavbarTitle title={title} activeTab={this.state.activeTab} toggle={this.toggle} />
                    <NavbarContent activeTab={this.state.activeTab} components={children} />
                </div>
            </div>
        );
    }
}

export default DetailTab;
