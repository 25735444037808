import React, { Component } from "react";
import Employee from "./EmployeeR";
import Micro from "./MicroR";
import Modal from "./ModalR";

class ShowFormPerorangan extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    pilih = () => {
        if (this.props.match.params.pilih === "1") {
            return <Modal />;
        } else if (this.props.match.params.pilih === "2") {
            return <Micro />;
        } else {
            return <Employee />;
        }
    };

    render() {
        return (
            <div>
                <section id="daftar" className="with-pad">
                    <div className="container no-lg">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-12 col-lg-10 col-xl-12">
                                <div className="card border-0">
                                    <div className="card-body">
                                        <div className="row d-flex justify-content-center mb-5">
                                            <div className="col-md-4"></div>
                                        </div>
                                        {this.pilih()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ShowFormPerorangan;
