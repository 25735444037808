import React, { Component } from "react";
import NoData from "../../assets/no data/NoData";
import Pagination from "react-js-pagination";
import { LoadingDashboard } from "../../assets/loading data/LoadingBorrower";

class CPending extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            todosPerPage: 15,
            filter: ""
        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(pageNumber) {
        window.scrollTo(0, 0);
        this.setState({
            currentPage: pageNumber
        });
    }
    handleChangeSearch = (event) => {
        this.setState({ filter: event.target.value });
    };

    PendingProject = (project, currentPage, todosPerPage, lengthPending) => {
        if (project === undefined) {
            // console.log('nono')
        } else {
            const indexOfLastTodo = currentPage * todosPerPage;
            const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
            //sorting descending
            function compare(a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }
            const currentTodos = project
                .filter(
                    (item) =>
                        item.status_proyek === "Pengajuan pinjaman" ||
                        item.status_proyek === "Menggalang dana" ||
                        item.status_proyek === "Proyek sedang berjalan" ||
                        item.status_proyek === "Terdanai sebagian" ||
                        item.status_proyek === "Batal" ||
                        item.status_proyek === "Proses persetujuan"
                )
                .sort(compare)
                .slice(indexOfFirstTodo, indexOfLastTodo);
            // console.log(project)
            if (lengthPending !== 0) {
                const hasil = currentTodos.map((val) => {
                    return (
                        <div key={val._id}>
                            <div className="transaction p-3">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-xl- mb-3 mb-xl-0">
                                                <div className="row">
                                                    <div className="col-6 col-md-3 mb-3">
                                                        <p className="fs-n1 text-secondary mb-1">Tanggal Pengajuan</p>
                                                        <p className="mb-0">{new Date(val.tgl_pengajuan_pinjaman).toLocaleDateString("IN")}</p>
                                                    </div>
                                                    <div className="col-6 col-md-3 mb-3">
                                                        <p className="fs-n1 text-secondary mb-1">Nominal yg diajukan</p>
                                                        <p className="mb-0">Rp {val.nilai_permohonan_pinjaman.toLocaleString("IN")}</p>
                                                    </div>
                                                    <div className="col-6 col-md-4 mb-3">
                                                        <p className="fs-n1 text-secondary mb-1">Status</p>
                                                        <p className="mb-0">{val.status_proyek}</p>
                                                    </div>

                                                    <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                                        <p className="fs-n1 text-secondary mb-1">Tenor</p>
                                                        <p className="mb-0">{val.jangka_waktu_pinjaman} Bulan</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                });
                return hasil;
            } else {
                if (this.props.loading) {
                    return (
                        <div>
                            <LoadingDashboard />
                            <LoadingDashboard />
                        </div>
                    );
                } else {
                    return <NoData symbol={<i className="material-icons-outlined">assignment</i>} keterangan={"Belum ada Pinjaman"} />;
                }
            }
        }
    };
    render() {
        const { currentPage, todosPerPage } = this.state;
        const lengthPending =
            this.props.project === undefined
                ? ""
                : this.props.project.filter(
                      (item) =>
                          item.status_proyek === "Pengajuan pinjaman" ||
                          item.status_proyek === "Menggalang dana" ||
                          item.status_proyek === "Proyek sedang berjalan" ||
                          item.status_proyek === "Terdanai sebagian" ||
                          item.status_proyek === "Batal" ||
                          item.status_proyek === "Proses persetujuan"
                  ).length;
        return (
            <div>
                {this.props.isLoadingGetUser ? (
                    <div>
                        <LoadingDashboard />
                        <LoadingDashboard />
                    </div>
                ) : (
                    this.PendingProject(this.props.project, currentPage, todosPerPage, lengthPending)
                )}
                <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        hideDisabled
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.currentPage}
                        itemsCountPerPage={this.state.todosPerPage}
                        totalItemsCount={parseInt(lengthPending)}
                        onChange={this.handleClick.bind(this)}
                    />
                </div>
            </div>
        );
    }
}
export default CPending;
