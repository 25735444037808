import React, { Component } from "react";
import { Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Input, Button, FormText } from "reactstrap";
import { ForgetPassword } from "../../../../redux/action/Password";
import { connect } from "react-redux";
import "../../../../sass/loader.css";
import Swal from "sweetalert2";
import { encryptedData } from "../../../../library/helper/Crypto";

const queryString = require("query-string");

class FormForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: "",
            konfirmPassword: "",
            errPasswordNew: "",
            errPasswordConfirm: "",
            token: "",
            hiddenNew: true,
            hiddenConfirm: true
        };
        this.showHideNew = this.showHideNew.bind(this);
        this.showHideConfirm = this.showHideConfirm.bind(this);
    }

    showHideNew() {
        this.setState({ hiddenNew: !this.state.hiddenNew });
    }
    showHideConfirm() {
        this.setState({ hiddenConfirm: !this.state.hiddenConfirm });
    }

    componentDidMount() {
        // window.scrollTo(0, 0);
        const values = queryString.parse(this.props.location.search);
        const { tokenResetPassword } = values;
        this.setState({
            token: tokenResetPassword
        });
    }

    onChangeNewPassword = (newPassword) => {
        // console.log(password.target.value)
        var regex = new Array();
        regex.push("[A-Z]"); //Uppercase Alphabet.
        regex.push("[a-z]"); //Lowercase Alphabet.
        regex.push("[0-9]"); //Digit.
        regex.push("[$@$!%*#?&]"); //Special Character.

        var passed = 0;

        //Validate for each Regular Expression.
        for (var i = 0; i < regex.length; i++) {
            if (new RegExp(regex[i]).test(newPassword.target.value)) {
                passed++;
            }
        }
        //kondisi password harus mengandung huruf besar, huruf kecil, angka dan simbol
        if (passed === 1 || passed === 2 || passed === 3) {
            if (newPassword.target.value.length < 8) {
                this.setState({
                    errPasswordNew: "Kata sandi harus melebihi 8 karakter"
                });
                this.setState({ newPassword });
                return false;
            } else {
                this.setState({
                    errPasswordNew: "Kata sandi harus mengandung simbol, angka, huruf besar, huruf kecil"
                });
                this.setState({ newPassword });
                return false;
            }
        } else if (passed === 4) {
            if (newPassword.target.value.length < 8) {
                this.setState({
                    errPasswordNew: "Kata sandi harus melebihi 8 karakter"
                });
                this.setState({ newPassword });
                return false;
            } else {
                this.setState({
                    newPassword: newPassword.target.value,
                    errPasswordNew: false
                });
            }
        }
    };
    onChangeConfirmPassword = (konfirmPassword) => {
        // console.log(password.target.value)
        var regex = new Array();
        regex.push("[A-Z]"); //Uppercase Alphabet.
        regex.push("[a-z]"); //Lowercase Alphabet.
        regex.push("[0-9]"); //Digit.
        regex.push("[$@$!%*#?&]"); //Special Character.

        var passed = 0;

        //Validate for each Regular Expression.
        for (var i = 0; i < regex.length; i++) {
            if (new RegExp(regex[i]).test(konfirmPassword.target.value)) {
                passed++;
            }
        }

        if (passed === 1 || passed === 2 || passed === 3) {
            if (konfirmPassword.target.value.length < 8) {
                this.setState({
                    errPasswordConfirm: "Kata sandi harus melebihi 8 karakter"
                });
                this.setState({ konfirmPassword });
                return false;
            } else {
                this.setState({
                    errPasswordConfirm: "Kata sandi harus mengandung simbol, angka, huruf besar, huruf kecil"
                });
                return false;
            }
        } else if (passed === 4) {
            if (konfirmPassword.target.value.length < 8) {
                this.setState({
                    errPasswordConfirm: "Kata sandi harus melebihi 8 karakter"
                });
                return false;
            } else if (konfirmPassword.target.value !== this.state.newPassword) {
                // console.log(this.state.password)
                this.setState({
                    errPasswordConfirm: "Password harus sama"
                });
                return false;
            } else {
                this.setState({
                    konfirmPassword: konfirmPassword.target.value,
                    errPasswordConfirm: false
                });
            }
        }
    };

    handleChangePassword = async () => {
        if (this.state.errPasswordNew === false && this.state.errPasswordConfirm === false) {
            await this.props
                .dispatch(
                    ForgetPassword({
                        token: this.state.token,
                        // newPassword: this.state.newPassword,
                        // konfirmPassword: this.state.konfirmPassword
                        newPassword: encryptedData(this.state.newPassword),
                        konfirmPassword: encryptedData(this.state.konfirmPassword)
                    })
                )
                .then((res) => {
                    this.props.history.push("/success-password");
                })
                .catch((err) => {
                    if (err.response.status === 404) {
                        Swal.fire({
                            title: "Gagal",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else {
                        this.props.history.push(`/ForgetPassword?tokenResetPassword=${this.state.token}`);
                    }
                });
        }
    };
    render() {
        return (
            <section id="daftar-lender" className="with-pad bg-grey">
                <div className="container">
                    <div className="sec-header">
                        <h4 className="text-center sec-title">Lupa Kata Sandi</h4>
                        <p className="text-center sec-desc">Silahkan masukkan password baru anda </p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-7 col-lg-5 col-xl-4">
                            <div className="card border-0">
                                <div className="card-body">
                                    <Form>
                                        <FormGroup>
                                            <Label for="Nama_pengguna">Password Baru</Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="material-icons-outlined">lock </i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    onChange={this.onChangeNewPassword}
                                                    type={this.state.hiddenNew ? "password" : "text"}
                                                    name="newPassword"
                                                    id="nama"
                                                    placeholder="Masukan kata sandi Anda"
                                                />
                                                <InputGroupAddon addonType="prepend">
                                                    <Button color="white" onClick={this.showHideNew} className="password-eye">
                                                        {this.state.hiddenNew === false ? (
                                                            <i className="fa fa-eye" aria-hidden="true" />
                                                        ) : (
                                                            <i className="fa fa-eye-slash" aria-hidden="true" />
                                                        )}
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                            <FormText color="danger">{this.state.errPasswordNew}</FormText>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="Nama_pengguna">Konfirmasi Password Baru</Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="material-icons-outlined">lock </i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    onChange={this.onChangeConfirmPassword}
                                                    type={this.state.hiddenConfirm ? "password" : "text"}
                                                    name="konfirmPassword"
                                                    id="nama"
                                                    placeholder="Masukan kata sandi Anda"
                                                />
                                                <InputGroupAddon addonType="prepend">
                                                    <Button color="white" onClick={this.showHideConfirm} className="password-eye">
                                                        {this.state.hiddenConfirm === false ? (
                                                            <i className="fa fa-eye" aria-hidden="true" />
                                                        ) : (
                                                            <i className="fa fa-eye-slash" aria-hidden="true" />
                                                        )}
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                            <FormText color="danger">{this.state.errPasswordConfirm}</FormText>
                                        </FormGroup>
                                        {this.props.password.isLoading === true ? (
                                            <Button
                                                disabled
                                                color="primary"
                                                onClick={this.handleChangePassword}
                                                className="btn btn-lg btn-block card-submit">
                                                <span className="loader"></span>
                                            </Button>
                                        ) : (
                                            <Button color="primary" onClick={this.handleChangePassword} className="btn btn-lg btn-block card-submit">
                                                Kirim
                                            </Button>
                                        )}
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        password: state.password
    };
};
export default connect(mapStateToProps)(FormForgetPassword);
