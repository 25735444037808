import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Button, ButtonGroup } from "reactstrap";
import { Register, RegisterLender } from "../../../redux/action/User";
import Swal from "sweetalert2";
import "../../../sass/loader.css";
import { validate } from "../../../library/validation/validate";
import { changeCapitalize } from "../../../library/helper/helper";
import { InputIcon } from "../../../library/components/Components";
import { InfoBrowser } from "../../../library/helper/helper";
import { encryptedData } from "../../../library/helper/Crypto";
import ModalKebijakanPrivacy from "../welcome/modalPrivacy/ModalKebijakanPrivacy";
class DaftarUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nama_pengguna: "",
            email: "",
            no_handphone: "",
            jenis_kelamin: "",
            password: "",
            passwordConfirm: "",
            errEmail: "",
            errPassword: "",
            errPasswordConfirm: "",
            errNama: "",
            errNumber: "",
            hidden: true,
            hiddenConfirm: true,
            pil: 1,
            modalKebjiakan: false
        };
        this.button = this.button.bind(this);
        this.showHide = this.showHide.bind(this);
        this.showHideConfirm = this.showHideConfirm.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        //tidak dapat membuka foto selfie jika mau daftar borrower
        if (this.props.match.params.user === "2") {
            InfoBrowser();
        }
    }

    showHide() {
        this.setState({ hidden: !this.state.hidden });
    }

    showHideConfirm() {
        this.setState({ hiddenConfirm: !this.state.hiddenConfirm });
    }

    handleChange = (e, type, changeError) => {
        // console.log(e.target.name)
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({
                [changeError]: validate(type, value, this.state.password, this.state.passwordConfirm)
            });
        });
    };

    button(e) {
        if (e.target.name === "badan hukum") {
            this.setState({
                pil: 2
            });
        } else {
            this.setState({
                pil: 1
            });
        }
    }

    toggleKebijakanPrivacy() {
        this.setState({
            modalKebjiakan: !this.state.modalKebjiakan
        });
    }

    handleRegisterValidate = async () => {
        if (
            this.state.errEmail !== 0 ||
            this.state.errPassword !== 0 ||
            this.state.errPasswordConfirm !== 0 ||
            this.state.errNama !== 0 ||
            this.state.errNumber !== 0
        ) {
            Swal.fire({
                title: "Gagal Daftar",
                text: "Mohon untuk periksa kembali data anda",
                icon: "warning",
                buttons: "ok"
            });
        } else {
            this.toggleKebijakanPrivacy();
        }
    };

    handleRegister = async () => {
        const { email, passwordConfirm, nama_pengguna, no_handphone } = this.state;
        await this.props
            .dispatch(
                Register({
                    email: encryptedData(email),
                    password: encryptedData(passwordConfirm),
                    nama_pengguna: encryptedData(changeCapitalize(nama_pengguna)), //get from helper
                    no_handphone: encryptedData(no_handphone)
                })
            )
            .then(async () => {
                //save lender
                if (this.props.match.params.user === "1") {
                    await this.props
                        .dispatch(
                            RegisterLender({
                                jenis_pengguna: this.state.pil
                            })
                        )
                        .then(() => {
                            localStorage.setItem("verif", true);
                            this.props.history.push("/emailverif/1");
                            window.location.reload();
                        })
                        .catch((err) => {
                            // console.log(err.response)

                            if (err.response.status === 400) {
                                Swal.fire({
                                    title: "Notifikasi",
                                    text: `${err.response.data.message}`,
                                    icon: "warning",
                                    button: "ok"
                                });
                            } else if (err.response.status === 404) {
                                Swal.fire({
                                    title: "Gagal",
                                    text: `${err.response.data.message}`,
                                    icon: "warning",
                                    button: "ok"
                                });
                            } else if (err.response.status === 409) {
                                Swal.fire({
                                    title: "Gagal",
                                    text: `${err.response.data.message}`,
                                    icon: "warning",
                                    button: "ok"
                                });
                            }
                        });
                    //move to borrower form
                } else {
                    if (this.state.pil === 1) {
                        this.props.history.push("/regist/personal");
                    } else {
                        this.props.history.push("/regist/badanhukum");
                    }
                }
            })
            .catch((err) => {
                // console.log(err.response)
                if (err.response.status === 404) {
                    Swal.fire({
                        title: "Gagal Register",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (err.response.status === 400) {
                    Swal.fire({
                        title: "Gagal Register",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                }
            })
            .finally(() => {
                this.toggleKebijakanPrivacy();
            });
    };

    handleLoadingButtonDaftar() {
        if (this.props.match.params.user === "1") {
            return this.props.user.isLoadingRegistL === true ? (
                <Button disabled color="primary" className="btn btn-primary btn-lg btn-block card-submit" onClick={this.handleRegisterValidate}>
                    <span className="loader"></span>
                </Button>
            ) : (
                <Button color="primary" className="btn btn-primary btn-lg btn-block card-submit" onClick={this.handleRegisterValidate}>
                    Selanjutnya
                </Button>
            );
        } else {
            return this.props.user.isLoadingRegist === true ? (
                <Button disabled color="primary" className="btn btn-primary btn-lg btn-block card-submit" onClick={this.handleRegisterValidate}>
                    <span className="loader"></span>
                </Button>
            ) : (
                <Button color="primary" className="btn btn-primary btn-lg btn-block card-submit" onClick={this.handleRegisterValidate}>
                    Selanjutnya
                </Button>
            );
        }
    }

    render() {
        return (
            <section id="daftar-lender" className="with-pad bg-grey">
                <div className="container pt-5">
                    <div className="sec-header">
                        <h4 className="text-center sec-title">
                            {this.props.match.params.user === "1" ? "Daftar Sebagai Pemberi Dana" : "Daftar Sebagai Penerima Dana"}
                        </h4>
                        <p className="text-center sec-desc">
                            {this.props.match.params.user === "1"
                                ? "Kembangkan dana Anda Bersama Indofund, daftar dan langsung mendanai!"
                                : "Pendanaan modal sesuai kebutuhan usahamu, daftar dan ajukan pendanaan"}
                        </p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-7 col-lg-5 col-xl-4">
                            <h5 className="text-center pb-3 font-weight-bold text-primary">Pilih Jenis Akun</h5>
                            <ButtonGroup className="d-flex justify-content-center pb-4">
                                <Button size="lg" outline={this.state.pil === 1 ? false : true} color="primary" name="personal" onClick={this.button}>
                                    Personal
                                </Button>
                                <Button
                                    size="lg"
                                    outline={this.state.pil > 1 ? false : true}
                                    color="primary"
                                    name="badan hukum"
                                    onClick={this.button}>
                                    Badan Hukum
                                </Button>
                            </ButtonGroup>
                            <p className="text-center pb-5">Anda memilih akun sebagai {this.state.pil === 1 ? "Personal" : "Badan Hukum"}</p>
                            <div className="card border-0">
                                <div className="card-body">
                                    {this.props.match.params.user !== "1" ? (
                                        <div className="alert-box-warning" style={{ margin: "9px 15px" }}>
                                            <i className="material-icons-outlined icon">info</i>
                                            <span className="mr-2">
                                                Dikarenakan pandemi, Indofund untuk saat ini hanya memproses pengajuan pendanaan daerah Jakarta
                                            </span>
                                        </div>
                                    ) : null}

                                    <Form>
                                        <InputIcon
                                            forLabel={"nama_pengguna"}
                                            label={this.state.pil === 1 ? "Nama Lengkap (Sesuai KTP)" : "Nama Perusahaan"}
                                            icon={<i className="material-icons-outlined">account_circle</i>}
                                            type={"text"}
                                            name={"nama_pengguna"}
                                            onChange={(e) => this.handleChange(e, "string", "errNama")}
                                            placeholder={"Contoh: John Doe"}
                                            maxLength={""}
                                            error={this.state.errNama}
                                        />
                                        <InputIcon
                                            forLabel={"email"}
                                            label={"Email"}
                                            icon={<i className="material-icons-outlined">mail</i>}
                                            type={"email"}
                                            name={"email"}
                                            onChange={(e) => this.handleChange(e, "email", "errEmail")}
                                            placeholder={"Contoh: example@email.com"}
                                            maxLength={""}
                                            error={this.state.errEmail}
                                        />
                                        <InputIcon
                                            forLabel={"no_handphone"}
                                            label={this.state.pil === 1 ? "No Handphone" : "No Handphone PIC Perusahaan"}
                                            icon={<i className="material-icons-outlined">smartphone</i>}
                                            type={"text"}
                                            name={"no_handphone"}
                                            onChange={(e) => this.handleChange(e, "number", "errNumber")}
                                            placeholder={"Contoh: 081xxxxxxxxxxx"}
                                            maxLength={""}
                                            error={this.state.errNumber}
                                        />
                                        <InputIcon
                                            forLabel={"password"}
                                            label={"Kata Sandi"}
                                            icon={<i className="material-icons-outlined">lock</i>}
                                            type={this.state.hidden ? "password" : "text"}
                                            name={"password"}
                                            onChange={(e) => this.handleChange(e, "password", "errPassword")}
                                            placeholder={"Kata sandi anda"}
                                            maxLength={""}
                                            error={this.state.errPassword}
                                            className={"password-form"}
                                            info={"*Case sensitive"}
                                            password={true}
                                            clickShow={this.showHide}
                                            hidden={this.state.hidden}
                                        />
                                        <InputIcon
                                            forLabel={"passwordConfirm"}
                                            label={"Konfirmasi Kata Sandi"}
                                            icon={<i className="material-icons-outlined">lock</i>}
                                            type={this.state.hiddenConfirm ? "password" : "text"}
                                            name={"passwordConfirm"}
                                            onChange={(e) => this.handleChange(e, "passwordConfirm", "errPasswordConfirm")}
                                            placeholder={"Kata sandi anda"}
                                            error={this.state.errPasswordConfirm}
                                            className={"password-form"}
                                            info={"*Case sensitive"}
                                            password={true}
                                            clickShow={this.showHideConfirm}
                                            hidden={this.state.hiddenConfirm}
                                        />

                                        {this.handleLoadingButtonDaftar()}
                                    </Form>
                                    <ModalKebijakanPrivacy
                                        isOpen={this.state.modalKebjiakan}
                                        toggle={this.toggleKebijakanPrivacy.bind(this)}
                                        isLoading={this.props.user.isLoadingRegistL || this.props.user.isLoadingRegist}
                                        onClick={this.handleRegister.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps)(DaftarUser);
