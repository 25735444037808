import React, { Component } from "react";
import Menu from "../template/borrower/SidebarMenu";
import Routes from "../../routes/RouteBorrower";
import "./Borrower.scss";
import Swal from "sweetalert2";
import { BrowserRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { AcceptTerm, TKB90, getUser } from "../../redux/action/User";
import { BorrowerId } from "../../redux/action/Borrower";
import Master from "../template/borrower/Master";
import axios from "axios";
import { getProjectByBorrower, getProjectByBorrowerSign, getProjectByBorrowerSigned } from "../../redux/action/Project";
import ModalKebijakanPrivacy from "../guest/welcome/modalPrivacy/ModalKebijakanPrivacy";
import { ShowElementContext } from "../../context/ShowElementContextProvider";

const url = process.env.REACT_APP_BASSE_API;

class Borrower extends Component {
  static contextType = ShowElementContext;
  constructor(props) {
    super(props);
    this.state = {
      jenis_pengguna: "",
      no_hp: "",
      email: "",
      nama_pengguna: "",
      saldo: "",
      borrower: "",
      employee: "",
      id: "",
      project: [],
      bank: [],
      va: "",
      nicepay_register: "",
      kyc: "",
      userDigisign: "",
      plafond_borrower: "",
      plafond_terpakai: "",
      update_borrower: "",
      micro: "",
      lender_personal: 1,
      tkb: { tkb0: '', tkb30: '', tkb60: '', tkb90: '' },
      isOpenTkbModal: false,
      modalKebijakan: false,
      term_timestamp: false,
      query: {
        limit: 10,
        sorting: 'createdAt',
        sort: 'desc',
      }
    };
  }

  toggleKebijakanPrivacyCheck() {
    this.setState({
      modalKebijakan: !this.state.modalKebijakan
    });
  }

  logout = (e) => {
    // console.log(this.props)
    e.preventDefault();
    Swal.fire({
      title: "Logout",
      text: "Yakin keluar dari akun anda ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Keluar sekarang"
    }).then((result) => {
      if (result.value) {
        axios.post(
          `${url}/user/logout`,
          { accessToken: localStorage.accessToken },
          {
            headers: { "x-auth-token": localStorage.jwtToken }
          }
        );
        localStorage.removeItem("id");
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("email");
        localStorage.removeItem("kode_pengguna");
        localStorage.removeItem("nama_pengguna");
        localStorage.removeItem("justOnce");
        localStorage.removeItem("masuk");
        localStorage.removeItem("no_hp");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("verif");
        localStorage.removeItem("kyc");
        window.location.reload();
        window.location.href = "/";
      }
    });
  };

  handleAcceptTerm = async () => {
    await this.props.dispatch(AcceptTerm()).finally(() => {
      this.toggleKebijakanPrivacyCheck();
    });
  };

  async componentDidMount() {
    localStorage.setItem("masuk", "2");

    await this.props.dispatch(getUser(localStorage.id));
    await this.props.dispatch(BorrowerId(this.props.user.userList.borrower_id._id));

    this.setState({
      term_timestamp: this.props.user.userList.term_timestamp,
      nama_pengguna: this.props.borrower.borrowerDetail.nama_borrower,
      jenis_pengguna: this.props.borrower.borrowerDetail.jenis_pengguna,
      saldo: this.props.user.userList.va_saldo.nilai_saldo.toLocaleString("IN"),
      borrower: this.props.borrower.borrowerDetail,
      employee: this.props.borrower.borrowerDetail.employee_loan,
      micro: this.props.borrower.borrowerDetail.micro_financing,
      update_borrower: this.props.borrower.borrowerDetail.update_borrower,
      id: this.props.borrower.borrowerDetail._id,
      bank: this.props.user.userList.bank,
      va: this.props.user.userList.va_saldo.va_saldo,
      nicepay_register: this.props.user.userList.nicepay_register,
      no_hp: this.props.user.userList.no_handphone,
      email: this.props.user.userList.email,
      kyc: this.props.user.userList.kyc, //dari admin ke digisign
      userDigisign: this.props.user.userList.userAktivasi, //user mau verifikasi email

      plafond_borrower: this.props.borrower.borrowerDetail.plafond_borrower, //plafond yg dipunya borrower
      plafond_terpakai: this.props.borrower.borrowerDetail.plafond_terpakai
    });


    if (!localStorage.justOnce) {
      localStorage.setItem("justOnce", "true");
    }

    if (!this.state.term_timestamp) {
      this.setState({
        modalKebijakan: true
      });
    }
    await this.props.dispatch(TKB90());
    this.setState({
      tkb: { tkb0: this.props.user.TKB.tkb0, tkb30: this.props.user.TKB.tkb30, tkb60: this.props.user.TKB.tkb60, tkb90: this.props.user.TKB.tkb90 }
    });
    await this.props.dispatch(getProjectByBorrower(this.state.id))
    // await this.fetchProjectByBorrowerSign(this.state.query)
    // await this.fetchProjectByBorrowerSigned(this.state.query)
    this.setState({
      project: this.props.project.projectBorrower,


    });
  }
  toggleTkbModal() {
    this.setState({ isOpenTkbModal: !this.state.isOpenTkbModal })
  }
  authNotif() {
    if (this.state.bank.length === 0) {
      return (
        <div className="alert-box">
          <span className="mr-2">Silahkan lengkapi rekening bank untuk mengajukan pendanaan</span>
          <NavLink
            to={{
              pathname: "/borrower/akun/" + this.state.borrower._id,
              state: {
                jenis: `${this.state.jenis_pengguna}`
              }
            }}
            className="btn btn-light ml-auto text-primary px-4 font-weight-semi-bold">
            Lengkapi
          </NavLink>
        </div>
      );
    } else if (this.state.bank.length !== 0 && this.state.userDigisign !== true) {
      return (
        <div className="alert-box">
          <span className="mr-2">
            Silahkan aktivasi akun Digisign, dengan mengklik <span className="text-black-50">Aktivasi akun</span> pada menu tanda tangan
            digital.
          </span>
          <NavLink to="/borrower/sign" className="btn btn-light ml-auto text-primary px-4 font-weight-semi-bold">
            Tanda tangan digital
          </NavLink>
        </div>
      );
    } else if (this.state.update_borrower === true && this.state.kyc === false) {
      return (
        <div className="alert-box">
          <span className="mr-2">Data Anda tidak sesuai oleh Dukcapil. Mohon untuk mengganti data Anda.</span>
          <NavLink
            to={{
              pathname: "/borrower/akun/" + this.state.borrower._id,
              state: {
                jenis: `${this.state.jenis_pengguna}`
              }
            }}
            className="btn btn-light ml-auto text-primary px-4 font-weight-semi-bold">
            Lengkapi
          </NavLink>
        </div>
      );
    } else if (this.state.kyc === false) {
      return (
        <div className="alert-box">
          <span className="mr-2">Data anda sedang di verifikasi oleh admin.</span>
        </div>
      );
    }
  }

  render() {
    const { isShowSaldo, isShowSaldoDipay, toggleIsShowSaldo, toggleIsShowSaldoDipay } = this.context;

    return (
      <BrowserRouter>
        <Master
          onLogout={(e) => this.logout(e)}
          borrower={this.state.borrower}
          nama_pengguna={this.state.nama_pengguna}
          email={this.state.email}
          saldo={this.state.saldo}
          isShowSaldo={isShowSaldo}
          toggleIsShowSaldo={toggleIsShowSaldo}
          employee={this.state.employee}
          jenis={this.state.jenis_pengguna}
          bankVerif={this.state.bank}
          tkb={this.state.tkb}
          isOpenTkbModal={this.state.isOpenTkbModal}
          toggle={this.toggleTkbModal.bind(this)}
        />
        <section className="with-pad">
          <div className="container">
            <div className="row">
              <Menu
                nama_pengguna={this.state.nama_pengguna}
                borrower={this.state.borrower}
                email={this.state.email}
                jenis={this.state.jenis_pengguna}
                saldo={this.state.saldo}
                isShowSaldo={isShowSaldo}
                toggleIsShowSaldo={toggleIsShowSaldo}
                onLogout={(e) => this.logout(e)}
                employeeLoan={this.state.employee}
                loading={this.props.user.isLoadingGetUser}
                bankVerif={this.state.bank}
              />
              <div className="col-12 col-lg-9">
                {this.authNotif()}
                <Routes
                  borrower={this.state.borrower}
                  jenis={this.state.jenis_pengguna}
                  project={this.state.project}

                  nama_pengguna={this.state.nama_pengguna}
                  bankPengguna={this.state.bank}
                  saldo={this.state.saldo}
                  va={this.state.va}
                  nicepay_register={this.state.nicepay_register}
                  no_hp={this.state.no_hp}
                  email={this.state.email}
                  id={this.state.id}
                  kyc={this.state.kyc}
                  userDigisign={this.state.userDigisign}
                  micro={this.state.micro}
                  employee={this.state.employee}
                  plafond_borrower={this.state.plafond_borrower}
                  plafond_terpakai={this.state.plafond_terpakai}
                  userBank={this.state.bank}
                  isLoadingProjectBr={this.props.project.isLoadingProjectBr}
                  isLoadingProjectSign={this.props.project.isLoadingProjectSign}
                  isLoadingProjectSigned={this.props.project.isLoadingProjectSigned}
                  isLoadingGetUser={this.props.user.isLoadingGetUser}
                />
              </div>
            </div>
          </div>
        </section>
        <ModalKebijakanPrivacy
          isOpen={this.state.modalKebijakan}
          toggle={this.toggleKebijakanPrivacyCheck.bind(this)}
          isLoading={this.props.user.isLoadingTerm}
          onClick={this.handleAcceptTerm.bind(this)}
        />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    project: state.project,
    borrower: state.borrower
  };
};
export default connect(mapStateToProps)(Borrower);
