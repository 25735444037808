import React, { Component } from "react";
import { TabContent, TabPane } from "reactstrap";
import Pending from "./CPending";
import Aktif from "./CAktif";
import Selesai from "./CSelesai";

class DasborContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            project: [],
            id: ""
        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(event) {
        window.scrollTo(0, 0);
        this.setState({
            currentPage: Number(event.target.id)
        });
    }
    handleChangeSearch = (event) => {
        this.setState({ filter: event.target.value });
    };
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        // console.log(this.props.isLoadingGetUser)
        return (
            <div>
                <TabContent activeTab={this.props.activeTab}>
                    <TabPane tabId="1">
                        <Pending
                            project={this.props.project}
                            loading={this.props.isLoadingProjectBr}
                            isLoadingGetUser={this.props.isLoadingGetUser}
                        />
                    </TabPane>
                    <TabPane tabId="2">
                        <Aktif project={this.props.project} loading={this.props.isLoadingProjectBr} isLoadingGetUser={this.props.isLoadingGetUser} />
                    </TabPane>
                    <TabPane tabId="3">
                        <Selesai
                            project={this.props.project}
                            loading={this.props.isLoadingProjectBr}
                            isLoadingGetUser={this.props.isLoadingGetUser}
                        />
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}

export default DasborContent;
