import axios from "axios";

const url = process.env.REACT_APP_BASSE_API;

export const ListBlog = (data) => {
    return {
        type: "LIST_BLOG",
        payload: axios.get(`${url}/blog/catalog`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const CategoryBlog = (data) => {
    return {
        type: "CATEGORY_BLOG",
        payload: axios.get(`${url}/category/list`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const DetailBlog = (id) => {
    return {
        type: "DETAIL_BLOG",
        payload: axios.get(`${url}/blog/find/${id}`, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};
