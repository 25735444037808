import React, { Component } from "react";
import "./proyek.scss";
import Produktif from "./ProyekProduktif";
import Employee from "./ProyekEmployee";
class Proyek extends Component {
    render() {
        return (
            <div>
                <section id="proyek" className="welcome pt-5 pb-5">
                    <div className="container">
                        <div className="sec-header" style={{ marginBottom: "40px" }}>
                            <h4 className="sec-title text-center">Proyek Berjalan Saat Ini</h4>
                            <p className="font-weight-semi-bold text-center text-secondary">
                                Temukan peluang pendanaan yang menguntungkan pada hari ini
                            </p>
                            <h4 className="sec-title text-center pt-5">Proyek Produktif</h4>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-11">
                                <Produktif />
                            </div>
                        </div>
                        <div className="sec-header" style={{ marginBottom: "40px" }}>
                            <h4 className=" sec-title  text-center" style={{ marginTop: "80px" }}>
                                Proyek Employee Loan
                            </h4>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-11">
                                <Employee />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Proyek;
