import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./Accordion.scss";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    }
}));

export default function ControlledExpansionPanels(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [expandeds, setExpandeds] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleChanges = (panels) => (event, isExpandeds) => {
        setExpandeds(isExpandeds ? panels : false);
    };
    return (
        <div className={classes.root}>
            <ExpansionPanel expanded={expanded === `${props.panel}`} onChange={handleChange(`${props.panel}`)}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={props.panel + "panel1d-content"}
                    id={props.panel + "panel1d-header"}>
                    <img src={process.env.PUBLIC_URL + "/images/bank/" + props.nama_bank + ".png"} alt="bank" className="img-bank" />
                    <h5 className="title">{props.title}</h5>
                    {/* <Typography className={classes.secondaryHeading}>I am an expansion panel</Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div style={{ width: "100%" }}>
                        {props.body}
                        {/* tab 1 */}
                        <ExpansionPanel expandeds={expandeds === `${props.panels}`} onChange={handleChanges(`${props.panels}`)}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={props.panels + "panel1d-content"}
                                id={props.panels + "panel1d-header"}>
                                <h6 className="title text-primary">{props.title1}</h6>
                                {/* <Typography className={classes.secondaryHeading}>I am an expansion panel</Typography> */}
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div>{props.body1}</div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        {/* tab 2 */}
                        <ExpansionPanel expandeds={expandeds === `${props.panels}`} onChange={handleChanges(`${props.panels}`)}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={props.panels + "panel1d-content"}
                                id={props.panels + "panel1d-header"}>
                                <h6 className="title  text-primary">{props.title2}</h6>
                                {/* <Typography className={classes.secondaryHeading}>I am an expansion panel</Typography> */}
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div>{props.body2}</div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        {/* tab 3 */}
                        <ExpansionPanel expandeds={expandeds === `${props.panels}`} onChange={handleChanges(`${props.panels}`)}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={props.panels + "panel1d-content"}
                                id={props.panels + "panel1d-header"}>
                                <h6 className="title  text-primary">{props.title3}</h6>
                                {/* <Typography className={classes.secondaryHeading}>I am an expansion panel</Typography> */}
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div>{props.body3}</div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}
