import axios from "axios";

// const url = `http://localhost:3000`
const url = process.env.REACT_APP_BASSE_API;

export const BorrowerId = (id) => {
    return {
        type: "GET_BORROWER_ID",
        payload: axios.get(`${url}/borrower/find/${id}`, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const BorrowerUpdatePersonal = (id, data) => {
    return {
        type: "BORROWER_UPDATE_PERSONAL",
        payload: axios.patch(`${url}/borrower/update-borrower-personal/${id}`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const BorrowerUpdateEntity = (id, data) => {
    return {
        type: "BORROWER_UPDATE_ENTITY",
        payload: axios.patch(`${url}/borrower/update-borrower-entity/${id}`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const LoanPortofolio = () => {
    return {
        type: "PORTOFOLIO",
        payload: axios.get(`${url}/borrower/loan-portfolio`, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const PaymentLoan = (borrowerId, projectId, index, data) => {
    return {
        type: "BORROWER_PAYMENT_LOAN",
        payload: axios.post(`${url}/project/loan-payment/${borrowerId}/${projectId}/${index}`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const PaymentLoanMicro = (borrowerId, projectId, index, data) => {
    return {
        type: "BORROWER_PAYMENT_LOAN_MICRO",
        payload: axios.patch(`${url}/project/daily-micro-payment/${borrowerId}/${projectId}/${index}`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const PaymentPenalty = (borrowerId, projectId, index, data) => {
    return {
        type: "BORROWER_PAYMENT_PENALTY",
        payload: axios.post(`${url}/project/penalty-payment/${borrowerId}/${projectId}/${index}`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const UpdateDigisignBorrower = (data) => {
    return {
        type: "UPDATE_DIGISIGIN_BORROWER",
        payload: axios.patch(`${url}/borrower/change-borrower-data`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const MarketLocationMicro = () => {
    return {
        type: "MARKET_LOCATION_MICRO",
        payload: axios.get(`${url}/market-location/list-market`, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};
