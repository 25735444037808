const initialState = {
    projectList: [],
    projectAll: [],
    projectEmployee: [],
    projectDetail: null,
    projectInterest: null,
    projectLoan: [],
    projectBorrower: [],
    projectBorrowerSigned: [], 
    projectBorrowerSign: [], 
    pagination:{sign:{},signed:{}},
    isLoading: false,
    isError: false,
    isLoadingAllProject: true,
    isLoadingIdProject: false,
    isLoadingProjectBr: false,
    isLoadingProjectSigned:false,
    isLoadingProjectSign:false,
    isLoadingPreLending: false,
};

const ListProject = (state = initialState, action) => {
    switch (action.type) {
        case "GET_MAINPROJECT_PENDING":
            return {
                ...state,
                isLoading: true
            };
        case "GET_MAINPROJECT_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case "GET_MAINPROJECT_FULFILLED":
            // console.log(action.payload.data.data)
            return {
                ...state,
                isLoading: false,
                isError: false,
                projectList: action.payload.data.data
            };
        case "GET_EMPLOYEEPROJECT_PENDING":
            return {
                ...state,
                isLoading: true
            };
        case "GET_EMPLOYEEPROJECT_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case "GET_EMPLOYEEPROJECT_FULFILLED":
            // console.log(action.payload.data.data)
            return {
                ...state,
                isLoading: false,
                isError: false,
                projectEmployee: action.payload.data.data
            };
        case "GET_ALLPROJECT_PENDING":
            return {
                ...state,
                isLoadingAllProject: true
            };
        case "GET_ALLPROJECT_REJECTED":
            return {
                ...state,
                isLoadingAllProject: false,
                isError: true
            };
        case "GET_ALLPROJECT_FULFILLED":
            // console.log(action.payload.data.data)
            return {
                ...state,
                isLoadingAllProject: false,
                isError: false,
                projectAll: action.payload.data.data
            };
        case "GET_PROJECT_ID_PENDING":
            return {
                ...state,
                isLoadingIdProject: true
            };
        case "GET_PROJECT_ID_REJECTED":
            return {
                ...state,
                isLoadingIdProject: false,
                isError: true
            };
        case "GET_PROJECT_ID_FULFILLED":
            return {
                ...state,
                isLoadingIdProject: false,
                projectDetail: action.payload.data.data
            };
        case "GET_PROJECT_INTEREST_PENDING":
            return {
                ...state,
                isLoadingPreLending: true
            };
        case "GET_PROJECT_INTEREST_REJECTED":
            return {
                ...state,
                isLoadingPreLending: false,
                isError: true
            };
        case "GET_PROJECT_INTEREST_FULFILLED":
            return {
                ...state,
                isLoadingPreLending: false,
                projectInterest: action.payload.data.result
            };
        case "GET_PROJECT_BORROWER_ID_PENDING":
            return {
                ...state,
                isLoadingProjectBr: true
            };
        case "GET_PROJECT_BORROWER_ID_REJECTED":
            return {
                ...state,
                isLoadingProjectBr: false,
                isError: true
            };
        case "GET_PROJECT_BORROWER_ID_FULFILLED":
            return {
                ...state,
                isLoadingProjectBr: false,
                projectBorrower: action.payload.data.result
            };
        case "GET_PROJECT_BORROWER_ID_SIGN_PENDING":
            return {
                ...state,
                isLoadingProjectSign: true
            };
        case "GET_PROJECT_BORROWER_ID_SIGN_REJECTED":
            return {
                ...state,
                isLoadingProjectSign: false,
                isError: true
            };
        case "GET_PROJECT_BORROWER_ID_SIGN_FULFILLED":
          console.log(action.payload.data)
            return {
                ...state,
                isLoadingProjectSign: false,
                projectBorrowerSign: action.payload.data.data,
                pagination:{...state.pagination,
                  sign:{
                    limit: action.payload.data.limit,
                    page: action.payload.data.page,
                    totalData: action.payload.data.totalData,
                    totalPage: action.payload.data.totalPage
                  }}
            };
        case "GET_PROJECT_BORROWER_ID_SIGNED_PENDING":
            return {
                ...state,
                isLoadingProjectSigned: true
            };
        case "GET_PROJECT_BORROWER_ID_SIGNED_REJECTED":
            return {
                ...state,
                isLoadingProjectSigned: false,
                isError: true
            };
        case "GET_PROJECT_BORROWER_ID_SIGNED_FULFILLED":
            return {
                ...state,
                isLoadingProjectSigned: false,
                projectBorrowerSigned: action.payload.data.data,
                pagination:{...state.pagination,
                  signed:{
                    limit: action.payload.data.limit,
                    page: action.payload.data.page,
                    totalData: action.payload.data.totalData,
                    totalPage: action.payload.data.totalPage
                  }}
            };

        case "CREATE_PENGAJUAN_PENDING":
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case "CREATE_PENGAJUAN_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case "CREATE_PENGAJUAN_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoading: false,
                projectLoan: action.payload.data
            };
        default:
            return state;
    }
};
export default ListProject;
