import React, { Component } from "react";
import { DetailBlog } from "../../../redux/action/Blog";
import { connect } from "react-redux";
import BeritaTerkait from "./BlogTerkait";
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, TelegramIcon, WhatsappIcon } from "react-share";
import Loader from "react-loader-spinner";

class BlogDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            publish: "",
            image: "",
            title: "",
            content: "",
            admin: "",
            category: ""
        };
    }
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        await this.props.dispatch(DetailBlog(this.props.match.params.blog_id));
        this.setState({
            publish: this.props.blog.detailBlog.tgl_publikasi,
            category: this.props.blog.detailBlog.kategori,
            image: this.props.blog.detailBlog.gambar_blog,
            title: this.props.blog.detailBlog.judul,
            content: this.props.blog.detailBlog.konten
            // admin: this.props.blog.detailBlog.penulis.nama_admin
        });
    };
    render() {
        const shareUrl = window.location.href;

        return (
            <div className="section-blog with-pad">
                {this.props.blog.isLoadingDetail ? (
                    <Loader type="ThreeDots" color="#FF7A00" height={100} width={100} className="loading-dots" />
                ) : (
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-lg-7 col-xl-6">
                                <div className="blog-detail">
                                    <div className="text-center">
                                        <div className="category">{this.state.category}</div>
                                        <h3 className="title-detail">{this.state.title}</h3>
                                        <div className="identity-detail font-weight-bolder text-secondary">
                                            <div>
                                                {new Date(this.state.publish).toLocaleDateString("IN", {
                                                    year: "numeric",
                                                    month: "long",
                                                    day: "numeric"
                                                })}
                                            </div>
                                            {/* <div className="by-detail">By</div> */}
                                            {/* <div>{this.state.admin}</div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-8">
                                <div className="blog-detail">
                                    <img src={this.state.image} className="img-detail rounded  justify-content-center" alt="gambar" />
                                </div>
                            </div>
                            <div className="col-12 col-lg-7 col-xl-6">
                                <div className="blog-detail">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.content }}></div>
                                </div>
                            </div>
                            <div className="col-md-12" style={{ display: "flex" }}>
                                <p>Share this post :</p>
                                <div className="pl-2" style={{ display: "inline" }}>
                                    <FacebookShareButton url={shareUrl} title={this.state.title} className="Demo__some-network__share-button pr-2">
                                        <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                    <TwitterShareButton url={shareUrl} title={this.state.title} className="Demo__some-network__share-button pr-2">
                                        <TwitterIcon size={32} round />
                                    </TwitterShareButton>
                                    <TelegramShareButton url={shareUrl} title={this.state.title} className="Demo__some-network__share-button pr-2">
                                        <TelegramIcon size={32} round />
                                    </TelegramShareButton>
                                    <WhatsappShareButton url={shareUrl} title={this.state.title} className="Demo__some-network__share-button pr-2">
                                        <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>
                                </div>
                            </div>
                        </div>
                        <BeritaTerkait />
                    </div>
                )}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        blog: state.blog
    };
};
export default connect(mapStateToProps)(BlogDetail);
