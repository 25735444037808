import React, { Component } from "react";
import { DownloadDocument } from "../../../redux/action/Digisign";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import NoData from "../../assets/no data/NoData";
import Pagination from "react-js-pagination";
import moment from "moment";
import { LoadingSignProject } from "../../assets/loading data/LoadingBorrower";

class DownloadDoc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            todosPerPage: 15
        };
        this.handleClick = this.handleClick.bind(this);
    }
    //pagination
    handleClick(pageNumber) {
      if(pageNumber==this.props.pagination.page) return
      window.scrollTo(0, 0)
      this.setState({
        currentPage: pageNumber
      }, async () => {
        await this.props.reFetchData({ ...this.props.query, page: pageNumber })
  
      })
      
  }
    handleDownload = async (id) => {
        // console.log(id)
        await this.props
            .dispatch(DownloadDocument(id))
            .then((res) => {
                // console.log(res)
                if (res.value.data.JSONFile.result === "05") {
                    Swal.fire({
                        title: "Gagal",
                        text: `${res.value.data.JSONFile.notif}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (res.value.data.JSONFile.result === "12") {
                    Swal.fire({
                        title: "Gagal",
                        text: `${res.value.data.JSONFile.notif}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else {
                    const pdf = res.value.data.JSONFile.file;
                    const linkSource = `data:application/pdf;base64,${pdf}`;
                    const downloadLink = document.createElement("a");
                    const fileName = "Perjanjian pinjaman.pdf";

                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }
            })
            .catch((err) => {
                // console.log(err.response)
            });
    };

    downloadProjectDocument = (projects, currentPage, todosPerPage, length) => {
        // const indexOfLastTodo = currentPage * todosPerPage;
        // const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        // function compare(a, b) {
        //     return new Date(b.createdAt) - new Date(a.createdAt);
        // }
        if (length !== 0) {
            //&& val.document_id !== undefined
        return projects.map((val) => {
                    if (val.userTtd === true) {
                        return (
                            <div className="card border-0 shadow-sm" key={val._id}>
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-12 mb-3 mb-xl-0">
                                            <div className="row">
                                              <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">
                                                  <p className="fs-n1 text-secondary mb-1 title-sign-card">Tanggal</p>
                                                  <p className="fs-n1 mb-0">{moment(new Date(val.createdAt)).format('LL')}</p>
                                                </div>
                                                <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">

                                                    <p className="fs-n1 text-secondary mb-1 title-sign-card">Nama Pinjaman</p>
                                                    <p className="mb-0">{val.nama_pinjaman}</p>
                                                </div>
                                                <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">

                                                    <p className="fs-n1 text-secondary mb-1 title-sign-card">Nominal yg terkumpul</p>
                                                    <p className="mb-0">Rp. {val.nilai_total_peminjam.toLocaleString("IN")}</p>
                                                </div>
                                                <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">

                                                    <p className="fs-n1 text-secondary mb-1 title-sign-card">Suku Bunga</p>
                                                    <p className="mb-0">{val.suku_bunga_pinjaman * 100} %</p>
                                                </div>
                                                <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">

                                                    <p className="fs-n1 text-secondary mb-1 title-sign-card">Tenor</p>
                                                    <p className="mb-0">{val.jangka_waktu_pinjaman} Bulan</p>
                                                </div>
                                                <div className="col-12 col-xl-2 text-center d-flex flex-column justify-content-center align-items-center">
                                                    {val.sendDocument === false ? (
                                                        <Button color="primary" disabled onClick={() => this.handleDownload(val._id)}>
                                                            Download PDF
                                                        </Button>
                                                    ) : (
                                                        <Button color="primary" onClick={() => this.handleDownload(val._id)}>
                                                            Download PDF
                                                        </Button>
                                                    )}
                                                    <a href="data:application/pdf;base64,[base64]" download="file.pdf"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                });
    
        } else {
            return (
                <NoData symbol={<i className="material-icons-outlined">assignment</i>} keterangan={"Belum ada proyek dalam tahap sedang berjalan"} />
            );
        }
    };
    loading() {
      return (
          <div>
              <LoadingSignProject />
              <LoadingSignProject />
          </div>
      );
  }
    render() {
       
          return (
          <div>{this.props.isLoadingProjectSigned ? this.loading() : this.downloadProjectDocument(this.props.projects, this.props.pagination.page, this.props.pagination?.limit, this.props.pagination?.totalData)}
             {this.props.projects?.length>0 &&
           

                <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        hideDisabled
                        
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.props.pagination?.page}
                        itemsCountPerPage={this.props.pagination?.limit}
                        totalItemsCount={parseInt(this.props.pagination?.totalData)}
                        onChange={this.handleClick.bind(this)}
                    />
                </div>
               }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        digisign: state.digisign
    };
};
export default connect(mapStateToProps)(DownloadDoc);
