import React, { Component } from "react";
import CompanyInfo from "./CompanyInfo";
import Login from "./login modal/Login";
import TKB from "./MenuTKB";
import "./Menu.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { TKB90 } from "../../../redux/action/User";
import { Link } from "react-router-dom";
import { DropdownToggle, DropdownMenu, DropdownItem, Collapse, Navbar, NavbarToggler, Nav, NavItem, UncontrolledDropdown } from "reactstrap";

class Menu extends Component {
    state = {
        dropdownOpen: false,
        setDropdownOpen: false,
        isTop: true,
        popoverOpen: false,
        modal: false,
        setModal: false,
        isOpen: false,
        isOpenTkbModal:false,
        setIsOpen: false,
        tkb: { tkb0: '', tkb30: '', tkb60: '', tkb90: '' }
    };

    async componentDidMount() {
        document.addEventListener("scroll", () => {
            const isTop = window.scrollY <= 50;
            if (isTop !== this.state.isTop) {
                this.setState({ isTop });
            }
        });
        await this.props.dispatch(TKB90());
        this.setState({
            tkb: { tkb0: this.props.user.TKB.tkb0, tkb30: this.props.user.TKB.tkb30, tkb60: this.props.user.TKB.tkb60, tkb90: this.props.user.TKB.tkb90 }
        });
    }

    togglenavbar() {
        if (window.innerWidth <= 985) {
            this.setState({
                isOpen: !this.state.isOpen,
                setIsOpen: !this.state.setIsOpen
            });
        }
    }
    toggleTkbModal(){
        this.setState({isOpenTkbModal:!this.state.isOpenTkbModal})
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
            setDropdownOpen: !this.state.setDropdownOpen
        });
    }

    togglemodal() {
        this.setState({
            modal: !this.state.modal,
            setModal: !this.state.setModal
        });
    }

    toggletkb() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    onHover = () => {
        this.setState({
            popoverOpen: true
        });
    };

    onHoverLeave = () => {
        this.setState({
            popoverOpen: false
        });
    };

    //kondisi menu jika punya token dan yg tidak
    renderAuthMenu = () => {
        if (localStorage.jwtToken === undefined || localStorage.justOnce === undefined) {
            return (
                <div className="nav-right">
                    <a
                        href="#section"
                        data-toggle="modal"
                        data-target="#loginModal"
                        className="nav-link text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={this.togglemodal.bind(this)}>
                        Masuk
                    </a>
                    <Link to="/pilihuser" className="btn btn-primary" onClick={this.togglenavbar.bind(this)}>
                        Daftar
                    </Link>
                    <TKB
                        className="d-none d-lg-block"
                        data={this.state.tkb}
                        isOpenModal={this.state.isOpenTkbModal}
                        toggle={this.toggleTkbModal.bind(this)}
                        popoClass="d-none d-sm-block"
                        id="Popovers1"
                    />
                </div>
            );
        } else {
            return (
                <TKB
                    className="nav-right d-none d-lg-block"
                    data={this.state.tkb}
                    isOpenModal={this.state.isOpenTkbModal}
                    toggle={this.toggleTkbModal.bind(this)}
                    popoClass="d-none d-sm-block"
                    id="Popovers1"
                />
            );
        }
    };

    //kondisi menu beranda jika punya token diganti dashboard
    renderBeranda = () => {
        if (localStorage.jwtToken === undefined || localStorage.justOnce === undefined) {
            return (
                <NavItem>
                    <Link to="/" className="nav-link" onClick={this.togglenavbar.bind(this)}>
                        Beranda
                    </Link>
                </NavItem>
            );
        } else {
            if (localStorage.masuk === "1") {
                return (
                    <>
                        <NavItem>
                            <Link className="nav-link" to="/lender/dasbor" onClick={this.togglenavbar.bind(this)}>
                                Dashboard
                            </Link>
                        </NavItem>
                        <NavItem className={this.props.location.pathname === "/proyek" ? "active" : ""}>
                            <Link to="/proyek" className="nav-link" onClick={this.togglenavbar.bind(this)}>
                                Proyek
                            </Link>
                        </NavItem>
                    </>
                );
            } else {
                return (
                    <NavItem>
                        <Link className="nav-link" to="/borrower/dasbor" onClick={this.togglenavbar.bind(this)}>
                            Dashboard
                        </Link>
                    </NavItem>
                );
            }
        }
    };
    render() {
        return (
            <div>
                <header className={this.props.location.pathname === "/" ? (this.state.isTop ? "transparent" : "") : ""}>
                    <div className="container">
                        <CompanyInfo />
                        <Navbar
                            className="navbar bg-white navbar-expand-lg"
                            style={{
                                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                                // display:
                                //   this.props.location.pathname === "/lender/"
                                //     ? "none"
                                //     : "block",
                            }}>
                            <div className="container">
                                <Link to="/" className="navbar-brand">
                                    <img
                                        src={process.env.PUBLIC_URL + "../images/logo.png"}
                                        width="140"
                                        className="d-inline-block align-top mt-2"
                                        alt="logo-indofund"
                                    />
                                </Link>
                                {/* ini jika mobile view */}
                                <TKB
                                    className="tkb-right"
                                    data={this.state.tkb}
                                    isOpenModal={this.state.isOpenTkbModal}
                                    toggle={this.toggleTkbModal.bind(this)}
                                    popoClass="d-block d-sm-none"
                                    id="Popovers2"
                                />
                                {/* end mobile view */}
                                <NavbarToggler onClick={this.togglenavbar.bind(this)}>
                                    <i className="material-icons-round">more_vert</i>
                                </NavbarToggler>
                                <Collapse isOpen={this.state.isOpen} navbar>
                                    <Nav className="mr-auto" navbar>
                                        {this.renderBeranda()}
                                        <UncontrolledDropdown
                                            nav
                                            inNavbar
                                            className={
                                                this.props.location.pathname === "/tentang-profil" ||
                                                    this.props.location.pathname === "/tentang-sukubunga"
                                                    ? "active"
                                                    : ""
                                            }>
                                            <DropdownToggle nav caret>
                                                Tentang Kami
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>
                                                    <Link
                                                        to="/tentang-profil"
                                                        className="dropdown-item"
                                                        style={{ color: "black" }}
                                                        onClick={this.togglenavbar.bind(this)}>
                                                        Profil Perusahaan
                                                    </Link>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Link
                                                        to="/tentang-sukubunga"
                                                        className="dropdown-item"
                                                        style={{ color: "black" }}
                                                        onClick={this.togglenavbar.bind(this)}>
                                                        Biaya dan Suku Bunga
                                                    </Link>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Link
                                                        to="/tentang-event"
                                                        className="dropdown-item"
                                                        style={{ color: "black" }}
                                                        onClick={this.togglenavbar.bind(this)}>
                                                        Event
                                                    </Link>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <NavItem className={this.props.location.pathname === "/faq" ? "active" : ""}>
                                            <Link to="/faq" className="nav-link" onClick={this.togglenavbar.bind(this)}>
                                                FAQ
                                            </Link>
                                        </NavItem>
                                        <NavItem className={this.props.location.pathname === "/layanan" ? "active" : ""}>
                                            <Link to="/layanan" className="nav-link" onClick={this.togglenavbar.bind(this)}>
                                                Layanan Pengaduan
                                            </Link>
                                        </NavItem>
                                    </Nav>
                                    {/* section right menu */}
                                    {this.renderAuthMenu()}
                                </Collapse>
                            </div>
                        </Navbar>
                    </div>
                </header>
                <Login isOpen={this.state.modal} toggle={this.togglemodal.bind(this)} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

export default withRouter(connect(mapStateToProps)(Menu));
