import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { Link } from "react-router-dom";

import "./ModalPengumuman.scss";

class ModalPengkinianData extends Component {
    render() {
        return (
            <Modal size="lg" isOpen={this.props.isOpen} toggle={this.props.toggle} className="modal-dialog modal-dialog-centered" tabIndex="-1">
                <ModalHeader>
                    <div className="text-center flex-fill">
                        <h5 className="modal-title text-left font-weight-bold">Konfirmasi Pengkinian Data!</h5>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <p className="mb-0 text-paragraf">
                        {" "}
                        Dalam rangka mendukung penerapan prinsip kehati-hatian yang dapat melindungi Anda sebagai pemberi pinjaman dari berbagai
                        risiko yang mungkin timbul, kami mengimbau Anda untuk melakukan pengecekan, melengkapi, dan melakukan pengkinian data pribadi
                        Anda di Indofund.
                    </p>
                    <br />
                    <ul>
                        <li>
                            <p className="mb-3 text-paragraf">
                                Pilih <b>“Perbarui Data”</b> apabila Data Pribadi anda perlu untuk di lakukan pembaruan Data.
                            </p>
                        </li>
                        <li>
                            <p className="mb-1 text-paragraf">
                                Pilih <b>“Data sudah Benar”</b> untuk mengkonfirmasi bahwa Data yang anda masukkan saat ini benar tidak ada yang perlu
                                di perbarui.
                            </p>
                        </li>
                    </ul>
                    <br />
                    <p className="mb-0 text-paragraf">
                        {" "}
                        Kami mendorong Anda untuk segera melakukan pengkinian data agar Anda dapat terus melakukan pendanaan serta mengajukan
                        penarikan dana di Indofund dengan nyaman.
                    </p>
                    <div className="button-container-data">
                        <Button className="btn-secondary" onClick={() => this.props.onClick("accepted")}>
                            {this.props.isLoading ? <span className="loader"></span> : "Data sudah Benar"}
                        </Button>
                        <Link
                            to={{
                                pathname: "/lender/akun/" + this.props.id,
                                state: {
                                    jenis: `${this.props.jenis}`
                                }
                            }}>
                            <Button disabled={this.props.isLoading} color="primary" className="ml-3" onClick={() => this.props.onClick("update")}>
                                Perbarui Data{" "}
                            </Button>
                        </Link>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}
export default ModalPengkinianData;
