import React, { Component } from "react";
import { UnlinkAccount } from "../../../redux/action/Dipay";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import Swal from "sweetalert2";

class Dipay extends Component {
    handleUnlinked = async (e) => {
        e.preventDefault();
        Swal.fire({
            title: "Hapus koneksi akun Dipay",
            text: "Yakin ingin menghapus koneksi akun Dipay ?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yakin"
        }).then(async (result) => {
            if (result.value) {
                await this.props
                    .dispatch(UnlinkAccount())
                    .then((res) => {
                        // console.log(res)
                        Swal.fire({
                            title: "Berhasil",
                            text: "Anda berhasil menghapus koneksi akun Dipay",
                            icon: "success",
                            buttons: "ok"
                        }).then(() => {
                            window.location.reload();
                        });
                    })
                    .catch((err) => {
                        // console.log(err)
                        if (err.response.status === 500) {
                            Swal.fire({
                                title: "Tidak Berhasil",
                                text: `${err.response.data.message}`,
                                icon: "warning",
                                button: "ok"
                            });
                        } else if (err.response.status === 404) {
                            Swal.fire({
                                title: "Tidak Berhasil",
                                text: `${err.response.data.message}`,
                                icon: "warning",
                                button: "ok"
                            });
                        }
                    });
            }
        });
    };
    render() {
        return (
            <div>
                <div className="card-body">
                    <h5 className="text-center font-weight-bold">Melepas koneksi akun Indofund dengan akun Dipay</h5>
                    <div className="row justify-content-center">
                        <div className="col-md-6 pt-5">
                            <p>
                                Halaman ini diperuntukan bagi user Indofund jika tidak ingin mengkoneksikan saldo Dipay dengan Indofund. Info lebih
                                lanjut bisa <a href="/faq">klik disini</a>
                            </p>
                            <div className="text-center pt-5">
                                <Button onClick={(e) => this.handleUnlinked(e)} color="primary" className="btn btn-md">
                                    Melepas koneksi
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dipay: state.dipay
    };
};
export default connect(mapStateToProps)(Dipay);
