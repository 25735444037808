import React from "react";
import Skeleton from "react-loading-skeleton";
import { Form, FormGroup, Label } from "reactstrap";

function LoadingPersonal() {
    return (
        <div className="card-body">
            <Form>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Nama Borrower</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Email</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Nomor Handphone</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Tempat Lahir</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Tempat Lahir</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Jenis Kelamin</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Agama</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Status Perkawinan</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Nomor NPWP</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                </div>
            </Form>
        </div>
    );
}

function LoadingBadanHukum() {
    return (
        <div className="card-body">
            <Form>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Nama Borrower</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Email</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Nomor NPWP</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Jenis Badan Usaha</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Sektor Usaha</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Pekerjaan Online</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Pendapatan per bulan</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Total Aset</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Deskripsi Usaha</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                </div>
            </Form>
        </div>
    );
}

function LoadingDashboard() {
    return (
        <div className="transaction p-3">
            <div className="card border-0 shadow-sm">
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-12 col-xl-9 mb-3 mb-xl-0">
                            <div className="row">
                                <div className="col-6 col-md-5 mb-3">
                                    <p className="fs-n1 text-secondary mb-1">Nama Proyek</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-4 mb-3">
                                    <p className="fs-n1 text-secondary mb-1">Jenis Pinjaman</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                    <p className="fs-n1 text-secondary mb-1">Nominal</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-5 mb-3 mb-xl-0">
                                    <p className="fs-n1 text-secondary mb-1">Suku Bunga</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-4 mb-3 mb-xl-0">
                                    <p className="fs-n1 text-secondary mb-1">Jatuh Tempo</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-3 mb-3 mb-xl-0">
                                    <p className="fs-n1 text-secondary mb-1">Cicilan Terdekat</p>
                                    <Skeleton />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function LoadingDetailCicil() {
    return (
        <div className="transaction p-3">
            <div className="card border-0 shadow-sm">
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-12 col-xl-9 mb-3 mb-xl-0">
                            <div className="row">
                                <div className="col-12 mb-3 bg-dark-gray">
                                    <h5 className="font-weight-bold">Bulan</h5>
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                    <p className="fs-n1 text-secondary mb-1">Tgl Jatuh Tempo</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                    <p className="fs-n1 text-secondary mb-1">Nominal Tagihan</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-3 mb-3">
                                    <p className="fs-n1 text-secondary mb-1">Tgl Bayar Cicilan</p>
                                    <Skeleton />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-3 text-center">
                            <Skeleton height={35} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function LoadingSignProject() {
    return (
        <div className="card border-0 shadow-sm">
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col-12 col-md-12 mb-3 mb-xl-0">
                        <div className="row">
                            <div className="col-6 col-md-3 mb-3">
                                <p className="fs-n1 text-secondary mb-1">Nama Pinjaman</p>
                                <Skeleton />
                            </div>
                            <div className="col-6 col-md-3 mb-3">
                                <p className="fs-n1 text-secondary mb-1">Nominal yg terkumpul</p>
                                <Skeleton />
                            </div>
                            <div className="col-6 col-md-2 mb-3">
                                <p className="fs-n1 text-secondary mb-1">Suku Bunga</p>
                                <Skeleton />
                            </div>
                            <div className="col-6 col-md-2 mb-3">
                                <p className="fs-n1 text-secondary mb-1">Tenor</p>
                                <Skeleton />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function LoadingAjukanPinjaman() {
    return (
        <div>
            <section id="pinjaman">
                <div className="sec-header">
                    <h4 className="text-center sec-title">Form Pengajuan Pinjaman</h4>
                </div>
                <Form>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <FormGroup>
                                <Label for="Nama_pengguna">Pinjaman yang diajukan</Label>
                                <Skeleton height={40} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="Email">Lama Pinjaman</Label>
                                <Skeleton height={40} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleText">Tujuan permohonan pinjaman</Label>
                                <Skeleton height={120} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleFile">Upload dokumen</Label>
                                <Skeleton height={40} />
                            </FormGroup>
                        </div>
                    </div>
                </Form>
            </section>
        </div>
    );
}

export { LoadingPersonal, LoadingBadanHukum, LoadingDashboard, LoadingDetailCicil, LoadingSignProject, LoadingAjukanPinjaman };
