import React, { Component } from "react";
import { ListBlog } from "../../../redux/action/Blog";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NoData from "../../assets/no data/NoData";
import Pagination from "react-js-pagination";

class MainContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: [],
            currentPageAktif: 1,
            todosPerPageAktif: 5,
            filter: ""
        };
        this.handleClickAktif = this.handleClickAktif.bind(this);
    }

    handleClickAktif(pageNumber) {
        window.scrollTo(0, 0);
        this.setState({
            currentPageAktif: pageNumber
        });
    }
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        await this.props.dispatch(ListBlog());
    };

    listBlog(currentPageAktif, todosPerPageAktif, lengthAktif) {
        if (this.props.blog.listBlog.length !== 0 && this.props.blog.listBlog.filter((item) => item.kategori === this.props.category).length !== 0) {
            const indexOfLastTodo = currentPageAktif * todosPerPageAktif;
            const indexOfFirstTodo = indexOfLastTodo - todosPerPageAktif;
            function compare(a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }
            const hasil = this.props.blog.listBlog
                .filter((item) => item.kategori === this.props.category)
                .sort(compare)
                .slice(indexOfFirstTodo, indexOfLastTodo)
                .map((val) => {
                    // console.log(val)
                    const length = 300;
                    const highlight = val.konten.length > length ? val.konten.substring(0, length - 3) + "..." : val.konten;
                    return (
                        <div className="content d-flex justify-content-center" key={val._id}>
                            <div className="row">
                                <div className="col-md-1 d-none d-sm-block" style={{ margin: "0" }}>
                                    <h2 className="bulan">{new Date(val.tgl_publikasi).toLocaleDateString("IN", { month: "short" })}</h2>
                                    <hr className="hr-bulan" />
                                    <div className="tanggal">{new Date(val.tgl_publikasi).toLocaleDateString("IN", { day: "numeric" })}</div>
                                </div>
                                <div className="col-md-4">
                                    <img src={val.gambar_blog} className="img-fluid img-content rounded" alt="img-blog" />
                                    <div className="name-category">{val.kategori}</div>
                                </div>
                                <div className="col-md-6">
                                    <h5 className="font-weight-bold judul">{val.judul}</h5>
                                    <div dangerouslySetInnerHTML={{ __html: highlight }}></div>
                                    <div className="identity">
                                        <div>By {val.penulis.nama_admin}</div>
                                        <span className="dot d-block d-sm-none">•</span>
                                        <div className="d-block d-sm-none">
                                            {new Date(val.tgl_publikasi).toLocaleDateString("IN", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric"
                                            })}
                                        </div>
                                    </div>
                                    <Link to={"/blog-detail/" + val._id} style={{ textDecoration: "none" }} className="btn btn-outline-primary">
                                        Baca selanjutnya
                                    </Link>
                                </div>
                            </div>
                        </div>
                    );
                });
            return hasil;
        } else {
            return <NoData symbol={<i className="material-icons">book</i>} keterangan={"Maaf saat ini belum ada artikel"} />;
        }
    }
    render() {
        const { currentPageAktif, todosPerPageAktif } = this.state;
        const lengthAktif = this.props.blog.listBlog.filter((item) => item.kategori === this.props.category).length;
        return (
            <div>
                {this.listBlog(currentPageAktif, todosPerPageAktif, lengthAktif)}

                <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        hideDisabled
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.currentPageAktif}
                        itemsCountPerPage={this.state.todosPerPageAktif}
                        totalItemsCount={parseInt(lengthAktif)}
                        onChange={this.handleClickAktif.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        blog: state.blog
    };
};
export default connect(mapStateToProps)(MainContent);
