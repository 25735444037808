import React, { Component } from "react";
import "./CalculatorBunga.scss";
import NumberFormat from "react-number-format";
import { FormGroup, Form, Button } from "reactstrap"; //select option search
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import { validate } from "../../../library/validation/validate";
import { Link } from "react-router-dom";

class CalculatorBunga extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            tenor: "",
            grade: "",
            nominal: "",
            activeNumeric: false,
            disabled_suku_bunga: true,
            suku_bunga: "",
            text_durasi: "-- Pilih Durasi Pendanaan --",
            text_grade: "-- Pilih Grade Proyek --",
            filter_durasi: ["3 Bulan", "6 Bulan", "12 Bulan"],
            filter_grade: ["Grade A", "Grade B", "Grade C"],
            total: 0
        };
    }

    handleChange = (e, type, changeError) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value) });
        });
    };

    onHandleChange(type, value) {
        switch (type) {
            case "durasi":
                this.setState({
                    text_durasi: value
                });
                break;
            case "grade":
                this.checkSukuBunga(value);
                this.setState({
                    text_grade: value
                });
                break;
            default:
                break;
        }
    }

    checkSukuBunga(grade) {
        this.setState({
            disabled_suku_bunga: false
        });
        switch (grade) {
            case "Grade A":
                this.setState({
                    suku_bunga: "15%"
                });
                break;
            case "Grade B":
                this.setState({
                    suku_bunga: "18%"
                });
                break;
            case "Grade C":
                this.setState({
                    suku_bunga: "24%"
                });
                break;
            default:
                break;
        }
    }

    handleUpdate = () => {
        const { text_durasi, nominal, suku_bunga } = this.state;
        const nilai_nominal = nominal.replace(/\D/g, "").replace(/^0+/, "");
        const pesentage = parseInt(suku_bunga) / 100;
        let price = 0;

        switch (text_durasi) {
            case "3 Bulan":
                price = ((+nilai_nominal * pesentage) / 12) * 3;
                break;
            case "6 Bulan":
                price = ((+nilai_nominal * pesentage) / 12) * 6;
                break;
            case "12 Bulan":
                price = ((+nilai_nominal * pesentage) / 12) * 12;
                break;
            default:
                break;
        }
        this.setState({
            total: Math.floor(+nilai_nominal + price).toLocaleString('id-ID')
        });
    };

    onHandleRedirect() {
        localStorage.jwtToken === undefined ? this.props.history.push("/daftar/1") : this.props.history.push("/proyek");
    }

    render() {
        const { filter_durasi, filter_grade, disabled_suku_bunga, total } = this.state;

        return (
            <section id="calculator">
                <div>
                    <h4 className="text-title">Simulasi Imbal Hasil Pendanaan</h4>
                    <p className="text">Hitung potensi hasil pendanaan Anda dengan mudah menggunakan kalkulator simulasi pendanaan kami.</p>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="box-container">
                        <Form>
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="text-label mb-2">Nominal yang akan di danai</p>
                                    <div className="form-group ">
                                        <div className="input-group ">
                                            <div className="input-group-prepend">
                                                <div className={`input-group-text ${this.state.activeNumeric ? "active-border" : ""}`}>
                                                    <span className="font-weight-semi-bold">Rp</span>
                                                </div>
                                            </div>
                                            <NumberFormat
                                                onChange={(e) => this.handleChange(e)}
                                                value={this.state.nominal}
                                                // onKeyDown={this.onKeyDown}
                                                autoComplete="off"
                                                placeholder="0"
                                                className="form-control text-right input-cal"
                                                name="nominal"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                allowNegative={false}
                                                decimalScale={0}
                                                onFocus={(e) =>
                                                    this.setState({
                                                        activeNumeric: true
                                                    })
                                                }
                                                onBlur={(e) =>
                                                    this.setState({
                                                        activeNumeric: false
                                                    })
                                                }

                                                // disabled={this.authDisabled(project)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <p className="text-label mb-2">Durasi Pendanaan</p>
                                    <UncontrolledDropdown className="dropdown-container-cal">
                                        <DropdownToggle color="#fff" outline={false} className="text-filter-cal">
                                            {this.state.text_durasi}{" "}
                                        </DropdownToggle>
                                        <DropdownMenu className="menu-dropdown-cal">
                                            {filter_durasi.map((text, i) => {
                                                return (
                                                    <DropdownItem
                                                        key={i}
                                                        onClick={() => this.onHandleChange("durasi", text)}
                                                        className={`${text === this.state.text_durasi ? "active-item" : ""}`}>
                                                        {text}
                                                    </DropdownItem>
                                                );
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <p className="text-label mb-2">Grade Proyek</p>
                                    <UncontrolledDropdown className="dropdown-container-cal">
                                        <DropdownToggle color="#fff" outline={false} className="text-filter-cal">
                                            {this.state.text_grade}{" "}
                                        </DropdownToggle>
                                        <DropdownMenu className="menu-dropdown-cal">
                                            {filter_grade.map((text, i) => {
                                                return (
                                                    <DropdownItem
                                                        key={i}
                                                        onClick={() => this.onHandleChange("grade", text)}
                                                        className={`${text === this.state.text_grade ? "active-item" : ""}`}>
                                                        {text}
                                                    </DropdownItem>
                                                );
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                                <div className="col-md-6">
                                    <FormGroup>
                                        <p className="text-label mb-2">Suku Bunga</p>
                                        <div className="input-container-cal">{this.state.suku_bunga}</div>
                                        <p className="text-note">*Suku Bunga mulai dari 10% - 36% p.a</p>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <Link
                                        // onClick={() => this.onHandleRedirect()}
                                        color="white"
                                        to={`${localStorage.jwtToken === undefined ? "/daftar/1" : "/proyek"}`}
                                        className="btn btn-outline-primary btn-block btn-lg mr-md-2">
                                        Mendanai Sekarang
                                    </Link>
                                </div>
                                <div className="col-md-6">
                                    <Button
                                        onClick={this.handleUpdate}
                                        color="primary"
                                        disabled={disabled_suku_bunga}
                                        className="btn btn-block btn-lg  mt-3 mt-md-0">
                                        Hitung
                                    </Button>
                                </div>
                            </div>
                            <div className="box-container-total mt-3">
                                <img className="img-fluid" src={process.env.PUBLIC_URL + "../../images/icon/logoCalculator.svg"} alt="logo-send" />
                                <div>
                                    <p className="text-note" style={{ fontSize: 12 }}>
                                        Proyeksi Imbal Hasil Pendanaan *(Sebelum Pajak)
                                    </p>
                                    <p className="text-total">Rp {total}</p>
                                </div>
                                <img
                                    className="img-fluid image-logo-cal"
                                    src={process.env.PUBLIC_URL + "../../images/icon/logoIndofund.svg"}
                                    alt="logo-send"
                                />
                            </div>
                            <p className="text-note mt-3">
                                {
                                    "*) Simulasi bersifat proyeksi. Besar total pengembalian pendanaan mengacu dengan parameter grade dan rate yang dipilih."
                                }
                            </p>
                        </Form>
                    </div>
                </div>
            </section>
        );
    }
}

export default CalculatorBunga;
