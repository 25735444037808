import React, { Component } from "react";
import Personal from "./personal/AjukanPinjamanPersonal";
import BadanHukum from "./badan hukum/AjukanPinjamanBadanHukum";
import { LoadingAjukanPinjaman } from "../../assets/loading data/LoadingBorrower";

class AjukanPinjaman extends Component {
    ShowForm() {
        if (this.props.jenisBorrower === 1) {
            return (
                <Personal
                    employee={this.props.employee}
                    micro={this.props.micro}
                    plafond_borrower={this.props.plafond_borrower}
                    plafond_terpakai={this.props.plafond_terpakai}
                />
            );
        } else {
            return <BadanHukum />;
        }
    }

    render() {
        return <div>{this.props.isLoadingGetUser ? <LoadingAjukanPinjaman /> : this.ShowForm()}</div>;
    }
}
export default AjukanPinjaman;
