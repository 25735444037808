import React, { Component } from "react";
const queryString = require("query-string");

class SuccessAktivasi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: ""
        };
    }
    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        const { tokenResetPassword } = values;
        this.setState({
            token: tokenResetPassword
        });
    }
    render() {
        return (
            <section id="email-sent" className="with-pad bg-grey">
                <div className="container no-lg">
                    <div className="card email-sent-card border-0">
                        <div className="card-body">
                            <div className="text-center">
                                <div className="my-5">
                                    <img
                                        className="ojk img-fluid mb-4"
                                        src={process.env.PUBLIC_URL + "images/partners-icon/sendemail.svg"}
                                        alt="logo-send"
                                    />
                                </div>
                                <div className="mb-4">
                                    <h4 className="font-weight-bold mb-3">Berhasil Aktivasi</h4>
                                    <p className="fs-1 mb-1 text-secondary">
                                        Email anda telah di verifikasi, silahkan login dengan email dan password Anda
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default SuccessAktivasi;
