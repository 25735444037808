import React, { Component } from "react";
import { connect } from "react-redux";
import { UserLupaPassword } from "../../../../redux/action/User";
import { Form, Button } from "reactstrap";
import Swal from "sweetalert2";
import "../../../../sass/loader.css";
import { InputIcon } from "../../../../library/components/Components";
import { validate } from "../../../../library/validation/validate";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ""
        };
    }

    handleChange = (e, type, changeError) => {
        // console.log(e.target.name)
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value) });
        });
    };

    handleClick = async () => {
        const { email } = this.state;
        // console.log(this.state.errEmail)
        if (this.state.errEmail === 0) {
            await this.props
                .dispatch(
                    UserLupaPassword({
                        email: email
                    })
                )
                .then(() => {
                    this.props.history.push("/email-forgotpassword");
                })
                .catch((err) => {
                    if (err.response.status === 400) {
                        Swal.fire({
                            title: "Email kosong",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (err.response.status === 404) {
                        Swal.fire({
                            title: "Tidak dapat ditemukan",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (err.response.status === 500) {
                        Swal.fire({
                            title: "Terdapat error",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    }
                });
        } else {
            Swal.fire({
                title: "Gagal ",
                text: `Periksa data Anda kembali`,
                icon: "warning",
                button: "ok"
            });
        }
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onKeyPressEmail = (e) => {
        if (e.which === 13) {
            this.handleClick();
        }
    };
    render() {
        return (
            <section id="daftar-lender" className="with-pad bg-grey">
                <div className="container">
                    <div className="sec-header">
                        <h4 className="text-center sec-title">Lupa Kata Sandi</h4>
                        <p className="text-center sec-desc">Silahkan masukkan email terdaftar</p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-7 col-lg-5 col-xl-4">
                            <div className="card border-0">
                                <div className="card-body">
                                    <Form>
                                        <InputIcon
                                            forLabel={"email"}
                                            label={"Email"}
                                            icon={<i className="material-icons-outlined">mail</i>}
                                            type={"email"}
                                            name={"email"}
                                            onChange={(e) => this.handleChange(e, "email", "errEmail")}
                                            placeholder={"Contoh: example@email.com"}
                                            maxLength={""}
                                            error={this.state.errEmail}
                                            onKeyPress={this.onKeyPressEmail}
                                        />
                                        {this.props.user.isLoading === true ? (
                                            <Button disabled color="primary" onClick={this.handleClick} className="btn btn-lg btn-block card-submit">
                                                <span className="loader"></span>
                                            </Button>
                                        ) : (
                                            <Button color="primary" onClick={this.handleClick} className="btn btn-lg btn-block card-submit">
                                                Kirim
                                            </Button>
                                        )}
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps)(ForgotPassword);
