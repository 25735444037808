import React, { Component } from "react";
import { connect } from "react-redux";
import { UbahPassword } from "../../../redux/action/User";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import { encryptedData } from "../../../library/helper/Crypto";
import { InputIcon } from "../../../library/components/Components";
import { validate } from "../../../library/validation/validate";
class GantiPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            old_password: "",
            new_password: "",
            confirm_password: "",
            errPasswordOld: "",
            errPasswordNew: "",
            errPasswordConfirm: "",
            hiddenOld: true,
            hiddenNew: true,
            hiddenConfirm: true
        };
        this.showHideOld = this.showHideOld.bind(this);
        this.showHideNew = this.showHideNew.bind(this);
        this.showHideConfirm = this.showHideConfirm.bind(this);
    }

    showHideOld() {
        this.setState({ hiddenOld: !this.state.hiddenOld });
    }
    showHideNew() {
        this.setState({ hiddenNew: !this.state.hiddenNew });
    }
    showHideConfirm() {
        this.setState({ hiddenConfirm: !this.state.hiddenConfirm });
    }

    handleChange = (e, type, changeError) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({
                [changeError]: validate(type, value, this.state.new_password, this.state.confirm_password)
            });
        });
    };

    //function
    handleUpdatePassword = async () => {
        // console.log(this.state.confirm_password)
        if (this.state.errPasswordOld === 0 && this.state.errPasswordNew === 0 && this.state.errPasswordConfirm === 0) {
            await this.props
                .dispatch(
                    UbahPassword({
                        old_password: encryptedData(this.state.old_password),
                        new_password: encryptedData(this.state.new_password),
                        confirm_password: encryptedData(this.state.confirm_password)
                    })
                )
                .then(() => {
                    Swal.fire({
                        title: "Berhasil",
                        text: "Anda berhasil rubah password",
                        icon: "success",
                        buttons: "ok"
                    }).then(() => {
                        window.location.reload();
                    });
                })
                .catch((err) => {
                    if (err.response.status === 400) {
                        Swal.fire({
                            title: "Tidak Berhasil",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (err.response.status === 401) {
                        Swal.fire({
                            title: "Tidak Berhasil",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    }
                });
        } else {
            Swal.fire({
                title: "Gagal Masuk",
                text: "Mohon periksa kembali password Anda",
                icon: "warning",
                buttons: "ok"
            });
        }
    };
    render() {
        return (
            <div className="card-body">
                <form action="">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"password"}
                                label={"Kata Sandi Sekarang"}
                                icon={<i className="material-icons-outlined">lock</i>}
                                type={this.state.hiddenOld ? "password" : "text"}
                                name={"old_password"}
                                onChange={(e) => this.handleChange(e, "passwordLogin", "errPasswordOld")}
                                placeholder={"Kata Sandi Sekarang"}
                                maxLength={""}
                                error={this.state.errPasswordOld}
                                className={"password-form"}
                                info={"*Case sensitive"}
                                password={true}
                                clickShow={this.showHideOld}
                                hidden={this.state.hiddenOld}
                            />
                            <InputIcon
                                forLabel={"password"}
                                label={"Kata Sandi Baru"}
                                icon={<i className="material-icons-outlined">lock</i>}
                                type={this.state.hiddenNew ? "password" : "text"}
                                name={"new_password"}
                                onChange={(e) => this.handleChange(e, "password", "errPasswordNew")}
                                placeholder={"Kata Sandi Baru"}
                                maxLength={""}
                                error={this.state.errPasswordNew}
                                className={"password-form"}
                                info={"*Case sensitive"}
                                password={true}
                                clickShow={this.showHideNew}
                                hidden={this.state.hiddenNew}
                            />
                            <InputIcon
                                forLabel={"password"}
                                label={"Konfirmasi Kata Sandi Baru"}
                                icon={<i className="material-icons-outlined">lock</i>}
                                type={this.state.hiddenConfirm ? "password" : "text"}
                                name={"confirm_password"}
                                onChange={(e) => this.handleChange(e, "passwordConfirm", "errPasswordConfirm")}
                                placeholder={"Konfirmasi Kata Sandi Baru"}
                                maxLength={""}
                                error={this.state.errPasswordConfirm}
                                className={"password-form"}
                                info={"*Case sensitive"}
                                password={true}
                                clickShow={this.showHideConfirm}
                                hidden={this.state.hiddenConfirm}
                            />
                            <div className="text-center">
                                {this.props.user.isLoadingUbahPassword ? (
                                    <Button disabled onClick={this.handleUpdatePassword} color="primary" className="btn btn-lg">
                                        <span className="loader"></span>
                                    </Button>
                                ) : (
                                    <Button onClick={this.handleUpdatePassword} color="primary" className="btn btn-lg">
                                        Simpan
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps)(GantiPassword);
