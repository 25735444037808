import React, { Component } from "react";
import "./PemegangSaham.scss";

class PemegangSaham extends Component {
    render() {
        return (
            <div>
                <section id="pemegang-saham">
                    <div className="sec-header">
                        <h4 className="text-center sec-title">Pemegang Saham</h4>
                    </div>
                    <div className="row">
                        <div className="col-md-6 text-center pb-5 p-lg-5 sf">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/images/partners-icon/dpi.png"} alt="sfc" width="300" />
                        </div>
                        <div className="col-md-6 mb-4 pb-4">
                            <h5 className="text-title text-center">PT. Digitalisasi Perangkat Indonesia</h5>
                            <p className="text">
                                Didirikan pada akhir tahun 2017, Perseroan awalnya bernama PT Panca Sejati Widya. Perseroan kemudian diakuisisi oleh
                                grup Surya Fajar dan dilakukan perubahan nama menjadi PT Digitalisasi Perangkat Indonesia pada tahun 2020. <br />
                                <br />
                                PT Digitalisasi Perangkat Indonesia diposisikan sebagai sub holding fintech dari grup Surya Fajar untuk meningkatkan
                                sinergy dan kolaborasi di antara anak usahanya.
                                <br />
                            </p>
                        </div>
                        <div className="col-md-6 text-center pb-5 p-lg-5">
                            <img width="45%" className="img-fluid" src={process.env.PUBLIC_URL + "/images/tentang-kami/team/Ryan.png"} alt="rf" />
                        </div>
                        <div className="col-md-6">
                            <h5 className="text-title text-center">Ryan Filbert</h5>
                            <p className="text">
                                Terkenal sebagai salah seorang praktisi dan inspirator investasi di Indonesia, Ryan Filbert berpengalaman lebih dari
                                10 tahun dalam bidang investasi, khususnya saham dan derivative. <br />
                                <br />
                                Selain sebagai praktisi dan inspirator, Ryan juga dikenal sebagai seorang mentor dan penulis buku-buku tentang
                                investasi dan analis saham, baik teknikal maupun secara fundamental. Kemampuan analisanya merupakan asset yang
                                berharga bagi perusahaan.
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default PemegangSaham;
