import React, { Component } from "react";
import OurTeam from "./OurTeam";

class Team extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <OurTeam />
                </div>
            </div>
        );
    }
}
export default Team;
