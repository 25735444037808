import React, { Component } from "react";
import Banner from "./banner/Banner";
import InfoPinjam from "./InfoPinjam";
import Indofund from "./kenapa pilih/Choice";
import BerikanPinjaman from "./cara pinjaman/BerikanPinjaman";
import AjukanPinjaman from "./cara pinjaman/AjukanPinjaman";
import Proyek from "../welcome/proyek/ProyekWelcome";
import Testimoni from "./testimoni/Testimoni";
import Article from "./Article";
// import NewsLetter from './NewsLetter';
import OurPartner from "./OurPartner";
import Registred from "../../template/guest/Registred";
import "../../template/guest/Menu.scss";
import PopUpPengumunan from "./ModalPengumuman";

class Welcome extends Component {
    state = {
        isOpen: false,
        setIsOpen: false,
        modal: false
    };

    togglemodal() {
        this.setState({
            modal: !this.state.modal,
            setModal: !this.state.setModal
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.togglemodal();
    }
    render() {
        return (
            <div>
                <Banner />
                <InfoPinjam />
                <Indofund />
                <BerikanPinjaman />
                <AjukanPinjaman />
                <Proyek />
                <Testimoni />
                <Article />
                {/* <NewsLetter /> */}
                <OurPartner />
                <Registred />
                <PopUpPengumunan isOpen={this.state.modal} toggle={this.togglemodal.bind(this)} />
            </div>
        );
    }
}
export default Welcome;
