import React from "react";
import { Button } from "reactstrap";

export default function ShowElement({ isShow, toggleIsShow }) {
    return (
        <Button size="sm" onClick={toggleIsShow} color="white" className="d-flex justify-content-center align-items-center ">
            {isShow ? (
                <i className="fa fa-eye icon-center d-flex justify-content-center align-items-center " aria-hidden="true" />
            ) : (
                <i className="fa fa-eye-slash  d-flex justify-content-center align-items-center icon-center" aria-hidden="true" />
            )}
        </Button>
    );
}
