import React, { Component } from "react";
import "./OurPartner.scss";

class OurPartner extends Component {
    render() {
        return (
            <div>
                <section id="our-partners" className="welcome pt-5 pb-5">
                    <div className="container">
                        <div className="sec-header">
                            <h4 className="text-center sec-title">Partner Kami</h4>
                        </div>
                        <div className="icon-wrapper" style={{ rowGap: '14px' }}>
                            <div className="icon sf">
                                <a href="http://www.sfsekuritas.co.id/" target="_blank" rel="noopener noreferrer">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/images/partners-icon/sf-sekuritas.png"} alt="sfk" />
                                </a>
                            </div>
                            {/* <div className="icon naso">
                <img
                  className="img-fluid"
                  src={
                    process.env.PUBLIC_URL +
                    "/images/partners-icon/nasoetion-atyanto.png"
                  }
                  alt="ns"
                />
              </div> */}
                            <div className="icon ryan">
                                <a href="https://ryanfilbert.id/" target="_blank" rel="noopener noreferrer">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/images/partners-icon/ryan-filbert.png"} alt="rf" />
                                </a>
                            </div>
                            <div className="icon pigijo">
                                <a href="https://www.pigijo.com/" target="_blank" rel="noopener noreferrer">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/images/partners-icon/pigijo.png"} alt="pigijo" />
                                </a>
                            </div>
                            <div className="icon dipay">
                                <a href="https://dipay.id/" target="_blank" rel="noopener noreferrer">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/images/partners-icon/dipay.png"} alt="dipay" />
                                </a>
                            </div>
                            <div className="icon nicepay">
                                <a href="https://nicepay.co.id/id/" target="_blank" rel="noopener noreferrer">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/images/partners-icon/nicepay.jpg"} alt="nicepay" />
                                </a>
                            </div>
                            <div className="icon digisign">
                                <a href="https://digisign.id/" target="_blank" rel="noopener noreferrer">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/images/partners-icon/digisign.png"} alt="nicepay" />
                                </a>
                            </div>
                            {/* <div className="icon collpindo">
                <a href="/#" target="_blank" rel="noopener noreferrer">
                  <img
                    className="img-fluid"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/partners-icon/Collpindo.jpg"
                    }
                    alt="collpindo"
                  />
                </a>
              </div> */}
                            {/* <div className="icon jps">
                                <a href="https://pasarpolis.io/" target="_blank" rel="noopener noreferrer">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/images/partners-icon/logo-pasar-polis.png"} alt="pasar-polis" />
                                </a>
                            </div> */}
                        </div>
                    </div>
                    <div className="mt-5" style={{ marginTop: "50px" }}>
                        <div className="sec-header">
                            <h4 className="text-center sec-title">Partner Bank</h4>
                        </div>
                        <div className="icon-wrapper">
                            <div className="icon mandiri">
                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/images/partners-icon/bank-mandiri.png"} alt="mandiri" />
                            </div>
                            <div className="icon bca">
                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/images/partners-icon/bank-bca.png"} alt="bca" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default OurPartner;
