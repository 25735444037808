import React, { Component } from "react";
import { Button, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { EmailVerif } from "../../../redux/action/Digisign";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import classnames from "classnames";
import "../../../sass/loader.css";
import SignPorjectBr from "./SignProjectBr";
import Loader from "react-loader-spinner";
import DownloadDoc from "./DownloadDoc";
import { getProjectByBorrowerSign, getProjectByBorrowerSigned } from "../../../redux/action/Project";
const axios = require("axios").default;
const url = process.env.REACT_APP_BASSE_API;

class Signing extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            disable: true,
            activeTab: "1",
            loading: true,
            query:{
              limit: 10,
              sorting: 'createdAt',
              sort:'desc',
            }
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    async fetchProjectByBorrowerSign(query) {
   
      const queryParams = { ...query, sign: "Not sign" }
      await this.props.dispatch(getProjectByBorrowerSign(queryParams))
  
    }
    async fetchProjectByBorrowerSigned(query) {
     
      const queryParams = { ...query, sign: "Done sign" }
      await this.props.dispatch(getProjectByBorrowerSigned(queryParams))
    }
    async componentDidMount (){
      await this.fetchProjectByBorrowerSign(this.state.query)
      await this.fetchProjectByBorrowerSigned(this.state.query)
    }
    handleClick = async (id) => {
        const data = await this.props.dispatch(EmailVerif(id));
        if (data.value.data.JSONFile.result === "05") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.notif === "General Error") {
            Swal.fire({
                title: "Gagal",
                text: `Terjadi Kesalahan General Error Harap Hubungi Customer Service Indofund`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "30") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "12") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "28") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "06") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "14") {
            if (data.value.data.JSONFile.notif === "Email sudah melakukan aktivasi") {
                await axios.post(`${url}/digisign/aktivasi`, { email_user: this.props.email, document_id: "" }, {});
                Swal.fire({
                    title: "Berhasil",
                    text: "Akun Telah Berhasil Diaktivasi",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Kembali"
                }).then(async (result) => {
                    if (result.value) {
                        window.location.reload();
                    }
                });
            } else {
                Swal.fire({
                    title: "Gagal",
                    text: `${data.value.data.JSONFile.notif}`,
                    icon: "warning",
                    button: "ok"
                });
            }
        } else if (data.value.data.JSONFile.result === "28") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "06") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "14") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else if (data.value.data.JSONFile.result === "12") {
            Swal.fire({
                title: "Gagal",
                text: `${data.value.data.JSONFile.notif}`,
                icon: "warning",
                button: "ok"
            });
        } else {
            window.location = this.props.digisign.response.JSONFile.link;
        }
    };

    authTandatangan = (id_borrower) => {
        if (this.props.userDigisign !== true) {
            return (
                <div className="row mb-4 d-flex justify-content-center">
                    <div className="col-12 col-md-10 mb-3 mb-md-0 mt-4 ">
                        {/* <h4 className=" font-weight-semi-bold">Aktivasi akun Digisign</h4> */}
                        <p className="text-secondary mb-4 mt-4">
                            Untuk menggunakan tanda tangan digital, anda diharuskan mendaftar ke Digisign. Tanda tangan digital akan digunakan dalam
                            menandatangani perjanjian pinjaman antara <b className="text-primary">Indofund</b> dengan Anda. Klik tombol di bawah ini
                            untuk mendafatar ke digisign.
                            {/* Untuk melakukan tanda tangan digital Anda diharuskan melakukan pendaftaran Ke Digisign. Tanda tangan ini digunakan untuk pihak <b className='text-primary'>Indofund.id</b> mengirim dokumen berisi form tanda tangan ke pihak <b className='text-primary'>Borrower</b>. Klik tombol berwarna oranye untuk daftar ke halaman Digisign */}
                        </p>
                        {this.props.kyc !== true ? (
                            <Button color="primary" disabled>
                                {" "}
                                Aktivasi Akun
                            </Button>
                        ) : (
                            <Button color="primary" onClick={() => this.handleClick(id_borrower)}>
                                {" "}
                                Aktivasi Akun
                            </Button>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="row mb-4 d-flex justify-content-center">
                    <div className="col-12 col-md-10 mb-3 mt-3">
                        <p className="text-secondary text-center">Saat ini fitur tanda tangan sudah aktif.</p>
                        {this.props.project.isLoadingProjectBr ? (
                            <Loader type="ThreeDots" color="#FF7A00" height={100} width={100} className="loading-dots" />
                        ) : (
                            <SignPorjectBr
                                projects={this.props.project.projectBorrowerSign}
                                pagination={this.props.project.pagination?.sign}
                                isLoadingProjectSign={this.props.project.isLoadingProjectSign}
                                reFetchData={this.fetchProjectByBorrowerSign.bind(this)}
                                query={this.state.query}
                                email={this.props.email}
                            />
                        )}
                    </div>
                </div>
            );
        }
    };
    render() {
      // console.log(this.props)
        return (
            <div>
                <nav>
                    <Nav tabs className="over-hidden">
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === "1" })}
                                onClick={() => {
                                    this.toggle("1");
                                }}
                                style={{ cursor: "pointer" }}>
                                Tanda Tangan Digital
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === "2" })}
                                onClick={() => {
                                    this.toggle("2");
                                }}
                                style={{ cursor: "pointer" }}>
                                Dokumen yang Telah Di Tanda Tangan
                            </NavLink>
                        </NavItem>
                    </Nav>
                </nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <div className="mt-5">
                            <h3 className="text-center font-weight-bold">Tanda Tangan Digital</h3>
                            {this.props.isLoadingGetUser ? (
                                <Loader type="ThreeDots" color="#FF7A00" height={100} width={100} className="loading-dots" />
                            ) : (
                                this.authTandatangan(this.props.id)
                            )}
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div>
                            <DownloadDoc projects={this.props.project.projectBorrowerSigned}
                                pagination={this.props.project.pagination?.signed}
                                isLoadingProjectSigned={this.props.project.isLoadingProjectSigned}
                                reFetchData={this.fetchProjectByBorrowerSigned.bind(this)}
                               
                                />
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        digisign: state.digisign,
        project:state.project
    };
};
export default connect(mapStateToProps)(Signing);
