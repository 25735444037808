import React, { Component } from "react";
import FaqHeader from "./FaqHeader";
import FaqSidebar from "./FaqSidebar";
import FaqContent from "./FaqContent";
import "./Faq.scss";

class Faq extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: "1"
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <section id="faq" className="with-pad">
                <div className="container">
                    <FaqHeader />
                    <div className="row">
                        <FaqSidebar activeTab={this.state.activeTab} toggle={this.toggle} />
                        <FaqContent activeTab={this.state.activeTab} />
                    </div>
                </div>
            </section>
        );
    }
}
export default Faq;
