import React, { Component } from "react";
import { Button } from "reactstrap";
import { RegisterBorrower_Personal } from "../../../../../redux/action/User";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Master } from "../../../../../redux/action/Master";
import { MarketLocationMicro } from "../../../../../redux/action/Borrower";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../../sass/loader.css";
import { validate } from "../../../../../library/validation/validate";
import { changeCapitalize, imgToFile } from "../../../../../library/helper/helper";
import MicroLoan from "./micro/Micro";
import InfoPribadi from "./InfoPribadi";
import Alamat from "./Alamat";
import Pekerjaan from "./Pekerjaan";
import KontakDarurat from "./KontakDarurat";
import { micro, info, alamat, pekerjaan } from "../../../../../library/validation/validateBP";
import { encryptedData } from "../../../../../library/helper/Crypto";
import { OpenCamera } from "../../../../../library/components/Components";
import { addYears } from "date-fns";

class MicroR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nama_borrower: localStorage.nama,
            email: localStorage.email,
            jenis_pengguna: "",
            no_ktp: "",
            no_npwp: "",
            tempat_lahir: "",
            id_jenis_kelamin: "",
            alamat: "",
            id_kota: "",
            id_provinsi: "",
            kode_pos: "",
            id_kewarganegaraan: "",
            kewarganegaraan_list: [],
            agamaList: [],
            status_perkawinanList: [],
            provinsiList: [],
            kotaList: [],
            kotaListDomisili: [],
            pendidikanList: [],
            pekerjaanList: [],
            pekerjaan_onlineList: [],
            bidang_pekerjaanList: [],
            pengalaman_kerjaList: [],
            pendapatanList: [],
            status_kepemilikan_rumahList: [],
            jenis_kelaminList: [],
            sektorList: [],
            id_agama: "",
            status_perkawinan: "",
            id_pekerjaan: "",
            id_bidang_pekerjaan: "",
            id_pekerjaan_online: "",
            pendapatan: "",
            pengalaman_kerja: "",
            id_pendidikan: "",
            status_kepemilikan_rumah: "",
            deskripsi_usaha: "",
            foto_ktp: "",
            foto_selfie: "",
            foto_npwp: "",
            foto_kartu_keluarga: "",
            file: "",
            surat_keterangan_usaha: "",
            nama_kontak_darurat: "",
            alamat_kontak_darurat: "",
            no_handphone_kontak_darurat: "",
            hubungan_kontak_darurat: "",
            errKtp: "",
            errNpwp: "",
            errNumber: "",
            kelurahan: "",
            kecamatan: "",
            selectedOption1: null,
            selectedOption2: null,
            selectedOption3: null,
            tgl_lahir: addYears(new Date(), -17),

            checked: false,
            disabled: true,
            camera: true,
            fotoDone: false,
            modalselfieWebcam: false,
            setModal: false,
            list_pasar: [],
            errNumberHP: "",
            switchDomisili: false,
            switchformDomisili: false,
            alamat_domisili: "",
            kota_domisili: "",
            provinsi_domisili: "",
            kode_pos_domisili: "",
            kelurahan_domisili: "",
            kecamatan_domisili: "",
            errKodepos_domisili: "",
            errKodepos: "",
            errAlamatD: "",
            errKelD: "",
            errKecD: "",
            errKodeD: "",
            count: 1,
            isNPWPSame: false,
            sektorLabel: "-- Pilih Sektor --",
            provLabel: "-- Pilih Provinsi --",
            kotaLabel: "-- Pilih Kota/Kabupaten --",
            dprovLabel: "-- Pilih Provinsi --",
            dkotaLabel: "-- Pilih Kota/Kabupaten --",
            errNamaD: "",
            errNoD: "",
            errHubD: ""
        };
        this.dateChanged = this.dateChanged.bind(this);
        this.fotoLagi = this.fotoLagi.bind(this);
        this.successFoto = this.successFoto.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
    }

    handleChange = (e, type, changeError) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value) });
        });
    };
    handleNPWPCheck = () => {
        this.setState({ isNPWPSame: !this.state.isNPWPSame }, () => {
            if (this.state.isNPWPSame) {
                this.setState({
                    no_npwp: this.state.no_ktp
                });
            }
            else {
                this.setState({
                    no_npwp: this.state.no_npwp
                });
            }
        });

    };
    handleNext() {
        window.scrollTo(0, 0);

        if (this.check() === false) {
            Swal.fire({
                title: "Gagal Daftar",
                text: "Data Anda masih salah, periksa kembali",
                icon: "warning",
                buttons: "ok"
            });
        } else {
            this.setState({
                count: this.state.count + 1
            });
        }
        // console.log(this.check())
    }

    handlePrev() {
        window.scrollTo(0, 0);
        this.setState({
            count: this.state.count - 1
        });
    }

    check() {
        switch (this.state.count) {
            case 1:
                return micro(this.state);
            case 2:
                return info(this.state, this.selfieBlob);
            case 3:
                return alamat(this.state);
            case 4:
                return pekerjaan(this.state);
            default:
                break;
        }
    }

    formCase() {
        switch (this.state.count) {
            case 1:
                return (
                    <MicroLoan
                        lokasi_pasar={this.state.lokasi_pasar}
                        surat_keterangan_usaha={this.state.surat_keterangan_usaha}
                        sektor_usaha={this.state.sektor_usaha}
                        sektorLabel={this.state.sektorLabel}
                        deskripsi_usaha={this.state.deskripsi_usaha}
                        onChangeS={this.handleSelectSearch.bind(null, "sektor_usaha", "sektorLabel")}
                        onChange={(e) => this.handleChange(e)}
                        handleUploadFile={this.handleUploadFile}
                        lokasi={this.list_pasar()}
                        sektorList={this.state.sektorList}
                    />
                );
            case 2:
                return (
                    <InfoPribadi
                        tempat_lahir={this.state.tempat_lahir}
                        tgl_lahir={this.state.tgl_lahir}
                        id_jenis_kelamin={this.state.id_jenis_kelamin}
                        id_agama={this.state.id_agama}
                        status_perkawinan={this.state.status_perkawinan}
                        no_ktp={this.state.no_ktp}
                        no_npwp={this.state.no_npwp}
                        isNPWPSame={this.state.isNPWPSame}
                        handleNPWPCheck={this.handleNPWPCheck}
                        foto_npwp={this.state.foto_npwp}
                        foto_kartu_keluarga={this.state.foto_kartu_keluarga}
                        foto_ktp={this.state.foto_ktp}
                        foto_selfie={this.state.foto_selfie}
                        selfieBlob={this.selfieBlob}
                        onChangeTL={(e) => this.handleChange(e, "string", "errTempatLahir")}
                        onChangeKTP={(e) => this.handleChange(e, "number", "errKtp")}
                        onChangeNPWP={(e) => this.handleChange(e, "number", "errNpwp")}
                        onChange={(e) => this.handleChange(e)}
                        dateChanged={this.dateChanged}
                        handleUploadFile={this.handleUploadFile}
                        toggleOpenCamera={this.toggleOpenCamera.bind(this)}
                        fotoDone={this.state.fotoDone}
                        jenis_kelaminList={this.state.jenis_kelaminList}
                        agamaList={this.state.agamaList}
                        status_perkawinanList={this.state.status_perkawinanList}
                        errTempatLahir={this.state.errTempatLahir}
                        errKtp={this.state.errKtp}
                        errNpwp={this.state.errNpwp}
                    />
                );
            case 3:
                return (
                    <Alamat
                        switchformDomisili={this.state.switchformDomisili}
                        alamat={this.state.alamat}
                        id_provinsi={this.state.id_provinsi}
                        provLabel={this.state.provLabel}
                        id_kota={this.state.id_kota}
                        kotaLabel={this.state.kotaLabel}
                        kelurahan={this.state.kelurahan}
                        kecamatan={this.state.kecamatan}
                        kode_pos={this.state.kode_pos}
                        alamat_domisili={this.state.alamat_domisili}
                        provinsi_domisili={this.state.provinsi_domisili}
                        dprovLabel={this.state.dprovLabel}
                        kota_domisili={this.state.kota_domisili}
                        dkotaLabel={this.state.dkotaLabel}
                        kelurahan_domisili={this.state.kelurahan_domisili}
                        kecamatan_domisili={this.state.kecamatan_domisili}
                        kode_pos_domisili={this.state.kode_pos_domisili}
                        onChange={(e) => this.handleChange(e)}
                        onChangePP={this.handleSelectSearch.bind(null, "id_provinsi", "provLabel")}
                        onChangePK={this.handleSelectSearch.bind(null, "id_kota", "kotaLabel")}
                        onChangeKel={(e) => this.handleChange(e, "string", "errKelurahan")}
                        onChangeKec={(e) => this.handleChange(e, "string", "errKecamatan")}
                        onChangeKode={(e) => this.handleChange(e, "number", "errKodepos")}
                        onChangePD={this.handleSelectSearch.bind(null, "provinsi_domisili", "dprovLabel")}
                        onChangeKD={this.handleSelectSearch.bind(null, "kota_domisili", "dkotaLabel")}
                        onChangeKelD={(e) => this.handleChange(e, "string", "errKelD")}
                        onChangeKecD={(e) => this.handleChange(e, "string", "errKecD")}
                        onChangeKodeD={(e) => this.handleChange(e, "number", "errKodeD")}
                        handleSwitch={this.handleSwitch}
                        provinsiList={this.state.provinsiList}
                        kotaList={this.state.kotaList}
                        kotaListDomisili={this.state.kotaListDomisili}
                        errKecamatan={this.state.errKecamatan}
                        errKelurahan={this.state.errKelurahan}
                        errKodepos={this.state.errKodepos}
                        errKelD={this.state.errKelD}
                        errKecD={this.state.errKecD}
                        errKodeD={this.state.errKodeD}
                    />
                );
            case 4:
                return (
                    <Pekerjaan
                        id_pendidikan={this.state.id_pendidikan}
                        id_pekerjaan={this.state.id_pekerjaan}
                        id_pekerjaan_online={this.state.id_pekerjaan_online}
                        id_bidang_pekerjaan={this.state.id_bidang_pekerjaan}
                        pengalaman_kerja={this.state.pengalaman_kerja}
                        pendapatan={this.state.pendapatan}
                        status_kepemilikan_rumah={this.state.status_kepemilikan_rumah}
                        onChange={(e) => this.handleChange(e)}
                        pendidikanList={this.state.pendidikanList}
                        pekerjaanList={this.state.pekerjaanList}
                        pekerjaan_onlineList={this.state.pekerjaan_onlineList}
                        bidang_pekerjaanList={this.state.bidang_pekerjaanList}
                        pengalaman_kerjaList={this.state.pengalaman_kerjaList}
                        pendapatanList={this.state.pendapatanList}
                        status_kepemilikan_rumahList={this.state.status_kepemilikan_rumahList}
                    />
                );
            case 5:
                return (
                    <KontakDarurat
                        nama_kontak_darurat={this.state.nama_kontak_darurat}
                        no_handphone_kontak_darurat={this.state.no_handphone_kontak_darurat}
                        alamat_kontak_darurat={this.state.alamat_kontak_darurat}
                        hubungan_kontak_darurat={this.state.hubungan_kontak_darurat}
                        onChangeNama={(e) => this.handleChange(e, "string", "errNamaD")}
                        onChangeNo={(e) => this.handleChange(e, "number", "errNoD")}
                        onChangeHub={(e) => this.handleChange(e, "string", "errHubD")}
                        onChange={(e) => this.handleChange(e)}
                        errNamaD={this.state.errNamaD}
                        errNoD={this.state.errNoD}
                        errAlamatD={this.state.errAlamatD}
                        errHubD={this.state.errHubD}
                        handleCheck={this.handleCheck}
                        checked={this.state.checked}
                        loading={this.props.user.isLoading}
                        handleSaveBorrower={this.handleSaveBorrower}
                        disabled={this.state.disabled}
                    />
                );
            default:
                break;
        }
    }
    handleUploadFile = (e) => {
        let file = e.target.files[0];
        this.setState({
            [e.target.name]: file
        });
        //    console.log(e.target.files[0])
    };

    //change select option
    handleSelectSearch = (name, label, e) => {
        // console.log({[name] : e.value})
        this.setState({ [name]: e.value, [label]: e.label });
    };
    //

    dateChanged(d) {
        this.setState({ tgl_lahir: d });
    }
    handleCheck = () => {
        this.setState({ checked: !this.state.checked });
        if (!this.state.checked) {
            this.setState({
                disabled: !this.state.disabled
            });
        } else {
            this.setState({
                disabled: !this.state.disabled
            });
        }
    };
    //

    //form domisili
    handleSwitch = () => {
        this.setState({ switchDomisili: !this.state.switchDomisili });
        if (!this.state.switchDomisili) {
            this.setState({
                switchformDomisili: !this.state.switchformDomisili
            });
        } else {
            this.setState({
                switchformDomisili: !this.state.switchformDomisili
            });
        }
    };
    //

    list_pasar = () => {
        const hasil = this.state.list_pasar.map((val) => {
            return (
                <option key={val._id} value={val.nama}>
                    {val.nama}
                </option>
            );
        });
        return hasil;
    };
    //

    async componentDidMount() {
        await this.props.dispatch(Master());
        await this.props.dispatch(MarketLocationMicro());
        this.setState({
            agamaList: this.props.master.masterList.master_agama,
            status_perkawinanList: this.props.master.masterList.master_status_perkawinan,
            provinsiList: this.props.master.masterList.master_provinsi,
            kotaList: this.props.master.masterList.master_kota_kabupaten,
            pendidikanList: this.props.master.masterList.master_pendidikan,
            pekerjaanList: this.props.master.masterList.master_pekerjaan,
            pekerjaan_onlineList: this.props.master.masterList.master_online,
            bidang_pekerjaanList: this.props.master.masterList.master_bidang_pekerjaan,
            pengalaman_kerjaList: this.props.master.masterList.master_pengalaman_kerja,
            pendapatanList: this.props.master.masterList.master_pendapatan,
            status_kepemilikan_rumahList: this.props.master.masterList.master_status_kepemilikan_rumah,
            jenis_kelaminList: this.props.master.masterList.master_jenis_kelamin,
            sektorList: this.props.master.masterList.master_sektor, //

            list_pasar: this.props.borrower.borrowerMarket.result //
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.id_provinsi !== prevState.id_provinsi) {
            await this.props.dispatch(Master("master_kota_kabupaten", `${this.state.id_provinsi}`));
            this.setState({
                kotaList: this.props.master.masterList
            });
        }
        if (this.state.provinsi_domisili !== prevState.provinsi_domisili) {
            await this.props.dispatch(Master("master_kota_kabupaten", `${this.state.provinsi_domisili}`));
            this.setState({
                kotaListDomisili: this.props.master.masterList
            });
        }
    }

    handleSaveBorrower = async () => {
        const {
            sektor_usaha,
            jenis_pengguna,
            no_ktp,
            no_npwp,
            tempat_lahir,
            tgl_lahir,
            id_jenis_kelamin,
            alamat,
            id_kota,
            id_provinsi,
            kode_pos,
            id_agama,
            status_perkawinan,
            id_pekerjaan,
            id_bidang_pekerjaan,
            id_pekerjaan_online,
            pendapatan,
            pengalaman_kerja,
            id_pendidikan,
            status_kepemilikan_rumah,
            deskripsi_usaha,
            foto_selfie,
            foto_ktp,
            foto_npwp,
            foto_kartu_keluarga,
            kelurahan,
            kecamatan,
            surat_keterangan_usaha,
            nama_kontak_darurat,
            alamat_kontak_darurat,
            no_handphone_kontak_darurat,
            hubungan_kontak_darurat,
            lokasi_pasar,
            alamat_domisili,
            kota_domisili,
            provinsi_domisili,
            kode_pos_domisili,
            kelurahan_domisili,
            kecamatan_domisili
        } = this.state;

        const formData = new FormData();
        formData.append("jenis_pengguna", jenis_pengguna);
        formData.append("no_ktp", encryptedData(no_ktp));
        formData.append("no_npwp", encryptedData(no_npwp));
        formData.append("tempat_lahir", encryptedData(changeCapitalize(tempat_lahir)));
        formData.append("tgl_lahir", encryptedData(tgl_lahir.toISOString()));
        formData.append("alamat", encryptedData(alamat));
        formData.append("kelurahan", encryptedData(changeCapitalize(kelurahan)));
        formData.append("kecamatan", encryptedData(changeCapitalize(kecamatan)));
        formData.append("deskripsi_usaha", encryptedData(deskripsi_usaha)); //
        formData.append("kode_pos", encryptedData(kode_pos));
        formData.append("sektor_usaha", encryptedData(sektor_usaha));
        formData.append("id_kewarganegaraan", encryptedData("0")); //auto indonesia
        formData.append("status_perkawinan", encryptedData(status_perkawinan.toString()));
        formData.append("id_pekerjaan", encryptedData(id_pekerjaan.toString()));
        formData.append("pengalaman_kerja", encryptedData(pengalaman_kerja.toString()));
        formData.append("id_pendidikan", encryptedData(id_pendidikan.toString()));
        formData.append("status_kepemilikan_rumah", encryptedData(status_kepemilikan_rumah.toString()));
        formData.append("id_agama", encryptedData(id_agama.toString()));
        formData.append("id_jenis_kelamin", encryptedData(id_jenis_kelamin.toString()));
        formData.append("id_kota", encryptedData(id_kota));
        formData.append("id_provinsi", encryptedData(id_provinsi.toString()));
        formData.append("id_bidang_pekerjaan", encryptedData(id_bidang_pekerjaan.toString()));
        formData.append("id_pekerjaan_online", encryptedData(id_pekerjaan_online.toString()));
        formData.append("pendapatan", encryptedData(pendapatan));
        formData.append("jenis_pinjaman", encryptedData("Dana Talangan")); //
        formData.append("nama_kontak_darurat", encryptedData(changeCapitalize(nama_kontak_darurat)));
        formData.append("alamat_kontak_darurat", encryptedData(alamat_kontak_darurat));
        formData.append("no_handphone_kontak_darurat", encryptedData(no_handphone_kontak_darurat));
        formData.append("hubungan_kontak_darurat", encryptedData(changeCapitalize(hubungan_kontak_darurat)));
        formData.append("lokasi_pasar", encryptedData(lokasi_pasar));
        formData.append("surat_keterangan_usaha", surat_keterangan_usaha); //
        formData.append("foto_selfie", imgToFile(foto_selfie));

        //domisili
        if (this.state.switchformDomisili === true) {
            formData.append("alamat_domisili", encryptedData(alamat_domisili));
            formData.append("kota_domisili", encryptedData(kota_domisili));
            formData.append("provinsi_domisili", encryptedData(provinsi_domisili.toString()));
            formData.append("kode_pos_domisili", encryptedData(kode_pos_domisili));
            formData.append("kelurahan_domisili", encryptedData(changeCapitalize(kelurahan_domisili)));
            formData.append("kecamatan_domisili", encryptedData(changeCapitalize(kecamatan_domisili)));
        } else {
            formData.append("alamat_domisili", encryptedData(alamat));
            formData.append("kota_domisili", encryptedData(id_kota));
            formData.append("provinsi_domisili", encryptedData(id_provinsi.toString()));
            formData.append("kode_pos_domisili", encryptedData(kode_pos));
            formData.append("kelurahan_domisili", encryptedData(changeCapitalize(kelurahan)));
            formData.append("kecamatan_domisili", encryptedData(changeCapitalize(kecamatan)));
        }
        formData.append("foto_ktp", foto_ktp);
        formData.append("foto_npwp", foto_npwp);
        formData.append("foto_kartu_keluarga", foto_kartu_keluarga); //

        if (this.state.errNamaD !== 0 || this.state.errHubD !== 0 || this.state.errNoD !== 0 || !this.state.alamat) {
            Swal.fire({
                title: "Gagal Daftar",
                text: "Mohon untuk periksa kembali data Anda",
                icon: "warning",
                buttons: "ok"
            });
        } else if (!window.navigator.onLine) {
            Swal.fire({
                title: "Koneksi Terputus",
                text: `Silakan cek kembali koneksi anda`,
                icon: "warning",
                button: "ok"
            });
        } else {
            await this.props
                .dispatch(RegisterBorrower_Personal(formData))
                .then((res) => {
                    localStorage.setItem("verif", true);

                    // console.log(res)
                    // console.log(res.value.status === 201 ? 'bener' : 'salah')
                    if (res.value.status === 201) {
                        window.location.href = "/emailverif/2";
                    }
                })
                .catch((err) => {
                    // console.log(err)
                    // console.log(err.response)
                    if (err.response.status === 400) {
                        Swal.fire({
                            title: "Gagal daftar",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (err.response.status === 404) {
                        Swal.fire({
                            title: "Tidak dapat daftar",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else {
                        Swal.fire({
                            title: "Tidak dapat daftar",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    }
                });
        }
    };

    // foto camera
    toggleOpenCamera() {
        this.setState({
            modalselfieWebcam: !this.state.modalselfieWebcam,
            setModal: !this.state.setModal
        });
    }
    handleTakePhotoAnimationDone(dataUri) {
        this.setState({
            foto_selfie: dataUri,
            camera: false
        });
    }
    fotoLagi() {
        this.setState({
            camera: !this.state.camera
        });
    }
    successFoto = () => {
        this.setState({
            modalselfieWebcam: false,
            setModal: false,
            fotoDone: true
        });
    };
    //
    render() {
        return (
            <div>
                <div className="sec-header">
                    <h4 className="text-center sec-title">Borrower Micro Loan</h4>
                </div>

                <ul className="progressbar d-flex justify-content-center mb-3">
                    <li id={this.state.count <= 1 ? "store" : "check"} className="active">
                        <strong className="d-none d-sm-block">Informasi Toko</strong>
                    </li>
                    <li id={this.state.count <= 2 ? "account" : "check"} className={this.state.count >= 2 ? "active" : null}>
                        <strong className="d-none d-sm-block">Info Pribadi</strong>
                    </li>
                    <li id={this.state.count <= 3 ? "address" : "check"} className={this.state.count >= 3 ? "active" : null}>
                        <strong className="d-none d-sm-block">Alamat</strong>
                    </li>
                    <li id={this.state.count <= 4 ? "work" : "check"} className={this.state.count >= 4 ? "active" : null}>
                        <strong className="d-none d-sm-block"> Pekerjaan</strong>
                    </li>
                    <li id={this.state.count <= 5 ? "contact" : "check"} className={this.state.count >= 5 ? "active" : null}>
                        <strong className="d-none d-sm-block">Kontak Darurat (Keluarga Yang Tidak Satu Rumah)</strong>
                    </li>
                </ul>
                <div className="pt-5">{this.formCase()}</div>
                <div className="row text-center mt-5">
                    <div className="col-md-6 col-6 ">
                        {this.state.count === 1 ? null : (
                            <Button onClick={this.handlePrev} className=" btn-lg btn-block" color="outline-secondary">
                                <span className="material-icons arrow-left ">keyboard_arrow_left</span>
                                Kembali
                            </Button>
                        )}
                    </div>
                    <div className="col-md-6 col-6">
                        {this.state.count === 5 ? null : (
                            <Button onClick={this.handleNext} className=" btn-lg btn-block" color="primary">
                                Lanjut
                                <span className="material-icons arrow-next">keyboard_arrow_right</span>
                            </Button>
                        )}
                    </div>
                </div>

                <OpenCamera
                    modalselfieWebcam={this.state.modalselfieWebcam}
                    toggle={this.toggleOpenCamera.bind(this)}
                    dataUri={this.state.foto_selfie}
                    handle={(e) => this.handleTakePhotoAnimationDone(e)}
                    fotoLagi={this.fotoLagi}
                    successFoto={this.successFoto}
                    camera={this.state.camera}
                />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        master: state.master,
        borrower: state.borrower
    };
};
export default connect(mapStateToProps)(MicroR);
