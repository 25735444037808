import React, { Component } from "react";
import { Form } from "reactstrap";
import { Inputs, InputIcon, SelectOptionSearchs } from "../../../../../library/components/Components";
import Location from "../../../../assets/svg/Location"; //icon svg

class AlamatPerusahaan extends Component {
    render() {
        return (
            <Form>
                <h4 className="text-center mb-4 d-block d-sm-none font-weight-bold">Alamat Perusahaan</h4>
                <div className="row">
                    <div className="col-12">
                        <InputIcon
                            forLabel={"alamat"}
                            label={"Alamat Perusahaan"}
                            icon={<Location />}
                            type={"text"}
                            name={"alamat"}
                            onChange={this.props.onChange}
                            placeholder={"Contoh: Jakarta"}
                            value={this.props.data_alamat ? this.props.data_alamat : this.props.alamat}
                            readOnly={this.props.data_alamat ? true : false}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {this.props.data_provinsi ? (
                            <Inputs
                                forLabel={"id_provinsi"}
                                label={"Provinsi"}
                                type={"text"}
                                name={"Provinsi"}
                                value={this.props.data_provinsi}
                                readOnly={true}
                            />
                        ) : (
                            <SelectOptionSearchs
                                forLabel={"id_provinsi"}
                                label={"Provinsi"}
                                onChange={this.props.onChangePP}
                                data={this.props.provinsiList}
                                value={{
                                    value: this.props.id_provinsi,
                                    label: this.props.pprovLabel
                                }}
                            />
                        )}
                    </div>
                    <div className="col-md-6">
                        {/* {this.props.data_kota ? (
              <Inputs
                forLabel={"id_kota"}
                label={"Kota / Kabupaten"}
                type={"text"}
                name={"Kota / Kabupaten"}
                value={this.props.data_kota}
                readOnly={true}
              />
            ) : ( */}
                        <SelectOptionSearchs
                            forLabel={"id_kota"}
                            label={"Kota / Kabupaten"}
                            onChange={this.props.onChangePK}
                            data={this.props.kotaList}
                            value={{
                                value: this.props.id_kota,
                                label: this.props.pkotaLabel
                            }}
                            disabled={!this.props.id_provinsi}
                        />
                        {/* )} */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"kelurahan"}
                            label={"Kelurahan"}
                            onChange={this.props.onChangePKel}
                            type={"text"}
                            name={"kelurahan"}
                            placeholder={"Contoh: Cengkareng"}
                            error={this.props.errKelurahan}
                            value={this.props.data_kelurahan ? this.props.data_kelurahan : this.props.kelurahan}
                            readOnly={this.props.data_kelurahan ? true : false}
                        />
                    </div>
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"kecamatan"}
                            label={"Kecamatan"}
                            onChange={this.props.onChangePKec}
                            type={"text"}
                            name={"kecamatan"}
                            placeholder={"Contoh: Cengkareng Barat"}
                            error={this.props.errKecamatan}
                            value={this.props.data_kecamatan ? this.props.data_kecamatan : this.props.kecamatan}
                            readOnly={this.props.data_kecamatan ? true : false}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"kode_pos"}
                            label={"Kodepos"}
                            onChange={this.props.onChangePKode}
                            type={"text"}
                            name={"kode_pos"}
                            placeholder={"Contoh : 12345"}
                            error={this.props.errKodepos}
                            maxLength={"5"}
                            value={this.props.data_kodepos ? this.props.data_kodepos : this.props.kode_pos}
                            readOnly={this.props.data_kodepos ? true : false}
                        />
                    </div>
                </div>
            </Form>
        );
    }
}

export default AlamatPerusahaan;
