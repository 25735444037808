import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import "./ModalPengumuman.scss";

class PopUpPengumunan extends Component {
    render() {
        return (
            <Modal size="lg" isOpen={this.props.isOpen} toggle={this.props.toggle} className="modal-dialog modal-dialog-centered" tabIndex="-1">
                <ModalHeader  className="mb-0 pb-0">
                    <div className="text-center flex-fill">
                        <h5 className="modal-title text-center font-weight-bold mb-2">PENGUMUMAN PENTING!</h5>
                    </div>
                </ModalHeader>
                <ModalBody className="text-pengumuman">
                    <p className="mb-0 text-paragraf">
                        {" "}
                        <b>Indofund.id</b> adalah merk dagang terdaftar dan diawasi milik <b>PT Bursa Akselerasi Indonesia</b> yang berizin dari
                        Otoritas Jasa Keuangan.
                    </p>
                    <br />
                    <p className="mb-0 text-paragraf">
                        Oleh karena itu kami <b>tidak pernah</b> menghubungi calon penerima dana dan pemberi Dana untuk :
                    </p>
                    <br />
                    <ul style={{paddingLeft:'24px'}}>
                        <li>
                            <p className="mb-1 text-paragraf">
                                <b>Meminta data pribadi</b>
                            </p>
                        </li>
                        <li>
                            <p className="mb-1 text-paragraf">
                                <b>Meminta pembayaran melalui nomor rekening atas nama pribadi</b>
                            </p>
                        </li>
                    </ul>
                    <br />
                    <p className="mb-0 text-paragraf"> Pastikan hanya menghubungi dan berkomunikasi melalui kontak resmi dibawah ini :</p>
                    <br />
                    <p className="mb-0 text-paragraf">
                        Email: <b>cs@indofund.id</b>
                    </p>
                    <p className="mb-0 text-paragraf">
                        Telepon: <b>021-25982507</b>
                    </p>
                    <p className="mb-0 text-paragraf">
                        Whatsapp: <b>0896 4344 8118</b>
                    </p>

                    <br/>
                    <p className="text-paragraf mb-0" style={{ fontWeight: 'bold', fontSize: '14px', textAlign: 'left' }}>
                        PERINGATAN:<br />
                        “HATI-HATI, TRANSAKSI INI BERISIKO TINGGI. ANDA DAPAT SAJA MENGALAMI KERUGIAN ATAU KEHILANGAN UANG. JANGAN BERUTANG JIKA TIDAK MEMILIKI KEMAMPUAN MEMBAYAR. PERTIMBANGKAN SECARA BIJAK SEBELUM BERTRANSAKSI.”
                    </p>
                </ModalBody>
                {/* <ModalFooter> */}
                    <Button color="primary" className="btn btn-primary btn-md mt-1 mb-4" style={{marginInline:'32px'}} onClick={this.props.toggle}>
                    Mengerti
                    </Button>
                {/* </ModalFooter> */}
            </Modal>
        );
    }
}
export default PopUpPengumunan;
