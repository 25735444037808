import React, { Component } from "react";
import "./statistik.scss";
import { Statistik } from "../../../../redux/action/User";
import { connect } from "react-redux";
import { conversStatistic } from "../../../../library/helper/helper";
import { LoadingDataStatistik } from "../../../assets/loading data/LoadingGuest";
import IconPinjaman from "../../../assets/svg/Pinjaman";
import IconPinjamanTahun from "../../../assets/svg/PinjamanTahun";
import IconOuts from "../../../assets/svg/OutstandingSvg";
// import TeamVerif from "../../../assets/svg/TeamVerif";

class DataStatistik extends Component {
    state = {
        totalLoan: ""
    };
    async componentDidMount() {
        await this.props.dispatch(Statistik());
        this.setState({
            totalLoan: this.props.user.statistik.totalLoan,
            yearlyLoanAmount: this.props.user.statistik.yearlyLoanAmount,
            outstandingLoan: this.props.user.statistik.outstandingLoan,
            numberOfBorrowers: this.props.user.statistik.numberOfBorrowers,
            activeBorrowers: this.props.user.statistik.activeBorrowers,
            numberOfLenders: this.props.user.statistik.numberOfLenders,
            activeLenders: this.props.user.statistik.activeLenders
        });
    }
    render() {
        return (
            <div>
                {this.props.user.isLoadingStatistik ? (
                    <LoadingDataStatistik />
                ) : (
                    <section id="statistik">
                        <div className="sec-header">
                            <h4 className="text-center sec-title">Statistik</h4>
                        </div>
                        <div className="row text-center">
                            <div className="col-12 col-md-4 mb-3">
                                <div className="card border-0 shadow-sm h-100">
                                    <div className="card-body">
                                        <div className="col d-flex flex-column justify-content-between h-100" style={{ gap: '2.25rem' }}>
                                            <div className="col-12 d-flex justify-content-center align-items-center" style={{ flex: 'auto', maxHeight: '80px' }}>
                                                <div className="icon-wrapper d-flex flex-1 justify-content-center icon-responsive"  >
                                                    <img
                                                        className=""
                                                        width="100%"
                                                        height="100%"
                                                        src={process.env.PUBLIC_URL + "/images/tentang-kami/icon/pendanaan.png"}
                                                        alt="3"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex flex-column justify-content-between flex-1" >
                                                <h6 >Total Pendanaan</h6>

                                                <h3 className="text-title p-0 mt-2" style={{ fontSize: '1.5rem' }}>
                                                    <span style={{ fontSize: "13pt" }}>Rp.</span> {conversStatistic(this.state.totalLoan)}
                                                </h3>
                                                <h6 className="p-0 mt-2 f-14" style={{}}>Total Akumulasi Sejak Indofund Berdiri</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 mb-3">
                                <div className="card border-0 shadow-sm h-100">
                                    <div className="card-body">
                                        <div className="col d-flex flex-column justify-content-between h-100" style={{ gap: '2.25rem' }}>
                                            <div className="col-12 d-flex justify-content-center align-items-center" style={{ flex: 'auto', maxHeight: '80px' }}>
                                                <div className="icon-wrapper d-flex flex-1 justify-content-center icon-responsive" >
                                                    <img
                                                        className=""
                                                        width="100%"
                                                        height="100%"
                                                        src={process.env.PUBLIC_URL + "/images/tentang-kami/icon/pendanaan-tahunan.png"}
                                                        alt="3"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex flex-column justify-content-between flex-1">
                                                <h6 >Total Pendanaan Pertahun</h6>

                                                <h3 className="text-title p-0 mt-2" style={{ fontSize: '1.5rem' }}>
                                                    <span style={{ fontSize: "13pt" }}>Rp.</span> {conversStatistic(this.state.yearlyLoanAmount) === undefined
                                                        ? `Nilai tidak ada`
                                                        : `${conversStatistic(this.state.yearlyLoanAmount)}`}
                                                </h3>
                                                <h6 className="p-0 mt-2 f-14" style={{}}>Total Akumulasi Pendanaan Tahun Ini</h6>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 mb-3">
                                <div className="card border-0 shadow-sm h-100">
                                    <div className="card-body">
                                        <div className="col d-flex flex-column justify-content-between h-100" style={{ gap: '2.25rem' }}>
                                            <div className="col-12 d-flex justify-content-center align-items-center" style={{ flex: 'auto', maxHeight: '80px' }}>
                                                <div className="icon-wrapper d-flex flex-1 justify-content-center icon-responsive" >
                                                    <img
                                                        className=""
                                                        width="100%"
                                                        height="100%"
                                                        src={process.env.PUBLIC_URL + "/images/tentang-kami/icon/outstanding.png"}
                                                        alt="3"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex flex-column justify-content-between flex-1" >
                                                <h6 >Outstanding Pendanaan</h6>

                                                <h3 className="text-title p-0 mt-2" style={{ fontSize: '1.5rem' }}>
                                                    <span style={{ fontSize: "13pt" }}>Rp.</span> {conversStatistic(this.state.outstandingLoan) === undefined
                                                        ? `Nilai tidak ada`
                                                        : `${conversStatistic(this.state.outstandingLoan)}`}
                                                </h3>
                                                <h6 className="p-0 mt-2 f-14" style={{}}>Total Pendanaan Berjalan</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-12 col-md-6 mb-3">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row gap-2">
                                            <div className="col-12 col-md-6 flex-column flex-md-row d-flex gap-3 justify-content-center align-items-center">
                                                <div className="icon-wrapper d-flex flex-1 justify-content-center icon-responsive">
                                                    <img
                                                        className=""
                                                        width="80%"
                                                        height="100%"
                                                        src={process.env.PUBLIC_URL + "/images/tentang-kami/icon/lender.png"}
                                                        alt="3"
                                                    />
                                                </div>
                                                <h6 className="flex-1 text-left m-0 d-none d-lg-inline">Pemberi <br />Pendanaan</h6>
                                            </div>
                                            <div className="col-12 col-md-5 mt-md-4 d-flex flex-column justify-content-between align-items-center align-items-md-left text-center text-md-left gap-2">
                                                <div className="d-flex flex-column gap-1 w-100">
                                                    <h6>Pemberi Pendanaan</h6>
                                                    <h3 className="text-title p-0 m-0" style={{ fontSize: '1.5rem' }}>
                                                        {this.state.numberOfLenders === undefined ? "Nilai tidak ada" : this.state.numberOfLenders}
                                                    </h3>
                                                </div>
                                                <div style={{ height: '1px', backgroundColor: '#1212121A', width: '100%' }}></div>
                                                <div className="d-flex flex-column gap-1 w-100">
                                                    <h6>Pemberi Pendanaan Aktif</h6>
                                                    <h3 className="text-title p-0" style={{ fontSize: '1.5rem' }}>
                                                        {this.state.activeLenders === undefined ? "Nilai tidak ada" : this.state.activeLenders}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row gap-2">
                                            <div className="col-12 col-md-6 flex-column flex-md-row d-flex gap-3 justify-content-center align-items-center">
                                                <div className="icon-wrapper d-flex flex-1 justify-content-center" style={{ width: '100%', maxWidth: '100px', height: 'auto' }} >
                                                    <img
                                                        className=""
                                                        width="100%"
                                                        height="100%"
                                                        src={process.env.PUBLIC_URL + "/images/tentang-kami/icon/team.png"}
                                                        alt="3"
                                                    />
                                                </div>
                                                <h6 className="flex-1 text-left m-0 d-none d-lg-inline">Penerima <br /> Pendanaan</h6>
                                            </div>
                                            <div className="col-12 col-md-5 mt-md-4 d-flex flex-column justify-content-between align-items-center align-items-md-left text-center text-md-left gap-2">
                                                <div className="d-flex flex-column gap-1 w-100">
                                                    <h6>Penerima Pendanaan</h6>
                                                    <h3 className="text-title p-0 m-0" style={{ fontSize: '1.5rem' }}>
                                                        {this.state.numberOfBorrowers === undefined ? "Nilai tidak ada" : this.state.numberOfBorrowers}
                                                    </h3>
                                                </div>
                                                <div style={{ height: '1px', backgroundColor: '#1212121A', width: '100%' }}></div>
                                                <div className="d-flex flex-column gap-1 w-100">
                                                    <h6>Penerima Pendanaan Aktif</h6>
                                                    <h3 className="text-title p-0" style={{ fontSize: '1.5rem' }}>
                                                        {this.state.activeBorrowers === undefined ? "Nilai tidak ada" : this.state.activeBorrowers}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-12 col-md-6">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6 col-md-6">
                                                <div className="icon-wrapper d-flex">
                                                    <img
                                                        // className="img-fluid"
                                                        width="50%"
                                                        src={process.env.PUBLIC_URL + "/images/tentang-kami/icon/lender 1.png"}
                                                        alt="3"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 mt-md-4">
                                                <h3 className="text-title">
                                                    {this.state.activeBorrowers === undefined ? "Nilai tidak ada" : this.state.activeLenders}
                                                </h3>
                                                <h6>Jumlah Pemberi Pendanaan Aktif</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row text-center">
                            <div className="col-12 col-md-6 mb-3">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6 col-md-4">
                                                <div className="icon-wrapper d-flex">
                                                    <img
                                                        className="img-fluid"
                                                        width="80%"
                                                        src={process.env.PUBLIC_URL + "/images/tentang-kami/icon/team.png"}
                                                        alt="3"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 mt-md-4">
                                                <h3 className="text-title">
                                                    {this.state.numberOfBorrowers === undefined ? "Nilai tidak ada" : this.state.numberOfBorrowers}
                                                </h3>
                                                <h6>Jumlah Penerima Pendanaan</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6 col-md-6">
                                                <div className="icon-wrapper d-flex">
                                                    <img
                                                        // className="img-fluid"
                                                        width="46%"
                                                        src={process.env.PUBLIC_URL + "/images/tentang-kami/icon/team 1.png"}
                                                        alt="3"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 mt-md-4">
                                                <h3 className="text-title">
                                                    {this.state.activeBorrowers === undefined ? "Nilai tidak ada" : this.state.activeBorrowers}
                                                </h3>
                                                <h6>Jumlah Penerima Pendanaan Aktif</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </section>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps)(DataStatistik);
