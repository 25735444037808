import React, { Component } from "react";
import { connect } from "react-redux";
import { getProject } from "../../../redux/action/Project";
import "./ProyekDetail.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import DetailProyek from "./ItemDetailProyek";
import Loader from "react-loader-spinner";
import { namaSektor } from "../../../library/helper/helper";
import Swal from "sweetalert2";
class ProyekDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: [],
            borrower: [],
            nilai_penawaran_pinjaman: "",
            errNominal: "",
            modal: false,
            setModal: false,
            checked: false,
            popoverOpenProyek: false,
            dana_proteksi: false,
            activeTab: "1",
            proyek_id: ""
        };
        // this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.props.dispatch(getProject(this.props.match.params.proyek_id));
        this.setState({
            project: this.props.project.projectDetail,
            borrower: this.props.project.projectDetail.borrower,
            proyek_id: this.props.match.params.proyek_id
        });

        //trigger jika lender berhasil ttd
        if (localStorage.berhasilTtd === "true") {
            Swal.fire({
                title: "Berhasil Pendanaan",
                text: "Anda berhasil melakukan tanda tangan perjanjian & pendanaan. Perjanjian dapat di download pada dashboard 'Tanda Tangan Digital' ",
                icon: "success",
                button: "ok"
            }).then(() => {
                localStorage.removeItem("currentPage");
                localStorage.removeItem("berhasilTtd");
                localStorage.removeItem("userAktivasi");
            });
        }
        // console.log(typeof localStorage.berhasilTtd);
    }

    authProyek = (diffDays, progressBar, sisa, penggunaan_pinjaman, estimasiBunga, project) => {
        // console.log(namaSektor)
        if (this.props.project.isLoadingIdProject === true) {
            return <Loader type="ThreeDots" color="#FF7A00" height={100} width={100} className="loading-dots" />;
        } else {
            return (
                <DetailProyek
                    project={project}
                    diffDays={diffDays}
                    progressBar={progressBar}
                    sisa={sisa}
                    namaSektor={namaSektor(penggunaan_pinjaman)}
                    estimasiBunga={estimasiBunga}
                    saldo={this.state.saldo}
                    kyc={this.state.kyc}
                    update={this.state.update}
                    proyek_id={this.props.match.params.proyek_id}
                />
            );
        }
    };
    render() {
        const project = this.state.project;
        const progressBar = (project.nilai_total_peminjam / project.nilai_pengajuan_pinjaman) * 100;
        const sisa = project.nilai_pengajuan_pinjaman - project.nilai_total_peminjam;

        //perhitungan jangka waktu
        const jangka_waktu = project.rencana_jangka_waktu_publikasi;
        const periode_akhir =
            project.tgl_mulai_publikasi_pinjaman === undefined
                ? ""
                : new Date(new Date(project.tgl_mulai_publikasi_pinjaman).getTime() + jangka_waktu * 24 * 60 * 60 * 1000);
        const date1 = Date.now();
        const date2 = new Date(periode_akhir);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        //estimasi bunga
        let estimasiBunga;
        if (!this.state.nilai_penawaran_pinjaman) {
            estimasiBunga = 0;
        } else {
            let pokok = project.nilai_pengajuan_pinjaman;
            let sukuBunga = project.suku_bunga_pinjaman - project.spread_bunga;
            let tenor = project.jangka_waktu_pinjaman;
            // console.log(sukuBunga);
            if (project.tipe_pinjaman === "Installment") {
                var angsuran = (pokok * (sukuBunga / 12)) / (1 - 1 / Math.pow(1 + sukuBunga / 12, tenor));
                estimasiBunga = Math.floor((this.state.nilai_penawaran_pinjaman / pokok) * (angsuran * tenor) - this.state.nilai_penawaran_pinjaman);
            } else {
                estimasiBunga = ((this.state.nilai_penawaran_pinjaman * sukuBunga) / 12) * tenor;
            }
        }

        return <div>{this.authProyek(diffDays, progressBar, sisa, project.penggunaan_pinjaman, estimasiBunga, project)}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
        lender: state.lender
    };
};
export default connect(mapStateToProps)(ProyekDetail);
