import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import NoData from "../../assets/no data/NoData";
import Pagination from "react-js-pagination";
import { LoadingDashboard } from "../../assets/loading data/LoadingBorrower";

class CSelesai extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPageSelesai: 1,
            todosPerPageSelesai: 15,
            filter: ""
        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(pageNumber) {
        window.scrollTo(0, 0);
        this.setState({
            currentPage: pageNumber
        });
    }
    handleChangeSearch = (event) => {
        this.setState({ filter: event.target.value });
    };

    SelesaiProject = (project, currentPage, todosPerPage, lengthSelesai) => {
        if (project === undefined) {
            // console.log('nono')
        } else {
            const indexOfLastTodo = currentPage * todosPerPage;
            const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
            //sorting descending
            function compare(a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }

            const currentTodos = project
                .filter((item) =>
                    this.state.filter === ""
                        ? item.status_proyek === "Proyek selesai"
                        : item.nama_pinjaman !== undefined
                        ? item.nama_pinjaman.toLowerCase().includes(this.state.filter.toLowerCase()) & (item.status_proyek === "Proyek selesai")
                        : ""
                )
                .sort(compare)
                .slice(indexOfFirstTodo, indexOfLastTodo);
            if (currentTodos.length !== 0 && lengthSelesai !== 0) {
                const hasil = currentTodos.map((val) => {
                    const rincianPinjaman = val.rincian_pinjaman;
                    // console.log(val.rincian_pinjaman.jatuh_tempo[val.rincian_pinjaman.jatuh_tempo- 1])
                    // console.log(val)
                    return (
                        <div key={val._id}>
                            {val.status_proyek === "Proyek selesai" ? (
                                <div className="transaction p-3">
                                    <div className="card border-0 shadow-sm">
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col-12 col-xl-9 mb-3 mb-xl-0">
                                                    <div className="row">
                                                        <div className="col-6 col-md-5 mb-3">
                                                            <p className="fs-n1 text-secondary mb-1">Nama Proyek</p>
                                                            <p className="mb-0">{val.nama_pinjaman}</p>
                                                        </div>
                                                        <div className="col-6 col-md-4 mb-3">
                                                            <p className="fs-n1 text-secondary mb-1">Jenis Proyek</p>
                                                            <p className="mb-0">{val.jenis_pinjaman}</p>
                                                        </div>
                                                        <div className="col-6 col-md-3 mb-3">
                                                            <p className="fs-n1 text-secondary mb-1">Nominal</p>
                                                            <p className="mb-0">Rp {val.nilai_total_peminjam.toLocaleString("IN")}</p>
                                                        </div>
                                                        <div className="col-6 col-md-5 mb-3 mb-xl-0">
                                                            <p className="fs-n1 text-secondary mb-1">Suku Bunga</p>
                                                            <p className="mb-0">{val.suku_bunga_pinjaman * 100} %</p>
                                                        </div>
                                                        <div className="col-6 col-md-4 mb-3 mb-xl-0">
                                                            <p className="fs-n1 text-secondary mb-1">Jatuh Tempo</p>
                                                            <div key={rincianPinjaman[rincianPinjaman.length - 1]._id}>
                                                                {new Date(rincianPinjaman[rincianPinjaman.length - 1].jatuh_tempo).toLocaleDateString(
                                                                    "IN"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-3 mb-3 mb-xl-0">
                                                            <p className="fs-n1 text-secondary mb-1">Status Project</p>
                                                            <p className="mb-0">{val.status_proyek}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-3 text-center">
                                                    <NavLink to={"/borrower/detail-selesai/" + val._id} className="btn btn-outline-primary btn-block">
                                                        <span>Detail</span>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    );
                });
                return hasil;
            } else {
                if (this.props.loading) {
                    return (
                        <div>
                            <LoadingDashboard />
                            <LoadingDashboard />
                        </div>
                    );
                } else {
                    return (
                        <NoData
                            symbol={<i className="material-icons-outlined">assignment</i>}
                            keterangan={project.length !== 0 ? "Proyek Anda belum selesai" : "Belum ada Pinjaman"}
                        />
                    );
                }
            }
        }
    };
    render() {
        const { currentPageSelesai, todosPerPageSelesai, filter } = this.state;
        //pagination Selesai
        const lengthSelesai = this.props.project?.filter((item) =>
            this.state.filter === ""
                ? item.status_proyek === "Proyek selesai"
                : item.nama_pinjaman !== undefined
                ? item.nama_pinjaman.toLowerCase().includes(this.state.filter.toLowerCase()) & (item.status_proyek === "Proyek selesai")
                : ""
        ).length;

        return (
            <div>
                <div className="d-flex align-items-center justify-content-between my-3">
                    {/* <div>
                        <label className="mb-0 mr-2 d-none d-md-inline-block" for="urutkan">Urutkan</label>
                        <select id="urutkan" className="custom-select w-auto mr-2">
                            <option value="">Suku Bunga</option>
                        </select>
                    </div> */}
                    <div>
                        <form>
                            <div className="input-group">
                                <input
                                    className="form-control"
                                    type="text"
                                    id="keyword"
                                    name="keyword"
                                    placeholder="Cari Transaksi"
                                    value={filter}
                                    onChange={this.handleChangeSearch}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <i className="material-icons-outlined">search</i>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {this.props.isLoadingGetUser ? (
                    <div>
                        <LoadingDashboard />
                        <LoadingDashboard />
                    </div>
                ) : (
                    this.SelesaiProject(this.props.project, currentPageSelesai, todosPerPageSelesai, lengthSelesai)
                )}

                <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        hideDisabled
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.currentPageSelesai}
                        itemsCountPerPage={this.state.todosPerPageSelesai}
                        totalItemsCount={parseInt(lengthSelesai)}
                        onChange={this.handleClick.bind(this)}
                    />
                </div>
            </div>
        );
    }
}
export default CSelesai;
