import React, { Component } from "react";
import { Form, FormGroup, CustomInput } from "reactstrap";
import { Inputs, InputIcon, SelectOptionSearchs, FormDomisili } from "../../../../../library/components/Components";
import Location from "../../../../assets/svg/Location"; //icon svg

class Alamat extends Component {
    render() {
        return (
            <Form>
                <h4 className="text-center mb-4 d-block d-sm-none font-weight-bold">Alamat Borrower</h4>
                <div className="row ">
                    <div className={this.props.switchformDomisili === true ? "col-md-6 col-12" : "col-md-12 col-12"}>
                        <div className="row">
                            <div className="col-12">
                                <InputIcon
                                    forLabel={"alamat"}
                                    label={"Alamat Sesuai KTP"}
                                    icon={<Location />}
                                    type={"text"}
                                    name={"alamat"}
                                    onChange={this.props.onChange}
                                    value={this.props.data_alamat ? this.props.data_alamat : this.props.alamat}
                                    placeholder={"Contoh: Jakarta"}
                                    readOnly={this.props.data_alamat ? true : false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                {this.props.data_provinsi ? (
                                    <Inputs
                                        forLabel={"id_provinsi"}
                                        label={"Provinsi"}
                                        type={"text"}
                                        name={"Provinsi"}
                                        value={this.props.data_provinsi}
                                        readOnly={true}
                                    />
                                ) : (
                                    <SelectOptionSearchs
                                        forLabel={"id_provinsi"}
                                        label={"Provinsi"}
                                        onChange={this.props.onChangePP}
                                        data={this.props.provinsiList}
                                        value={{
                                            value: this.props.id_provinsi,
                                            label: this.props.provLabel
                                        }}
                                    />
                                )}
                            </div>
                            <div className="col-md-6">
                                {/* {this.props.data_kota ? (
                  <Inputs
                    forLabel={"id_kota"}
                    label={"Kota / Kabupaten"}
                    type={"text"}
                    name={"Kota / Kabupaten"}
                    value={this.props.data_kota}
                    readOnly={true}
                  />
                ) : ( */}
                                <SelectOptionSearchs
                                    forLabel={"id_kota"}
                                    label={"Kota / Kabupaten"}
                                    onChange={this.props.onChangePK}
                                    data={this.props.kotaList}
                                    value={{
                                        value: this.props.id_kota,
                                        label: this.props.kotaLabel
                                    }}
                                    disabled={!this.props.id_provinsi}
                                />
                                {/* )} */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Inputs
                                    forLabel={"kelurahan"}
                                    label={"Kelurahan"}
                                    onChange={this.props.onChangeKel}
                                    type={"text"}
                                    name={"kelurahan"}
                                    placeholder={"Contoh: Cengkareng"}
                                    error={this.props.errKelurahan}
                                    value={this.props.data_kelurahan ? this.props.data_kelurahan : this.props.kelurahan}
                                    readOnly={this.props.data_kelurahan ? true : false}
                                />
                            </div>
                            <div className="col-md-6">
                                <Inputs
                                    forLabel={"kecamatan"}
                                    label={"Kecamatan"}
                                    onChange={this.props.onChangeKec}
                                    type={"text"}
                                    name={"kecamatan"}
                                    placeholder={"Contoh: Cengkareng Barat"}
                                    error={this.props.errkecamatan}
                                    value={this.props.data_kecamatan ? this.props.data_kecamatan : this.props.kecamatan}
                                    readOnly={this.props.data_kecamatan ? true : false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Inputs
                                    forLabel={"kode_pos"}
                                    label={"Kodepos"}
                                    onChange={this.props.onChangeKode}
                                    type={"text"}
                                    name={"kode_pos"}
                                    placeholder={"Contoh : 12345"}
                                    error={this.props.errKodepos}
                                    maxLength={"5"}
                                    value={this.props.data_kodepos ? this.props.data_kodepos : this.props.kode_pos}
                                    readOnly={this.props.data_kodepos ? true : false}
                                />
                            </div>
                        </div>
                        <FormGroup>
                            <div style={{ zIndex: 0, position: "relative" }}>
                                <CustomInput
                                    type="switch"
                                    id="exampleCustomSwitch"
                                    onChange={this.props.handleSwitch}
                                    name="customSwitch"
                                    className="text-primary"
                                    label="Jika alamat ktp dengan alamat domisili berbeda klik tombol ini"
                                />
                            </div>
                        </FormGroup>
                    </div>
                    {this.props.switchformDomisili === true ? (
                        <FormDomisili
                            listProv={this.props.provinsiList}
                            listKota={this.props.kotaListDomisili}
                            onChangeAlamat={this.props.onChange}
                            onChangeProvinsi={this.props.onChangePD}
                            onChangeKota={this.props.onChangeKD}
                            onChangeKelurahan={this.props.onChangeKelD}
                            onChangeKecamatan={this.props.onChangeKecD}
                            onChangeKodepos={this.props.onChangeKodeD}
                            errorKel={this.props.errKelD}
                            errorKec={this.props.errKecD}
                            errorKode={this.props.errKodeD}
                            valueProv={{
                                value: this.props.provinsi_domisili,
                                label: this.props.dprovLabel
                            }}
                            valueKota={{
                                value: this.props.kota_domisili,
                                label: this.props.dkotaLabel
                            }}
                            alamat_domisili={this.props.alamat_domisili}
                            kelurahan_domisili={this.props.kelurahan_domisili}
                            kecamatan_domisili={this.props.kecamatan_domisili}
                            kode_pos_domisili={this.props.kode_pos_domisili}
                        />
                    ) : null}
                </div>
            </Form>
        );
    }
}
export default Alamat;
