import React, { Component } from "react";
import Swal from "sweetalert2";
import { createPengajuan } from "../../../../redux/action/Project";
import { connect } from "react-redux";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import "../../../../sass/loader.css";
import { InputIcon, InputNominal, InputTextArea, UploadDoc } from "../../../../library/components/Components";
import { validate } from "../../../../library/validation/validate";

class MicroL extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nilai_permohonan_pinjaman: "",
            jangka_waktu_pinjaman: "",
            tujuan_permohonan_pinjaman: "",
            errNumber: "",
            errNominal: "",
            errString: "",
            tipe_pinjaman: "Invoice Financing",
            dokumen_pendukung: "",
            pemberi_kerja: ""
        };
    }

    handleChange = (e, type, changeError) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value) });
        });
    };

    handleChangeJenis = (e) => {
        this.setState({
            tipe_pinjaman: e.target.value
        });
    };

    handleDokumen = (e) => {
        let file = e.target.files[0];
        this.setState({
            dokumen_pendukung: file
        });
    };

    handleSave = async () => {
        const { nilai_permohonan_pinjaman, jangka_waktu_pinjaman, tujuan_permohonan_pinjaman, tipe_pinjaman, dokumen_pendukung, pemberi_kerja } =
            this.state;
        if (!nilai_permohonan_pinjaman || !jangka_waktu_pinjaman || !tujuan_permohonan_pinjaman) {
            Swal.fire({
                title: "Data masih kosong",
                text: "Data Anda belum lengkap mohon di cek kembali",
                icon: "warning",
                button: "ok"
            });
        } else {
            var nilaipermohonan = nilai_permohonan_pinjaman.replace(/\D/g, "").replace(/^0+/, "");

            const formData = new FormData();
            if (this.props.micro === true) {
                formData.append("tipe_pinjaman", "Micro Financing");
            } else {
                formData.append("tipe_pinjaman", tipe_pinjaman);
                formData.append("pdf", dokumen_pendukung);
                formData.append("pemberi_kerja", pemberi_kerja);
            }
            formData.append("nilai_permohonan_pinjaman", parseInt(nilaipermohonan));
            formData.append("jangka_waktu_pinjaman", parseInt(jangka_waktu_pinjaman));
            formData.append("tujuan_permohonan_pinjaman", tujuan_permohonan_pinjaman);

            await this.props
                .dispatch(createPengajuan(formData))
                .then(() => {
                    Swal.fire({
                        title: "Anda berhasil mengajukan pinjaman",
                        text: "Mohon tunggu tim terkait memproses pengajuan pinjaman anda",
                        icon: "success",
                        buttons: "ok"
                    }).then(() => {
                        window.location.href = "/borrower/dasbor";
                    });
                })
                .catch((err) => {
                    if (err.response.status === 400) {
                        Swal.fire({
                            title: "Gagal Ajukan",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (err.response.status === 404) {
                        Swal.fire({
                            title: "Gagal Ajukan",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (err.response.status === 401) {
                        Swal.fire({
                            title: "Gagal Ajukan",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    }
                });
        }
    };
    render() {
        return (
            <div>
                <section id="pinjaman">
                    <div className="sec-header">
                        <h4 className="text-center sec-title">Form Pengajuan Pinjaman</h4>
                    </div>

                    <Form>
                        <div className="row justify-content-center">
                            <div className="form-group col-md-12 d-flex justify-content-center">
                                <FormGroup>
                                    <Label for="jenis_borrower">Pilih Jenis Pinjaman</Label>
                                    <Input ref="jenis" onChange={this.handleChangeJenis} type="select" name="jenis pengguna" id="exampleSelect">
                                        <option value="Invoice Financing">Invoice Financing</option>
                                        <option value="Project Financing">Project Financing</option>
                                        <option value="Working Capital Financing">Working Capital Financing</option>
                                    </Input>
                                </FormGroup>
                            </div>
                        </div>
                    </Form>

                    <Form>
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <InputNominal
                                    label={"Pinjaman yang diajukan"}
                                    onChange={(e) => this.handleChange(e, "currency", "errNominal")}
                                    name={"nilai_permohonan_pinjaman"}
                                    error={this.state.errNominal}
                                />
                                <InputIcon
                                    forLabel={"tenor"}
                                    label={"Tenor (Lama Pinjaman)"}
                                    icon={<i className="material-icons-outlined">access_time</i>}
                                    onChange={(e) => this.handleChange(e, "tenor", "errNumber")}
                                    type={"text"}
                                    name="jangka_waktu_pinjaman"
                                    placeholder="Lama pinjaman maksimal 12 bulan"
                                    maxLength="2"
                                    error={this.state.errNumber}
                                />
                                <InputTextArea
                                    label={"Tujuan permohonan pinjaman"}
                                    name="tujuan_permohonan_pinjaman"
                                    onChange={(e) => this.handleChange(e)}
                                    error={this.state.errString}
                                />
                                {this.state.tipe_pinjaman === "Project Financing" ? (
                                    <FormGroup>
                                        <Label for="jenis_borrower">Pemberi kerja</Label>
                                        <Input ref="jenis" onChange={this.handleChange} type="select" name="pemberi_kerja" id="exampleSelect">
                                            <option>-- Pilih --</option>
                                            <option value="BUMN">BUMN</option>
                                            <option value="BUMD">BUMD</option>
                                            <option value="PEMDA">PEMDA </option>
                                            <option value="PEMKOT">PEMKOT</option>
                                            <option value="Swasta">Swasta</option>
                                        </Input>
                                    </FormGroup>
                                ) : null}
                                {this.props.micro === true || this.state.tipe_pinjaman === "Working Capital Financing" ? null : (
                                    <UploadDoc label={"Upload dokumen"} onChange={this.handleDokumen} name="file" />
                                )}
                                <div className="text-center mt-5">
                                    {this.props.project.isLoading ? (
                                        <Button color="primary" disabled className="btn btn-lg btn-block card-submit" onClick={this.handleSave}>
                                            <span className="loader"></span>
                                        </Button>
                                    ) : (
                                        <Button color="primary" className="btn btn-lg btn-block card-submit" onClick={this.handleSave}>
                                            Kirim
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Form>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project
    };
};
export default connect(mapStateToProps)(MicroL);
