import React, { Component } from "react";

class SuccessEmail extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <section id="email-sent" className="with-pad bg-grey">
                <div className="container no-lg">
                    <div className="card email-sent-card border-0">
                        <div className="card-body">
                            <div className="text-center">
                                <div className="my-5">
                                    <img
                                        className="ojk img-fluid mb-4"
                                        style={{ color: "red" }}
                                        src={process.env.PUBLIC_URL + "images/partners-icon/sendemail.svg"}
                                        alt="logo-send"
                                    />
                                </div>
                                <div className="mb-4">
                                    <h4 className="font-weight-bold mb-3">Password baru tidak berhasil dirubah</h4>
                                    <p className="fs-1 mb-1 text-secondary">Silahkan hubungi admin konfirmasi password baru anda</p>
                                    <span className="text-primary">{localStorage.emailReg} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default SuccessEmail;
