import React, { Component } from "react";
import { RegisterBorrower_BadanHukum } from "../../../../redux/action/User";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import { Master } from "../../../../redux/action/Master";
import { getUser } from "../../../../redux/action/User";

import "../../../../sass/loader.css";
import "react-datepicker/dist/react-datepicker.css";
import { validate } from "../../../../library/validation/validate";
import { step1, step2, step3, step4, step5 } from "../../../../library/validation/validateBE";
import { changeCapitalize, imgToFile } from "../../../../library/helper/helper";
import InfoPerusahaan from "./badan hukum/InfoPerusahaan";
import AlamatPerusahaan from "./badan hukum/AlamatPerusahaan";
import UploadFile from "./badan hukum/UploadFile";
import InfoPIC from "./badan hukum/InfoPIC";
import AlamatPIC from "./badan hukum/AlamatPIC";
import { addYears } from "date-fns";

import { encryptedData } from "../../../../library/helper/Crypto";
import { OpenCamera } from "../../../../library/components/Components";

class BorrowerBadanHukum extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nama_borrower: localStorage.nama_pengguna ? localStorage.namaUser : localStorage.nama_pengguna,
            email: localStorage.email,
            id_jenis_kelamin: "",
            no_npwp: "",
            id_jenis_badan_hukum: "",
            alamat: "",
            id_kota: "",
            id_provinsi: "",
            kode_pos: "",
            id_bidang_pekerjaan: "",
            id_pekerjaan_online: "",
            aset: "",
            total_asset: "",
            jabatan_perwakilan: "",
            alamat_perwakilan: "",
            provinsi_perwakilan: "",
            kota_perwakilan: "",
            kecamatan_perwakilan: "",
            kelurahan_perwakilan: "",
            kode_pos_perwakilan: "",
            jenis_entityList: [],
            kotaList: [],
            kotaListPerwakilan: [],
            provinsiList: [],
            bidang_pekerjaanList: [],
            pekerjaan_onlineList: [],
            asetList: [],
            total_assetList: [],
            sektorList: [],
            nama_perwakilan: "",
            no_ktp_perwakilan: "",
            foto_selfie: "",
            foto_ktp: "",
            foto_npwp: "",
            akta_pendirian: "",
            akta_perubahan: "",
            surat_domisili: "",
            laporan_keuangan: "",
            rekening_koran: "",
            NIB: "",
            SIUP: "",
            TDP: "",
            deskripsi_usaha: "",
            checked: false,
            disabled: true,
            kecamatan: "",
            keluarahan: "",
            tgl_lahir: addYears(new Date(), -17),
            camera: true,
            fotoDone: false,
            modalselfieWebcam: false,
            setModal: false,
            tempat_lahir: "",
            errNominal: "",
            errKtp: "",
            errNpwp: "",
            errNama: "",
            errTempatLahir: "",
            errKelurahan: "",
            errKecamatan: "",
            errJabatan: "",
            errKodepos: "",
            errkelurahan_perwakilan: "",
            errkecamatan_perwakilan: "",
            errkode_pos_perwakilan: "",
            count: 1,

            suLabel: "-- Pilih Sektor Usaha --",
            pprovLabel: "-- Pilih Provinsi --",
            pkotaLabel: "-- Pilih Kota --",
            perprovLabel: "-- Pilih Provinsi --",
            perkotaLabel: "-- Pilih Kota --",

            data_nonpwp: "",
            data_fotonpwp: "",
            data_alamat: "",
            data_jenis: "",
            data_provinsi: "",
            data_kota: "",
            data_kecamatan: "",
            data_kelurahan: "",
            data_kodepos: "",
            data_namaperwakilan: "",
            data_noktpperwakilan: "",
            data_fotoktp: "",
            data_fotoselfie: "",
            data_tgllahirLender: "",
            data_jabatanperwakilan: "",
            data_alamatperwakilan: "",
            data_kelurahanperwakilan: "",
            data_kecamatanperwakilan: "",
            data_kodeposperwakilan: "",
            data_tempatlahir: "",
            data_jk: "",
            data_pp: "",
            data_kp: ""
        };
        this.dateChanged = this.dateChanged.bind(this);
        this.fotoLagi = this.fotoLagi.bind(this);
        this.successFoto = this.successFoto.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
    }

    handleChange = (e, type, changeError) => {
        let value = e.target.value;
        // console.log({[e.target.name] : e.target.value})
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value) });
        });
    };

    dateChanged(d) {
        this.setState({ tgl_lahir: d });
    }
    togglemodalselfieWebcam() {
        this.setState({
            modalselfieWebcam: !this.state.modalselfieWebcam,
            setModal: !this.state.setModal
        });
    }
    handleCheck = () => {
        this.setState({ checked: !this.state.checked });
        if (!this.state.checked) {
            this.setState({
                disabled: !this.state.disabled
            });
        } else {
            this.setState({
                disabled: !this.state.disabled
            });
        }
    };

    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.props.dispatch(Master());
        if (localStorage.id !== undefined) {
            localStorage.setItem("verif", "true");
            await this.props.dispatch(getUser(localStorage.id));
            this.setState({
                // lender
                data_nonpwp: this.props.user.userList.lender_id.no_npwp,
                data_fotonpwp: this.props.user.userList.lender_id.foto_npwp,
                data_alamat: this.props.user.userList.lender_id.alamat,
                data_jenis: this.props.user.userList.lender_id.id_jenis_badan_hukum,
                data_provinsi: this.props.user.userList.lender_id.ojkJson.provinsi.keterangan,
                data_kota: this.props.user.userList.lender_id.ojkJson.kota.keterangan,
                data_kecamatan: this.props.user.userList.lender_id.kecamatan,
                data_kelurahan: this.props.user.userList.lender_id.kelurahan,
                data_kodepos: this.props.user.userList.lender_id.kode_pos,
                data_namaperwakilan: this.props.user.userList.lender_id.nama_perwakilan,
                data_noktpperwakilan: this.props.user.userList.lender_id.no_ktp_perwakilan,
                data_pp: this.props.user.userList.lender_id.ojkJson.provinsiPerwakilan.keterangan,
                data_kp: this.props.user.userList.lender_id.ojkJson.kotaPerwakilan.keterangan,
                data_fotoktp: this.props.user.userList.lender_id.foto_ktp,
                data_fotoselfie: this.props.user.userList.lender_id.foto_selfie,
                data_tgllahirLender: new Date(this.props.user.userList.lender_id.tgl_lahir),
                data_jk: this.props.user.userList.lender_id.id_jenis_kelamin,
                data_jabatanperwakilan: this.props.user.userList.lender_id.jabatan_perwakilan,
                data_alamatperwakilan: this.props.user.userList.lender_id.alamat_perwakilan,
                data_kelurahanperwakilan: this.props.user.userList.lender_id.kelurahan_perwakilan,
                data_kecamatanperwakilan: this.props.user.userList.lender_id.kecamatan_perwakilan,
                data_kodeposperwakilan: this.props.user.userList.lender_id.kode_pos_perwakilan,
                data_tempatlahir: this.props.user.userList.lender_id.tempat_lahir,
                data_bidangpekerjaan: this.props.user.userList.lender_id.id_bidang_pekerjaan
            });
        }

        this.setState({
            jenis_entityList: this.props.master.masterList.master_jenis_badan_hukum,
            provinsiList: this.props.master.masterList.master_provinsi,
            kotaList: this.props.master.masterList.master_kota_kabupaten,
            pekerjaan_onlineList: this.props.master.masterList.master_online,
            bidang_pekerjaanList: this.props.master.masterList.master_bidang_pekerjaan,
            asetList: this.props.master.masterList.master_aset,
            jeniskelaminList: this.props.master.masterList.master_jenis_kelamin,
            sektorList: this.props.master.masterList.master_sektor
            //data
        });
    }

    //function
    handleSaveBorrower = async () => {
        const {
            NIB,
            SIUP,
            TDP,
            kecamatan,
            kelurahan,
            akta_pendirian,
            akta_perubahan,
            surat_domisili,
            laporan_keuangan,
            rekening_koran,
            id_jenis_kelamin,
            deskripsi_usaha,
            tgl_lahir,
            no_npwp,
            id_jenis_badan_hukum,
            alamat,
            id_kota,
            id_provinsi,
            kode_pos,
            id_bidang_pekerjaan,
            id_pekerjaan_online,
            aset,
            total_asset,
            nama_perwakilan,
            no_ktp_perwakilan,
            foto_selfie,
            foto_ktp,
            foto_npwp,
            sektor_usaha,
            jabatan_perwakilan,
            alamat_perwakilan,
            provinsi_perwakilan,
            kota_perwakilan,
            kelurahan_perwakilan,
            kecamatan_perwakilan,
            kode_pos_perwakilan,
            tempat_lahir
        } = this.state;
        var totalasset = total_asset.replace(/\D/g, "").replace(/^0+/, "");

        const formData = new FormData();
        formData.append("jenis_pengguna", 2);
        formData.append("no_npwp", encryptedData(no_npwp));
        formData.append("deskripsi_usaha", encryptedData(deskripsi_usaha));
        formData.append("id_jenis_badan_hukum", encryptedData(id_jenis_badan_hukum.toString()));
        formData.append("id_jenis_kelamin", encryptedData(id_jenis_kelamin.toString()));
        formData.append("jabatan_perwakilan", encryptedData(changeCapitalize(jabatan_perwakilan)));
        formData.append("alamat_perwakilan", encryptedData(alamat_perwakilan));
        formData.append("provinsi_perwakilan", encryptedData(provinsi_perwakilan.toString()));
        formData.append("kota_perwakilan", encryptedData(kota_perwakilan));
        formData.append("kelurahan_perwakilan", encryptedData(changeCapitalize(kelurahan_perwakilan)));
        formData.append("kecamatan_perwakilan", encryptedData(changeCapitalize(kecamatan_perwakilan)));
        formData.append("kode_pos_perwakilan", encryptedData(kode_pos_perwakilan));
        formData.append("alamat", encryptedData(alamat));
        formData.append("id_kota", encryptedData(id_kota));
        formData.append("id_provinsi", encryptedData(id_provinsi.toString()));
        formData.append("kode_pos", encryptedData(kode_pos));
        formData.append("kecamatan", encryptedData(changeCapitalize(kecamatan)));
        formData.append("kelurahan", encryptedData(changeCapitalize(kelurahan)));
        formData.append("tgl_lahir", encryptedData(tgl_lahir.toISOString()));
        formData.append("tempat_lahir", encryptedData(changeCapitalize(tempat_lahir)));
        formData.append("id_bidang_pekerjaan", encryptedData(id_bidang_pekerjaan));
        formData.append("id_pekerjaan_online", encryptedData(id_pekerjaan_online));
        formData.append("aset", encryptedData(aset));
        formData.append("total_asset", encryptedData(totalasset));
        formData.append("sektor_usaha", encryptedData(sektor_usaha));
        formData.append("nama_perwakilan", encryptedData(changeCapitalize(nama_perwakilan)));
        formData.append("no_ktp_perwakilan", encryptedData(no_ktp_perwakilan));
        formData.append("id_kewarganegaraan", encryptedData("0")); //auto indonesia

        formData.append("foto_npwp", foto_npwp);
        formData.append("foto_selfie", imgToFile(foto_selfie));
        formData.append("foto_ktp", foto_ktp);
        formData.append("akta_pendirian", akta_pendirian);
        formData.append("akta_perubahan", akta_perubahan);
        formData.append("domisili", surat_domisili);
        formData.append("laporan_keuangan", laporan_keuangan);
        formData.append("rekening_koran", rekening_koran);
        formData.append("nib", NIB);
        formData.append("siup", SIUP);
        formData.append("tdp", TDP);

        formData.append("isLender", !this.state.data_fotoktp ? "" : true);

        await this.props
            .dispatch(RegisterBorrower_BadanHukum(formData))
            .then((res) => {
                if (res.value.status === 201) {
                    Swal.fire({
                        title: "Berhasil ",
                        text: "Berhasil register borrower",
                        icon: "success",
                        button: "ok"
                    }).then(() => {
                        if (localStorage.verif === "true") {
                            localStorage.setItem("kode_pengguna", "1,2");
                            localStorage.removeItem("verif");
                            window.location.href = "/borrower/dasbor";
                        } else {
                            localStorage.setItem("verif", true);
                            window.location.href = "/emailverif/2";
                        }
                    });
                }
            })
            .catch((err) => {
                // console.log(err)
                // console.log(err)
                if (err.response.status === 400) {
                    Swal.fire({
                        title: "Tidak Dapat Daftar",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (err.response.status === 404) {
                    Swal.fire({
                        title: "Gagal Ajukan",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else {
                    Swal.fire({
                        title: "Gagal",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                }
            });
    };
    //

    // foto camera
    toggleOpenCamera() {
        this.setState({
            modalselfieWebcam: !this.state.modalselfieWebcam,
            setModal: !this.state.setModal
        });
    }
    handleTakePhotoAnimationDone(dataUri) {
        this.setState({
            foto_selfie: dataUri,
            camera: false
        });
    }
    fotoLagi() {
        this.setState({
            camera: !this.state.camera
        });
    }
    successFoto = () => {
        this.setState({
            modalselfieWebcam: false,
            setModal: false,
            fotoDone: true
        });
    };
    //

    //change select option
    handleSelectSearch = (name, label, e) => {
        this.setState({
            [name]: e.value,
            [label]: e.label
        });
    };
    //

    handleUploadFile = (e) => {
        let file = e.target.files[0];
        this.setState({
            [e.target.name]: file
        });
        // console.log({[e.target.name]: file})
    };

    handleNext(e) {
        // console.log(this.state.total_asset)
        window.scrollTo(0, 0);
        // console.log(this.check());
        if (this.check() === false) {
            Swal.fire({
                title: "Gagal Daftar",
                text: "Data Anda masih salah, periksa kembali",
                icon: "warning",
                buttons: "ok"
            });
        } else {
            this.setState({
                count: this.state.count + 1
            });
        }
        // console.log(this.check())
    }

    check() {
        switch (this.state.count) {
            case 1:
                return step1(this.state, this.state.data_nonpwp);
            case 2:
                return step2(this.state, this.state.data_nonpwp);
            case 3:
                return step4(this.state, this.selfieBlob, this.state.data_nonpwp), step5(this.state, this.state.data_nonpwp); //info pic, alamat pic
            case 4:
                return step3(this.state); //upload file
            default:
                break;
        }
    }
    handlePrev(e) {
        this.setState({
            count: this.state.count - 1
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.id_provinsi !== prevState.id_provinsi) {
            await this.props.dispatch(Master("master_kota_kabupaten", `${this.state.id_provinsi}`));
            this.setState({
                kotaList: this.props.master.masterList
            });
        }
        if (this.state.provinsi_perwakilan !== prevState.provinsi_perwakilan) {
            await this.props.dispatch(Master("master_kota_kabupaten", `${this.state.provinsi_perwakilan}`));
            this.setState({
                kotaListDomisili: this.props.master.masterList
            });
        }
    }

    formCase() {
        switch (this.state.count) {
            case 1:
                return (
                    <InfoPerusahaan
                        email={this.state.email}
                        nama={this.state.nama_borrower}
                        total_asset={this.state.total_asset}
                        no_npwp={this.state.no_npwp}
                        id_pekerjaan_online={this.state.id_pekerjaan_online}
                        foto_npwp={this.state.foto_npwp}
                        aset={this.state.aset}
                        deskripsi_usaha={this.state.deskripsi_usaha}
                        id_jenis_badan_hukum={this.state.id_jenis_badan_hukum}
                        sektor_usaha={this.state.sektor_usaha}
                        suLabel={this.state.suLabel}
                        handleUploadFile={this.handleUploadFile}
                        onChangeNpwp={(e) => this.handleChange(e, "number", "errNpwp")}
                        onChange={(e) => this.handleChange(e)}
                        onChangeSS={this.handleSelectSearch.bind(null, "sektor_usaha", "suLabel")}
                        onChangeCurrency={(e) => this.handleChange(e, "currency", "errNominal")}
                        errNpwp={this.state.errNpwp}
                        errNominal={this.state.errNominal}
                        jenis_entityList={this.state.jenis_entityList}
                        sektorList={this.state.sektorList}
                        pekerjaan_onlineList={this.state.pekerjaan_onlineList}
                        asetList={this.state.asetList}
                        data_nonpwp={this.state.data_nonpwp}
                        data_fotonpwp={this.state.data_fotonpwp}
                        data_jenis={this.state.data_jenis}
                    />
                );
            case 2:
                return (
                    <AlamatPerusahaan
                        alamat={this.state.alamat}
                        id_provinsi={this.state.id_provinsi}
                        pprovLabel={this.state.pprovLabel}
                        id_kota={this.state.id_kota}
                        pkotaLabel={this.state.pkotaLabel}
                        kelurahan={this.state.kelurahan}
                        kecamatan={this.state.kecamatan}
                        kode_pos={this.state.kode_pos}
                        onChangePP={this.handleSelectSearch.bind(null, "id_provinsi", "pprovLabel")}
                        onChangePK={this.handleSelectSearch.bind(null, "id_kota", "pkotaLabel")}
                        onChangePKel={(e) => this.handleChange(e, "string", "errKelurahan")}
                        onChangePKec={(e) => this.handleChange(e, "string", "errKecamatan")}
                        onChangePKode={(e) => this.handleChange(e, "number", "errKodepos")}
                        onChange={(e) => this.handleChange(e)}
                        errKelurahan={this.state.errKelurahan}
                        errKecamatan={this.state.errKecamatan}
                        errKodepos={this.state.errKodepos}
                        provinsiList={this.state.provinsiList}
                        kotaList={this.state.kotaList}
                        data_alamat={this.state.data_alamat}
                        data_provinsi={this.state.data_provinsi}
                        data_kota={this.state.data_kota}
                        data_kecamatan={this.state.data_kecamatan}
                        data_kelurahan={this.state.data_kelurahan}
                        data_kodepos={this.state.data_kodepos}
                    />
                );
            case 3:
                return (
                    <div>
                        <InfoPIC
                            nama_perwakilan={this.state.nama_perwakilan}
                            jabatan_perwakilan={this.state.jabatan_perwakilan}
                            id_bidang_pekerjaan={this.state.id_bidang_pekerjaan}
                            tempat_lahir={this.state.tempat_lahir}
                            tgl_lahir={this.state.tgl_lahir}
                            id_jenis_kelamin={this.state.id_jenis_kelamin}
                            no_ktp_perwakilan={this.state.no_ktp_perwakilan}
                            foto_selfie={this.state.foto_selfie}
                            foto_ktp={this.state.foto_ktp}
                            selfieBlob={this.selfieBlob}
                            onChangeName={(e) => this.handleChange(e, "string", "errNama")}
                            onChangeJabatan={(e) => this.handleChange(e, "string", "errJabatan")}
                            onChangeTL={(e) => this.handleChange(e, "string", "errTempatLahir")}
                            onChangeKTP={(e) => this.handleChange(e, "number", "errKtp")}
                            onChangeTGL={this.dateChanged}
                            onChange={(e) => this.handleChange(e)}
                            handleUploadFile={this.handleUploadFile}
                            toggleOpenCamera={this.toggleOpenCamera.bind(this)}
                            fotoDone={this.state.fotoDone}
                            errNama={this.state.errNama}
                            errJabatan={this.state.errJabatan}
                            errTempatLahir={this.stateerrTempatLahir}
                            errKtp={this.state.errKtp}
                            bidang_pekerjaanList={this.state.bidang_pekerjaanList}
                            jeniskelaminList={this.state.jeniskelaminList}
                            data_namaperwakilan={this.state.data_namaperwakilan}
                            data_noktpperwakilan={this.state.data_noktpperwakilan}
                            data_fotoktp={this.state.data_fotoktp}
                            data_fotoselfie={this.state.data_fotoselfie}
                            data_tgllahirLender={this.state.data_tgllahirLender}
                            data_jabatanperwakilan={this.state.data_jabatanperwakilan}
                            data_tempatlahir={this.state.data_tempatlahir}
                            data_jk={this.state.data_jk}
                            data_bidangpekerjaan={this.state.data_bidangpekerjaan}
                        />

                        <AlamatPIC
                            alamat_perwakilan={this.state.alamat_perwakilan}
                            provinsi_perwakilan={this.state.provinsi_perwakilan}
                            perprovLabel={this.state.perprovLabel}
                            kota_perwakilan={this.state.kota_perwakilan}
                            perkotaLabel={this.state.perkotaLabel}
                            kelurahan_perwakilan={this.state.kelurahan_perwakilan}
                            kecamatan_perwakilan={this.state.kecamatan_perwakilan}
                            kode_pos_perwakilan={this.state.kode_pos_perwakilan}
                            onChange={(e) => this.handleChange(e)}
                            onChangePP={this.handleSelectSearch.bind(null, "provinsi_perwakilan", "perprovLabel")}
                            onChangePK={this.handleSelectSearch.bind(null, "kota_perwakilan", "perkotaLabel")}
                            onChangeKel={(e) => this.handleChange(e, "string", "errkelurahan_perwakilan")}
                            onChangeKec={(e) => this.handleChange(e, "string", "errkecamatan_perwakilan")}
                            onChangeKode={(e) => this.handleChange(e, "number", "errkode_pos_perwakilan")}
                            provinsiList={this.state.provinsiList}
                            kotaList={this.state.kotaListDomisili}
                            errkelurahan_perwakilan={this.state.errkelurahan_perwakilan}
                            errkecamatan_perwakilan={this.state.errkecamatan_perwakilan}
                            errkode_pos_perwakilan={this.state.errkode_pos_perwakilan}
                            data_alamatperwakilan={this.state.data_alamatperwakilan}
                            data_kelurahanperwakilan={this.state.data_kelurahanperwakilan}
                            data_kecamatanperwakilan={this.state.data_kecamatanperwakilan}
                            data_kodeposperwakilan={this.state.data_kodeposperwakilan}
                            data_pp={this.state.data_pp}
                            data_kp={this.state.data_kp}
                        />
                    </div>
                );
            case 4:
                return (
                    <UploadFile
                        akta_pendirian={this.state.akta_pendirian}
                        akta_perubahan={this.state.akta_perubahan}
                        surat_domisili={this.state.surat_domisili}
                        laporan_keuangan={this.state.laporan_keuangan}
                        rekening_koran={this.state.rekening_koran}
                        NIB={this.state.NIB}
                        SIUP={this.state.SIUP}
                        TDP={this.state.TDP}
                        handleUploadFile={this.handleUploadFile}
                        handleCheck={this.handleCheck}
                        checked={this.state.checked}
                        loading={this.props.user.isLoading}
                        handleSaveBorrower={this.handleSaveBorrower}
                        disabled={this.state.disabled}
                    />
                );
            default:
                break;
        }
    }
    render() {
        return (
            <div>
                <section id="daftar" className="with-pad">
                    <div className="container no-lg">
                        <div className="sec-header">
                            <h4 className="text-center sec-title">Form Penerima Dana Badan Hukum</h4>
                        </div>
                        <hr className="d-block d-sm-none" />
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-12 col-lg-10 col-xl-12">
                                <div className="card border-0">
                                    <div className="card-body">
                                        <ul className="progressbar d-flex justify-content-center mb-3">
                                            <li id={this.state.count <= 1 ? "company" : "check"} className="active">
                                                <strong className="d-none d-sm-block">Info Perusahan</strong>
                                            </li>
                                            <li id={this.state.count <= 2 ? "address" : "check"} className={this.state.count >= 2 ? "active" : null}>
                                                <strong className="d-none d-sm-block">Alamat Perusahaan</strong>
                                            </li>
                                            <li id={this.state.count <= 3 ? "pic" : "check"} className={this.state.count >= 3 ? "active" : null}>
                                                <strong className="d-none d-sm-block"> Informasi PIC</strong>
                                            </li>
                                            <li id={this.state.count <= 4 ? "file" : "check"} className={this.state.count >= 4 ? "active" : null}>
                                                <strong className="d-none d-sm-block">Upload File</strong>
                                            </li>
                                        </ul>
                                        <div className="pt-5">{this.formCase()}</div>

                                        <div className="row text-center mt-4">
                                            <div className="col-md-6 col-6 ">
                                                {this.state.count === 1 ? null : (
                                                    <Button onClick={this.handlePrev} className=" btn-lg btn-block" color="outline-secondary">
                                                        <span className="material-icons arrow-left ">keyboard_arrow_left</span>
                                                        Kembali
                                                    </Button>
                                                )}
                                            </div>
                                            <div className="col-md-6 col-6">
                                                {this.state.count === 4 ? null : (
                                                    <Button onClick={this.handleNext} className=" btn-lg btn-block" color="primary">
                                                        Lanjut
                                                        <span className="material-icons arrow-next">keyboard_arrow_right</span>
                                                    </Button>
                                                )}
                                            </div>
                                        </div>

                                        <OpenCamera
                                            modalselfieWebcam={this.state.modalselfieWebcam}
                                            toggle={this.toggleOpenCamera.bind(this)}
                                            dataUri={this.state.foto_selfie}
                                            handle={(e) => this.handleTakePhotoAnimationDone(e)}
                                            fotoLagi={this.fotoLagi}
                                            successFoto={this.successFoto}
                                            camera={this.state.camera}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        master: state.master
    };
};
export default connect(mapStateToProps)(BorrowerBadanHukum);
