import React from "react";

const Facebook = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.625 0.953125H1.375C0.960156 0.953125 0.625 1.28828 0.625 1.70312V18.9531C0.625 19.368 0.960156 19.7031 1.375 19.7031H18.625C19.0398 19.7031 19.375 19.368 19.375 18.9531V1.70312C19.375 1.28828 19.0398 0.953125 18.625 0.953125ZM16.4594 6.42578H14.9617C13.7875 6.42578 13.5602 6.98359 13.5602 7.80391V9.61094H16.3633L15.9977 12.4398H13.5602V19.7031H10.6375V12.4422H8.19297V9.61094H10.6375V7.525C10.6375 5.10391 12.1164 3.78438 14.2773 3.78438C15.3133 3.78438 16.2016 3.86172 16.4617 3.89688V6.42578H16.4594Z"
                fill="white"
            />
        </svg>
    );
};

export default Facebook;
