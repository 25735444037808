import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Dasbor from "../component/lender/dashboard/Dasbor";
import Portofolio from "../component/lender/portofolio/Portofolio";
import Akun from "../component/lender/akun/Akun";
import Dompet from "../component/userAkun/dompet/Dompet";
import Topup from "../component/userAkun/dompet/topupsaldo/PilihTopUp";
import SigningLend from "../component/lender/signing/SigningLending";
import ShowElementContextProvider from "../context/ShowElementContextProvider";
// import NotFound from "../component/guest/welcome/Redirect/failed/NotFound";

class RouteLender extends Component {
    render() {
        return (
            <Switch>
                <Route path="/lender/dasbor" render={(props) => <Dasbor {...props} />} />
                <Route path="/lender/portofolio" component={Portofolio} />
                <Route
                    path="/lender/dompet"
                    render={(props) => (
                        <Dompet
                            nama_pengguna={this.props.nama_pengguna}
                            saldo={this.props.saldo}
                            angka_saldo={this.props.angka_saldo}
                            userNameDipay={this.props.userNameDipay}
                            userTlpDipay={this.props.userTlpDipay}
                            loadingUserDipay={this.props.loadingUserDipay}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/lender/topup"
                    render={(props) => (
                        <Topup
                            va={this.props.va}
                            nicepay_register={this.props.nicepay_register}
                            saldo={this.props.saldo}
                            admin_kyc={this.props.admin_kyc}
                            jenis={this.props.jenis}
                            lender={this.props.lender}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/lender/akun/:id"
                    render={(props) => (
                        <Akun
                            no_hp={this.props.no_hp}
                            email={this.props.email}
                            userBank={this.props.userBank}
                            nama_pengguna={this.props.nama_pengguna}
                            loadingUser={this.props.loadingUser}
                            dipay_key={this.props.dipay_key}
                            userDigisign={this.props.userDigisign}
                            adminVerifLog={this.props.adminVerifLog}
                            update_lender={this.props.update_lender}
                            statusCheckYearly={this.props.statusCheckYearly}
                            admin_kyc={this.props.admin_kyc}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/lender/sign"
                    render={(props) => (
                        <SigningLend
                            id={this.props.id}
                            digisign_kyc={this.props.digisign_kyc}
                            userDigisign={this.props.userDigisign}
                            email={this.props.email}
                            {...props}
                        />
                    )}
                />
                {/* <Route path="*" component={NotFound} /> */}
            </Switch>
        );
    }
}

export default RouteLender;
