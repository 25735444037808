import React, { Component } from "react";
import Definisi from "./Definisi";
import ByOJK from "./ByOJK";
import Potensi from "./Potensi";
import KamiAda from "./KamiAda";
import Akselerasi from "./Akselerasi";

class Tentang extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className="tab-pane fade show active " id="tentang" role="tabpanel">
                <Definisi />
                <ByOJK />
                <Potensi />
                <KamiAda />
                <Akselerasi />
            </div>
        );
    }
}
export default Tentang;
