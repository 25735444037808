import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./Daftar.scss";

class PilihUser extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
                <section id="daftar" className="with-pad bg-grey">
                    <div className="container no-lg">
                        <div className="sec-header">
                            <h4 className="text-center sec-title">Pilih Jenis Akun</h4>
                            <p className="text-center sec-desc">
                                Pilihlah jenis akun yang sesuai dengan kebutuhan Anda untuk mendapatkan manfaat maksimal dari layanan yang tersedia
                            </p>
                        </div>
                        <div className="row justify-content-center mb-4">
                            <div className="col-12 col-lg-10 col-xl-9">
                                <div className="card pick-daftar border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 text-center text-md-left mb-4 mb-lg-0">
                                                <p className="fs-1 font-weight-bold mb-2 text-dark">Daftar Sebagai Pemberi Dana</p>
                                                <p className="text-secondary mb-0">
                                                    Kembangkan dana Anda Bersama Indofund, daftar dan langsung mendanai!
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6 text-right">
                                                {localStorage.user === "1" ? (
                                                    <Link to="/daftar/1" className="btn disabled btn-primary btn-lg">
                                                        Anda sudah menjadi Pemberi Dana
                                                    </Link>
                                                ) : (
                                                    <Link to="/daftar/1" className="btn btn-primary btn-lg" value="1">
                                                        Daftar sebagai Pemberi Dana
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-4">
                            <div className="col-12 col-lg-10 col-xl-9">
                                <div className="card pick-daftar border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 text-center text-md-left mb-4 mb-lg-0">
                                                <p className="fs-1 font-weight-bold mb-2 text-dark">Daftar Sebagai Penerima Dana</p>
                                                <p className="text-secondary mb-0">
                                                    Pendanaan modal sesuai kebutuhan usahamu, daftar dan ajukan pendanaan
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6 text-right">
                                                {localStorage.user === "2 " ? (
                                                    <Link to="/daftar/2" className="btn btn-primary btn-lg disabled ">
                                                        Anda sudah menjadi Penerima Dana
                                                    </Link>
                                                ) : (
                                                    <Link to="/daftar/2" className="btn btn-primary btn-lg">
                                                        Daftar sebagai Penerima Dana
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps)(PilihUser);
