import React, { Component } from "react";
import Navbar from "./LoginNavbar";
import Content from "./LoginContent";

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: "1"
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    render() {
        return (
            <div className="container">
                <Navbar activeTab={this.state.activeTab} toggle={this.toggle} />
                <Content activeTab={this.state.activeTab} toggle={this.props.toggle} />
            </div>
        );
    }
}
export default LoginForm;
