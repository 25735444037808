import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import ShowElement from "../../../library/components/ShowElement";
import NumberFormat from "react-number-format";

class SidebarMenu extends Component {
    loading(data) {
        if (this.props.loading) {
            return <Skeleton />;
        } else {
            return data;
        }
    }
    authAjukanPinjaman() {
        if (this.props.bankVerif.length === 0) {
            return (
                <Link to={"/borrower/ajukan-pinjaman"} className="btn btn-primary btn-block disabled">
                    Ajukan Pendanaan
                </Link>
            );
        } else {
            return (
                <Link to={"/borrower/ajukan-pinjaman"} className="btn btn-primary btn-block">
                    Ajukan Pendanaan
                </Link>
            );
        }
    }
    render() {
        // console.log(this.props.bankVerif)
        const borrower = () => {
            const borrower = this.props.borrower;
            if (borrower === undefined) {
                return (
                    <Link className="nav-link">
                        <i className="material-icons-outlined">account_circle</i>
                        <span>Akun</span>
                    </Link>
                );
            } else {
                return (
                    <Link
                        to={{
                            pathname: "/borrower/akun/" + borrower._id,
                            state: {
                                jenis: `${this.props.jenis}`
                            }
                        }}
                        className={this.props.location.pathname === "/borrower/akun/" + borrower._id ? "nav-link active" : "nav-link"}>
                        <i className="material-icons-outlined">account_circle</i>
                        <span>Akun</span>
                    </Link>
                );
            }
        };

        return (
            <div className="col-12 col-lg-3">
                <div className="card border-0 shadow sidebar" id="sidebar">
                    <div className="card-body">
                        <div className="text-center mb-4">
                            <p className="fs-n1 font-weight-semi-bold text-primary">
                                PENERIMA DANA {this.props.jenis === 1 ? "PERSONAL" : "BADAN HUKUM"}
                            </p>
                            <p className="font-weight-semi-bold mb-1">{this.loading(this.props.borrower.pengguna?.nama_pengguna)}</p>
                            <p className="text-secondary mb-0">{this.loading(this.props.email)}</p>
                        </div>
                        <div className={`mb-4  position-relative d-flex flex-column ${this.props.saldo.length > 7 && this.props.isShowSaldo ? 'flex-xl-col': 'flex-xl-row' } gap-1 justify-content-between`}>
                            <div style={{minWidth:'max-content'}}>
                                <i className="material-icons-outlined text-primary mr-2 ">account_balance_wallet</i>
                                <span style={{ position: "absolute" }} className="font-weight-semi-bold">
                                    Saldo
                                </span>

                                <i className="material-icons-outlined ml-1 saldo">info</i>
                                <p className="text-alert saldo-hover mr-3 text-center">
                                    *Apabila sampai dengan T + 2 tidak dilakukan penarikan, maka akan otomatis ditransfer pada rekening yang terdaftar
                                </p>
                            </div>
                            <div className="float-right d-flex justify-content-between align-items-center ">
                                <span className={`font-weight-semi-bold mr-2 `} style={{ fontWeight: "600 !important" }}>
                                    <NumberFormat
                                        value={this.props.saldo}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        decimalScale={0}
                                        renderText={(value) => <span>{this.props.isShowSaldo ? `Rp. ${this.loading(value)}` : "*****"}</span>}
                                    />
                                </span>
                                <div className="d-inline-flex">
                                    <ShowElement isShow={this.props.isShowSaldo} toggleIsShow={() => this.props.toggleIsShowSaldo()} />
                                </div>
                            </div>
                        </div>
                        {/* <div className='d-flex mb-4 '>
              <i className='material-icons-outlined text-primary mr-2'>
                account_balance_wallet
              </i>
              <span
                className='font-weight-semi-bold'
                style={{ fontWeight: '600 !important' }}>
                {' '}
                {this.loading('Rp ' + this.props.saldo)}
              </span>
              <div className='d-inline-flex'>
                <ShowElement
                  isShow={this.props.isShowSaldo}
                  toggleIsShow={() => this.props.toggleIsShowSaldo()}
                />
              </div>
            </div> */}
                        {this.authAjukanPinjaman()}
                        <hr className="my-4" />
                        <ul className="nav flex-column lender-nav">
                            <li className="nav-item">
                                <Link
                                    to={"/borrower/dasbor"}
                                    className={this.props.location.pathname === "/borrower/dasbor" ? "nav-link active" : "nav-link"}>
                                    <i className="material-icons-outlined">dashboard</i>
                                    <span>Dashboard</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="/borrower/dompet"
                                    className={this.props.location.pathname === "/borrower/dompet" ? "nav-link active" : "nav-link"}>
                                    <i className="material-icons-outlined">account_balance_wallet</i>
                                    <span>Dompet</span>
                                </Link>
                            </li>
                            <li className="nav-item">{borrower()}</li>
                            <li className="nav-item">
                                <Link
                                    to={"/borrower/sign"}
                                    className={this.props.location.pathname === "/borrower/sign" ? "nav-link active" : "nav-link"}>
                                    <i className="material-icons">edit</i>
                                    <span>Tanda Tangan Digital </span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a href="/#" className="nav-link" onClick={(e) => this.props.onLogout(e)}>
                                    <i className="material-icons-outlined">exit_to_app</i>
                                    <span>Keluar</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(SidebarMenu);
