import React, { Component } from "react";
import "../../../../sass/loader.css";
import EmployeeLoan from "./EmployeeL";
import PersonalLoan from "./PersonalL";

class AjukanPinjaman extends Component {
    render() {
        return (
            <div>
                {
                    this.props.employee === true ? (
                        <EmployeeLoan plafond_borrower={this.props.plafond_borrower} plafond_terpakai={this.props.plafond_terpakai} />
                    ) : (
                        <PersonalLoan micro={this.props.micro} />
                    ) //micro dan working capital form nya sama
                }
            </div>
        );
    }
}

export default AjukanPinjaman;
