import React, { Component } from "react";
import "./KamiAda.scss";

class KamiAda extends Component {
    render() {
        return (
            <div>
                <section id="mengapa-kami-ada">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-md-10 col-xl-9 ">
                                <div className="row align-items-center">
                                    <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                                        <h4 className="text-title">Mengapa Indofund.id ada?</h4>
                                        <p className="text-intro">
                                            Tidak sedikit bisnis dan usaha yang terkendala untuk membesarkan usahanya saat ini karena mungkin skala
                                            risiko usaha dan ketentuan pendanaan melalui bank tidak dapat mereka penuhi.
                                        </p>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="card border-0 shadow reason-card" style={{ borderRadius: "16px" }}>
                                            <div className="card-body">
                                                <h2 className="card-title">"Banyak proyek dan bisnis feasible tapi tidak bankable"</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default KamiAda;
