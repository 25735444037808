import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";

class PilihBorrower extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jenis_pengguna: ""
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        // window.location.reload(false)
        if (localStorage.jenisPengguna !== undefined) {
            this.setState({
                jenis_pengguna: localStorage.jenisPengguna
            });
        }
    }
    handleChangeJenis = (e) => {
        this.setState({
            jenis_pengguna: e.target.value
        });
        // console.log(e.target.value)
    };
    test = () => {
        if (this.state.jenis_pengguna === "1") {
            this.props.history.push("/regist/personal");
        } else {
            this.props.history.push("/regist/badanhukum");
        }
    };
    render() {
        return (
            <div>
                <section id="daftar" className="with-pad bg-grey">
                    <div className="container no-lg">
                        <div className="sec-header">
                            <h4 className="text-center sec-title">Pilih Jenis Akun</h4>
                            {/* <!-- <p className="text-center sec-desc">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consequatur odit possimus</p> --> */}
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-12 col-md-7 col-lg-5 col-xl-4">
                                <div className="card border-0">
                                    <div className="card-body">
                                        <Form>
                                            <FormGroup>
                                                <Label for="jenis pengguna">Pilih Jenis Penerima Dana</Label>
                                                <Input
                                                    value={this.state.jenis_pengguna}
                                                    onChange={this.handleChangeJenis}
                                                    type="select"
                                                    name="jenis pengguna"
                                                    id="exampleSelect">
                                                    <option>-- Pilih Jenis Penerima Dana --</option>
                                                    <option value="1">Personal</option>
                                                    <option value="2">Badan Usaha</option>
                                                </Input>
                                            </FormGroup>
                                            <Button onClick={this.test} color="primary" className="btn btn-primary btn-lg btn-block card-submit">
                                                Daftar
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default withRouter(PilihBorrower);
