import React, { Component } from "react";
import "./DanaProteksi.scss";

class DanaProteksi extends Component {
    render() {
        const background = {
            backgroundImage: "url(/images/dana-proteksi/header-dana.jpg)"
        };

        return (
            <section id="faq" className="section-dana-proteksi">
                <div className="page-header overlay" style={background}>
                    <div className="container">
                        <div className="box-header">
                            <h1 className="text-title">Asuransi</h1>
                            {/* <p className='paragraph'>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. </p> */}
                        </div>
                    </div>
                </div>
                <div className="container">
                    <h2 className="padding text-title">Asuransi</h2>
                    <p>
                        Setiap aktifitas pendanaan secara umum mengandung risiko yang tinggi, khususnya risiko kredit atau kegagalan peminjam dalam
                        mengembalikan dana pinjaman, baik dalam kegiatan pendanaan konvensional maupun modern seperti yang dilakukan oleh Indofund.id
                        melalui platform P2P lendingnya. Meskipun Indofund.id menerapkan penilaian yang sangat ketat terhadap setiap pinjaman yang
                        diajukan, namun terdapat banyak faktor yang dapat menyebabkan meningkatnya risiko kredit, termasuk kondisi eksternal yang
                        sulit dikendalikan atau dikenali saat melakukan analisa kredit, salah satunya perubahan kondisi perekonomian secara
                        signifikan.
                    </p>

                    <p>
                        Guna meminimalisir risiko gagal bayar, saat ini PT Bursa Akselerasi Indonesia selaku Penyelenggara telah menjalin Kerjasama
                        dengan PT Pasarpolis Indonesia untuk melindungi modal pendana (selanjutnya disebut "Asuransi"). Asuransi diberikan dengan besaran
                        yang berbeda-beda, sesuai dengan tingkat risiko pinjaman yang diambil oleh Pendana. Para pendana wajib membayar sejumlah premi
                        kepada PT Pasarpolis Indonesia untuk mendapatkan perlindungan modal ini.
                    </p>

                    <p>
                        Dalam pelaksanaannya, apabila terdapat Peminjam yang dinyatakan gagal bayar atau telah mengalami keterlambatan pembayaran
                        lebih dari 90 hari, Indofund.id selaku kuasa dari Pendana yang memiliki polis asuransi akan memproses klaim terhadap PT Pasarpolis
                        Indonesia. Setelah dana hasil klaim diterima, dana tersebut akan disalurkan kepada para pendana. Penting untuk diperhatikan,
                        bahwa Asuransi yang disediakan oleh Penjamin bukanlah jaminan atas keamanan pendanaan tapi hanya sebagai salah satu cara untuk
                        memitigasi resiko yang timbul dari pendanaan yang diberikan.
                    </p>

                    <h2 className="text-title">Bagaimana Asuransi Bekerja ?</h2>
                    <div className="row text-center">
                        <div className="col-md-3">
                            <img className="img-dana" src={process.env.PUBLIC_URL + "/images/dana-proteksi/kalender.png"} alt="2" />
                            <p>Peminjam sudah 90 hari tidak membayar cicilan sama sekali.</p>
                        </div>
                        <div className="col-md-3">
                            <img className="img-dana" src={process.env.PUBLIC_URL + "/images/dana-proteksi/gagal.png"} alt="2" />
                            <p>Pinjaman ditandai sebagai Gagal Bayar.</p>
                        </div>
                        <div className="col-md-3">
                            <img className="img-dana" src={process.env.PUBLIC_URL + "/images/dana-proteksi/salurkan.png"} alt="2" />
                            <p>Asuransi disalurkan PT Pasarpolis Indonesia kepada Indofund.id .</p>
                        </div>
                        <div className="col-md-3">
                            <img className="img-dana" src={process.env.PUBLIC_URL + "/images/dana-proteksi/cairkan.png"} alt="2" />
                            <p>Indofund.id mencairkan Asuransi kepada Pendana.</p>
                        </div>
                    </div>
                    <div className="row padding-kerugian">
                        <div className="col-md-6">
                            <h1 className="title-kerugian">Kerugian Modal</h1>
                            <br />
                            <p>
                                Defisit bersih antara modal awal yang telah dialokasikan ke suatu pinjaman dengan total pengembalian yang telah
                                diterima dari pinjaman.
                            </p>
                            <hr />
                            <h4 className="text-center title-rumus">Kerugian Modal</h4>
                            <h4 className="text-center title-rumus">Modal Awal - Σ (pengembalian bersih)*</h4>
                            <p className="ketentuan">(*) Pengembalian bersih terdiri dari total pokok + bunga + biaya keterlambatan</p>
                        </div>
                        <div className="col-md-6">
                            <h5 className="title-kerugian">Contoh kasus:</h5>
                            <ul>
                                <li>
                                    <p>
                                        Pendana memberikan pinjaman awal sebesar <b>Rp. 50,000,000,-</b>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Peminjam telah melakukan 4 bulan pembayaran sebesar <b>Rp. 10,000,000.-</b>
                                    </p>
                                </li>
                            </ul>
                            <p>sebelum akhirnya gagal bayar, maka</p>
                            <h4 className="text-center title-rumus">Kerugian Modal</h4>
                            <h4 className="text-center title-rumus">Rp. 50,000,000 - Rp. 10,000,000 = Rp. 40,000,000.-</h4>
                        </div>
                    </div>
                    <p>Rincian Besaran Asuransi yang akan dicairkan:</p>
                    <h5 className="text-center rumus">
                        <b>Asuransi = 0.8 x Kerugian Modal</b>
                    </h5>
                    {/* <p>
            Sehingga apabila Grade pinjaman yang gagal bayar ialah A, maka
            Asuransi akan dicairkan sebesar 100% dari Kerugian Modal, yakni 100%
            x Rp40.000.000 = Rp40.000.000. Begitu seterusnya hingga Grade E,
            yakni 20% x Rp40.000.000 = Rp8.000.000.
          </p> */}
                </div>
            </section>
        );
    }
}
export default DanaProteksi;
