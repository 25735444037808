import React, { Component } from "react";
import { Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, FormText, Label, Input, CustomInput } from "reactstrap";
import { InputIcon, SelectOptions, SelectOptionSearchs } from "../../../../../library/components/Components";
import NumberFormat from "react-number-format";
class InfoPerusahaan extends Component {
    showNama() {
        if (localStorage.namaUser === undefined || localStorage.namaUser === "undefined") {
            return localStorage.nama_pengguna;
        } else {
            return localStorage.namaUser;
        }
    }

    render() {
        return (
            <Form>
                <h4 className="text-center mb-4 d-block d-sm-none font-weight-bold">Info Perusahaan</h4>
                <div className="row ">
                    <div className="col-md-6">
                        <InputIcon
                            forLabel={"nama_borrower"}
                            label={"Nama Penerima Dana"}
                            icon={<i className="material-icons-outlined">account_circle</i>}
                            type={"text"}
                            name={"nama_borrower"}
                            placeholder={"Contoh: PT Kaya Raya"}
                            value={this.showNama()}
                            readOnly={localStorage.namaUser || localStorage.nama_pengguna !== undefined ? true : false}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputIcon
                            forLabel={"Email"}
                            label={"Email"}
                            icon={<i className="material-icons-outlined">mail </i>}
                            type={"email"}
                            name={"email"}
                            placeholder={"Contoh: example@email.com"}
                            value={this.props.email}
                            readOnly={localStorage.email !== undefined ? true : false}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <InputIcon
                            forLabel={"no_npwp"}
                            label={"Nomor NPWP"}
                            icon={<i className="material-icons-outlined">credit_card</i>}
                            type={"text"}
                            name={"no_npwp"}
                            onChange={this.props.onChangeNpwp}
                            placeholder={"Contoh: 12.345.678.9-123.000"}
                            maxLength={"15"}
                            error={this.props.errNpwp}
                            value={this.props.data_nonpwp ? this.props.data_nonpwp : this.props.no_npwp}
                            readOnly={this.props.data_nonpwp ? true : false}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <div className="custom-file">
                                <Label for="exampleFile">Foto NPWP Badan Usaha</Label>
                                <CustomInput
                                    type="file"
                                    onChange={this.props.handleUploadFile}
                                    name="foto_npwp"
                                    id="exampleFile"
                                    label={this.props.foto_npwp.name === undefined ? "Upload Maksimal 10mb" : this.props.foto_npwp.name}
                                    accept="image/*"
                                    capture
                                />
                            </div>
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <SelectOptions
                            forLabel={"id_jenis_badan_hukum"}
                            label={"Jenis Badan Usaha"}
                            onChange={this.props.onChange}
                            name={"id_jenis_badan_hukum"}
                            default={"Pilih Jenis Badan Usaha"}
                            data={this.props.jenis_entityList}
                            value={this.props.data_jenis ? this.props.data_jenis : this.props.id_jenis_badan_hukum}
                            readOnly={this.props.data_jenis ? true : false}
                        />
                    </div>
                    <div className="col-md-6">
                        <SelectOptionSearchs
                            forLabel={"sektor_usaha"}
                            label={"Sektor Usaha"}
                            onChange={this.props.onChangeSS}
                            data={this.props.sektorList}
                            value={{
                                value: this.props.sektor_usaha,
                                label: this.props.suLabel
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <SelectOptions
                            forLabel={"id_pekerjaan_online"}
                            label={"Pekerjaan Online"}
                            onChange={this.props.onChange}
                            name={"id_pekerjaan_online"}
                            default={"Pilih Pekerjaan Online"}
                            data={this.props.pekerjaan_onlineList}
                            value={this.props.id_pekerjaan_online}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <SelectOptions
                            forLabel={"pendapatan"}
                            label={"Pendapatan Per Bulan"}
                            onChange={this.props.onChange}
                            name={"aset"}
                            default={"Pilih Pendapatan per bulan"}
                            data={this.props.asetList}
                            value={this.props.aset}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="total aset">Total Aset</Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <span className="font-weight-semi-bold">Rp</span>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <NumberFormat
                                    onChange={this.props.onChangeCurrency}
                                    value={this.props.total_asset}
                                    autoComplete="off"
                                    placeholder="0"
                                    type="text"
                                    className="form-control text-right"
                                    name="total_asset"
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    decimalScale={0}
                                    allowNegative={false}
                                />
                            </InputGroup>
                            <FormText color="danger" className="mb-3">
                                {this.props.errNominal === 0 ? null : this.props.errNominal}
                            </FormText>
                        </FormGroup>
                    </div>
                    <div className="col-md-12">
                        <FormGroup>
                            <Label for="exampleText">Deskripsi Usaha</Label>
                            <Input
                                type="textarea"
                                onChange={this.props.onChange}
                                rows="8"
                                name="deskripsi_usaha"
                                id="exampleText"
                                placeholder="Contoh : Kami adalah perusahaan konsultan keuangan yang menyediakan layanan konsultasi keuangan untuk bisnis dan individu. Kami membantu klien kami merencanakan dan mengelola keuangan mereka dengan baik, termasuk dalam hal perencanaan keuangan, investasi, manajemen risiko, dan pajak"
                                value={this.props.deskripsi_usaha}
                            />
                        </FormGroup>
                    </div>
                </div>
            </Form>
        );
    }
}
export default InfoPerusahaan;
