import React, { Component } from "react";
import { LoanPortofolio } from "../../../redux/action/Borrower";
import { connect } from "react-redux";
import Bone from "react-loading-skeleton";

class DasborLoan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeLoanAmount: "",
            finishedLoanAmount: ""
        };
    }
    componentDidMount = async () => {
        await this.props.dispatch(LoanPortofolio());
        this.setState({
            activeLoanAmount: this.props.borrower.borrowerPorto.activeLoanAmount.toLocaleString("IN"),
            finishedLoanAmount: this.props.borrower.borrowerPorto.finishedLoanAmount.toLocaleString("IN")
        });
    };
    render() {
        return (
            <div className="row mb-4">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <div className="card border-0 shadow card-statistic">
                        <div className="card-body">
                            <div className="d-flex">
                                <i className="text-primary material-icons mr-3">assignment</i>
                                <div>
                                    <p className="text-secondary mb-0">Total Pendanaan Aktif</p>
                                    <h4 className="font-weight-semi-bold mb-0">
                                        {this.props.borrower.isLoadingPorto ? <Bone /> : "Rp " + this.state.activeLoanAmount}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="card border-0 shadow card-statistic">
                        <div className="card-body">
                            <div className="d-flex">
                                <i className="text-primary material-icons mr-3">assignment_turned_in</i>
                                <div>
                                    <p className="text-secondary mb-0">Total Pendanaan Selesai</p>
                                    <h4 className="font-weight-semi-bold mb-0">
                                        {this.props.borrower.isLoadingPorto ? <Bone /> : "Rp " + this.state.finishedLoanAmount}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        project: state.project,
        borrower: state.borrower
    };
};
export default connect(mapStateToProps)(DasborLoan);
