import React, { useEffect, useState } from 'react'
import { Form, FormGroup, Label, FormText, CustomInput, Input, Button, FormFeedback } from "reactstrap";
import { Inputs, SelectOptionsWithDanger } from '../../../../../library/components/Components';
import DatePicker from "react-datepicker";
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { addYears } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import CustomAccordion from '../../../../atom/CustomAccordion';
import moment from "moment";


export default function ContentModalEntity(props) {
    const [fieldState, setFieldState] = useState({
        field: {
            no_akta_perubahan: '',
            tgl_akta_perubahan: addYears(new Date(), -17),
            tgl_berdiri: addYears(new Date(), -17),
            file_akta_pendirian: '',
            file_perubahan: '',
            tempat_berdiri: ''

        }, error: {
            no_akta_perubahan: '',
            tgl_akta_perubahan: '',
            tgl_berdiri: '',
            file_akta_pendirian: '',
            file_perubahan: '',
            tempat_berdiri: ''
        }
    })
    // const [accordionState, setAccordionState] = useState({
    //     accordion1: false,
    //     accordion2: false,
    //     accordion3: false,
    //     accordion4: false,
    // })

    // const toggleAccordion = () => {
    //     setAccordionState({ accordion1: accordionState.accordion1 })
    // }
    const onChangeHandler = (type) => (e) => {
        e.persist(); // Ensure event properties are retained
        // if (type == 'no_akta_perubahan') {
        //     var numberRegex = /^\d+$/;
        //     if (!numberRegex.test(e.target.value)) {
        //         setFieldState(prevState => ({
        //             ...prevState,
        //             field: {
        //                 ...prevState.field,
        //                 [type]: e.target.value
        //             },
        //             error: {
        //                 ...prevState.error,
        //                 [type]: ""
        //             }
        //         }));
        //     };
        // }
        setFieldState(prevState => ({
            ...prevState,
            field: {
                ...prevState.field,
                [type]: e.target.value
            },
            error: {
                ...prevState.error,
                [type]: ""
            }
        }));
    };

    const onSubmitHandler = () => {
        let error = {}
        for (let i in fieldState.field) {
            if (fieldState.field[i] == "") {
                error[i] = "Field is required"
            }
        }
        setFieldState(prevState => ({
            ...prevState,
            error: {
                ...prevState.error,
                ...error
            }
        }));
        if (error.no_akta_perubahan || error.tgl_akta_perubahan || error.tgl_berdiri ||
            error.tempat_berdiri || error.file_akta_pendirian ||
            error.file_perubahan
        ) {
            return;
        }
        else {
            const formData = new FormData()
            formData.append("no_akta_perubahan", fieldState.field.no_akta_perubahan);
            formData.append("tgl_berdiri", moment(fieldState.field.tgl_berdiri).format("YYYY-MM-DD"));
            formData.append("tgl_akta_perubahan", moment(fieldState.field.tgl_akta_perubahan).format("YYYY-MM-DD"));
            formData.append("tempat_berdiri", fieldState.field.tempat_berdiri);
            formData.append("akta_pendirian", fieldState.field.file_akta_pendirian);
            formData.append("akta_perubahan", fieldState.field.file_perubahan);
            props.onSubmit(formData)
        }
    }


    const onChangeDate = (type) => (e) => {

        setFieldState(prevState => ({
            ...prevState,
            field: {
                ...prevState.field,
                [type]: e
            },
            error: {
                ...prevState.error,
                [type]: ""
            }
        }));

    }

    const handleUploadFile = (type) => (e) => {
        e.persist(); // Ensure event properties are retained

        let file = e.target.files[0];
        setFieldState(prevState => ({
            ...prevState,
            field: {
                ...prevState.field,
                [type]: file
            },
            error: {
                ...prevState.error,
                [type]: ""
            }
        }));

        // console.log({[e.target.name]: file})
    };

    return (
        <>
            <div style={{ overflowY: 'auto', maxHeight: '60vh', overflowX: 'hidden' }}>
                <div className='row mb-4'>
                    <div className='col-12 col-md-6'>
                        <Inputs
                            forLabel={"no_akta_perubahan"}
                            label={"No. Akta Perubahan"}
                            type={"text"}
                            error={fieldState.error.no_akta_perubahan}
                            onChange={onChangeHandler('no_akta_perubahan')}
                            name={"no_akta_perubahan"}
                            value={fieldState.field.no_akta_perubahan}

                        />

                    </div>
                    <div className='col-12 col-md-6'>
                        <FormGroup>
                            <Label for="exampleFile">FIle Akta Pendirian</Label>
                            <CustomInput
                                type="file"
                                onChange={handleUploadFile('file_akta_pendirian')}
                                name="file_akta_pendirian"
                                id="exampleFile"
                                label={"FIle Akta Pendirian"}
                                accept="application/pdf"
                            />
                            <FormText color="danger" style={{ fontSize: "11px" }}>
                                {fieldState.error?.file_akta_pendirian === "" ? null : fieldState.error?.file_akta_pendirian}
                            </FormText>
                        </FormGroup>

                    </div>

                </div>
                <div className='row mb-4'>
                    <div className='col-12 col-md-6'>
                        <FormGroup>
                            <Label for="tanggal_perubahan_akta">Tanggal Perubahan Akta Terakhir </Label>
                            <DatePicker
                                className="form-control"
                                autoComplete="off"
                                dateFormat="dd-MM-yyyy"
                                selected={fieldState.field.tgl_akta_perubahan}
                                onChange={onChangeDate('tgl_akta_perubahan')}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                name="tgl_akta_perubahan"
                                maxDate={new Date()}
                                withPortal
                            />
                            <FormText color="danger" style={{ fontSize: "11px" }}>
                                {fieldState.error?.tgl_akta_perubahan === "" ? null : fieldState.error?.tgl_akta_perubahan}
                            </FormText>
                        </FormGroup>
                    </div>
                    <div className='col-12 col-md-6'>
                        <FormGroup>
                            <Label for="exampleFile">File Perubahan</Label>
                            <CustomInput
                                type="file"
                                onChange={handleUploadFile('file_perubahan')}
                                name="file_perubahan"
                                id="exampleFile"
                                label={"File Perubahan"}
                                accept="application/pdf"
                            />
                            <FormText color="danger" style={{ fontSize: "11px" }}>
                                {fieldState.error?.file_perubahan === "" ? null : fieldState.error?.file_perubahan}
                            </FormText>
                        </FormGroup>

                    </div>

                </div>

                <div className='row mb-4'>

                    <div className='col-12 col-md-6'>
                        <FormGroup>
                            <Label for="ttl">Tanggal Berdiri </Label>
                            <DatePicker
                                className="form-control"
                                autoComplete="off"
                                dateFormat="dd-MM-yyyy"
                                selected={fieldState.field.tgl_berdiri}
                                onChange={onChangeDate('tgl_berdiri')}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                maxDate={new Date()}
                                name="tgl_berdiri"
                                // maxDate={addYears(new Date(), -17)}
                                withPortal
                            />
                            <FormText color="danger" style={{ fontSize: "11px" }}>
                                {fieldState.error?.tgl_berdiri === "" ? null : fieldState.error?.tgl_berdiri}
                            </FormText>
                        </FormGroup>

                    </div>
                    <div className='col-12 col-md-6'>
                        <Inputs
                            forLabel={"tempat_berdiri"}
                            label={"Tempat Berdiri"}
                            type={"text"}
                            onChange={onChangeHandler('tempat_berdiri')}
                            name={"tempat_berdiri"}
                            error={fieldState.error.tempat_berdiri}
                            value={fieldState.field.tempat_berdiri}

                        />
                    </div>

                </div>

                <div className='row mb-4'>

                </div>
                <hr />
            </div>
            <div className='col-12 row flex-1 justify-content-end p-0'>
                <div className='row d-flex gap-2'>
                    <div className="d-flex align-items-center flex-1 col-12 col-md-6  justify-content-end pr-0">
                        <Button outline className="btn btn-md btn-block card-submit" onClick={() => { props.toggle() }}>
                            Cancel
                        </Button>

                    </div>
                    <div className="d-flex align-items-center flex-1 col-12 col-md-6 justify-content-end p-0">
                        <Button
                            color="primary"
                            disabled={props.loading}
                            className="btn btn-primary btn-block btn-md d-flex justify-content-center align-items-center gap-1"
                            onClick={onSubmitHandler}>
                            {props.loading &&
                                <span className="loader"></span>
                            }
                            Simpan
                        </Button>

                    </div>

                </div>
            </div>
        </>

    )
}
