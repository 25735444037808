import React, { Component } from "react";
import { Form } from "reactstrap";
import { Inputs, InputIcon, SelectOptionSearchs } from "../../../../../library/components/Components";
import Location from "../../../../assets/svg/Location"; //icon svg

class AlamatPIC extends Component {
    render() {
        return (
            <Form>
                <h4 className="text-center mb-4 d-block d-sm-none font-weight-bold">Alamat Perusahaan</h4>
                <div className="row">
                    <div className="col-12">
                        <InputIcon
                            forLabel={"alamat_perwakilan"}
                            label={"Alamat Sesuai KTP"}
                            icon={<Location />}
                            type={"text"}
                            name={"alamat_perwakilan"}
                            onChange={this.props.onChange}
                            placeholder={"Contoh: Jakarta"}
                            value={this.props.data_alamatperwakilan ? this.props.data_alamatperwakilan : this.props.alamat_perwakilan}
                            readOnly={this.props.data_alamatperwakilan ? true : false}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {this.props.data_pp ? (
                            <Inputs
                                forLabel={"id_provinsi"}
                                label={"Provinsi"}
                                type={"text"}
                                name={"Provinsi"}
                                value={this.props.data_pp}
                                readOnly={true}
                            />
                        ) : (
                            <SelectOptionSearchs
                                forLabel={"provinsi_perwakilan"}
                                label={"Provinsi"}
                                onChange={this.props.onChangePP}
                                data={this.props.provinsiList}
                                value={{
                                    value: this.props.provinsi_perwakilan,
                                    label: this.props.perprovLabel
                                }}
                            />
                        )}
                    </div>
                    <div className="col-md-6">
                        {/* {this.props.data_kp ? (
              <Inputs
                forLabel={"id_kota"}
                label={"Kota / Kabupaten"}
                type={"text"}
                name={"Kota / Kabupaten"}
                value={this.props.data_kp}
                readOnly={true}
              />
            ) : ( */}
                        <SelectOptionSearchs
                            forLabel={"kota_perwakilan"}
                            label={"Kota / Kabupaten"}
                            onChange={this.props.onChangePK}
                            data={this.props.kotaList}
                            value={{
                                value: this.props.kota_perwakilan,
                                label: this.props.perkotaLabel
                            }}
                            disabled={!this.props.provinsi_perwakilan}
                        />
                        {/* )} */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"kecamatan_perwakilan"}
                            label={"Kecamatan"}
                            onChange={this.props.onChangeKec}
                            type={"text"}
                            name={"kecamatan_perwakilan"}
                            placeholder={"Contoh: Cengkareng"}
                            error={this.props.errkecamatan_perwakilan}
                            value={this.props.data_kecamatanperwakilan ? this.props.data_kecamatanperwakilan : this.props.kecamatan_perwakilan}
                            readOnly={this.props.data_kecamatanperwakilan ? true : false}
                        />
                    </div>
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"kelurahan_perwakilan"}
                            label={"Kelurahan"}
                            onChange={this.props.onChangeKel}
                            type={"text"}
                            name={"kelurahan_perwakilan"}
                            placeholder={"Contoh: Cengkareng Barat"}
                            error={this.props.errkelurahan_perwakilan}
                            value={this.props.data_kelurahanperwakilan ? this.props.data_kelurahanperwakilan : this.props.kelurahan_perwakilan}
                            readOnly={this.props.data_kelurahanperwakilan ? true : false}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"kode_pos_perwakilan"}
                            label={"Kode Pos"}
                            onChange={this.props.onChangeKode}
                            type={"text"}
                            name={"kode_pos_perwakilan"}
                            placeholder={"Contoh: 12345"}
                            maxLength={"5"}
                            error={this.props.errkode_pos_perwakilan}
                            value={this.props.data_kodeposperwakilan ? this.props.data_kodeposperwakilan : this.props.kode_pos_perwakilan}
                            readOnly={this.props.data_kodeposperwakilan ? true : false}
                        />
                    </div>
                </div>
            </Form>
        );
    }
}
export default AlamatPIC;
