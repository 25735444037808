import { combineReducers } from "redux";
import user from "./User";
import project from "./Project";
import lender from "./Lender";
import borrower from "./Borrower";
import master from "./Master";
import digisign from "./Digisign";
import password from "./Password";
import dipay from "./Dipay";
import blog from "./Blog";
import tiketing from "./Tiketing";

const Appreducer = combineReducers({
    user,
    project,
    lender,
    borrower,
    master,
    digisign,
    password,
    dipay,
    blog,
    tiketing
});

export default Appreducer;
