import React, { Component } from "react";
import { Form, FormGroup, FormText, Label, CustomInput, Button } from "reactstrap";

class UploadFile extends Component {
    render() {
        const namePendirian = this.props.akta_pendirian.name;
        const namePerubahan = this.props.akta_perubahan.name;
        const nameDomisili = this.props.surat_domisili.name;
        const nameLaporan = this.props.laporan_keuangan.name;
        const nameRekening = this.props.rekening_koran.name;
        const nameNIB = this.props.NIB.name;
        const nameSIUP = this.props.SIUP.name;
        const nameTDP = this.props.TDP.name;
        const label = "Masukan file PDF";
        return (
            <Form>
                <h4 className="text-center mb-4 d-block d-sm-none font-weight-bold">Upload File</h4>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="exampleFile">Akta Pendirian</Label>
                            <CustomInput
                                type="file"
                                onChange={this.props.handleUploadFile}
                                name="akta_pendirian"
                                id="exampleFile"
                                label={namePendirian !== undefined ? namePendirian : label}
                                accept="application/pdf"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="exampleFile">Akta Perubahan</Label>
                            <CustomInput
                                type="file"
                                onChange={this.props.handleUploadFile}
                                name="akta_perubahan"
                                id="exampleFile"
                                label={namePerubahan !== undefined ? namePerubahan : label}
                                accept="application/pdf"
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="exampleFile">Surat Domisili</Label>
                            <CustomInput
                                type="file"
                                onChange={this.props.handleUploadFile}
                                name="surat_domisili"
                                id="exampleFile"
                                label={nameDomisili !== undefined ? nameDomisili : label}
                                accept="application/pdf"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="exampleFile">Laporan Keuangan</Label>
                            <CustomInput
                                type="file"
                                onChange={this.props.handleUploadFile}
                                name="laporan_keuangan"
                                id="exampleFile"
                                label={nameLaporan !== undefined ? nameLaporan : label}
                                accept="application/pdf"
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="exampleFile">Rekening Koran</Label>
                            <CustomInput
                                type="file"
                                onChange={this.props.handleUploadFile}
                                name="rekening_koran"
                                id="exampleFile"
                                label={nameRekening !== undefined ? nameRekening : label}
                                accept="application/pdf"
                            />
                        </FormGroup>
                    </div>
                </div>
                <FormText color="danger" className="pb-3">
                    Jika tidak ada SIUP dan TDP, mohon upload NIB.
                    <br /> Jika tidak ada NIB, mohon upload SIUP dan TDP
                </FormText>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="exampleFile">NIB</Label>
                            <CustomInput
                                type="file"
                                onChange={this.props.handleUploadFile}
                                name="NIB"
                                id="exampleFile"
                                label={nameNIB !== undefined ? nameNIB : label}
                                accept="application/pdf"
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="exampleFile">SIUP</Label>
                            <CustomInput
                                className="custom-file"
                                type="file"
                                onChange={this.props.handleUploadFile}
                                name="SIUP"
                                id="exampleFile"
                                label={nameSIUP !== undefined ? nameSIUP : label}
                                accept="application/pdf"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="exampleFile">TDP</Label>
                            <CustomInput
                                type="file"
                                onChange={this.props.handleUploadFile}
                                name="TDP"
                                id="exampleFile"
                                label={nameTDP !== undefined ? nameTDP : label}
                                accept="application/pdf"
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="mt-4 mb-5">
                    <div className="custom-control custom-checkbox filter-input">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="agreement"
                            name="agreement"
                            onChange={this.props.handleCheck}
                            defaultChecked={this.props.checked || ""}
                        />
                        <label className="custom-control-label" htmlFor="agreement">
                            Saya menyatakan bahwa data-data yang telah saya isi diatas adalah valid, saya telah melakukan pengecekan atas kebenaran
                            data-data tersebut, dan saya telah membaca ketentuan privacy dan cookies .
                        </label>
                    </div>
                </div>
                <div className="text-center">
                    {this.props.loading === true ? (
                        <Button
                            onClick={this.props.handleSaveBorrower}
                            disabled
                            color="primary"
                            className="btn btn-primary btn-lg btn-block card-submit">
                            <span className="loader"></span>
                        </Button>
                    ) : (
                        <Button
                            onClick={this.props.handleSaveBorrower}
                            disabled={this.props.disabled}
                            color="primary"
                            className="btn btn-primary btn-lg btn-block card-submit">
                            Daftar{" "}
                        </Button>
                    )}
                </div>
            </Form>
        );
    }
}
export default UploadFile;
