import React, { Component } from "react";
import "./testimoni.scss";
import OwlCarousel from "react-owl-carousel2";
import "../../../../../node_modules/react-owl-carousel2/lib/styles.css";
import Item from "./ItemTesti";

class Testimoni extends Component {
    render() {
        const content = [
            {
                pict: "1.png",
                lender: "Lulu",
                job: "Karyawan Swasta",
                body: `“Wah, Indofund very helpful for my personal cash flow,
        apalagi di masa pandemic begini, bunga deposito semua
        anjlok, mending aku "main" di INDOFUND saja yang
        bunganya jauh lebih tinggi, pokoknya INDOFUND
        terpercaya, dan berkomitmen!”`
            },
            {
                pict: "2.png",
                lender: "Yohana",
                job: "Wirausahawan",
                body: `“Semenjak bergabung dengan Indofund, hasil pendanaan
        saya sudah bertumbuh. Hingga saat ini, pendanaan yang
        saya lakukan belum pernah mengalami gagal bayar.
        Menariknya pendanaan di Indofund bisa dimulai dari 50
        ribu, dan imbal hasil yang menarik yaitu
        15%-20%/tahun.”`
            },
            {
                pict: "3.png",
                lender: "Mart Lam Manihuruk",
                job: "Karyawan Swasta",
                body: `“Selama ini semua pendanaan saya belum pernah ada
        kendala di Indofund. Selalu terbayar dengan baik.
        Selain itu, pendanaan di Indofund sangat terjangkau,
        bisa mulai dari 50 ribu.”`
            },
            {
                pict: "4.png",
                lender: "Sophian",
                job: "Wirausahawan",
                body: `“Awalnya saya memang cuman coba-coba di Rp 300.000 dan
        selama dua tahun berjalan saya lihat pembayarannya
        oke. Makanya, saya sering banget ngajakin temen-temen
        untuk bikin akun di Indofund.”`
            }
        ];

        const testiWeb = () => {
            const optionsWeb = {
                dots: true,
                dotsEach: true,
                dotData: true,
                center: true,
                items: 2,
                dotsClass: "owl-dots no-lg",
                loop: true,
                autoplay: true,
                autoplayTimeout: 7000,
                autoplayHoverPause: true,
                nav: true,
                navText: ["<i class='fa fa-chevron-left icons' ></i>", "<i class='fa fa-chevron-right icons' ></i>"]
            };
            return (
                <div className="web-testi">
                    <OwlCarousel options={optionsWeb}>
                        {content.map((val, i) => {
                            return (
                                <div className="item" key={i}>
                                    <div className="row justify-content-center">
                                        <Item pict={val.pict} lender={val.lender} job={val.job} content={val.body} />
                                    </div>
                                </div>
                            );
                        })}
                    </OwlCarousel>
                </div>
            );
        };

        const testiMobile = () => {
            const options = {
                dots: true,
                items: 1,
                dotsClass: "owl-dots container no-lg",
                loop: true,
                autoplay: true,
                autoplayTimeout: 7000,
                autoplayHoverPause: true,
                nav: true,
                navText: ["<i class='fa fa-chevron-left icons' ></i>", "<i class='fa fa-chevron-right icons' ></i>"]
            };

            return (
                <div className="mobile-testi">
                    <OwlCarousel options={options}>
                        {content.map((val, i) => {
                            return (
                                <div className="item" key={i}>
                                    <div className="row">
                                        <Item pict={val.pict} lender={val.lender} job={val.job} content={val.body} />
                                    </div>
                                </div>
                            );
                        })}
                    </OwlCarousel>
                </div>
            );
        };

        return (
            <section id="testimoni" className="bg-gray">
                <div className="container no-lg">
                    <h4 className="title text-center">Cerita Mereka tentang Indofund.id</h4>
                    <div className="pt-4 pb-4">{window.innerWidth <= 480 ? testiMobile() : testiWeb()}</div>
                </div>
            </section>
        );
    }
}

export default Testimoni;
