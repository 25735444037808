import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import './Daftar.scss'

class BorrowerPerorang extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
                {/* {localStorage.jwtToken !== undefined ?  */}
                <section id="daftar" className="with-pad bg-grey">
                    <div className="container no-lg">
                        <div className="sec-header">
                            <h4 className="text-center sec-title">Pilih Jenis Penerima Dana</h4>
                            {/* <p className="text-center sec-desc">Kembangkan dana Anda Bersama Indofund, daftar dan langsung mendanai!</p>  */}
                        </div>
                        <div className="row justify-content-center mb-4">
                            <div className="col-12 col-lg-10 col-xl-9">
                                <div className="card pick-daftar border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 text-center text-md-left mb-4 mb-lg-0">
                                                <p className="fs-1 font-weight-bold mb-2 text-dark">Working Capital Financing (Modal Kerja)</p>
                                                <p className="text-secondary mb-0">
                                                    Pendanaan berbasis kebutuhan modal kerja yang dibuktikan dengan perputaran dana di rekening koran
                                                    dengan berbasis pada analisa arus kas Penerima Dana.
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6 text-right">
                                                <Link to="/regist-personal/1" className="btn btn-primary btn-lg" value="1">
                                                    Pilih Modal Kerja
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-4">
                            <div className="col-12 col-lg-10 col-xl-9">
                                <div className="card pick-daftar border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 text-center text-md-left mb-4 mb-lg-0">
                                                <p className="fs-1 font-weight-bold mb-2 text-dark">Pendanaan Multiguna</p>
                                                <p className="text-secondary mb-0">Pendanaan ke sektor UMKM dengan skala mikro secara langsung</p>
                                            </div>
                                            <div className="col-12 col-md-6 text-right">
                                                <Link to="/regist-personal/2" className="btn btn-primary btn-lg">
                                                    Pilih Pendanaan Multiguna
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-4">
                            <div className="col-12 col-lg-10 col-xl-9">
                                <div className="card pick-daftar border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 text-center text-md-left mb-4 mb-lg-0">
                                                <p className="fs-1 font-weight-bold mb-2 text-dark">Employee Loan</p>
                                                <p className="text-secondary mb-0">
                                                    Pendanaan yang diberikan kepada karyawan yang menjadi partner dari Indofund.id dengan jaminan
                                                    pembayaran dari partner.
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6 text-right">
                                                <Link to="/regist-personal/3" className="btn btn-primary btn-lg">
                                                    Pilih Employee Loan
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps)(BorrowerPerorang);
