import Cookie from "universal-cookie";

const cookie = new Cookie();

const initialState = {
    user: [],
    userList: [],
    bankList: [],
    statistik: [],
    NicepayVA: [],
    NicepayCI: [],
    checkPusdafil: {},
    isLoading: false,
    isError: false,
    response: [],
    lapKeuangan: [],
    TKB: null,
    otp: "",
    userDompet: null,
    isLoadingPrimary: false,
    isLoadingWithdraw: false,
    isLoadingLogin: false,
    isLoadingUbahPassword: false,
    isLoadingGetUser: false,
    isLoadingBalance: false,
    isLoadingRegistL: false,
    isLoadingRegist: false,
    isLoadingOTPV: false,
    isLoadingOTPR: false,
    isLoadingStatistik: false,
    isLoadingLap: false,
    isLoadingTerm: false,
    isLoadingCheckPusdafil: false,
    isLoadingPostPusdafil: false,
};

const LoginUser = (state = initialState, action) => {
    switch (action.type) {
        //Register
        case "REGISTER_PENDING":
            return {
                ...state,
                isLoadingRegist: true,
                isError: false
            };
        case "REGISTER_REJECTED":
            return {
                ...state,
                isLoadingRegist: false,
                isError: true
            };

        case "REGISTER_FULFILLED":
            const tokenReg = action.payload.data.token;
            const emailReg = action.payload.data.data.email;
            const namaPengguna = action.payload.data.data.nama_pengguna;
            const nomorHandphone = action.payload.data.data.no_handphone;
            localStorage.setItem("email", emailReg);
            localStorage.setItem("jwtToken", tokenReg);
            localStorage.setItem("no_hp", nomorHandphone);
            localStorage.setItem("nama_pengguna", namaPengguna);
            // console.log(action.payload.data.data.nama_pengguna)
            return {
                ...state,
                isLoadingRegist: false,
                userList: [state.userList, action.payload.data[0]]
            };
        //Register Lender
        case "REGISTERLENDER_PENDING":
            return {
                ...state,
                isLoadingRegistL: true,
                isError: false
            };
        case "REGISTERLENDER_REJECTED":
            return {
                ...state,
                isLoadingRegistL: false,
                isError: true
            };

        case "REGISTERLENDER_FULFILLED":
            return {
                ...state,
                isLoadingRegistL: false,
                userList: action.payload.data
            };
        //Register Borrower Badan Hukum
        case "REGISTERBORROWER_BADANHUKUM_PENDING":
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case "REGISTERBORROWER_BADANHUKUM_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case "REGISTERBORROWER_BADANHUKUM_FULFILLED":
            return {
                ...state,
                isLoading: false,
                userList: action.payload.data
            };
        //Register Borrower Personal
        case "REGISTERBORROWER_PERSONAL_PENDING":
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case "REGISTERBORROWER_PERSONAL_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case "REGISTERBORROWER_PERSONAL_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoading: false,
                userList: action.payload.data
            };
        //Login
        case "LOGIN_PENDING":
            return {
                ...state,
                isLoadingLogin: true
            };
        case "LOGIN_REJECTED":
            // console.log(action.payload.response.data)
            const token_reject = action.payload.response.data.message.token;
            const namaUser = action.payload.response.data.message.nama_pengguna;
            const emailUser = action.payload.response.data.message.email;
            const no_handphone = action.payload.response.data.message.no_handphone;
            const verif = action.payload.response.data.message.verification;

            localStorage.setItem("jwtToken", token_reject);
            localStorage.setItem("namaUser", namaUser);
            localStorage.setItem("email", emailUser);
            localStorage.setItem("no_handphone", no_handphone);
            localStorage.setItem("verif", verif);
            //
            if (action.payload.response.data.data !== undefined) {
                if (action.payload.response.data.data.user.lender_id !== undefined) {
                    const jenispengguna_reject = action.payload.response.data.data.user.lender_id.jenis_pengguna;
                    localStorage.setItem("jenisPengguna", jenispengguna_reject);
                } else {
                    const jenispengguna_reject = action.payload.response.data.data.user.borrower_id.jenis_pengguna;
                    localStorage.setItem("jenisPengguna", jenispengguna_reject);
                }
            }
            return {
                ...state,
                isLoadingLogin: false,
                isError: true,
                response: action.payload.response
            };
        case "LOGIN_FULFILLED":
            // console.log(action.payload.data);
            if (action.payload.data.kode_pengguna === 2) {
                const kyc = action.payload.data.data.borrower_id.admin_kyc;
                localStorage.setItem("kyc", kyc);
            }

            if (action.payload.data.kode_pengguna === 1 && action.payload.data.data.lender_id) {
                const lenderId = action.payload.data.data.lender_id._id;
                localStorage.setItem("lenderId", lenderId);
            }
            const id = action.payload.data.data._id;
            const email = action.payload.data.data.email;
            const no_hp = action.payload.data.data.no_handphone;
            const kode_pengguna = action.payload.data.data.kode_pengguna;
            const nama_pengguna = action.payload.data.data.nama_pengguna;
            const masuk = action.payload.data.kode_pengguna;
            const token = action.payload.data.token;
            const refreshToken = action.payload.data.refreshToken;

            localStorage.setItem("id", id);
            localStorage.setItem("no_hp", no_hp);
            localStorage.setItem("email", email);
            localStorage.setItem("kode_pengguna", kode_pengguna);
            localStorage.setItem("nama_pengguna", nama_pengguna);
            localStorage.setItem("masuk", masuk);
            localStorage.setItem("jwtToken", token);
            localStorage.setItem("refreshToken", refreshToken);

            // cookie.set('user', {})

            return {
                ...state,
                isLoadingLogin: false,
                isError: false,
                user: action.payload.data.data
            };

        case "REFRESH_TOKEN_PENDING":
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case "REFRESH_TOKEN_REJECTED":
            return {
                ...state,
                isLoading: false
            };

        case "REFRESH_TOKEN_FULFILLED":
            return {
                ...state,
                isLoading: false,
                userList: [state.userList]
            };

        case "LUPA_PASSWORD_PENDING":
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case "LUPA_PASSWORD_REJECTED":
            return {
                ...state,
                isLoading: false
            };

        case "LUPA_PASSWORD_FULFILLED":
            const emailForget = action.payload.data.email;

            localStorage.setItem("email", emailForget);
            return {
                ...state,
                isLoading: false,
                userList: [state.userList]
            };

        case "GET_USER_ID_PENDING":
            return {
                ...state,
                isLoadingGetUser: true
            };

        case "GET_USER_ID_REJECTED":
            return {
                ...state,
                isLoadingGetUser: false,
                isError: true
            };

        case "GET_USER_ID_FULFILLED":
            return {
                ...state,
                isLoadingGetUser: false,
                userList: action.payload.data.data
            };

        //OTP VERIF
        case "OTP_VERIF_PENDING":
            return {
                ...state,
                isLoadingOTPV: true,
                isError: false
            };
        case "OTP_VERIF_REJECTED":
            return {
                ...state,
                isLoadingOTPV: false,
                isError: true
            };

        case "OTP_VERIF_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoadingOTPV: false,
                otp: action.payload.data
            };

        //OTP resend
        case "OTP_RESEND_PENDING":
            return {
                ...state,
                isLoadingOTPR: true,
                isError: false
            };
        case "OTP_RESEND_REJECTED":
            return {
                ...state,
                isLoadingOTPR: false
            };

        case "OTP_RESEND_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoadingOTPR: false,
                otp: [state.userList]
            };

        case "LOGOUT":
            cookie.remove("user");
            return {
                ...state,
                isLoading: false,
                isError: false,
                user: {}
            };
        //kenapa diuser? karena dompet hanya satu dengan 2 tipe yg berbeda
        case "TOPUP_PENDING":
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case "TOPUP_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case "TOPUP_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoading: false,
                userList: action.payload.data
            };
        case "WITHDRAW_PENDING":
            return {
                ...state,
                isLoadingWithdraw: true
            };
        case "WITHDRAW_REJECTED":
            return {
                ...state,
                isLoadingWithdraw: false,
                isError: true
            };

        case "WITHDRAW_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoadingWithdraw: false,
                userList: action.payload.data
            };
        case "CANCEL_WITHDRAW_PENDING":
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case "CANCEL_WITHDRAW_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case "CANCEL_WITHDRAW_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoading: false,
                userList: action.payload.data
            };
        case "REKENING_PENDING":
            return {
                ...state,
                isLoading: true
            };
        case "REKENING_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case "REKENING_FULFILLED":
            // console.log(state.userList)
            // console.log(action.payload.data.data)
            return {
                ...state,
                isLoading: false,
                userList: action.payload.data.data
            };
        case "DELETE_REKENING_PENDING":
            return {
                ...state,
                isLoading: true
            };
        case "DELETE_REKENING_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case "DELETE_REKENING_FULFILLED":
            // console.log(state.userList)
            // console.log(action.payload)
            return {
                ...state,
                isLoading: false,
                userList: action.payload.data
            };
        case "PRIMARY_REKENING_PENDING":
            return {
                ...state,
                isLoadingPrimary: true
            };

        case "PRIMARY_REKENING_REJECTED":
            return {
                ...state,
                isLoadingPrimary: false,
                isError: true
            };

        case "PRIMARY_REKENING_FULFILLED":
            return {
                ...state,
                isLoadingPrimary: false,
                bankList: action.payload.data.data
            };
        case "BALANCE_TRANSACTION_PENDING":
            return {
                ...state,
                isLoadingBalance: true
            };

        case "BALANCE_TRANSACTION_REJECTED":
            return {
                ...state,
                isLoadingBalance: false,
                isError: true
            };

        case "BALANCE_TRANSACTION_FULFILLED":
            return {
                ...state,
                isLoadingBalance: false,
                userDompet: action.payload.data.data
            };
        case "SETASPRIMARY_REKENING_PENDING":
            return {
                ...state,
                isLoading: true
            };

        case "SETASPRIMARY_REKENING_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case "SETASPRIMARY_REKENING_FULFILLED":
            return {
                ...state,
                isLoading: false,
                bankList: action.payload.data.data
            };
        case "UBAH_PASSWORD_PENDING":
            return {
                ...state,
                isLoadingUbahPassword: true
            };
        case "UBAH_PASSWORD_REJECTED":
            return {
                ...state,
                isLoadingUbahPassword: false,
                isError: true
            };
        case "UBAH_PASSWORD_FULFILLED":
            return {
                ...state,
                isLoadingUbahPassword: false,
                userList: [state.userList, action.payload.data]
            };
        case "STATISTIK_PENDING":
            return {
                ...state,
                isLoadingStatistik: true
            };
        case "STATISTIK_REJECTED":
            return {
                ...state,
                isLoadingStatistik: false,
                isError: true
            };
        case "STATISTIK_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoadingStatistik: false,
                statistik: action.payload.data
            };
        case "TKB90_PENDING":
            return {
                ...state,
                isLoading: true
            };
        case "TKB90_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case "TKB90_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoading: false,
                TKB: action.payload.data
            };

        case "KEUANGAN_PENDING":
            return {
                ...state,
                isLoadinglap: true
            };
        case "KEUANGAN_REJECTED":
            return {
                ...state,
                isLoadinglap: false,
                isError: true
            };
        case "KEUANGAN_FULFILLED":
            //   console.log(action.payload.data);
            return {
                ...state,
                isLoadinglap: false,
                lapKeuangan: action.payload.data
            };
        case "SEND_ID_PENDING":
            return {
                ...state,
                isLoading: true
            };

        case "SEND_ID_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case "SEND_ID_FULFILLED":
            // console.log(action.payload.data.data)
            return {
                ...state,
                isLoading: false,
                NicepayVA: action.payload.data.data
            };
        case "CUSTOMER_INQUIRY_PENDING":
            return {
                ...state,
                isLoading: true
            };

        case "CUSTOMER_INQUIRY_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case "CUSTOMER_INQUIRY_FULFILLED":
            // console.log(action.payload.data.data)
            return {
                ...state,
                isLoading: false,
                NicepayCI: action.payload.data.data
            };
        case "EDIT_REKENING_PENDING":
            return {
                ...state,
                isLoading: true
            };
        case "TERM_REJECTED":
            return {
                ...state,
                isLoadingTerm: false,
                isError: true
            };
        case "TERM_FULFILLED":
            return {
                ...state,
                isLoadingTerm: false
            };
        case "TERM_PENDING":
            return {
                ...state,
                isLoadingTerm: true
            };
        case "CHECK_UPDATE_PUSDAFIL_PENDING":
            return {
                ...state,
                isLoadingCheckPusdafil: true,

            };
        case "CHECK_UPDATE_PUSDAFIL_REJECTED":
            return {
                ...state,
                isLoadingCheckPusdafil: false,
                isError: true
            };
        case "CHECK_UPDATE_PUSDAFIL_FULFILLED":
            return {
                ...state,
                isLoadingCheckPusdafil: false,
                checkPusdafil: action.payload.data.data
            };
        case "POST_UPDATE_PUSDAFIL_PENDING":
            return {
                ...state,
                isLoadingPostPusdafil: true,

            };
        case "POST_UPDATE_PUSDAFIL_REJECTED":
            return {
                ...state,
                isLoadingPostPusdafil: false,
                isError: true
            };
        case "POST_UPDATE_PUSDAFIL_FULFILLED":
            return {
                ...state,
                isLoadingPostPusdafil: false,
                checkPusdafil: action.payload.data.data
            };
        default:
            return state;
    }
};

export default LoginUser;
