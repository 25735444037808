import React from 'react'
import { LoadingPortofolio } from '../../assets/loading data/LoadingLender'
import Pagination from "react-js-pagination";

export default function PortfolioListLayout({ isLoading, filter, renderComponent, page, limit, totalData, onChangePage, handleChangeSearch }) {
    return (
        <>
            <div>
                <div className="d-flex align-items-center justify-content-between my-3">
                    <div>
                        <form>
                            <div className="input-group">
                                <input
                                    className="form-control"
                                    type="text"
                                    id="keyword"
                                    name="keyword"
                                    placeholder="Cari Transaksi"
                                    value={filter}
                                    onChange={handleChangeSearch}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <i className="material-icons-outlined">search</i>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {isLoading ?
                    <div>
                        {Array.from({ length: 10 }).map((_, index) => (
                            <LoadingPortofolio key={index} />
                        ))}
                    </div>
                    :
                    <>
                        {renderComponent()}

                        <div className="d-flex justify-content-center mt-3">
                            <Pagination
                                hideDisabled
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={page}
                                itemsCountPerPage={limit}
                                totalItemsCount={totalData}
                                onChange={onChangePage}
                            />
                        </div>
                    </>
                }
            </div>
        </>
    )
}
