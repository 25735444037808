export function employee(props) {
    if (props.kode_perusahaan.length < 5 || !props.surat_keterangan_kerja) {
        return false;
    } else {
        return true;
    }
}

export function micro(props) {
    if (!props.lokasi_pasar || !props.surat_keterangan_usaha || !props.sektor_usaha || !props.deskripsi_usaha) {
        return false;
    } else {
        return true;
    }
}

export function modal(props) {
    if (!props.surat_keterangan_usaha || !props.sektor_usaha || !props.deskripsi_usaha) {
        return false;
    } else {
        return true;
    }
}

// buat micro //

export function info(props, selfie, dataLender) {
    var tgl = props.tgl_lahir.toDateString() === new Date().toDateString();

    if (!dataLender) {
        if (
            props.errTempatLahir !== 0 ||
            tgl ||
            !props.id_jenis_kelamin ||
            !props.id_agama ||
            !props.status_perkawinan ||
            props.errKtp !== 0 ||
            props.no_ktp.length < 16 ||
            !props.foto_ktp ||
            !props.foto_selfie || !props.no_npwp
        ) {
            return false;
        } else {
            return true;
        }
    }
}

export function alamat(props, dataLender) {
    if (!dataLender) {
        if (props.switchformDomisili !== true) {
            if (
                !props.alamat ||
                !props.id_provinsi ||
                !props.id_kota ||
                props.errKelurahan !== 0 ||
                props.errKecamatan !== 0 ||
                props.errKodepos !== 0 ||
                props.kode_pos.length < 5
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            if (
                !props.alamat_domisili ||
                !props.provinsi_domisili ||
                !props.kota_domisili ||
                props.errKelD !== 0 ||
                props.errKecD !== 0 ||
                props.errKodeD !== 0 ||
                props.kode_pos_domisili.length < 5 ||
                !props.alamat ||
                !props.id_provinsi ||
                !props.id_kota ||
                props.errKelurahan !== 0 ||
                props.errKecamatan !== 0 ||
                props.errKodepos !== 0 ||
                props.kode_pos.length < 5
            ) {
                return false;
            } else {
                return true;
            }
        }
    }
}

export function pekerjaan(props) {
    if (
        !props.id_pendidikan ||
        !props.id_pekerjaan ||
        !props.id_pekerjaan_online ||
        !props.id_bidang_pekerjaan ||
        !props.pengalaman_kerja ||
        !props.pendapatan ||
        !props.status_kepemilikan_rumah
    ) {
        return false;
    } else {
        return true;
    }
}
