import React, { Component } from "react";
import { connect } from "react-redux";
import NoData from "../../assets/no data/NoData";
import ChartPie from "../../assets/svg/ChartPie";
import Pagination from "react-js-pagination";

class ListTiketing extends Component {
    async handlePage(page) {
        await this.props.onChange(page);
    }

    render() {
        const list_tiketing = this.props.list_tiketing;
        return (
            <div>
                <div className="card-body">
                    {/* <h5 className="text-center font-weight-bold">Melepas koneksi akun Indofund dengan akun Dipay</h5> */}
                    {list_tiketing.length > 0 ? (
                        <div>
                            {list_tiketing.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <div className="transaction p-3">
                                            <div className="card border-0 shadow-sm">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                                            <p className="fs-n1 text-secondary mb-1">Terupdate</p>
                                                            <p className="mb-0 text-warning">{new Date(item.createdAt).toLocaleDateString("IN")}</p>
                                                        </div>
                                                        <div className="col-6 col-md-4 mb-3 mb-xl-0">
                                                            <p className="fs-n1 text-secondary mb-1">ID Tiket</p>
                                                            <p className="mb-0">{item.id_tiket}</p>
                                                        </div>
                                                        <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                                            <p className="fs-n1 text-secondary mb-1">Status Tiket</p>
                                                            <p className="mb-0">{item.status_tiket}</p>
                                                        </div>
                                                        <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                                            <a href={"/detail-tiketing/" + item._id} className="btn btn-outline-primary btn-block">
                                                                <span>Detail</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <div className="d-flex justify-content-center mt-3">
                                <Pagination
                                    hideDisabled
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={this.props.page}
                                    itemsCountPerPage={5}
                                    totalItemsCount={this.props.totalData}
                                    onChange={this.handlePage.bind(this)}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="row justify-content-center">
                            <NoData symbol={<ChartPie />} keterangan={"Belum ada Laporan Pengaduan"} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tiketing: state.tiketing
    };
};
export default connect(mapStateToProps)(ListTiketing);
