import axios from "axios";

// const url = `http://localhost:3000`
const url = process.env.REACT_APP_BASSE_API;

export const ForgetPassword = (data) => {
    // console.log(data)
    return {
        type: "FORGET_PASSWORD",
        payload: axios.post(`${url}/reset/resetpassword`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const NewPassword = (data) => {
    return {
        type: "NEW_PASSWORD",
        payload: axios.patch(`${url}/user/add-password/`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};
