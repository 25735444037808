import React, { Component } from "react";
import Eventbox from "./EventBox";
import NoData from "../../../assets/no data/NoData";
import EventSVG from "../../../assets/svg/Event";
class Event extends Component {
    render() {
        const background = {
            backgroundImage: "url(/images/banner/bannerEvent.png)"
        };
        return (
            <section id="event" className="with-pad">
                <div className="container">
                    <div className="header-image" style={background}>
                        <h2 className="title-head">Kalender Event</h2>
                        <p className="text-head">Kalender kegiatan event dan sosialisasi</p>
                    </div>

                    <h4 className="sec-title text-center mt-5">Event yg akan datang</h4>
                    <NoData symbol={<EventSVG />} keterangan={"Saat ini belum ada Event mendatang"} />
                    <h4 className="sec-title text-center">Event yg lalu</h4>
                    <div className="row d-flex justify-content-center">
                        <Eventbox tgl="22" bulanTahun="Feb 2020" namaEvent="Investnoob" tempatEvent="Jakarta" />
                        <Eventbox tgl="12-15" bulanTahun="Des 2019" namaEvent="Panbil Winter Night Festival" tempatEvent="Kepulauan Riau " />
                        <Eventbox tgl="2" bulanTahun="Des 2019" namaEvent="Milenial Berinvestasi" tempatEvent="Sulawesi Tengah" />
                        <Eventbox tgl="30" bulanTahun="Nov 2019" namaEvent="Generasi Sadar Investasi Berani Membangun Negeri" tempatEvent="Jakarta" />
                        <Eventbox tgl="29" bulanTahun="Okt 2019" namaEvent="Investival FE UNP" tempatEvent="Sumatera Barat" />
                        <Eventbox tgl="28" bulanTahun="Sept 2019" namaEvent="Bandarmology Gathering" tempatEvent="Jakarta" />
                        <Eventbox tgl="16" bulanTahun="Sept 2019" namaEvent="Pengenalan Pasar Modal dan Fintech" tempatEvent="Yogyakarta" />
                        <Eventbox tgl="31" bulanTahun="Agu 2019" namaEvent="Bandarmology Gathering" tempatEvent="Jakarta" />
                        <Eventbox tgl="20" bulanTahun="Jul 2019" namaEvent="Millenial Melek Finansial" tempatEvent="Jakarta" />
                        <Eventbox tgl="13" bulanTahun="Jul 2019" namaEvent="Edukasi Keuangan" tempatEvent="Jambi" />
                        <Eventbox tgl="29" bulanTahun="Jun 2019" namaEvent="Sharing of Authors 201" tempatEvent="Jakarta" />
                        <Eventbox tgl="12" bulanTahun="Mei 2019" namaEvent="Investnoob" tempatEvent="Jakarta" />
                        <Eventbox
                            tgl="30"
                            bulanTahun="Mar 2019"
                            namaEvent="Menjadi Kaya dan Terencana dengan Reksadana"
                            tempatEvent="Sulawesi Selatan"
                        />
                        <Eventbox tgl="9-14" bulanTahun="Okt 2018" namaEvent="Crowdfunding Investment" tempatEvent="Jakarta" />
                        <Eventbox tgl="10" bulanTahun="Agus 2018" namaEvent="Crowdfunding Meetup" tempatEvent="Dieng" />
                        <Eventbox tgl="29" bulanTahun="Sept 2018" namaEvent="Sosialisasi di Event UMKM" tempatEvent="Tangerang" />
                        <Eventbox tgl="7" bulanTahun="Jul 2018" namaEvent="Crowdfunding Meetup" tempatEvent="Jakarta" />
                    </div>
                </div>
            </section>
        );
    }
}
export default Event;
