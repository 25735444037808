import React, { Component } from "react";

class NotFound extends Component {
    render() {
        return (
            <div style={{ padding: "400px" }}>
                <h1 className="text-center"> 404 Not Found</h1>
            </div>
        );
    }
}
export default NotFound;
