import React, { Component } from "react";
const queryString = require("query-string");
const axios = require("axios").default;
const url = process.env.REACT_APP_BASSE_API;

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: []
        };
    }
    async componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        const { resend } = values;
        // console.log(resend)
        const data = await axios.post(`${url}/user/resend`, { resendToken: resend });

        // console.log(data.data)
        if (data.data.status === 200) {
            this.props.history.push("/success-email");
        } else {
            this.props.history.push("/failed-resend"); //failed aktivasi resend
        }
    }
    render() {
        return <div className="app flex-row align-items-center"></div>;
    }
}

export default Register;
