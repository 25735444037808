import React, { Component } from "react";
import { Link } from "react-router-dom";
import Whatsapp from "../../assets/svg/Whatsapp";
import Instagram from "../../assets/svg/Instagram";
import Facebook from "../../assets/svg/Facebook";
class CompanyInfo extends Component {
    render() {
        return (
            <section id="company-info">
                <div className="info-wrapper">
                    <a href="/layanan?state=2" className="item">
                        <i className="fas fa-phone-alt fs-0 mr-2"></i>
                        <p className="mb-0">Hubungi Kami</p>
                    </a>
                    <a href="/faq" className="item">
                        <i className="far fa-question-circle fs-0 mr-2"></i>
                        <p className="mb-0">Pusat Bantuan</p>
                    </a>
                    <div className="item social-icons">
                        <a
                            href="http://wassmee.us/w/send.aspx?phone=6289643448118&text=Halo%20Indofund.id!%20Saya%20mau%20bertanya%20:"
                            className="icons pr-1">
                            <Whatsapp />
                        </a>
                        <a href="https://www.instagram.com/indofund.id/" className="icons pr-1 ">
                            <Instagram />
                        </a>
                        <a href="https://www.facebook.com/Indofund.id/" className="icons pr-1">
                            <Facebook />
                        </a>
                    </div>
                </div>
            </section>
        );
    }
}
export default CompanyInfo;
