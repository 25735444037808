import React, { Component } from "react";
import { getProject } from "../../../redux/action/Project";
import { PaymentLoan, PaymentPenalty } from "../../../redux/action/Borrower";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import Swal from "sweetalert2";
class DetailCicilan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectdet: []
        };
    }

    async componentDidMount() {
        await this.props.dispatch(getProject(this.props.match.params.id));
        this.setState({
            projectdet: this.props.project.projectDetail,
            nominal: this.props.project.projectDetail.nilai_pengajuan_pinjaman.toLocaleString("IN"),
            totalPinjaman: this.props.project.projectDetail.nilai_total_peminjam.toLocaleString("IN"),
            borrower: this.props.project.projectDetail.borrower
        });
        //  console.log(this.state.projectdet)
    }

    PaymentLoan = async (borrowerId, projectId, index) => {
        Swal.fire({
            title: "Bayar cicilan",
            text: "Anda yakin membayar cicilan?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ya bayar sekarang"
        })
            .then(async (result) => {
                if (result.value) {
                    await this.props.dispatch(PaymentLoan(borrowerId, projectId, index));
                    Swal.fire({
                        title: "Berhasil",
                        text: "Anda berhasil bayar",
                        icon: "success",
                        buttons: "ok"
                    });
                    window.location.reload();
                }
            })
            .catch((err) => {
                // console.log(err.response)
                if (err.response.status === 400) {
                    Swal.fire({
                        title: "Tidak Berhasil",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (err.response.status === 404) {
                    Swal.fire({
                        title: "",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (err.response.status === 500) {
                    Swal.fire({
                        title: "",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                }
            });
    };

    PaymentPenalty = async (borrowerId, projectId, index) => {
        Swal.fire({
            title: "Bayar denda",
            text: "Anda yakin membayar denda?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ya bayar sekarang"
        })
            .then(async (result) => {
                if (result.value) {
                    await this.props.dispatch(PaymentPenalty(borrowerId, projectId, index));
                    Swal.fire({
                        title: "Berhasil",
                        text: "Anda berhasil bayar",
                        icon: "success",
                        buttons: "ok"
                    });
                }
            })
            .catch((err) => {
                // console.log(err.response)
                if (err.response.status === 400) {
                    Swal.fire({
                        title: "Tidak Berhasil",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (err.response.status === 404) {
                    Swal.fire({
                        title: "",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (err.response.status === 500) {
                    Swal.fire({
                        title: "",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                }
            });
    };
    render() {
        const RincianPinjaman = () => {
            const cicilan = this.state.projectdet.rincian_pinjaman;
            if (cicilan === undefined) {
            } else {
                const hasil = cicilan.map((val, i) => {
                    return (
                        <tr key={val._id}>
                            <td className="card-text"> Bulan ke {i + 1} </td>
                            <td className="card-text">{new Date(val.jatuh_tempo).toLocaleDateString("IN")}</td>
                            <td className="card-text"> Rp {val.borrower_bayar.toLocaleString("IN")} </td>
                            <td>Rp. {val.denda.toLocaleString("IN")}</td>
                            <td>
                                {val.status_bayar === true && val.status_denda === true
                                    ? "Cicilan dan denda sudah terbayar"
                                    : val.status_bayar === true
                                    ? "Cicilan sudah terbayar"
                                    : "kosong"}
                            </td>
                        </tr>
                    );
                });
                return hasil;
            }
        };
        const project = this.state.projectdet;

        return (
            <div>
                <h4 className="text-center font-weight-semi-bold mb-5 mt-3">
                    <b>Detail Proyek Selesai</b>
                </h4>
                <div className="card border-0 shadow">
                    <div className="card-body">
                        <div className="row mb-3 mt-3">
                            <div className="col-6 col-md-6 mb-3 mb-xl-0 pb-3">
                                <p className="text-secondary mb-1">Nama Proyek</p>
                                <p className="mb-0">{project.nama_pinjaman}</p>
                            </div>
                            <div className="col-6 col-md-6 mb-3 mb-xl-0">
                                <p className="text-secondary mb-1">Nominal yg diajukan</p>
                                <p className="mb-0">Rp. {this.state.nominal}</p>
                            </div>
                            <div className="col-6 col-md-6 mb-3 mb-xl-0">
                                <p className="text-secondary mb-1">Dana terkumpul</p>
                                <p className="mb-0">Rp. {this.state.totalPinjaman}</p>
                            </div>
                            <div className="col-6 col-md-6 mb-3 mb-xl-0">
                                <p className="text-secondary mb-1">Suku bunga</p>
                                <p className="mb-0">{project.suku_bunga_pinjaman * 100} %</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Table borderless className="mt-5">
                    <thead>
                        <tr>
                            <th className="fs-n1 text-secondary mb-1"> Bulan</th>
                            <th className="fs-n1 text-secondary mb-1">Tanggal jatuh tempo</th>
                            <th className="fs-n1 text-secondary mb-1">Jumlah tagihan</th>
                            <th className="fs-n1 text-secondary mb-1">Denda</th>
                            <th className="fs-n1 text-secondary mb-1">Keterangan</th>
                        </tr>
                    </thead>
                    <tbody>{RincianPinjaman()}</tbody>
                </Table>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        project: state.project,
        borrower: state.borrower
    };
};
export default connect(mapStateToProps)(DetailCicilan);
