import React, { Component } from "react";

class FaqHeader extends Component {
    render() {
        const background = {
            backgroundImage: "url(/images/banner/BannerFAQ.png)"
        };
        return (
            <div className="header-image mb-5" style={background}>
                <h2 className="title-head">Frequently Asked Questions</h2>
                <p className="text-head">Pertanyaan yang sering diajukan</p>
            </div>
        );
    }
}
export default FaqHeader;
