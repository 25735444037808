import React, { Component } from "react";
import { Button } from "reactstrap";
import { DocumentLender } from "../../../redux/action/Digisign";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import NoData from "../../assets/no data/NoData";
import Pagination from "react-js-pagination";
import { sukuBunga } from "../../../library/helper/helper";
import NumberFormat from "react-number-format";
import moment from "moment";

const axios = require("axios").default;
const url = process.env.REACT_APP_BASSE_API;
class SignProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: [],
      currentPage: 1,

      todosPerPage: this.props.pagination?.sign?.limit || 15,
      sort: 'desc',
      filter: ""
    };
    this.handleClick = this.handleClick.bind(this);
  }
  async componentDidUpdate(prevState) {


    if (this.state.currentPage !== prevState.currentPage) {
      console.log(this.state.currentPage)



    }
  }
  async handleClick(pageNumber) {
    if(pageNumber==this.props.pagination.page) return
    window.scrollTo(0, 0)
    this.setState({
      currentPage: pageNumber
    }, async () => {
      await this.props.reFetchData({ ...this.props.query, page: pageNumber })

    })

    // this.setState({
    //   currentPage: pageNumber
    // }, () => {

    // });
  }

  handleClickLagi = async (id, no_perjanjian_lender) => {
    const data = await this.props.dispatch(DocumentLender(id));
    if (data.value.data.JSONFile.result === "05") {
      if (data.value.data.JSONFile.notif === "user telah menandatangani dokumen ini") {
        await axios
          .post(`${url}/digisign/aktivasi`, { email_user: this.props.email, document_id: no_perjanjian_lender }, {})
          .then(function (response) {
            Swal.fire({
              title: "Berhasil",
              text: "Dokument telah berhasil ditanda tangan",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Kembali"
            }).then(async (result) => {
              if (result.value) {
                window.location.reload();
              }
            });
          })
          .catch((err) => {
            Swal.fire({
              title: "Gagal",
              text: `${err.response.data.message}`,
              icon: "warning",
              button: "ok"
            });
          });
      } else {
        Swal.fire({
          title: "Gagal",
          text: `${data.value.data.JSONFile.notif}`,
          icon: "warning",
          button: "ok"
        });
      }
    } else if (data.value.data.JSONFile.result === "30") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "12") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "28") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "06") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "14") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "28") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "06") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "14") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "12") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else {
      window.location = this.props.digisign.responseLender.JSONFile.link;
    }
  };
  signProject(lenderLend) {

    const { totalData, currentPage, todosPerPage, totalPage } = this.state;
    console.log(this.state)


    // function compare(a, b) {
    //   return new Date(b.createdAt) - new Date(a.createdAt);
    // }

    // const indexOfLastTodo = currentPage * todosPerPage;
    // const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    // console.log(indexOfLastTodo)
    // console.log(indexOfFirstTodo)
    if (totalData !== 0) {
      //&& val.document_id !== undefined
      const hasil = lenderLend
        // .filter((item) => item.sendDocument === true && item.userTtd === false)
        // .sort(compare)
        // .slice(indexOfFirstTodo, indexOfLastTodo)
        .map((val) => {
          return (
            <div className="transaction p-3" key={val._id}>
              <div className="card border-0 shadow-sm">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-12 mb-3 mb-xl-0">
                      <div className="row">
                        <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">
                          <p className="fs-n1 text-secondary mb-1 title-sign-card">Tanggal</p>
                          <p className="fs-n1 mb-0">{moment(new Date(val.createdAt)).format('LL')}</p>
                        </div>
                        <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">
                          <p className="fs-n1 text-secondary mb-1 title-sign-card">Nama Pinjaman</p>
                          <p className="mb-0">{val.id_pinjaman.nama_pinjaman}</p>
                        </div>
                        <div className="col-6 col-md-2 mb-3 d-flex flex-column flex-1 align-items:stretch">
                          <p className="fs-n1 text-secondary mb-1 title-sign-card">Bunga</p>
                          <p className="mb-0">
                            {sukuBunga(
                              val.id_pinjaman.tipe_pinjaman,
                              val.id_pinjaman.jenis_pinjaman,
                              val.id_pinjaman.suku_bunga_pinjaman,
                              val.id_pinjaman.suku_bunga_konsumtif,
                              val.id_pinjaman.suku_bunga_micro,
                              val.id_pinjaman.spread_bunga,
                              true
                            )}
                          </p>
                        </div>
                        <div className="col-6 col-md-2 mb-3 d-flex flex-column flex-1">
                          <p className="fs-n1 text-secondary mb-1 title-sign-card">Nominal</p>
                          <NumberFormat
                            value={val.nilai_penawaran_disetujui}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"Rp "}
                            renderText={(value) => <p className="mb-0">{value}</p>}
                          />
                        </div>
                        <div className="col-6 col-md-2 mb-3 d-flex flex-column flex-1" >
                          <p className="fs-n1 text-secondary mb-1 title-sign-card">Tenor</p>
                          <p className="mb-0">{val.id_pinjaman.jangka_waktu_pinjaman} Bulan</p>
                        </div>
                        <div className="col-12 col-xl-2 text-center d-flex flex-column justify-content-center align-items-center">
                          <Button
                            color="primary"
                            disabled={val.sendDocument === false ? true : false}
                            onClick={() => this.handleClickLagi(val._id, val.no_perjanjian_lender)}>
                            Tanda tangan
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
      return hasil;
    } else {
      return <NoData symbol={<i className="material-icons-outlined">assignment</i>} keterangan={"Anda belum mendanai proyek"} />;
    }
  }
  render() {
    // const lenght = this.props.lending.filter((item) => item.sendDocument === true && item.userTtd === false).length;
    console.log(this.state.currentPage)
    console.log(this.props.pagination)
    return (
      <div>
        {this.signProject(this.props.lending)}
        <div className="d-flex justify-content-center mt-3">
          {this.props.pagination && this.props.pagination.totalData>0 &&
            <Pagination
              hideDisabled
              itemClass="page-item"
              linkClass="page-link"
              activePage={parseInt(this.props.pagination?.page) || 1}
              itemsCountPerPage={this.props.pagination.limit}
              totalItemsCount={parseInt(this.props.pagination?.totalData)}
              onChange={this.handleClick.bind(this)}
            />
          }
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    digisign: state.digisign,
  };
};
export default connect(mapStateToProps)(SignProject);
