import React, { Component } from "react";
import DatadiriPersonal from "./AkunPersonal/DatadiriPersonal";
import DatadiriEntity from "./AkunEntity/DatadiriEntity";
import Rekening from "../../userAkun/akun/TambahRekening";
import Password from "../../userAkun/akun/GantiPassword";
import { connect } from "react-redux";
import { Master } from "../../../redux/action/Master";
import { NavbarTitle, NavbarContent } from "../../../library/components/NavbarComponent";
import { getListTiketing } from "../../../redux/action/Tiketing";
import ListTiketing from "../../tiketing/ListTiketing/ListTiketing";
class Akun extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 0,
            bankList: [],
            list_tiketing: [],
            page_list_tiketing: 0,
            total_data_list_tiketing: 0
        };
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.getListTiketing;
        await this.props.dispatch(Master());
        this.setState({
            bankList: this.props.master.masterList.master_escrow
        });
    }

    async getListTiketing(page) {
        await this.props.dispatch(getListTiketing({ page: page }));
        this.setState({
            list_tiketing: this.props.tiketing.listTiketing,
            page_list_tiketing: page,
            total_data_list_tiketing: this.props.tiketing.totalData
        });
    }

    authDataDiri = (jenis, jenisMaster) => {
        if (jenis === "1" || jenisMaster === "1") {
            // console.log(this.props.employee)
            return (
                <DatadiriPersonal
                    id={this.props.match.params.id}
                    no_hp={this.props.no_hp}
                    email={this.props.email}
                    employee={this.props.employee}
                    micro={this.props.micro}
                    loading={this.props.master.isLoading}
                />
            );
        } else {
            return (
                <DatadiriEntity
                    id={this.props.match.params.id}
                    no_hp={this.props.no_hp}
                    email={this.props.email}
                    loading={this.props.master.isLoading}
                />
            );
        }
    };

    authAlert = () => {
        if (this.props.kyc === true) {
            return (
                <div className="alert-box">
                    <span className="mr-2">
                        Jika ada perubahan data diri hubungi admin via{" "}
                        <a
                            style={{ color: "white" }}
                            href="http://wassmee.us/w/send.aspx?phone=6289643448118&text=Halo%20Indofund.id!%20Saya%20mau%20bertanya%20:">
                            <b> whatsapp </b>
                        </a>
                    </span>
                </div>
            );
        }
    };

    render() {
        const { jenis } = this.props.location.state;
        const { jenisMaster } = this.props.location.state;
        const title = ["Data Diri", "Rekening Bank", "Ubah Kata Sandi", "Layanan Pengaduan"];
        const children = [
            <div className="card-body">
                {this.authAlert()}
                {this.authDataDiri(jenis, jenisMaster, this.props.borrower)}
            </div>,
            <Rekening
                id={this.props.match.params.id}
                userBank={this.props.userBank}
                bank={this.state.bankList}
                bankPengguna={this.props.bankPengguna}
                nama_pengguna={this.props.nama_pengguna}
            />,
            <Password />,
            <ListTiketing
                list_tiketing={this.state.list_tiketing}
                onChange={this.getListTiketing.bind(this)}
                page={this.state.page_list_tiketing}
                totalData={this.state.total_data_list_tiketing}
            />
        ];
        return (
            <div className="akun-wrapper">
                <div className="card border-0 shadow">
                    <NavbarTitle title={title} activeTab={this.state.activeTab} toggle={this.toggle} />
                    <NavbarContent activeTab={this.state.activeTab} components={children} className={"pt-5"} />
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        master: state.master
    };
};
export default connect(mapStateToProps)(Akun);
