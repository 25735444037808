import React, { Component } from "react";

class ItemTesti extends Component {
    render() {
        return (
            <div className="col-11 col-md-11">
                <div className="card border-0 shadow r-16">
                    <div className="card-body height-mobile">
                        <div className="row">
                            <div className="col-4 col-md-3 col-xl-2">
                                <img src={process.env.PUBLIC_URL + `images/testimoni/${this.props.pict}`} style={{ width: "100%" }} alt="foto" />
                            </div>
                            <div className="col-8 col-md-8">
                                <h5 className="font-weight-bold name-lender">{this.props.lender}</h5>
                                <div className="occupation">Pemberi Dana, {this.props.job}</div>
                            </div>
                        </div>
                        <div className="body-testi"> {this.props.content}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ItemTesti;
