import React, { Component } from "react";

class NoData extends Component {
    render() {
        return (
            <div className="no-data col-md-12 col-12">
                <div className="icon-wrapper">{this.props.symbol}</div>
                <span>{this.props.keterangan}</span>
            </div>
        );
    }
}
export default NoData;
