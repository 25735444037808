import React from "react";
import Skeleton from "react-loading-skeleton";
import { Form, FormGroup, Label } from "reactstrap";

function LoadingPersonal() {
    return (
        <div className="card-body">
            <Form>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Nama Lengkap (Sesuai KTP)</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Email</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Nomor Handphone</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Nomor KTP</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Nomor NPWP</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Foto KTP</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Foto Selfie</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Tempat Lahir</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Tanggal Lahir</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                </div>
            </Form>
        </div>
    );
}

function LoadingBadanHukum() {
    return (
        <div className="card-body">
            <Form>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Nama Lender</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Email</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Nomor NPWP Badan Usaha</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Jenis Badan Usaha</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Nama Perwakilan</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Nomor Handphone Perwakilan</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Tempat Lahir Perwakilan</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Tanggal Lahir Perwakilan</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label htmlFor="nama-lengkap">Jenis Kelamin Perwakilan</Label>
                            <Skeleton height={40} />
                        </FormGroup>
                    </div>
                </div>
            </Form>
        </div>
    );
}

function LoadingDasboard() {
    return (
        <div className="transaction p-3">
            <div className="card border-0 shadow-sm">
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-12 col-xl-8 mb-3 mb-xl-0">
                            <div className="row">
                                <div className="col-6 col-md-4 mb-3">
                                    <p className="fs-n1 text-secondary mb-1">Nama Proyek</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-4 mb-3">
                                    <p className="fs-n1 text-secondary mb-1">Tenor</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-4 mb-3">
                                    <p className="fs-n1 text-secondary mb-1">Bunga</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-4 mb-3 mb-xl-0">
                                    <p className="fs-n1 text-secondary mb-1">Sektor Usaha</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-4 mb-3 mb-xl-0">
                                    <p className="fs-n1 text-secondary mb-1">Status Proyek</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-4 mb-3 mb-xl-0">
                                    <p className="fs-n1 text-secondary mb-1">Sisa Pendanaan</p>
                                    <Skeleton />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-4 progress-divider">
                            <div className="progress mb-3 mt-0">
                                <Skeleton amount={2} />
                            </div>
                            <div className="row text-information">
                                <div className="col-6">
                                    <p className="fs-n1 text-secondary mb-1">Terkumpul</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6">
                                    <p className="fs-n1 text-secondary mb-1">Plafon</p>
                                    <Skeleton />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function LoadingPortofolio() {
    return (
        <div className="transaction p-3">
            <div className="card border-0 shadow-sm">
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-12 col-xl-12 mb-3 mb-xl-0">
                            <div className="row">
                                <div className="col-6 col-md-3 mb-3">
                                    <p className="fs-n1 text-secondary mb-1">Nama Proyek</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-2 mb-3">
                                    <p className="fs-n1 text-secondary mb-1">Bunga</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-2 mb-3">
                                    <p className="fs-n1 text-secondary mb-1">Nominal</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-3 mb-3 mb-xl-0">
                                    <p className="fs-n1 text-secondary mb-1">Status Proyek</p>
                                    <Skeleton />
                                </div>
                                <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                    <p className="fs-n1 text-secondary mb-1">Tgl Mulai Project</p>
                                    <Skeleton />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function LoadingPortofolioDetail() {
    return (
        <section id="proyek-detail-page" className="with-pad">
            <div className="container">
                <div className="project-detail">
                    <div className="row mb-3">
                        <div className="col-12 col-md-5">
                            <div className="mb-4">
                                <div>
                                    <Skeleton height={300} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-7 d-flex align-items-center">
                            <div className="project-body">
                                <div style={{ display: "flex" }} className="mb-4">
                                    <h3 className="font-weight-bold">
                                        <Skeleton />{" "}
                                    </h3>
                                    <h5 className="text-secondary" style={{ position: "relative", alignSelf: "center" }}>
                                        <Skeleton />
                                    </h5>
                                </div>
                                <p>
                                    <Skeleton />
                                </p>
                                <div className="row">
                                    <div className="col-md-3 pb-3">
                                        <p className="card-text label-info pb-1">Nama borrower</p>
                                        <Skeleton />
                                    </div>
                                    <div className="col-md-3 pb-3">
                                        <p className="card-text label-info pb-1">Sektor usaha</p>
                                        <Skeleton />
                                    </div>
                                    <div className="col-md-3 pb-3">
                                        <p className="card-text label-info pb-1">Tanggal mulai proyek</p>
                                        <Skeleton />
                                    </div>
                                    <div className="col-md-3 pb-3">
                                        <p className="card-text label-info pb-1">Jatuh tempo</p>
                                        <Skeleton />
                                    </div>
                                    <div className="col-md-3 pb-3">
                                        <p className="card-text label-info pb-1">Durasi peminjaman</p>
                                        <Skeleton />
                                    </div>
                                    <div className="col-md-3 pb-3">
                                        <p className="card-text label-info pb-1">Suku bunga</p>
                                        <Skeleton />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export { LoadingPersonal, LoadingBadanHukum, LoadingDasboard, LoadingPortofolio, LoadingPortofolioDetail };
