import React, { Component } from "react";
import { LendPort } from "../../../redux/action/Lender";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import NumberFormat from "react-number-format";
import ModalAsset from "./Modal/ModalAsset";
import DatePicker from "react-datepicker";
import { addYears } from "date-fns";
import TuneOutline from '@mui/icons-material/Tune';
import moment from "moment";

class PortoInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalBunga: "",
            asetAmount: "",
            selesaiAmount: "",
            berjalanAmount: "",
            balanceAmount: "",
            pendingAmount: "",
            selesaiAmount: "",
            popoverOut: false,
            popoverDone: false,
            popoverAsset: false,
            popoverProfit: false,
            selectedDate: new Date(),
            isOpenModal: false,
        };
        this.datePickerRef = React.createRef();
    }

    toggleOpenModal() {
        this.setState({ isOpenModal: !this.state.isOpenModal })
    }


    toggleInfo() {
        this.setState({
            popoverOut: !this.state.popoverOut
        });
    }
    toggleInfoAsset() {
        this.setState({
            popoverAsset: !this.state.popoverAsset
        });
    }
    toggleInfoProfit() {
        this.setState({
            popoverProfit: !this.state.popoverProfit
        });
    }

    toggleInfoDone() {
        this.setState({
            popoverDone: !this.state.popoverDone
        });
    }

    onHover = () => {
        this.setState({
            popoverOut: true
        });
    };
    onHoverDone = () => {
        this.setState({
            popoverDone: true
        });
    };

    onHoverLeave = () => {
        this.setState({
            popoverOut: false
        });
    };
    onHoverProfit = () => {
        this.setState({
            popoverProfit: true
        });
    };

    onHoverProfitLeave = () => {
        this.setState({
            popoverProfit: false
        });
    };
    onHoverLeaveDone = () => {
        this.setState({
            popoverDone: false
        });
    };


    onHoverAsset = () => {
        this.setState({
            popoverAsset: true
        });
    };
    onHoverAssetLeave = () => {
        this.setState({
            popoverAsset: false
        });
    };
    onDateChange = (e) => {


        this.setState({
            selectedDate: e
        })
    }

    datepickerTrigger = () => {
        if (this.datePickerRef.current) {
            this.datePickerRef.current.setFocus(); // Programmatically focus DatePicker when icon or text is clicked
        }
    }
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        await this.fetchData()

    };
    componentDidUpdate = (prevProprs, prevState) => {
        if (prevState.selectedDate !== this.state.selectedDate)
            this.fetchData();

    }

    fetchData = async () => {
        await this.props.dispatch(LendPort({ date: moment(this.state.selectedDate).format("YYYY-MM-DD") }));
        this.setState({
            asetAmount: Math.round(this.props.lender.lenderPort?.data.asetAmount),
            totalBunga: Math.round(this.props.lender.lenderPort?.data.totalBunga),
            selesaiAmount: Math.round(this.props.lender.lenderPort?.data.selesaiAmount),
            pendingAmount: Math.round(this.props.lender.lenderPort?.data.pendingAmount),
            berjalanAmount: Math.round(this.props.lender.lenderPort?.data.berjalanAmount),
            balanceAmount: Math.round(this.props.lender.lenderPort?.data.balanceAmount),
        });
    }
    render() {
        console.log(this.props.lender.lenderPort.date)

        console.log(this.props.lender)
        return (
            <div>
                <div className="d-flex flex-column wrapper-statistics border-0 card-statistic mbc-7" style={{ padding: 0 }}>
                    <div className="mb-2 mt-3 ">
                        <div className="col-12 col-md-4 mb-3 mb-md-0 p-0">
                            <div
                                className="card-statistic mb-2">
                                <div className="d-flex flex-column gap-1">
                                    <p className="text-secondary mb-0"> Nilai Asset</p>
                                    <div className="d-flex gap-1">
                                        <h5 className="font-weight-semi-bold mb-0 d-flex align-align-items-center">
                                            {this.props.lender.isLoadingLendPort ? (
                                                <Skeleton width={150} />
                                            ) : (
                                                <NumberFormat
                                                    value={this.state.asetAmount}
                                                    displayType={"text"}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    decimalScale={0}
                                                    prefix={"Rp "}
                                                    renderText={(value) => <span>{value}</span>}
                                                />
                                            )}
                                        </h5>
                                        <span className="d-flex justify-content-center align-items-center" id="Popovers6" data-html="true" style={{ cursor: 'pointer' }} onClick={this.toggleOpenModal.bind(this)} onMouseEnter={this.onHoverAsset} onMouseLeave={this.onHoverAssetLeave}>
                                            <i className="material-icons-outlined text-primary" style={{ fontSize: "20px" }}>
                                                info
                                            </i>
                                        </span>
                                        <Popover
                                            // className="d-none d-sm-block"
                                            placement="bottom"
                                            isOpen={this.state.popoverAsset}

                                            target="Popovers6"
                                            toggle={this.toggleInfoAsset.bind(this)}
                                        >

                                            <PopoverHeader className="popover-header dark-style" ></PopoverHeader>
                                            <PopoverBody className="dark-style fs-n2">Akumulasi dari saldo dompet + pendanaan berjalan (dalam proses) + pendanaan outstanding <br />  <br /> <b className="font-italic">klik untuk informasi detil</b></PopoverBody>
                                        </Popover>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-5">
                        <p className="text-secondary m-0" style={{ fontSize: '14px' }}> Terakhir Update {moment(this.props.lender.lenderPort.date).format("DD/MM/YYYY h:mm")}</p>
                        <div className="position-relative d-flex justify-content-center align-items-center m-0" >
                            <div className="position-absolute cursor-pointer d-flex justify-content-center align-items-center" style={{gap:'0.2rem'}} onClick={this.datepickerTrigger}>
                                <TuneOutline onClick={this.datepickerTrigger} className="material-icons-outlined" sx={{ cursor: 'pointer', zIndex: '99', transform: 'rotate(90deg)' }} fontSize="small" />

                                <p onClick={this.datepickerTrigger} style={{ cursor: 'pointer', zIndex: 99, margin: 0, fontSize: '14px', fontWeight: 'bold' }}>{this.state.selectedDate ? moment(this.state.selectedDate).format("DD, MMM YYYY") : 'Atur Pertanggal'}</p>
                            </div>
                            <div style={{ maxWidth: '180px' }}>
                                <DatePicker
                                    ref={this.datePickerRef}
                                    className="form-control custom-date-input"
                                    autoComplete="off"
                                    isClearable
                                    dateFormat="yyyy-MM-dd"
                                    selected={this.state.selectedDate}
                                    onChange={this.onDateChange}
                                    peekNextMonth
                                    maxDate={new Date()}

                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    name="tgl_lahir"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-0">
                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                            <div
                                className="card border-0 shadow
                            card-statistic">
                                <div className="card-body d-flex flex-column gap-1">
                                    <p className="text-secondary d-flex align-items-center gap-1 mb-0"> Total Profit {" "}
                                        <span className="d-flex justify-content-center align-items-center" id="Popovers" data-html="true" onMouseEnter={this.onHoverProfit} onMouseLeave={this.onHoverProfitLeave}>
                                            <i className="material-icons-outlined text-primary" style={{ fontSize: "16px" }}>
                                                info
                                            </i>
                                        </span>
                                        <Popover
                                            // className="d-none d-sm-block"
                                            placement="bottom"
                                            isOpen={this.state.popoverProfit}

                                            target="Popovers"
                                            toggle={this.toggleInfoProfit.bind(this)}
                                        >

                                            <PopoverHeader className="popover-header dark-style" ></PopoverHeader>
                                            <PopoverBody className="dark-style fs-n2">Total profit adalah total keuntungan yang Anda dapat setelah mendanai project</PopoverBody>
                                        </Popover>
                                    </p>
                                    <h4 className="font-weight-semi-bold mb-0">
                                        {this.props.lender.isLoadingLendPort ? (
                                            <Skeleton />
                                        ) : (
                                            <NumberFormat
                                                value={this.state.totalBunga}
                                                displayType={"text"}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                decimalScale={0}
                                                prefix={"Rp "}
                                                renderText={(value) => <span>{value}</span>}
                                            />
                                        )}
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                            <div
                                className="card border-0 shadow
                            card-statistic">
                                <div className="card-body d-flex flex-column gap-1">
                                    <p className="text-secondary d-flex align-items-center gap-1 mb-0"> Pendanaan Selesai {" "}
                                        <span className="d-flex justify-content-center align-items-center" id="Popovers3" data-html="true" onMouseEnter={this.onHoverDone} onMouseLeave={this.onHoverLeaveDone}>
                                            <i className="material-icons-outlined text-primary" style={{ fontSize: "16px" }}>
                                                info
                                            </i>
                                        </span>
                                        <Popover
                                            // className="d-none d-sm-block"
                                            placement="bottom"
                                            isOpen={this.state.popoverDone}
                                            target="Popovers3"
                                            toggle={this.toggleInfoDone.bind(this)}>
                                            <PopoverHeader className="popover-header dark-style" ></PopoverHeader>
                                            <PopoverBody className="dark-style fs-n2">Pendanaan selesai adalah nominal total proyek selesai yang Anda danai</PopoverBody>
                                        </Popover>
                                    </p>
                                    <h4 className="font-weight-semi-bold mb-0">
                                        {this.props.lender.isLoadingLendPort ? (
                                            <Skeleton />
                                        ) : (
                                            <NumberFormat
                                                value={this.state.selesaiAmount}
                                                displayType={"text"}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                decimalScale={0}
                                                prefix={"Rp "}
                                                renderText={(value) => <span>{value}</span>}
                                            />
                                        )}
                                    </h4>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <ModalAsset isOpen={this.state.isOpenModal} toggle={this.toggleOpenModal.bind(this)} balanceAmount={this.state.balanceAmount} outstandingAmount={this.state.berjalanAmount} berjalanAmount={this.state.pendingAmount} totalAmount={this.state.asetAmount} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lender: state.lender
    };
};
export default connect(mapStateToProps)(PortoInfo);
