import React, { Component } from "react";

class CardTeam extends Component {
    render() {
        return (
            <div className="col-12 col-lg-4 p-2">
                <div className="card team-card border-0">
                    <img className="card-img" src={process.env.PUBLIC_URL + "/images/tentang-kami/team/" + this.props.gambar} alt="pict tim" />
                    <div className="card-img-overlay">
                        <div className="team-profile">
                            <div className="no-overflow">
                                <p className="card-text name">{this.props.nama}</p>
                                <p className="card-text title pt-0">{this.props.jabatan}</p>
                                <p className="card-text desc">{this.props.content}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default CardTeam;
