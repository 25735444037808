import React, { Component } from "react";
import TanpaBiaya from "./TanpaBiaya";
import TotalBiaya from "./TotalBiaya";
import "./SukuBunga.scss";
import CalculatorBunga from "./CalculatorBunga";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import CalculatorBungaPenerimaDana from "./CalculatorBungaPenerima";

class SukuBunga extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "2",
    }
    this.toggle = this.toggle.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render() {
    return (
      <div className="container pt-5 mt-5">
        <TanpaBiaya />
        <TotalBiaya />
        <div className="d-flex justify-content-center mb-5">
          <navigation>
            <Nav tabs className="over-hidden">
              <NavItem className="flex-fill">
                <NavLink
                  className={classnames({ active: this.state.activeTab === "1" })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                  style={{ cursor: "pointer" }}>
                  Simulasi Pemberi Dana
                </NavLink>
              </NavItem>
              <NavItem className="flex-fill">
                <NavLink
                  className={classnames({ active: this.state.activeTab === "2" })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                  style={{ cursor: "pointer" }}>
                  Simulasi Penerima Dana
                </NavLink>
              </NavItem>
            </Nav>
          </navigation>
        </div>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <CalculatorBunga />
          </TabPane>
          <TabPane tabId="2">
          <CalculatorBungaPenerimaDana/>
          </TabPane>
        </TabContent>

      </div>
    );
  }
}
export default SukuBunga;
