import React, { Component } from "react";
import "./TotalBiaya.scss";

class TotalBiaya extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <section id="total-biaya">
                <div className="container">
                    <div className="col">
                        <div className="container-section">
                            <h4 className="text-title-suku">Total biaya</h4>
                            <p className="text-suku">
                                Sesuai dengan Kode Etik Asosiasi Fintech Pendanaan Bersama Indonesia (AFPI), total biaya pendanaan yang dibebankan
                                kepada Penerima dana tidak akan melebihi tingkat bunga tetap sebesar 0,40% per hari dihitung dari jumlah uang yang
                                diterima oleh Penerima dana. Simulasi beban biaya maksimum dan minimum dapat dilihat contoh di bawah ini:
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-xs-12">
                                <h5 className="text-title">Simulasi Pendanaan</h5>
                                <br />
                                <p className="subtext">
                                    Pokok Pendanaan : Rp. 10,000,000 <br />
                                    Tenor : 1 Bulan
                                </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-xs-12">
                                <h5 className="text-title">Beban Biaya</h5>
                                <br />
                                <p className="subtext">
                                    Bunga : <span className="text-green">10%</span> - <span className="text-red">36%</span> efektif per tahun <br />
                                    Biaya admin : <span className="text-green">3%</span> - <span className="text-red">5%</span> per pendanaan <br />
                                </p>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <h5 className="text-header-table text-green">Simulasi Biaya Terendah</h5>
                                    </th>
                                    <th>
                                        <h5 className="text-header-table text-red">Simulasi Biaya Tertinggi</h5>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <h6 className="text-center">
                                            <span className="text-green">Rp. 9,700,000 </span>
                                        </h6>
                                        <h6 className="text-center">Nilai uang yang diterima Penerima Pendanaan</h6>
                                    </td>
                                    <td>
                                        <h6 className="text-center">
                                            <span className="text-red">Rp. 9,500,000</span>
                                        </h6>
                                        <h6 className="text-center">Nilai uang yang diterima Penerima Pendanaan</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6 className="text-center">
                                            <span className="text-green">Rp. 10,083,333 </span>
                                        </h6>
                                        <h6 className="text-center">Nilai uang yang harus dikembalikan</h6>
                                    </td>
                                    <td>
                                        <h6 className="text-center">
                                            <span className="text-red">Rp. 10,300,000</span>
                                        </h6>
                                        <h6 className="text-center">Nilai uang yang harus dikembalikan</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6 className="text-center">
                                            <span className="text-green">Rp. 383,333 </span>
                                        </h6>
                                        <h6 className="text-center">Total Cost</h6>
                                    </td>
                                    <td>
                                        <h6 className="text-center">
                                            <span className="text-red">Rp. 800,000</span>
                                        </h6>
                                        <h6 className="text-center">Total Cost</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6 className="text-center">
                                            <span className="text-green">0.04%</span>
                                        </h6>
                                        <h6 className="text-center">Persentase total cost/hari</h6>
                                    </td>
                                    <td>
                                        <h6 className="text-center">
                                            <span className="text-red">0.08%</span>
                                        </h6>
                                        <h6 className="text-center">Persentase total cost/hari</h6>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        );
    }
}
export default TotalBiaya;
