const url = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyD5VMS1roRZtoR5tUeJmvta8DuNOfN-API";

export const postLinkUrl = async (email, documentId, notif) => {
    const data = {
        dynamicLinkInfo: {
            domainUriPrefix: "https://indofundid.page.link",
            link:
                process.env.REACT_APP_STAGING === "dev"
                    ? `https://develop-pub.indofund.id/success/redirect?email=${email}&documentId=${documentId}&notif=${notif}`
                    : `https://indofund.id/success/redirect?email=${email}&documentId=${documentId}&notif=${notif}`,
            androidInfo: {
                androidPackageName: "com.indofund.indofund"
            },
            iosInfo: {
                iosBundleId: "com.indofund.bai",
                iosAppStoreId: "1613671081"
            },
            navigationInfo: {
                enableForcedRedirect: true
            }
        },
        suffix: {
            option: "UNGUESSABLE"
        }
    };
    try {
        const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Content-Type": "application/json"
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(data)
        });
        // console.log(response.json());
        return response.json();
    } catch (error) {
        console.log(error);
        return error;
    }
};
