import React from "react";
import NumberFormat from "react-number-format";

const PilihBayar = (props) => {
    const showingDipaySaldo = () => {
        if (props.dipayKey === undefined) {
            return (
                <a href="#section" onClick={props.dipayAktivasi} className="text-primary">
                    Aktivasi
                </a>
            );
        } else {
            return (
                <NumberFormat
                    value={!props.saldoDipay ? "0" : props.saldoDipay}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"Rp "}
                    renderText={(value) => (
                        <p className="text-secondary mb-0">
                            Saldo Anda <span className="font-weight-semi-bold">{value}</span>
                        </p>
                    )}
                />
            );
        }
    };

    const showingSaldo = () => {
        if (props.judul === "Indofund") {
            return (
                <NumberFormat
                    value={props.saldo}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"Rp "}
                    renderText={(value) => (
                        <p className="text-secondary mb-0">
                            Saldo Anda <span className="font-weight-semi-bold">{value}</span>
                        </p>
                    )}
                />
            );
        } else {
            return showingDipaySaldo();
        }
    };
    return (
        <div style={{ marginBottom: "22px" }}>
            <label className="card border-0 shadow-sm payment-method">
                <div className="card-body">
                    <div className="d-flex align-items-center">
                        <div className="radio-wrapper">
                            <div className="custom-control custom-radio">
                                <input
                                    type="radio"
                                    id="method-1"
                                    value="wallet"
                                    name="payment_method"
                                    className="custom-control-input"
                                    onChange={props.onChange}
                                    defaultChecked={props.defaultChecked}
                                />
                                <label className="custom-control-label" htmlFor="method-1"></label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center flex-fill">
                            <div>
                                <p className="fs-1 mb-1 font-weight-semi-bold">Saldo {props.judul}</p>
                                {showingSaldo()}
                            </div>
                            <img className="payment-logo d-none d-sm-block" width="100" src={props.img} alt="logo" />
                        </div>
                    </div>
                </div>
            </label>
        </div>
    );
};

export default PilihBayar;
