import React, { Component } from "react";
import "./Event.scss";

class Eventbox extends Component {
    render() {
        return (
            <div className="col-md-7 box">
                <div className="row">
                    <div className="col-md-2 col-2 text-center">
                        <h4 className="sec-title">{this.props.tgl}</h4>
                        {this.props.bulanTahun}
                    </div>
                    <div className="col-md-10 col-10 nama-event">
                        <h4 className="sec-title">{this.props.namaEvent}</h4>
                        <div className="location">{this.props.tempatEvent}</div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Eventbox;
