import React, { Component } from "react";
import { AllProject } from "../../../redux/action/Project";
import { connect } from "react-redux";
import { LoadingDasboard } from "../../assets/loading data/LoadingLender";
import { namaSektor, sukuBunga, satuanJWP } from "../../../library/helper/helper";
import NoProyekWelcome from "../../assets/no data/NoProyekWelcome";
import Pagination from "react-js-pagination";

class CPendanaan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: [],
            currentPage: 1,
            todosPerPage: 15,
            filter: ""
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(pageNumber) {
        this.setState({
            currentPage: pageNumber
        });
    }
    componentDidMount = async () => {
        await this.props.dispatch(AllProject());
        this.setState({
            project: this.props.project.projectAll
        });
    };

    Proyek = () => {
        const { currentPage, todosPerPage } = this.state;
        const { filter } = this.state;
        // Logic for displaying page numbers
        const lenght = this.state.project.filter((item) => item.status_proyek === "Menggalang dana").length;

        //sorting descending
        function compare(a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
        }
        const lowercasedFilter = filter.toLowerCase();
        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const currentTodos = this.state.project
            .filter((item) => item.status_proyek === "Menggalang dana")
            .sort(compare)
            .slice(indexOfFirstTodo, indexOfLastTodo);
        if (currentTodos !== 0 && lenght !== 0) {
            const hasil = currentTodos
                .filter((item) => {
                    return Object.keys(item).some((key) => item[key].toString().toLowerCase().includes(lowercasedFilter));
                })
                .map((val) => {
                    const sisa = val.nilai_pengajuan_pinjaman - val.nilai_total_peminjam;
                    const progressBar = (val.nilai_total_peminjam / val.nilai_pengajuan_pinjaman) * 100;
                    return (
                        <a href={"/proyek/" + val._id} key={val._id} className="link-text">
                            <div className="transaction p-3">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-xl-8 mb-3 mb-xl-0">
                                                <div className="row">
                                                    <div className="col-6 col-md-4 mb-3">
                                                        <p className="fs-n1 text-secondary mb-1">Nama Proyek</p>
                                                        <p className="mb-0">{val.nama_pinjaman}</p>
                                                    </div>
                                                    <div className="col-6 col-md-4 mb-3">
                                                        <p className="fs-n1 text-secondary mb-1">Tenor</p>
                                                        <p className="mb-0">
                                                            {val.jangka_waktu_pinjaman} {satuanJWP(val.satuan_jangka_waktu_pinjaman)}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 col-md-4 mb-3">
                                                        <p className="fs-n1 text-secondary mb-1">Bunga</p>
                                                        <p className="mb-0">
                                                            {sukuBunga(
                                                                val.tipe_pinjaman,
                                                                val.jenis_pinjaman,
                                                                val.suku_bunga_pinjaman,
                                                                val.suku_bunga_konsumtif,
                                                                val.suku_bunga_micro,
                                                                val.spread_bunga
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 col-md-4 mb-3 mb-xl-0">
                                                        <p className="fs-n1 text-secondary mb-1">Sektor Usaha</p>
                                                        <p className="mb-0">{namaSektor(val.penggunaan_pinjaman)}</p>
                                                    </div>
                                                    <div className="col-6 col-md-4 mb-3 mb-xl-0">
                                                        <p className="fs-n1 text-secondary mb-1">Status Proyek</p>
                                                        <p className="mb-0">{val.status_proyek}</p>
                                                    </div>
                                                    <div className="col-6 col-md-4 mb-3 mb-xl-0">
                                                        <p className="fs-n1 text-secondary mb-1">Sisa Pendanaan</p>
                                                        <p className="mb-0">Rp. {sisa.toLocaleString("IN")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-xl-4 progress-divider">
                                                <div className="progress mb-3 mt-0">
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{ width: `${parseInt(progressBar)}%` }}>
                                                        <div className="text-progress-bar">{parseInt(progressBar)}%</div>
                                                    </div>
                                                </div>
                                                <div className="row text-information">
                                                    <div className="col-6">
                                                        <p className="fs-n1 text-secondary mb-1">Terkumpul</p>
                                                        <p className="mb-0 font-weight-semi-bold text-primary">
                                                            Rp. {val.nilai_total_peminjam.toLocaleString("IN")}
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p className="fs-n1 text-secondary mb-1">Plafon</p>
                                                        <p className="mb-0 font-weight-semi-bold">
                                                            Rp. {val.nilai_pengajuan_pinjaman.toLocaleString("IN")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    );
                });
            return hasil;
        } else {
            return <NoProyekWelcome />;
        }
    };

    loading = () => {
        return (
            <div>
                <LoadingDasboard />
                <LoadingDasboard />
            </div>
        );
    };
    render() {
        // Logic for displaying page numbers
        const lenght = this.state.project.filter((item) => item.status_proyek === "Menggalang dana").length;

        return (
            <div>
                {this.props.project.isLoadingAllProject ? this.loading() : this.Proyek()}
                <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        hideDisabled
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.currentPage}
                        itemsCountPerPage={this.state.todosPerPage}
                        totalItemsCount={parseInt(lenght)}
                        onChange={this.handleClick.bind(this)}
                    />
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        project: state.project
    };
};
export default connect(mapStateToProps)(CPendanaan);
