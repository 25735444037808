import React, { Component } from "react";
import { Form, FormGroup, Label, Input, CustomInput } from "reactstrap";
import { SelectOptionSearchs } from "../../../../../../library/components/Components";

class Micro extends Component {
    render() {
        return (
            <Form>
                <h4 className="text-center mb-4 d-block d-sm-none font-weight-bold">Informasi Toko</h4>
                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="lokasi_pasar">Lokasi Pasar</Label>
                            <Input
                                onChange={this.props.onChange}
                                type="select"
                                name="lokasi_pasar"
                                id="exampleSelect"
                                value={this.props.lokasi_pasar}>
                                <option>-- Pilih Lokasi Pasar --</option>
                                {this.props.lokasi}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="exampleFile">Upload Surat Keterangan Usaha</Label>
                            <CustomInput
                                type="file"
                                onChange={this.props.handleUploadFile}
                                name="surat_keterangan_usaha"
                                id="exampleFile"
                                label={
                                    this.props.surat_keterangan_usaha.name !== undefined ? this.props.surat_keterangan_usaha.name : "Upload File PDF"
                                }
                                accept="application/pdf"
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <SelectOptionSearchs
                            forLabel={"sektor_usaha"}
                            label={"Sektor Usaha"}
                            onChange={this.props.onChangeS}
                            data={this.props.sektorList}
                            value={{ value: this.props.sektor_usaha, label: this.props.sektorLabel }}
                        />
                    </div>
                    <div className="col-md-12">
                        <FormGroup>
                            <Label for="exampleText">Deskripsi Usaha</Label>
                            <Input
                                type="textarea"
                                onChange={this.props.onChange}
                                rows="8"
                                name="deskripsi_usaha"
                                id="exampleText"
                                value={this.props.deskripsi_usaha}
                            />
                        </FormGroup>
                    </div>
                </div>
            </Form>
        );
    }
}
export default Micro;
