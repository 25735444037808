import axios from "axios";

// const url = `http://localhost:3000`
const url = process.env.REACT_APP_BASSE_API;

export const MainProject = () => {
    return {
        type: "GET_MAINPROJECT",
        payload: axios.get(`${url}/project/main-project-list`)
    };
};

export const EmployeeProject = () => {
    return {
        type: "GET_EMPLOYEEPROJECT",
        payload: axios.get(`${url}/project/main-employee-loan-list`)
    };
};

export const AllProject = () => {
    return {
        type: "GET_ALLPROJECT",
        payload: axios.get(`${url}/project/loan-list`, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const getProject = (id) => {
    return {
        type: "GET_PROJECT_ID",
        payload: axios.get(`${url}/project/project-details/${id}`, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const getInterestLend = (id, nominal) => {
    return {
        type: "GET_PROJECT_INTEREST",
        payload: axios.get(`${url}/project/interest?id_pinjaman=${id}&nilai_transaksi=${nominal}`, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const getProjectByBorrower = (id) => {
    return {
        type: "GET_PROJECT_BORROWER_ID",
        payload: axios.get(`${url}/project/borrower/${id}`, {
            headers: { "x-auth-token": localStorage.jwtToken}
           
        })
    };
};
export const getProjectByBorrowerSigned = (params) => {
    return {
        type: "GET_PROJECT_BORROWER_ID_SIGNED",
        payload: axios.get(`${url}/borrower/sign`, {
            headers: { "x-auth-token": localStorage.jwtToken},
            params:params
        })
    };
};
export const getProjectByBorrowerSign = (params) => {
    return {
        type: "GET_PROJECT_BORROWER_ID_SIGN",
        payload: axios.get(`${url}/borrower/sign`, {
            headers: { "x-auth-token": localStorage.jwtToken},
            params:params
        })
    };
};

export const createPengajuan = (data) => {
    return {
        type: "CREATE_PENGAJUAN",
        payload: axios.post(`${url}/project/create`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};
