import React, { Component } from "react";
import { postLinkUrl } from "../../../../library/helper/firebase";
import { isMobileCheck } from "../../../../library/helper/helper";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";

const queryString = require("query-string");
const axios = require("axios").default;
const url = process.env.REACT_APP_BASSE_API;

class Register extends Component {
    async componentDidMount() {
        try {
            const values = queryString.parse(this.props.location.search);
            const { msg } = values;

            // decript from query digisign
            const decript = await axios.post(`${url}/digisign/decrypt`, { base64: msg });
            const { email_user, document_id = "", notif } = decript.data.result;
            const isMobile = isMobileCheck();

            // aktivasi digisign
            await axios.post(`${url}/digisign/aktivasi`, { email_user: email_user, document_id: document_id }, {});
            if (isMobile && !localStorage.jwtToken && msg) {
                let notifMessage = "";
                notif.includes("Aktivasi") ? (notifMessage = "aktivasi") : (notifMessage = "ttd");
                const pattFireBase = await postLinkUrl(email_user, document_id, notifMessage);
                window.location.replace(pattFireBase.shortLink);
            } else {
                if (localStorage.userAktivasi === "true") {
                    this.props.history.push(localStorage.currentPage);
                    localStorage.setItem("berhasilTtd", "true");
                } else {
                    this.props.history.push("/");
                }
            }
        } catch (error) {
            Swal.fire({
                title: "Opps",
                text: "Aktivasi/Pendanaan terjadi kesalahan harap menghubungi Customer Service Indofund.id",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Kembali Ke Home"
            }).then(async (result) => {
                if (result.value) {
                    this.props.history.push("/");
                }
            });
        }
        //     // if (data.data.status === 200) {
        //     // axios.post(
        //     //   `${url}/user/logout`,
        //     //   { accessToken: localStorage.accessToken },
        //     //   {
        //     //     headers: { "x-auth-token": localStorage.jwtToken },
        //     //   }
        //     // );

        //     // localStorage.removeItem('id')
        //     // localStorage.removeItem('jwtToken')
        //     // localStorage.removeItem('email')
        //     // localStorage.removeItem('id_penyelenggara')
        //     // localStorage.removeItem('kode_pengguna')
        //     // localStorage.removeItem('tgl_registrasi')
        //     // localStorage.removeItem('status_pengguna')
        //     // localStorage.removeItem('nama_pengguna')
        //     // localStorage.removeItem('id_pengguna')
        //     // localStorage.removeItem('bank')
        //     // localStorage.removeItem('jenis_kelamin')
        //     // localStorage.removeItem('justOnce')
        //     // localStorage.removeItem('masuk')
        //     // localStorage.removeItem('refreshToken')
        //     // } else {
        //     //   console.log("END");
        //     //   // this.props.history.push("/");

        //     // }
        //   }
        // }
    }
    render() {
        return (
            <div style={{ padding: "8rem 0 4rem 0" }} className="">
                <div className="container" style={{ marginTop: "80px" }}>
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <Spinner animation="grow" variant="primary" style={{ width: "4rem", height: "4rem" }} />
                        <div style={{ paddingBottom: "16px" }} />
                        <p className="text text-center">
                            Mohon Tunggu. <br /> Permintaan anda sedang kami proses
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Register;
