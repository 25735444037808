import React, { Component } from "react";
import { Link } from "react-router-dom";

class Step extends Component {
    render() {
        return (
            <div className="d-flex flex-column h-100">
                <div>
                    <div className="d-flex progress-wrapper">
                        <div className="progress-dot">
                            <span className="step-num">1</span>
                        </div>
                        <div>
                            <h5 className="progress-title">{this.props.title1}</h5>
                            <p className="progress-desc">{this.props.body1}</p>
                        </div>
                    </div>
                    <div className="d-flex progress-wrapper">
                        <div className="progress-dot">
                            <span className="step-num">2</span>
                        </div>
                        <div>
                            <h5 className="progress-title">{this.props.title2}</h5>
                            <p className="progress-desc">{this.props.body2}</p>
                        </div>
                    </div>
                    <div className="d-flex progress-wrapper">
                        <div className="progress-dot">
                            <span className="step-num">3</span>
                        </div>
                        <div>
                            <h5 className="progress-title">{this.props.title3}</h5>
                            <p className="progress-desc">{this.props.body3}</p>
                        </div>
                    </div>
                </div>
                <div className="mt-auto">
                    <div className="d-flex flex-column flex-md-row">
                        <Link to="/pilihuser" className="btn btn-primary btn-block btn-lg mr-md-2">
                            {this.props.namebutton}
                        </Link>
                        <Link
                            to={this.props.link ? this.props.link : "/faq"}
                            className="btn btn-outline-primary btn-block btn-lg ml-md-2 mt-3 mt-md-0">
                            {this.props.secondButton ? this.props.secondButton : "Pelajari Lebih Lanjut"}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Step;
