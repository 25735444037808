import React from "react";

const Hand = () => {
    return (
        <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <circle cx="68.375" cy="35.25" r="23.25" fill="#FFE7D9" />
                <path
                    d="M47.2373 27.4922C47.2373 42.6514 59.5703 54.9854 74.7295 54.9854C89.8887 54.9854 102.222 42.6514 102.222 27.4922C102.222 12.333 89.8887 0 74.7295 0C59.5703 0 47.2373 12.333 47.2373 27.4922ZM97.2237 27.4922C97.2237 39.8955 87.1328 49.9863 74.7295 49.9863C62.3262 49.9863 52.2354 39.8955 52.2354 27.4922C52.2354 15.0898 62.3262 4.99903 74.7295 4.99903C87.1328 4.99903 97.2237 15.0898 97.2237 27.4922Z"
                    fill="#FF7A00"
                />
                <path
                    d="M71.9205 12.0092C71.9205 12.9659 72.7974 13.679 73.7523 13.6186C74.0423 13.6002 74.3333 13.5909 74.6249 13.5909C82.2951 13.5909 88.5341 19.8299 88.5341 27.5C88.5341 30.1879 87.7473 32.8235 86.2958 35.0644C85.8316 35.7809 85.9198 36.7511 86.5801 37.2923C87.2402 37.8333 88.2204 37.7403 88.6989 37.0335C90.5944 34.2331 91.625 30.9009 91.625 27.5C91.625 18.1268 83.9982 10.5 74.6249 10.5C74.1488 10.5 73.674 10.5202 73.2018 10.5602C72.4601 10.6229 71.9205 11.2649 71.9205 12.0092Z"
                    fill="#FF7A00"
                />
                <path
                    d="M60.7159 27.5C60.7159 35.1701 66.9549 41.4091 74.6251 41.4091C77.3129 41.4091 79.9486 40.6223 82.1894 39.1708C82.906 38.7067 83.8761 38.7948 84.4173 39.4551C84.9584 40.1152 84.8654 41.0955 84.1586 41.5739C81.3581 43.4694 78.0259 44.5 74.6251 44.5C65.2518 44.5 57.625 36.8732 57.625 27.5C57.625 24.0991 58.6557 20.7669 60.5512 17.9665C61.0296 17.2597 62.0098 17.1667 62.6699 17.7077C63.3302 18.2489 63.4184 19.2191 62.9542 19.9356C61.5027 22.1765 60.7159 24.8121 60.7159 27.5Z"
                    fill="#FF7A00"
                />
                <ellipse cx="67.25" cy="14" rx="1.875" ry="2" fill="#FF7A00" />
                <path
                    d="M75.1474 30.264C75.2577 30.4326 75.3128 30.5964 75.3128 30.7556C75.3128 30.971 75.2255 31.1489 75.0509 31.2893C74.8856 31.4298 74.688 31.5 74.4584 31.5C74.3114 31.5 74.169 31.4672 74.0312 31.4017C73.9025 31.3268 73.7923 31.2191 73.7004 31.0787L71.8676 28.2416C71.6838 27.942 71.4863 27.7313 71.275 27.6096C71.0729 27.4878 70.8064 27.427 70.4757 27.427H69.1252V30.573C69.1252 30.8539 69.0471 31.0787 68.8909 31.2472C68.7347 31.4064 68.5234 31.486 68.257 31.486C67.9905 31.486 67.7746 31.4064 67.6093 31.2472C67.4531 31.0787 67.375 30.8539 67.375 30.573V22.3567C67.375 22.0852 67.4485 21.8745 67.5955 21.7247C67.7517 21.5749 67.963 21.5 68.2294 21.5H71.6746C72.7771 21.5 73.6131 21.7528 74.1828 22.2584C74.7615 22.7547 75.0509 23.4757 75.0509 24.4214C75.0509 25.1891 74.835 25.8212 74.4032 26.3174C73.9806 26.8043 73.3789 27.118 72.5979 27.2584C72.8644 27.324 73.0987 27.441 73.3008 27.6096C73.5029 27.7781 73.7004 28.0122 73.8934 28.3118L75.1474 30.264ZM71.4266 26.0365C72.0972 26.0365 72.5888 25.9101 72.9011 25.6573C73.2135 25.3951 73.3697 24.9972 73.3697 24.4635C73.3697 23.9204 73.2135 23.5272 72.9011 23.2837C72.5888 23.0309 72.0972 22.9045 71.4266 22.9045H69.1114V26.0365H71.4266Z"
                    fill="#FF7A00"
                />
                <path
                    d="M80.8846 24.3652C81.4634 24.3652 81.9778 24.515 82.428 24.8146C82.8874 25.1142 83.2411 25.5403 83.4891 26.0927C83.7464 26.6358 83.875 27.2631 83.875 27.9747C83.875 28.6863 83.7464 29.309 83.4891 29.8427C83.2411 30.3764 82.892 30.7884 82.4418 31.0787C81.9916 31.3689 81.4725 31.514 80.8846 31.514C80.4068 31.514 79.9796 31.4111 79.6029 31.2051C79.2263 30.9991 78.9369 30.7135 78.7347 30.3483V33.2135C78.7347 33.4569 78.6567 33.6489 78.5005 33.7893C78.3443 33.9298 78.1376 34 77.8803 34C77.6231 34 77.4118 33.9251 77.2464 33.7753C77.0902 33.6255 77.0121 33.4288 77.0121 33.1854V25.2219C77.0121 24.9691 77.0856 24.7678 77.2326 24.618C77.3888 24.4682 77.5955 24.3933 77.8528 24.3933C78.11 24.3933 78.3167 24.4682 78.4729 24.618C78.6291 24.7678 78.7072 24.9691 78.7072 25.2219V25.573C78.9093 25.1891 79.1987 24.8942 79.5754 24.6882C79.9612 24.4728 80.3976 24.3652 80.8846 24.3652ZM80.4298 30.1517C80.9718 30.1517 81.3945 29.9644 81.6976 29.5899C82.0008 29.206 82.1524 28.6676 82.1524 27.9747C82.1524 27.2631 82.0008 26.7107 81.6976 26.3174C81.3945 25.9242 80.9718 25.7275 80.4298 25.7275C79.8786 25.7275 79.4513 25.9195 79.1482 26.3034C78.8542 26.6873 78.7072 27.235 78.7072 27.9466C78.7072 28.6489 78.8542 29.192 79.1482 29.5758C79.4513 29.9597 79.8786 30.1517 80.4298 30.1517Z"
                    fill="#FF7A00"
                />
                <path
                    d="M29.0379 127.261C30.0066 128.238 31.5838 128.248 32.5652 127.282L44.6824 115.344C46.741 113.285 47.367 110.238 46.4617 107.588L49.0681 105.074C50.4715 103.719 52.3191 102.972 54.2722 102.972H87.4979C93.3924 102.972 98.9637 100.708 103.187 96.5979C103.361 96.429 101.868 98.1878 125.854 69.5198C129.412 65.3108 128.88 58.9905 124.667 55.429C120.48 51.9007 114.209 52.4075 110.641 56.5442L95.8934 71.7024C94.0311 69.4104 91.1912 67.9817 88.1229 67.9817H60.2527C56.2869 66.3225 52.0789 65.4827 47.7371 65.4827C35.7019 65.4827 25.1931 71.0413 19.5535 81.4632C17.1775 81.013 14.6716 81.7317 12.8689 83.5345L0.97925 95.4593C0.00854638 96.4329 0.00659338 98.0081 0.975344 98.9846L29.0379 127.261ZM47.7361 70.4807C51.5642 70.4807 55.2654 71.2512 58.739 72.7708C59.0555 72.9094 59.3963 72.9807 59.741 72.9807H88.1229C90.8328 72.9807 93.1219 75.1907 93.1219 77.9788C93.1219 80.7356 90.8797 82.9778 88.1229 82.9778H67.7312C66.3514 82.9778 65.2322 84.097 65.2322 85.4768C65.2322 86.8577 66.3514 87.9759 67.7312 87.9759H88.1229C93.6356 87.9759 98.1199 83.4915 98.1199 77.9788C98.1199 77.5393 98.0887 77.1038 98.032 76.6721C112.299 62.0071 114.31 59.9466 114.396 59.844C116.178 57.7415 119.34 57.4749 121.443 59.2483C123.549 61.0286 123.815 64.1887 122.029 66.303L99.6121 93.1018C96.3387 96.2444 92.0408 97.9739 87.4979 97.9739H54.2722C51.0164 97.9739 47.9344 99.219 45.5965 101.478L43.4724 103.528L23.8923 83.9485C28.4685 75.3636 37.0554 70.4807 47.7361 70.4807ZM16.406 87.0667C17.2302 86.2425 18.4959 86.094 19.5007 86.7151C19.9334 86.9788 18.6912 85.8479 41.1482 108.274C42.1472 109.273 42.0935 110.865 41.1619 111.796L30.8318 121.973L6.27418 97.2278L16.406 87.0667Z"
                    fill="#FF7A00"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="127.75" height="128" fill="white" transform="translate(0.125)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Hand;
