import React, { Component } from "react";
import classnames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";

class DasborNav extends Component {
    render() {
        return (
            <nav>
                <Nav tabs className="over-hidden">
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.props.activeTab === "1" })}
                            onClick={() => {
                                this.props.toggle("1");
                            }}
                            style={{ cursor: "pointer" }}>
                            Pending
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.props.activeTab === "2" })}
                            onClick={() => {
                                this.props.toggle("2");
                            }}
                            style={{ cursor: "pointer" }}>
                            Aktif
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.props.activeTab === "3" })}
                            onClick={() => {
                                this.props.toggle("3");
                            }}
                            style={{ cursor: "pointer" }}>
                            Selesai
                        </NavLink>
                    </NavItem>
                </Nav>
            </nav>
        );
    }
}
export default DasborNav;
