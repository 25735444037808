import React, { Component } from "react";
import { connect } from "react-redux";
import { createPengajuan } from "../../../../redux/action/Project";
import { validate } from "../../../../library/validation/validate";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { InputIcon, InputNominal, InputTextArea, UploadDoc } from "../../../../library/components/Components";
import Swal from "sweetalert2";
import "../../../../sass/loader.css";

class AjukanPinjaman extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jenis_pinjaman: "1",
            nominal: "",
            errNominal: "",
            nilai_permohonan_pinjaman: "",
            jangka_waktu_pinjaman: "",
            dokumen_pendukung: "",
            errNumber: "",
            errString: "",
            pemberi_kerja: "",
            file: ""
        };
    }

    handleChangeJenis = (e) => {
        this.setState({
            jenis_pinjaman: e.target.value
        });
        // console.log(e.target.value)
    };

    handleChange = (e, type, changeError) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value) });
        });
    };

    handleDokumen = (e) => {
        let file = e.target.files[0];
        this.setState({
            dokumen_pendukung: file
        });
    };

    handleSave = async () => {
        const { nilai_permohonan_pinjaman, jangka_waktu_pinjaman, tujuan_permohonan_pinjaman, dokumen_pendukung, pemberi_kerja } = this.state;

        if (
            this.state.jenis_pinjaman === "1" &&
            (!nilai_permohonan_pinjaman || !jangka_waktu_pinjaman || !tujuan_permohonan_pinjaman || !dokumen_pendukung)
        ) {
            Swal.fire({
                title: "Data masih kosong",
                text: "Data Anda belum lengkap mohon di cek kembali",
                icon: "warning",
                button: "ok"
            });
        } else if (
            this.state.jenis_pinjaman === "2" &&
            (!nilai_permohonan_pinjaman || !jangka_waktu_pinjaman || !tujuan_permohonan_pinjaman || !dokumen_pendukung || !pemberi_kerja)
        ) {
            Swal.fire({
                title: "Data masih kosong",
                text: "Data Anda belum lengkap mohon di cek kembali",
                icon: "warning",
                button: "ok"
            });
        } else {
            var nilaipermohonan = nilai_permohonan_pinjaman.replace(/\D/g, "").replace(/^0+/, "");

            const formData = new FormData();
            formData.append("nilai_permohonan_pinjaman", parseInt(nilaipermohonan));
            formData.append("jangka_waktu_pinjaman", parseInt(jangka_waktu_pinjaman));
            formData.append("tujuan_permohonan_pinjaman", tujuan_permohonan_pinjaman);
            formData.append("pemberi_kerja", pemberi_kerja);
            formData.append("pdf", dokumen_pendukung);

            if (this.state.jenis_pinjaman === "1") {
                formData.append("tipe_pinjaman", "Invoice Financing");
            } else {
                formData.append("tipe_pinjaman", "Project Financing");
            }
            await this.props
                .dispatch(createPengajuan(formData))
                .then(() => {
                    Swal.fire({
                        title: "Anda berhasil mengajukan pinjaman",
                        text: "Mohon tunggu tim terkait memproses pengajuan pinjaman anda",
                        icon: "success",
                        buttons: "ok"
                    }).then(() => {
                        window.location.href = "/borrower/dasbor";
                    });
                })
                .catch((err) => {
                    if (err.response.status === 400) {
                        Swal.fire({
                            title: "Gagal Ajukan",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (err.response.status === 404) {
                        Swal.fire({
                            title: "Gagal Ajukan",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (err.response.status === 401) {
                        Swal.fire({
                            title: "Gagal Ajukan",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    }
                });
        }
    };

    render() {
        return (
            <div>
                <section id="pinjaman">
                    <div className="card-body">
                        <div className="sec-header">
                            <h4 className="text-center sec-title">Form Pengajuan Pinjaman</h4>
                        </div>
                        <Form>
                            <div className="row justify-content-center">
                                <div className="form-group col-md-12 d-flex justify-content-center">
                                    <FormGroup>
                                        <Label for="jenis_borrower">Pilih Jenis Pinjaman</Label>
                                        <Input ref="jenis" onChange={this.handleChangeJenis} type="select" name="jenis pengguna" id="exampleSelect">
                                            <option value="1">Invoice Financing</option>
                                            <option value="2">Project Financing</option>
                                        </Input>
                                    </FormGroup>
                                </div>
                            </div>
                        </Form>

                        <Form>
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <InputNominal
                                        label={"Nominal"}
                                        onChange={(e) => this.handleChange(e, "currency", "errNominal")}
                                        name={"nilai_permohonan_pinjaman"}
                                        error={this.state.errNominal}
                                    />
                                    <InputIcon
                                        forLabel={"tenor"}
                                        label={"Tenor (Lama Pinjaman)"}
                                        icon={<i className="material-icons-outlined">access_time</i>}
                                        onChange={(e) => this.handleChange(e, "tenor", "errNumber")}
                                        type={"text"}
                                        name="jangka_waktu_pinjaman"
                                        placeholder="Lama pinjaman maksimal 12 bulan"
                                        maxLength="2"
                                        error={this.state.errNumber}
                                    />
                                    {this.state.jenis_pinjaman === "2" ? (
                                        <FormGroup>
                                            <Label for="jenis_borrower">Pemberi kerja</Label>
                                            <Input ref="jenis" onChange={this.handleChange} type="select" name="pemberi_kerja" id="exampleSelect">
                                                <option>-- Pilih --</option>
                                                <option value="BUMN">BUMN</option>
                                                <option value="BUMD">BUMD</option>
                                                <option value="PEMDA">PEMDA </option>
                                                <option value="PEMKOT">PEMKOT</option>
                                                <option value="Swasta">Swasta</option>
                                            </Input>
                                        </FormGroup>
                                    ) : null}
                                    <InputTextArea
                                        label={"Tujuan permohonan pinjaman"}
                                        name="tujuan_permohonan_pinjaman"
                                        onChange={(e) => this.handleChange(e)}
                                        error={this.state.errString}
                                    />
                                    <UploadDoc label={"Upload dokumen"} onChange={this.handleDokumen} name="file" />

                                    <div className="text-center mt-5">
                                        {this.props.project.isLoading ? (
                                            <Button color="primary" disabled className="btn btn-lg btn-block card-submit" onClick={this.handleSave}>
                                                <span className="loader"></span>
                                            </Button>
                                        ) : (
                                            <Button color="primary" className="btn btn-lg btn-block card-submit" onClick={this.handleSave}>
                                                Kirim
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project
    };
};
export default connect(mapStateToProps)(AjukanPinjaman);
