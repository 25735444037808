import axios from "axios";

const url = process.env.REACT_APP_BASSE_API;

export const EmailVerif = (id, data) => {
    // console.log(id)
    return {
        type: "SEND_EMAIL",
        payload: axios.post(`${url}/digisign/verifikasiemail/${id}`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const Document = (id, data) => {
    // console.log(id)
    return {
        type: "SIGN_DOCUMENT",
        payload: axios.post(`${url}/digisign/SignDocument/${id}`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const DownloadDocument = (id, data) => {
    // console.log(id)
    return {
        type: "DOWNLOAD_DOCUMENT",
        payload: axios.post(`${url}/digisign/DownloadDocument/${id}`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const EmailVerifLender = (id, data) => {
    // console.log(id)
    return {
        type: "SEND_EMAIL_LENDER",
        payload: axios.post(`${url}/digisign/verifikasiemaillender/${id}`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const DocumentLender = (id, data) => {
    // console.log(id)
    return {
        type: "SIGN_DOCUMENT_LENDER",
        payload: axios.post(`${url}/digisign/SignDocumentLender/${id}`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const DownloadDocumentLender = (id, data) => {
    // console.log(id)
    return {
        type: "DOWNLOAD_DOCUMENT_LENDER",
        payload: axios.post(`${url}/digisign/DownloadDocumentLender/${id}`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};
