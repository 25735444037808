export function step1(props, dataLender) {
    if (!dataLender) {
        if (
            props.errNominal !== 0 ||
            props.errNpwp !== 0 ||
            props.no_npwp.length < 15 ||
            !props.id_pekerjaan_online ||
            !props.id_jenis_badan_hukum ||
            !props.sektor_usaha ||
            !props.foto_npwp ||
            !props.aset ||
            !props.deskripsi_usaha
        ) {
            return false;
        } else {
            return true;
        }
    } else {
        if (
            props.errNominal !== 0 ||
            !props.id_pekerjaan_online ||
            !props.sektor_usaha ||
            !props.foto_npwp ||
            !props.aset ||
            !props.deskripsi_usaha
        ) {
            return false;
        } else {
            return true;
        }
    }
}

export function step2(props, dataLender) {
    if (!dataLender) {
        if (
            !props.alamat ||
            props.errKelurahan !== 0 ||
            props.errKecamatan !== 0 ||
            !props.id_provinsi ||
            !props.id_kota ||
            props.errKodepos !== 0 ||
            props.kode_pos.length < 5
        ) {
            return false;
        } else {
            return true;
        }
    }
}

export function step3(props) {
    if (
        !props.akta_pendirian ||
        !props.akta_perubahan ||
        !props.surat_domisili ||
        !props.laporan_keuangan ||
        !props.rekening_koran ||
        (!props.NIB && !props.SIUP && !props.TDP)
    ) {
        return false;
    } else {
        return true;
    }
}

export function step4(props, fotoselfie, dataLender) {
    var tgl = props.tgl_lahir.toDateString() === new Date().toDateString();
    if (!dataLender) {
        if (
            props.errNama !== 0 ||
            props.errJabatan !== 0 ||
            !props.id_bidang_pekerjaan ||
            props.errTempatLahir !== 0 ||
            props.errKtp !== 0 ||
            props.no_ktp_perwakilan.length < 16 ||
            !props.foto_ktp ||
            !props.id_jenis_kelamin ||
            !props.foto_selfie ||
            tgl
        ) {
            return false;
        } else {
            return true;
        }
    }
}

export function step5(props, dataLender) {
    if (!dataLender) {
        if (
            !props.nama_perwakilan ||
            !props.alamat_perwakilan ||
            props.errkelurahan_perwakilan !== 0 ||
            props.errkecamatan_perwakilan !== 0 ||
            !props.provinsi_perwakilan ||
            !props.kota_perwakilan ||
            props.errkode_pos_perwakilan !== 0 ||
            props.kode_pos_perwakilan.length < 5
        ) {
            return false;
        } else {
            return true;
        }
    }
}
