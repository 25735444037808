import React from "react";

const ChartPie = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="pie-chart-2-line" width="72" height="72" viewBox="0 0 72 72">
            <path id="Path_3321" data-name="Path 3321" d="M0,0H72V72H0Z" fill="none" />
            <path
                id="Path_3322"
                data-name="Path 3322"
                d="M29,.629Q30.485.5,32,.5A34.5,34.5,0,0,1,66.5,35q0,1.517-.129,3H61.853A30,30,0,1,1,29,5.15V.626ZM29,38V11.186A24,24,0,1,0,55.814,38Zm31.344-6A28.509,28.509,0,0,0,35,6.656V32Z"
                transform="translate(4 1)"
                fill="#8e8e93"
            />
        </svg>
    );
};

export default ChartPie;
