import axios from "axios";

const url = process.env.REACT_APP_BASSE_API;

export const LinkAccount = (data) => {
    return {
        type: "LINK_ACCOUNT",
        payload: axios.post(`${url}/dipay/link-account/`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const CreateTransaction = (id, data) => {
    return {
        type: "CREATE_TRANSACTION",
        payload: axios.post(`${url}/dipay/create-transaction/${id}`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};

export const UnlinkAccount = (data) => {
    return {
        type: "UNLINK_ACCOUNT",
        payload: axios.patch(`${url}/dipay/unlink/`, data, {
            headers: { "x-auth-token": localStorage.jwtToken }
        })
    };
};
