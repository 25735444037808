import React, { Component } from "react";
import NoData from "./NoData";
import Airplane from "../svg/Airplane";
class NoDataProyekWelcome extends Component {
    render() {
        return (
            <div className="no-data">
                <NoData symbol={<Airplane />} keterangan={"Maaf saat ini belum ada project tersedia "} />

                <div className="text-center mt-5">
                    <a href="/proyek" className="btn btn-outline-primary btn-lg px-5">
                        Lihat Lebih Banyak Proyek
                    </a>
                </div>
            </div>
        );
    }
}
export default NoDataProyekWelcome;
