import React, { Component } from "react";
import "./ByOJK.scss";

class ByOJK extends Component {
    render() {
        return (
            <section id="guaranteedby-ojk">
                <div className="container">
                    <div className="row d-flex justify-content-center ">
                        <div className="col-12 col-md-10 col-xl-9 padding-section">
                            <div className="row">
                                <div className="col col-md-5 col-xl-5">
                                    <p className="info-text">
                                        <span className="font-weight-bold">
                                            Indofund merupakan perusahaan P2P (Peer to Peer lending) yang berizin dan diawasi oleh Otoritas Jasa
                                            keuangan (OJK). Dengan nomor registrasi <br /> KEP-74/D.05/2021
                                        </span>
                                    </p>
                                </div>
                                <div className="col col-md-7 img-ojk">
                                    <div className="d-inline-block ">
                                        <img className="mb-4" width="180" src={process.env.PUBLIC_URL + "/images/partners-icon/ojk.svg"} alt="ojk" />
                                        <p className="mb-0">Terdaftar dan diawasi oleh OJK</p>
                                        <a
                                            rel="noopener noreferrer"
                                            href="https://www.ojk.go.id/id/kanal/iknb/financial-technology/Documents/Penyelenggara%20Fintech%20Lending%20Berizin%20per%205%20Januari%202023.pdf"
                                            target="_blank"
                                            className="d-flex text-primary font-weight-semi-bold">
                                            Lihat no. tanda daftar <i className="material-icons">chevron_right</i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default ByOJK;
