import React, { useCallback } from "react";

export const ShowElementContext = React.createContext({});
export const ShowElementContextProvider = ({ children }) => {
    const [isShowSaldo, setIsShowSaldo] = React.useState(false);
    const [isShowSaldoDipay, setIsShowSaldoDipay] = React.useState(false);

    const toggleIsShowSaldo = () => {
        setIsShowSaldo(!isShowSaldo);
    };

    const toggleIsShowSaldoDipay = useCallback(() => {
        setIsShowSaldoDipay(!isShowSaldoDipay);
    }, [isShowSaldoDipay]);

    return (
        <ShowElementContext.Provider
            value={{
                isShowSaldo,
                isShowSaldoDipay,
                toggleIsShowSaldo,
                toggleIsShowSaldoDipay
            }}>
            {children}
        </ShowElementContext.Provider>
    );
};
