import React, { Component } from "react";
import { Form, Button } from "reactstrap";
import { SelectOptions } from "../../../../../library/components/Components";

class Pekerjaan extends Component {
    render() {
        return (
            <Form>
                <h4 className="text-center mb-4 d-block d-sm-none font-weight-bold">Pekerjaan Dan Kepemilikan Rumah Borrower</h4>
                <div className="row">
                    <div className="col-md-6">
                        <SelectOptions
                            value={this.props.id_pendidikan}
                            forLabel={"id_pendidikan"}
                            label={"Pendidikan Terakhir"}
                            onChange={this.props.onChange}
                            name={"id_pendidikan"}
                            default={"Pilih Pendidikan Terakhir"}
                            data={this.props.pendidikanList}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <SelectOptions
                            value={this.props.id_pekerjaan}
                            forLabel={"id_pekerjaan"}
                            label={"Pekerjaan"}
                            onChange={this.props.onChange}
                            name={"id_pekerjaan"}
                            default={"Pilih Pekerjaan"}
                            data={this.props.pekerjaanList}
                        />
                    </div>
                    <div className="col-md-6">
                        <SelectOptions
                            value={this.props.id_pekerjaan_online}
                            forLabel={"id_pekerjaan_online"}
                            label={"Pekerjaan Online"}
                            onChange={this.props.onChange}
                            name={"id_pekerjaan_online"}
                            default={"Pilih Pekerjaan Online"}
                            data={this.props.pekerjaan_onlineList}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <SelectOptions
                            value={this.props.id_bidang_pekerjaan}
                            forLabel={"id_bidang_pekerjaan"}
                            label={"Bidang Pekerjaan"}
                            onChange={this.props.onChange}
                            name={"id_bidang_pekerjaan"}
                            default={"Pilih Bidang Pekerjaan"}
                            data={this.props.bidang_pekerjaanList}
                        />
                    </div>
                    <div className="col-md-6">
                        <SelectOptions
                            value={this.props.pengalaman_kerja}
                            forLabel={"pengalaman_kerja"}
                            label={"Pengalaman Kerja"}
                            onChange={this.props.onChange}
                            name={"pengalaman_kerja"}
                            default={"Pilih Pengalaman Kerja"}
                            data={this.props.pengalaman_kerjaList}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <SelectOptions
                            value={this.props.pendapatan}
                            forLabel={"pendapatan"}
                            label={"Pendapatan per bulan"}
                            onChange={this.props.onChange}
                            name={"pendapatan"}
                            default={"Pilih Pendapatan per bulan"}
                            data={this.props.pendapatanList}
                        />
                    </div>
                    <div className="col-md-6">
                        <SelectOptions
                            value={this.props.status_kepemilikan_rumah}
                            forLabel={"status_kepemilikan_rumah"}
                            label={"Status Kepemilikan Rumah"}
                            onChange={this.props.onChange}
                            name={"status_kepemilikan_rumah"}
                            default={"Pilih Status Kepemilikan Rumah"}
                            data={this.props.status_kepemilikan_rumahList}
                        />
                    </div>
                </div>
                {this.props.employee === true ? (
                    <div>
                        <div className="mt-4 mb-5">
                            <div className="custom-control custom-checkbox filter-input">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="agreement"
                                    name="agreement"
                                    onChange={this.props.handleCheck}
                                    defaultChecked={this.props.checked || ""}
                                />
                                <label className="custom-control-label" htmlFor="agreement">
                                    Saya menyatakan bahwa data-data yang telah saya isi diatas adalah valid, saya telah melakukan pengecekan atas
                                    kebenaran data-data tersebut, dan saya telah membaca ketentuan privacy dan cookies .
                                </label>
                            </div>
                        </div>
                        <div className="text-center">
                            {this.props.loading ? (
                                <Button
                                    onClick={this.props.handleSaveBorrower}
                                    disabled
                                    color="primary"
                                    className="btn btn-primary btn-lg btn-block card-submit">
                                    <span className="loader"></span>
                                </Button>
                            ) : (
                                <Button
                                    onClick={this.props.handleSaveBorrower}
                                    disabled={this.props.disabled}
                                    color="primary"
                                    className="btn btn-primary btn-lg btn-block card-submit">
                                    Daftar{" "}
                                </Button>
                            )}
                        </div>
                    </div>
                ) : null}
            </Form>
        );
    }
}
export default Pekerjaan;
