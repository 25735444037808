import React from "react";

function selectSearch(data) {
    if (data === undefined) {
        // console.log(this.state.jenis_kelaminList)
    } else {
        const hasil = data.map((val) => {
            return {
                value: val.kode,
                label: val.keterangan
            };
        });
        return hasil;
    }
}

function selectOptionWithTooltip(data, tooltip = () => { }) {
    if (data === undefined) {
        // console.log(data)
    } else {
        const hasil = data.map((val) => {
            return (
                <option key={val.kode} value={val.kode}>
                    {val.keterangan}
                    {/* <i className="text-alert float-start">
                        *Apabila sampai dengan T + 2 tidak dilakukan penarikan, maka akan otomatis ditransfer pada rekening yang terdaftar
                    </i> */}
                </option>
            );
        });
        return hasil;
    }
}
function selectOption(data) {
    if (data === undefined) {
        // console.log(data)
    } else {
        const hasil = data.map((val) => {
            return (
                <option key={val.kode} value={val.kode}>
                    {val.keterangan}
                </option>
            );
        });
        return hasil;
    }
}

export { selectSearch, selectOption, selectOptionWithTooltip };
