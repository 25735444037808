import React, { Component } from "react";
import "./Potensi.scss";

class Potensi extends Component {
    render() {
        return (
            <div>
                <section id="potensi">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-md-10 col-xl-9 ">
                                <div className="row">
                                    <div className="col-md-6">
                                        <img className="img-fluid" src={process.env.PUBLIC_URL + "/images/tentang-kami/potensi/1.png"} alt="ojk" />
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="potensi-title">
                                            Setujukah Anda bahwa Indonesia memiliki banyak potensi usaha yang dapat berkembang dengan besar dan
                                            menguntungkan?
                                        </h4>
                                        <p className="potensi-intro">
                                            Dengan kekayaan alam dan kesuburan tanah membuat setiap bisnis dan industri yang dijalankan di Indonesia
                                            dapat dipastikan akan lebih besar peluang keberhasilannya.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default Potensi;
