import React, { Component } from "react";
import Navbar from "./DasborNav";
import Content from "./DasborContent";
import TotalLoan from "./DasborLoan";
class Dasbor extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: "1"
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
                <TotalLoan />
                <div className="dasbor-wrapper">
                    <Navbar activeTab={this.state.activeTab} toggle={this.toggle} />
                    <Content
                        activeTab={this.state.activeTab}
                        project={this.props.project}
                        isLoadingProjectBr={this.props.isLoadingProjectBr}
                        isLoadingGetUser={this.props.isLoadingGetUser}
                    />
                </div>
            </div>
        );
    }
}

export default Dasbor;
