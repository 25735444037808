import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import "./ModalTKB.scss";

function ModalTKB(props) {
    return (
        <Modal size="md" isOpen={props.isOpen} toggle={props.toggle} className="modal-dialog modal-dialog-centered" tabIndex="-1">
            <ModalHeader toggle={props.toggle} className="modal-tkb-wrapper">
                <div className="text-center flex-fill">
                    <h5 className="modal-title text-center font-weight-bold mb-2">{props.data.title?.toString().toUpperCase()}</h5>
                </div>
            </ModalHeader>
            <ModalBody className="modal-tkb-body">
                <p className="mb-0 text-body-modal ">
                    <span className="font-weight-semi-bold">{props.data.title}</span> {props.data.body}
                </p>


            </ModalBody>

            <Button color="primary" className="btn btn-primary btn-md mt-4 mb-5 mx-5" onClick={props.toggle}>
                Mengerti
            </Button>

        </Modal>
    );

}
export default ModalTKB;
