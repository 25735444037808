const initialState = {
    master: [],
    masterList: [],
    masterListPusdafil: [],
    isLoading: false,
    isError: false
};

const ListMaster = (state = initialState, action) => {
    switch (action.type) {
        case "GET_MASTER_PENDING":
            return {
                ...state,
                isLoading: true
            };
        case "GET_MASTER_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case "GET_MASTER_FULFILLED":
            return {
                ...state,
                isLoading: false,
                masterList: action.payload.data.message
            };
        case "GET_MASTER_PUSDAFIL_PENDING":
            return {
                ...state,
                isLoading: true
            };
        case "GET_MASTER_PUSDAFIL_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case "GET_MASTER_PUSDAFIL_FULFILLED":
            return {
                ...state,
                isLoading: false,
                masterListPusdafil: action.payload.data.data
            };
        default:
            return state;
    }
};
export default ListMaster;
