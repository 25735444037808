import React from "react";

const Event = () => {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.995 511.995">
            <g transform="translate(0 -1)">
                <g>
                    <g>
                        <path
                            d="M503.467,457.533h-21.683c8.149-9.071,13.15-21.009,13.15-34.133c0-26.12-19.661-47.735-44.954-50.816
                            c1.519-5.675,2.287-11.511,2.287-17.451c0-37.641-30.626-68.267-68.267-68.267c-28.766,0-53.905,18.082-63.778,44.006
                            c-8.653-6.323-19.183-9.873-30.089-9.873c-19.763,0-36.915,11.273-45.44,27.708c-9.395-11.674-23.774-19.174-39.893-19.174
                            c-10.095,0-19.738,2.91-27.981,8.329c-11.076-15.693-29.269-25.395-48.819-25.395c-32.93,0-59.733,26.803-59.733,59.733
                            c-28.237,0-51.2,22.963-51.2,51.2c0,13.124,5.001,25.062,13.141,34.133H8.533c-4.719,0-8.533,3.814-8.533,8.533
                            s3.814,8.533,8.533,8.533h59.733h93.867h102.4H281.6h162.133h59.733c4.719,0,8.533-3.814,8.533-8.533
                            S508.186,457.533,503.467,457.533z M210.833,429.433l-28.1,28.1h-41.199l-28.1-28.1c-3.337-3.337-3.337-8.73,0-12.066
                            c3.337-3.337,8.73-3.337,12.066,0l28.1,28.1v-39.134c0-4.719,3.814-8.533,8.533-8.533s8.533,3.814,8.533,8.533v39.134l28.1-28.1
                            c3.336-3.337,8.73-3.337,12.066,0C214.17,420.703,214.17,426.097,210.833,429.433z M443.733,457.533h-39.134l28.1-28.1
                            c3.336-3.337,3.336-8.73,0-12.066c-3.337-3.337-8.73-3.337-12.066,0l-28.1,28.1v-39.134c0-4.719-3.814-8.533-8.533-8.533
                            c-4.719,0-8.533,3.814-8.533,8.533v39.134l-28.1-28.1c-3.336-3.337-8.73-3.337-12.066,0c-3.337,3.336-3.337,8.73,0,12.066
                            l28.1,28.1h-60.817c2.057-2.287,3.9-4.753,5.521-7.381c0.043-0.068,0.085-0.137,0.119-0.205c1.527-2.492,2.85-5.111,3.934-7.868
                            c0.102-0.273,0.196-0.546,0.299-0.819c0.981-2.637,1.783-5.359,2.33-8.175c0.051-0.282,0.077-0.563,0.128-0.853
                            c0.503-2.876,0.819-5.811,0.819-8.832c0-28.237-22.963-51.2-51.2-51.2c-2.842,0-5.692,0.247-8.525,0.759
                            C256,372.703,256,372.448,256,372.2c0-18.825,15.309-34.133,34.133-34.133c10.931,0,21.299,5.376,27.733,14.387
                            c2.039,2.85,5.623,4.173,9.028,3.311c3.396-0.853,5.931-3.703,6.383-7.194c3.243-25.446,25.054-44.638,50.722-44.638
                            c28.237,0,51.2,22.963,51.2,51.2c0,7.791-1.766,15.351-5.257,22.46c-1.391,2.85-0.819,6.187,1.05,8.747
                            c1.877,2.56,5.367,3.866,8.405,3.371c1.425-0.213,2.85-0.444,4.335-0.444c18.825,0,34.133,15.309,34.133,34.133
                            S462.558,457.533,443.733,457.533z"
                            fill="#8e8e93"
                        />
                        <path
                            d="M305.066,201.533h99.2c17.058,0,30.933-14.831,30.933-33.067c0-16.41-11.238-30.063-25.924-32.631
                            c-3.046-37.06-27.059-53.768-50.876-53.768c-33.058,0-51.831,22.903-58.317,32.631c-20.011,2.654-35.55,21.035-35.55,43.255
                            C264.532,181.983,282.717,201.533,305.066,201.533z"
                            fill="#8e8e93"
                        />
                        <path
                            d="M93.866,65c18.825,0,34.133,15.309,34.133,34.133c0,4.719,3.814,8.533,8.533,8.533s8.533-3.814,8.533-8.533
                            c0-23.526,19.14-42.667,42.667-42.667c4.719,0,8.533-3.814,8.533-8.533s-3.814-8.533-8.533-8.533
                            c-22.272,0-41.702,12.279-51.968,30.396c-9.276-13.201-24.585-21.862-41.899-21.862c-4.719,0-8.533,3.814-8.533,8.533
                            S89.147,65,93.866,65z"
                            fill="#8e8e93"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Event;
