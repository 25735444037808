import React, { Component } from "react";
import { Link } from "react-router-dom";

class ItemBanner extends Component {
    render() {
        return (
            <div className="item">
                <div className="bg-orange">
                    <div
                        style={{
                            width: "auto",
                            height: 0,
                            borderLeft: "180px solid transparent",
                            borderRight: "206px solid transparent",
                            borderTop: "36px solid #ff7a00",
                            position: "relative",
                            top: "100%",
                            marginTop: "-1px"
                        }}
                        className="panah-orange"></div>
                    <div className="container-banner">
                        <h1 className="font-weight-bold text-white">{this.props.heading1}</h1>
                        <h4 className="common-text">{this.props.heading2}</h4>
                        <Link to={"/faq"} className="btn btn-white font-weight-semi-bold">
                            Pelajari lebih lanjut
                        </Link>
                    </div>
                </div>
                <div className="bg-img" style={{ backgroundImage: this.props.rightBg }}></div>
            </div>
        );
    }
}

export default ItemBanner;
