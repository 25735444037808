import React, { Component } from "react";
import { NavbarTitle, NavbarContent } from "../../../library/components/NavbarComponent";
import Tentang from "./tentang/Tentang";
import Saham from "./saham/Saham";
import Team from "./ourteam/Team";
import Statistik from "./statistik/Statistik";
// import Laporan from "./laporanKeuangan/laporan";
class TentangKami extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 0
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    render() {
        const title = ["Tentang Indofund", "Pemegang Saham", "Tim Manajemen", "Statistik Indofund"];

        const children = [<Tentang />, <Saham />, <Team />, <Statistik />];

        return (
            <div className="container" style={{ paddingTop: "150px" }}>
                <NavbarTitle title={title} activeTab={this.state.activeTab} toggle={this.toggle} />
                <NavbarContent activeTab={this.state.activeTab} components={children} />
            </div>
        );
    }
}
export default TentangKami;
