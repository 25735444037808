import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Dasbor from "../component/borrower/dashboard/Dasbor";
import Akun from "../component/borrower/akun/Akun";
import Signing from "../component/borrower/signing/Signing";
import Dompet from "../component/userAkun/dompet/Dompet";
import Topup from "../component/userAkun/dompet/topupsaldo/PilihTopUp";
import DetailCicilan from "../component/borrower/dashboard/DetailCicilan";
import DetailSelesai from "../component/borrower/dashboard/DetailProyekSelesai";
import AjukanPinjaman from "../component/borrower/ajukanpinjaman/AjukanPinjaman";
class RouteBorrower extends Component {
    render() {
        return (
            <div>
                <Route
                    exact
                    path="/borrower/dasbor"
                    render={(props) => (
                        <Dasbor
                            project={this.props.project}
                            isLoadingProjectBr={this.props.isLoadingProjectBr}
                            isLoadingGetUser={this.props.isLoadingGetUser}
                            {...props}
                        />
                    )}
                />

                <Route
                    path="/borrower/ajukan-pinjaman"
                    render={(props) => (
                        <AjukanPinjaman
                            employee={this.props.employee}
                            micro={this.props.micro}
                            jenisBorrower={this.props.jenis}
                            plafond_borrower={this.props.plafond_borrower}
                            plafond_terpakai={this.props.plafond_terpakai}
                            isLoadingGetUser={this.props.isLoadingGetUser}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/borrower/akun/:id"
                    render={(props) => (
                        <Akun
                            borrower={this.props.borrower}
                            no_hp={this.props.no_hp}
                            email={this.props.email}
                            nama_pengguna={this.props.nama_pengguna}
                            employee={this.props.employee}
                            micro={this.props.micro}
                            userBank={this.props.userBank}
                            kyc={this.props.kyc}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/borrower/dompet"
                    render={(props) => <Dompet nama_pengguna={this.props.nama_pengguna} saldo={this.props.saldo} {...props} />}
                />
                <Route path="/borrower/detail-cicilan/:id" component={DetailCicilan} />
                <Route path="/borrower/detail-selesai/:id" component={DetailSelesai} />
                <Route
                    path="/borrower/topup"
                    render={(props) => (
                        <Topup
                            va={this.props.va}
                            nicepay_register={this.props.nicepay_register}
                            saldo={this.props.saldo}
                            admin_kyc={!this.props.borrower.admin_kyc}
                            jenis={this.props.jenis_pengguna}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/borrower/sign"
                    render={(props) => (
                        <Signing
                            id={this.props.id}
                            kyc={this.props.kyc}
                            project={this.props.project}
                          
                            
                            userDigisign={this.props.userDigisign}
                            isLoadingGetUser={this.props.isLoadingGetUser}
                            isLoadingProjectBr={this.props.isLoadingProjectBr}
                           
                            email={this.props.email}
                            {...props}
                        />
                    )}
                />
            </div>
        );
    }
}
export default RouteBorrower;
