import React from "react";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

function NavbarTitle(props) {
    const title = props.title;
    // const query =
    //   props.akunLender === true && props.dipay_key === undefined
    //     ? title.slice(0, 3)
    //     : title;

    const NavbarTitle = () => {
        return (
            <nav>
                <Nav tabs className="over-hidden">
                    {title.map((val, index) => {
                        return (
                            <NavItem key={index}>
                                <NavLink
                                    className={classnames({
                                        active: props.activeTab === index
                                    })}
                                    onClick={() => {
                                        props.toggle(index);
                                    }}
                                    style={{ cursor: "pointer" }}>
                                    {val}
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </Nav>
            </nav>
        );
    };

    return <div>{NavbarTitle()}</div>;
}

function NavbarContent(props) {
    const component = props.components;
    const Content = () => {
        const content = component.map((val, index) => {
            return (
                <TabContent activeTab={props.activeTab} key={index}>
                    <TabPane tabId={index}>
                        <div className={props.className}>{val}</div>
                    </TabPane>
                </TabContent>
            );
        });
        return content;
    };

    return <div>{Content()}</div>;
}

export { NavbarTitle, NavbarContent };
