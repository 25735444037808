import React, { Component } from "react";
import { Form, Button } from "reactstrap";
import { NewPassword } from "../../../redux/action/Password";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import "../../../sass/loader.css";
import { InputIcon } from "../../../library/components/Components";
import { validate } from "../../../library/validation/validate";
import { encryptedData } from "../../../library/helper/Crypto";

class newPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirm_password: "",
            errPassword: "",
            errPasswordConfirm: "",
            hidden: true,
            hiddenConfirm: true
        };
        this.showHide = this.showHide.bind(this);
        this.showHideConfirm = this.showHideConfirm.bind(this);
    }

    showHide() {
        this.setState({ hidden: !this.state.hidden });
    }

    showHideConfirm() {
        this.setState({ hiddenConfirm: !this.state.hiddenConfirm });
    }
    handleChange = (e, type, changeError) => {
        // console.log(e.target.name)
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value, this.state.password, this.state.confirm_password) });
        });
    };

    handleSavePassword = async () => {
        await this.props
            .dispatch(
                NewPassword({
                    password: encryptedData(this.state.password),
                    confirm_password: encryptedData(this.state.confirm_password)
                    // password: this.state.password,
                    // confirm_password: this.state.confirm_password
                })
            )
            .then(() => {
                Swal.fire({
                    title: "Berhasil",
                    text: "Anda berhasil mengganti password",
                    icon: "success",
                    buttons: "ok"
                }).then(() => {
                    window.location.href = "/";
                });
            })
            .catch((err) => {
                // console.log(err)
            });
    };
    render() {
        return (
            <section id="daftar-lender" className="with-pad bg-grey">
                <div className="container">
                    <div className="sec-header">
                        <h4 className="text-center sec-title">User Indofund</h4>
                        <p className="text-center sec-desc">Silahkan masukkan password baru anda </p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-7 col-lg-5 col-xl-4">
                            <div className="card border-0">
                                <div className="card-body">
                                    <Form>
                                        <InputIcon
                                            forLabel={"password"}
                                            label={"Password Baru"}
                                            icon={<i className="material-icons-outlined">lock</i>}
                                            type={this.state.hidden ? "password" : "text"}
                                            name={"password"}
                                            onChange={(e) => this.handleChange(e, "password", "errPassword")}
                                            placeholder={"Kata sandi anda"}
                                            maxLength={""}
                                            error={this.state.errPassword}
                                            className={"password-form"}
                                            info={"*Case sensitive"}
                                            password={true}
                                            clickShow={this.showHide}
                                            hidden={this.state.hidden}
                                        />
                                        <InputIcon
                                            forLabel={"confirm_password"}
                                            label={"Konfirmasi Kata Sandi"}
                                            icon={<i className="material-icons-outlined">lock</i>}
                                            type={this.state.hiddenConfirm ? "password" : "text"}
                                            name={"confirm_password"}
                                            onChange={(e) => this.handleChange(e, "passwordConfirm", "errPasswordConfirm")}
                                            placeholder={"Konfirmasi kata sandi"}
                                            error={this.state.errPasswordConfirm}
                                            className={"password-form"}
                                            info={"*Case sensitive"}
                                            password={true}
                                            clickShow={this.showHideConfirm}
                                            hidden={this.state.hiddenConfirm}
                                        />
                                        {this.props.password.isLoading === true ? (
                                            <Button
                                                color="primary"
                                                disabled
                                                onClick={this.handleSavePassword}
                                                className="btn btn-lg btn-block card-submit">
                                                <span className="loader"></span>
                                            </Button>
                                        ) : (
                                            <Button color="primary" onClick={this.handleSavePassword} className="btn btn-lg btn-block card-submit">
                                                Kirim
                                            </Button>
                                        )}
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        password: state.password
    };
};
export default connect(mapStateToProps)(newPassword);
