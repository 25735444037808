const initialState = {
    password: [],
    isLoading: false,
    isError: false
};

const Password = (state = initialState, action) => {
    switch (action.type) {
        case "FORGET_PASSWORD_PENDING":
            return {
                ...state,
                isLoading: true
            };
        case "FORGET_PASSWORD_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case "FORGET_PASSWORD_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoading: false,
                password: action.payload.data
            };
        case "NEW_PASSWORD_PENDING":
            return {
                ...state,
                isLoading: true
            };
        case "NEW_PASSWORD_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case "NEW_PASSWORD_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoading: false,
                password: action.payload.data
            };
        default:
            return state;
    }
};
export default Password;
