import React, { Component } from "react";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane, Form, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { LoginUser } from "../../../../redux/action/User";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import "../../../../sass/loader.css";
import axios from "axios";
import { InputIcon } from "../../../../library/components/Components";
import { validate } from "../../../../library/validation/validate";
import { encryptedData } from "../../../../library/helper/Crypto";

const url = process.env.REACT_APP_BASSE_API;

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            errEmail: "",
            errPassword: "",
            activeTab: "1",
            hidden: true
        };
        this.toggle = this.toggle.bind(this);
        this.showHide = this.showHide.bind(this);
    }
    showHide() {
        this.setState({ hidden: !this.state.hidden });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleChange = (e, type, changeError) => {
        // console.log(e.target.name)
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value, this.state.password, this.state.passwordConfirm) });
        });
    };

    handleLoginLender = async () => {
        if (this.state.errEmail === 0 && this.state.errPassword === 0) {
            await this.props
                .dispatch(
                    LoginUser({
                        email: encryptedData(this.state.email),
                        password: encryptedData(this.state.password),
                        kode_pengguna: encryptedData("1")
                    })
                )
                .then((res) => {
                    if (res.value.data.data.kode_pengguna.length === 0) {
                        this.props.history.push("/pilihuser");
                    } else if (res.value.data.kode_pengguna === 1) {
                        localStorage.setItem("justOnce", "true");
                        var blog_id = this.props.match.params.blog_id;
                        var proyek_id = this.props.match.params.proyek_id;
                        if (this.props.history.location.pathname === "/proyek") {
                            this.props.history.push("/proyek");
                            window.location.reload();
                        } else if (this.props.history.location.pathname === `/proyek/${proyek_id}`) {
                            this.props.history.push(`/proyek/${proyek_id}`);
                            window.location.reload();
                        } else if (this.props.history.location.pathname === `/blog-detail/${blog_id}`) {
                            this.props.history.push(`/blog-detail/${blog_id}`);
                            window.location.reload();
                        } else {
                            this.props.history.push("/lender/dasbor");
                            window.location.reload();
                        }
                    }
                })
                .catch((err) => {
                    if (err.response.status === 404) {
                        Swal.fire({
                            title: "Tidak Dapat Masuk",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (err.response.status === 401) {
                        Swal.fire({
                            title: "Tidak Dapat Masuk",
                            text: `${err.response.data.message.message}`,
                            icon: "warning",
                            button: "ok"
                        }).then(() => {
                            this.props.history.push("/emailverif/1");
                            window.location.reload();
                        });
                    } else if (err.response.status === 400) {
                        if (err.response.data.message.borrowerLogin === true) {
                            localStorage.removeItem("jwtToken");
                            localStorage.removeItem("email");
                            localStorage.removeItem("no_handphone");
                            localStorage.removeItem("namaUser");
                            localStorage.removeItem("verif");
                            Swal.fire({
                                title: "Login Gagal",
                                text: `${err.response.data.message.message}`,
                                icon: "warning",
                                button: "ok"
                            });
                        } else if (err.response.data.message.inputPassword === true) {
                            Swal.fire({
                                title: "Login Gagal",
                                text: `${err.response.data.message.message}`,
                                icon: "warning",
                                button: "ok"
                            }).then(() => {
                                window.location.href = "/new-password";
                            });
                        } else if (err.response.data.message.retryLogin === true) {
                            //setelah percobaan 5 kali
                            if (err.response.data.message.nextAttempt !== undefined) {
                                const now = new Date().getTime();
                                const nextAttempt = new Date(err.response.data.message.nextAttempt).getTime();
                                var diffTime = nextAttempt - now;
                                //hitung jam
                                var hoursDiff = Math.floor(diffTime / (1000 * 3600));
                                // hitung menit
                                let diffInMilliSeconds = Math.abs(nextAttempt - now) / 1000;
                                const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
                                diffInMilliSeconds -= minutes * 60;

                                var time = hoursDiff + " jam " + minutes + " menit";
                                Swal.fire({
                                    title: "Gagal login",
                                    text: `Anda dapat login kembali setelah ${time}, karena percobaan login gagal selama 5 kali`,
                                    icon: "warning",
                                    button: "ok"
                                });
                            } else {
                                Swal.fire({
                                    title: "Gagal login",
                                    text: `${err.response.data.message.message}`,
                                    icon: "warning",
                                    button: "ok"
                                });
                            }
                        }
                    }
                });
        } else {
            Swal.fire({
                title: "Gagal Masuk",
                text: "Mohon untuk mengisi Email dan Password",
                icon: "warning",
                buttons: "ok"
            });
        }
    };
    handleLoginBorrower = async () => {
        // console.log(this.props)
        if (this.state.errEmail === 0 && this.state.errPassword === 0) {
            await this.props
                .dispatch(
                    LoginUser({
                        email: encryptedData(this.state.email),
                        password: encryptedData(this.state.password),
                        kode_pengguna: encryptedData("2")
                    })
                )
                .then((res) => {
                    if (res.value.data.data.borrower_id.admin_kyc === false) {
                        Swal.fire({
                            title: "Gagal Masuk",
                            text: "Data anda sedang di review oleh tim Indofund. Proses verifikasi membutuhkan 1x24 jam hari kerja",
                            icon: "warning",
                            buttons: "ok"
                        }).then(() => {
                            axios.post(
                                `${url}/user/logout`,
                                { accessToken: localStorage.accessToken },
                                {
                                    headers: { "x-auth-token": localStorage.jwtToken }
                                }
                            );
                            localStorage.removeItem("id");
                            localStorage.removeItem("jwtToken");
                            localStorage.removeItem("email");
                            localStorage.removeItem("id_penyelenggara");
                            localStorage.removeItem("kode_pengguna");
                            localStorage.removeItem("tgl_registrasi");
                            localStorage.removeItem("status_pengguna");
                            localStorage.removeItem("nama_pengguna");
                            localStorage.removeItem("id_pengguna");
                            localStorage.removeItem("bank");
                            localStorage.removeItem("jenis_kelamin");
                            localStorage.removeItem("justOnce");
                            localStorage.removeItem("masuk");
                            localStorage.removeItem("nama");
                            localStorage.removeItem("kyc");
                            localStorage.removeItem("noHP");
                            localStorage.removeItem("refreshToken");
                            window.location.reload();
                            window.location.href = "/";
                        });
                    } else if (res.value.data.data.kode_pengguna.length === 0) {
                        this.props.history.push("/pilihuser");
                    } else if (res.value.data.data.kode_pengguna[0] === 2 || res.value.data.data.kode_pengguna[1] === 2) {
                        this.props.history.push("/borrower/dasbor");
                        window.location.reload();
                    }
                    else if ( res.value.data.data.kode_pengguna[0] !== 2 ||
                      res.value.data.data.kode_pengguna[1] !== 2){
                        Swal.fire({
                          title: "Tidak Dapat Masuk",
                          text: `Akun belum terdaftar sebagai penerima dana`,
                          icon: "warning",
                          button: "ok"
                      });
                      }
                })
                .catch((err) => {
                    if (err.response.status === 404) {
                        Swal.fire({
                            title: "Tidak Dapat Masuk",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (err.response.status === 401) {
                        Swal.fire({
                            title: "Tidak Dapat Masuk",
                            text: `${err.response.data.message.message}`,
                            icon: "warning",
                            button: "ok"
                        }).then(() => {
                            this.props.history.push("/emailverif/2");
                            window.location.reload();
                        });
                    } else if (err.response.status === 400) {
                        //jika sudah punya akun lender tapi login borrower
                        if (err.response.data.borrowerRegister === true) {
                            Swal.fire({
                                title: "Login Gagal",
                                text: `${err.response.data.message}`,
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Daftar borrower"
                            }).then((result) => {
                                if (result.value) {
                                    this.props.history.push("/pilihborrower");
                                }
                            });
                        } else if (err.response.data.message.inputPassword === true) {
                            Swal.fire({
                                title: "",
                                text: `${err.response.data.message.message}`,
                                icon: "warning",
                                button: "ok"
                            }).then(() => {
                                window.location.href = "/new-password";
                            });
                        } else if (err.response.data.message.continueRegister === true) {
                            Swal.fire({
                                title: "",
                                text: `${err.response.data.message.message}`,
                                icon: "warning",
                                button: "ok"
                            }).then(() => {
                                this.props.history.push("/pilihborrower");
                                this.props.toggle();
                            });
                        } else if (err.response.data.message.lenderLogin === true) {
                            localStorage.removeItem("jwtToken");
                            localStorage.removeItem("email");
                            localStorage.removeItem("no_handphone");
                            localStorage.removeItem("namaUser");
                            localStorage.removeItem("verif");
                            Swal.fire({
                                title: "Tidak Dapat Login",
                                text: `${err.response.data.message.message}`,
                                icon: "warning",
                                button: "ok"
                            });
                        } else if (err.response.data.message.retryLogin === true) {
                            //setelah percobaan 5 kali
                            if (err.response.data.message.nextAttempt !== undefined) {
                                const now = new Date();
                                const nextAttempt = new Date(err.response.data.message.nextAttempt);
                                nextAttempt.setHours(12);
                                nextAttempt.setMinutes(0);
                                nextAttempt.setSeconds(0);
                                if (now > nextAttempt) {
                                    nextAttempt.setDate(nextAttempt.getDate() + 1);
                                }
                                var diff = (nextAttempt.getTime() - now.getTime()) / 1000;
                                var hrs = parseInt(diff / 3600);
                                diff = diff % 3600;
                                var minutes = parseInt(diff / 60);
                                const timeDiff = hrs + " jam, " + minutes + " menit";
                                Swal.fire({
                                    title: "Gagal login",
                                    text: `Anda dapat login kembali setelah ${timeDiff}, karena percobaan login gagal selama 5 kali`,
                                    icon: "warning",
                                    button: "ok"
                                });
                            } else {
                                Swal.fire({
                                    title: "Gagal login",
                                    text: `${err.response.data.message.message}`,
                                    icon: "warning",
                                    button: "ok"
                                });
                            }
                        }
                    }
                });
        } else {
            Swal.fire({
                title: "Gagal Masuk",
                text: "Mohon untuk mengisi Email dan Password",
                icon: "warning",
                buttons: "ok"
            });
        }
    };
    onKeyPressLender = (e) => {
        if (e.which === 13) {
            this.handleLoginLender();
        }
    };
    onKeyPressBorrower = (e) => {
        if (e.which === 13) {
            this.handleLoginBorrower();
        }
    };
    render() {
        return (
            <section id="daftar-lender" className="with-pad">
                <div className="container pt-5">
                    <div className="sec-header">
                        {/* <h4 className="text-center sec-title">Login</h4> */}
                        <h4 className="text-center sec-title">Masuk Akun Indofund.id</h4>
                        <p className="mb-0 text-center">
                            Belum memiliki akun? Yuk daftar{" "}
                            <Link to="/pilihuser" onClick={this.props.toggle}>
                                disini
                            </Link>
                        </p>
                    </div>
                    <div className="text-center flex-fill"></div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-7 col-lg-5 col-xl-4">
                            <nav className="login-nav">
                                <Nav tabs className="over-hidden">
                                    <NavItem className="flex-fill">
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "1" })}
                                            onClick={() => {
                                                this.toggle("1");
                                            }}
                                            style={{ cursor: "pointer" }}>
                                            Pemberi Dana
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="flex-fill">
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "2" })}
                                            onClick={() => {
                                                this.toggle("2");
                                            }}
                                            style={{ cursor: "pointer" }}>
                                            Penerima Dana
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </nav>
                            <p className="text-center sec-desc"></p>

                            <div className="card border-0">
                                <div className="card-body">
                                    <TabContent activeTab={this.state.activeTab}>
                                        <TabPane tabId="1">
                                            {/* <div className="sec-header" style={{marginBottom: '50px'}}>
                                    <h4 className="text-center sec-title">Masuk sebagai Lender</h4>
                                </div> */}
                                            <Form>
                                                <InputIcon
                                                    forLabel={"email"}
                                                    label={"Email"}
                                                    icon={<i className="material-icons-outlined">mail</i>}
                                                    type={"email"}
                                                    name={"email"}
                                                    onChange={(e) => this.handleChange(e, "email", "errEmail")}
                                                    placeholder={"Contoh: example@email.com"}
                                                    maxLength={""}
                                                    error={this.state.errEmail}
                                                    onKeyPress={this.onKeyPressLender}
                                                />
                                                <InputIcon
                                                    forLabel={"password"}
                                                    label={"Kata Sandi"}
                                                    icon={<i className="material-icons-outlined">lock</i>}
                                                    type={this.state.hidden ? "password" : "text"}
                                                    name={"password"}
                                                    onChange={(e) => this.handleChange(e, "passwordLogin", "errPassword")}
                                                    placeholder={"Kata sandi Anda"}
                                                    error={this.state.errPassword}
                                                    onKeyPress={this.onKeyPressLender}
                                                    className={"password-form"}
                                                    password={true}
                                                    clickShow={this.showHide}
                                                    hidden={this.state.hidden}
                                                />
                                                <div className="text-right" style={{ marginBottom: "40px" }}>
                                                    <Link to="/forgot-password" onClick={this.props.toggle}>
                                                        Lupa Kata Sandi?
                                                    </Link>
                                                </div>
                                                {this.props.user.isLoadingLogin ? (
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary btn-block btn-lg"
                                                        disabled
                                                        onClick={this.handleLoginLender}>
                                                        <span className="loader"></span>
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary btn-block btn-lg"
                                                        onClick={this.handleLoginLender}>
                                                        Masuk sebagai Pemberi Dana
                                                    </Button>
                                                )}
                                            </Form>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            {/* <div className="sec-header" style={{marginBottom: '50px'}}>
                                            <h4 className="text-center sec-title">Masuk sebagai Borrower</h4>
                                        </div> */}
                                            <Form>
                                                <InputIcon
                                                    forLabel={"email"}
                                                    label={"Email"}
                                                    icon={<i className="material-icons-outlined">mail</i>}
                                                    type={"email"}
                                                    name={"email"}
                                                    onChange={(e) => this.handleChange(e, "email", "errEmail")}
                                                    placeholder={"Contoh: example@email.com"}
                                                    error={this.state.errEmail}
                                                    onKeyPress={this.onKeyPressBorrower}
                                                />
                                                <InputIcon
                                                    forLabel={"password"}
                                                    label={"Kata Sandi"}
                                                    icon={<i className="material-icons-outlined">lock</i>}
                                                    type={this.state.hidden ? "password" : "text"}
                                                    name={"password"}
                                                    onChange={(e) => this.handleChange(e, "passwordLogin", "errPassword")}
                                                    placeholder={"Kata sandi Anda"}
                                                    error={this.state.errPassword}
                                                    onKeyPress={this.onKeyPressBorrower}
                                                    className={"password-form"}
                                                    password={true}
                                                    clickShow={this.showHide}
                                                    hidden={this.state.hidden}
                                                />

                                                <div className="text-right" style={{ marginBottom: "40px" }}>
                                                    <Link to="/forgot-password" onClick={this.props.toggle}>
                                                        Lupa Kata Sandi?
                                                    </Link>
                                                </div>
                                                {this.props.user.isLoadingLogin ? (
                                                    <Button
                                                        disabled
                                                        color="primary"
                                                        className="btn btn-primary btn-block btn-lg"
                                                        onClick={this.handleLoginBorrower}>
                                                        <span className="loader"></span>
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary btn-block btn-lg"
                                                        onClick={this.handleLoginBorrower}>
                                                        Masuk sebagai Penerima Dana
                                                    </Button>
                                                )}
                                            </Form>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
export default withRouter(connect(mapStateToProps)(Login));
