import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Proyek.scss";
import NoData from "../../assets/no data/NoData";
import Airplane from "../../assets/svg/Airplane";
import Pagination from "react-js-pagination";
import NumberFormat from "react-number-format";
import { progressBarProject } from "../../../library/helper/helper";

class Proyek extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: [],
            currentPage: 1,
            todosPerPage: 10
        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(pageNumber) {
        this.setState({
            currentPage: pageNumber
        });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    ProjectEmployee = (currentPage, todosPerPage) => {
        //page
        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const currentTodos = this.props.projects;

        //sorting descending
        function compare(a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
        }

        const projectList = currentTodos
            .filter((item) => item.tipe_pinjaman === "Employee Loan")
            .sort(compare)
            .slice(indexOfFirstTodo, indexOfLastTodo)
            .map((project) => {
                const progressBar = (project.nilai_total_peminjam / project.nilai_pengajuan_pinjaman) * 100;

                return (
                    <Link to={"/proyek/" + project._id} key={project._id} className="link-text">
                        <div className="transaction p-3">
                            <div className="card border-0 shadow-sm">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-xl-8 mb-3 mb-xl-0">
                                            <div className="row">
                                                <div className="col-6 col-md-2 mb-3">
                                                    <p className="fs-n1 text-secondary mb-1">Nama Proyek</p>
                                                    <p className="mb-0">{project.nama_pinjaman}</p>
                                                </div>
                                                <div className="col-6 col-md-4 mb-3">
                                                    <p className="fs-n1 text-secondary mb-1">Avalis</p>
                                                    <p className="mb-0">{project.avalis_perusahaan}</p>
                                                </div>
                                                <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                                    <p className="fs-n1 text-secondary mb-1">Tujuan</p>
                                                    <p className="mb-0">{project.tujuan_permohonan_pinjaman}</p>
                                                </div>
                                                <div className="col-6 col-md-2 mb-3">
                                                    <p className="fs-n1 text-secondary mb-1">Tenor</p>
                                                    <p className="mb-0">{project.jangka_waktu_pinjaman} bulan</p>
                                                </div>
                                                <div className="col-6 col-md-2 mb-3">
                                                    <p className="fs-n1 text-secondary mb-1">Bunga</p>
                                                    <p className="mb-0">{project.suku_bunga_konsumtif * 100} % Flat / bulan</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-4 progress-divider">
                                            <div className="progress mb-3 mt-0">
                                                <div
                                                    className="progress-bar bg-primary"
                                                    role="progressbar"
                                                    style={{
                                                        width: `${parseInt(progressBar)}%`,
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}>
                                                    <div className={progressBar !== 100 ? "text-progress-bar" : "text-progress-bar"}>
                                                        {progressBarProject(progressBar)}%
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row text-information">
                                                <div className="col-6">
                                                    <p className="fs-n1 text-secondary mb-1">Terkumpul</p>
                                                    <p className="mb-0 font-weight-semi-bold text-primary">
                                                        Rp.
                                                        <NumberFormat
                                                            value={project.nilai_total_peminjam}
                                                            displayType={"text"}
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            decimalScale={0}
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="fs-n1 text-secondary mb-1">Plafon</p>
                                                    <p className="mb-0 font-weight-semi-bold">
                                                        Rp. {project.nilai_pengajuan_pinjaman.toLocaleString("IN")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                );
            });

        return projectList;
    };

    render() {
        const { currentPage, todosPerPage } = this.state;
        // Logic for displaying page numbers
        const lengthProyek = this.props.projects.filter((item) => item.tipe_pinjaman === "Employee Loan").length;

        return (
            <div>
                <div className="row">
                    {lengthProyek !== 0 ? (
                        <div className="col-12 col-md-12 col-xl-12">{this.ProjectEmployee(currentPage, todosPerPage)}</div>
                    ) : (
                        <NoData symbol={<Airplane />} keterangan={"Maaf saat ini belum ada project tersedia "} />
                    )}
                </div>

                <div className="d-flex justify-content-center mt-3">
                    <Pagination
                        hideDisabled
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.currentPage}
                        itemsCountPerPage={this.state.todosPerPage}
                        totalItemsCount={parseInt(lengthProyek)}
                        onChange={this.handleClick.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

export default Proyek;
