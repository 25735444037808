import React, { Component } from "react";
import { UpdateRekening, DeleteRekening, SetAsPrimaryRekening, EditRekening } from "../../../redux/action/User";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Select from "react-select";
import { FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, FormText } from "reactstrap";
import NoData from "../../assets/no data/NoData";
import { encryptedData } from "../../../library/helper/Crypto";
import { changeCapitalize } from "../../../library/helper/helper";
import { validate } from "../../../library/validation/validate";

class TambahRekening extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankList: [],
            bank: [],
            modal: false,
            modalEdit: false,
            setModal: false,
            setModalEdit: false,
            nama_bank: "",
            nomor_akun_bank: "",
            nama_pengguna: "",
            nama_pemilik_rekening: "",
            index: "",
            errNumber: "",
            errNama: ""
        };
    }

    handleChange = (e, type, changeError) => {
        // console.log(e.target.name)
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value) });
        });
    };

    onChangeNumber = (e) => {
        let noVal = /^\d*$/;
        // console.log(emailVal.test(email.target.value))
        if (noVal.test(e.target.value) === false) {
            this.setState({
                errNumber: "Hanya berupa angka"
            });
            this.setState([e.target.name]);
            return false;
        } else {
            this.setState({
                [e.target.name]: e.target.value,
                errNumber: false
            });
        }
    };

    onChangeNama = (nama_pemilik_rekening) => {
        let namaVal = /^[a-zA-Z ]*$/;
        // console.log(emailVal.test(email.target.value))
        if (namaVal.test(nama_pemilik_rekening.target.value) === false) {
            this.setState({
                errNama: "Hanya berupa huruf"
            });
            this.setState({ nama_pemilik_rekening });
            return false;
        } else {
            this.setState({
                nama_pemilik_rekening: nama_pemilik_rekening.target.value.replace(/(?:^|\s)\S/g, function (a) {
                    return a.toUpperCase();
                }),
                errNama: false
            });
        }
    };

    togglemodal() {
        this.setState({
            modal: !this.state.modal,
            setModal: !this.state.setModal
        });
    }
    togglemodalEdit() {
        this.setState({
            modalEdit: !this.state.modalEdit,
            setModalEdit: !this.state.setModalEdit
        });
    }

    handleBank = (selectedOption1) => {
        this.setState({ nama_bank: selectedOption1.label });
    };

    //function save
    handleSaveRekening = async () => {
        if (this.state.errNumber === 0 && this.state.errNama === 0) {
            await this.props
                .dispatch(
                    UpdateRekening({
                        nama_bank: encryptedData(this.state.nama_bank),
                        nomor_akun_bank: encryptedData(this.state.nomor_akun_bank),
                        nama_pemilik_rekening: changeCapitalize(encryptedData(this.state.nama_pemilik_rekening))
                    })
                )
                .then(async (res) => {
                    // console.log(res)
                    this.setState({
                        bank: this.props.userBank
                    });
                    Swal.fire({
                        title: "Berhasil",
                        text: "Anda berhasil menambahkan rekening",
                        icon: "success",
                        buttons: "ok"
                    }).then(() => {
                        window.location.reload();
                    });
                    this.setState({
                        modal: false
                    });
                })
                .catch((err) => {
                    if (err.response.status === 400) {
                        Swal.fire({
                            title: "Tidak Berhasil",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (err.response.status === 404) {
                        Swal.fire({
                            title: "Tidak Berhasil",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    }
                });
        } else {
            Swal.fire({
                title: "Gagal Menambahkan",
                text: "Periksa kembali data Anda",
                icon: "warning",
                buttons: "ok"
            });
        }
    };

    fetchHandleEditRekening = async () => {
        await this.props
            .dispatch(
                EditRekening({
                    nama_bank: encryptedData(this.state.nama_bank),
                    nomor_akun_bank: encryptedData(this.state.nomor_akun_bank),
                    nama_pemilik_rekening: changeCapitalize(encryptedData(this.state.nama_pemilik_rekening)),
                    index: this.state.index
                })
            )
            .then(async (res) => {
                // console.log(res)
                // this.setState({
                //   bank: this.props.userBank,
                // });
                Swal.fire({
                    title: "Berhasil",
                    text: "Anda berhasil Edit rekening",
                    icon: "success",
                    buttons: "ok"
                }).then(() => {
                    window.location.reload();
                });
                this.setState({
                    modal: false
                });
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 400) {
                    Swal.fire({
                        title: "Tidak Berhasil",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (err.response.status === 404) {
                    Swal.fire({
                        title: "Tidak Berhasil",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                }
            });
    };

    handleEditRekening = (e, val, index) => {
        e.preventDefault();
        this.setState({
            nama_bank: val.nama_bank,
            nama_pemilik_rekening: val.nama_pemilik_rekening,
            nomor_akun_bank: val.nomor_akun_bank,
            index: index
        });
        this.setState({
            modalEdit: true
        });
    };

    handleDeleteRekening = async (e, index) => {
        e.preventDefault();
        Swal.fire({
            title: "Menghapus rekening",
            text: "Yakin ingin menghapus rekening ini ?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yakin"
        }).then(async (result) => {
            if (result.value) {
                await this.props
                    .dispatch(DeleteRekening(index))
                    .then((res) => {
                        // console.log(res)
                        this.setState({
                            bank: this.props.userBank
                        });
                        Swal.fire({
                            title: "Berhasil",
                            text: "Anda berhasil menghapus rekening",
                            icon: "success",
                            buttons: "ok"
                        }).then(() => {
                            window.location.reload();
                        });
                        this.setState({
                            modal: false
                        });
                    })
                    .catch((err) => {
                        if (err.response.status === 400) {
                            Swal.fire({
                                title: "Tidak Berhasil",
                                text: `${err.response.data.message}`,
                                icon: "warning",
                                button: "ok"
                            });
                        } else if (err.response.status === 404) {
                            Swal.fire({
                                title: "Tidak Berhasil",
                                text: `${err.response.data.message}`,
                                icon: "warning",
                                button: "ok"
                            });
                        }
                    });
            }
        });
    };
    handleSetAs = async (e, index) => {
        // console.log(this.props)
        e.preventDefault();
        Swal.fire({
            title: "Merubah rekening utama",
            text: "Yakin ingin menjadikan rekening utama ?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yakin"
        }).then(async (result) => {
            if (result.value) {
                await this.props
                    .dispatch(SetAsPrimaryRekening(index))
                    .then((res) => {
                        this.setState({
                            bank: this.props.userBank
                        });
                        Swal.fire({
                            title: "Berhasil",
                            text: "Anda berhasil menambahkan sebagai rekening utama",
                            icon: "success",
                            buttons: "ok"
                        }).then(() => {
                            window.location.reload();
                        });
                        this.setState({
                            modal: false
                        });
                    })
                    .catch((err) => {
                        if (err.response.status === 500) {
                            Swal.fire({
                                title: "Tidak Berhasil",
                                text: `${err.response.data.message}`,
                                icon: "warning",
                                button: "ok"
                            });
                        }
                    });
            }
        });
    };
    render() {
        const bank = () => {
            if (this.props.bank === undefined) {
                // console.log(this.state.jenis_kelaminList)
            } else {
                const hasil = this.props.bank.map((val) => {
                    return {
                        value: val.kode,
                        label: val.keterangan
                    };
                });
                // console.log(hasil)
                return hasil;
            }
        };
        const namabank = () => {
            if (this.props.bank === undefined) {
                // console.log('undefined')
            } else {
                const hasil = this.props.bank.map((val) => {
                    return val.keterangan + " ";
                });
                return hasil;
            }
        };
        const listBank = () => {
            if (this.props.userBank === undefined) {
                // console.log('yeye')
            } else {
                const hasil = this.props.userBank.map((val, index) => {
                    return (
                        <div className="row m-n3" key={val._id}>
                            <div className="col-12 p-3">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-4 col-md-6 col-xl-3 mb-3 mb-md-0">
                                                {val.nama_bank === namabank() ? (
                                                    ""
                                                ) : (
                                                    <img
                                                        className="img-fluid"
                                                        src={process.env.PUBLIC_URL + "/images/bank/" + val.nama_bank + ".png"}
                                                        alt="bank-img"
                                                    />
                                                )}
                                            </div>
                                            <div className="col-8 col-md-6 col-xl-4">
                                                <p className="mb-1">{val.nama_bank}</p>
                                                <p className="mb-1 fs-1 font-weight-semi-bold">{val.nomor_akun_bank}</p>
                                                <p className="mb-0">
                                                    {val.nama_pemilik_rekening ? val.nama_pemilik_rekening : localStorage.nama_pengguna}
                                                </p>
                                            </div>

                                            <div className="text-right col-6 col-xl-5 mt-4 mt-xl-0">
                                                {val.bank_utama === true ? (
                                                    <div>
                                                        <div className="d-flex align-items-center font-weight-semi-bold justify-content-xl-end mt-2">
                                                            REKENING UTAMA
                                                        </div>

                                                        <div className="d-flex align-items-center justify-content-xl-end mt-2">
                                                            {val.status === "Reject" && (val.verifikasi_bank === true || !val.verifikasi_bank) ? (
                                                                <>
                                                                    <a
                                                                        href="/#"
                                                                        className="text-secondary d-flex ml-4"
                                                                        onClick={(e) => {
                                                                            this.handleEditRekening(e, val, index);
                                                                        }}>
                                                                        <i className="material-icons">edit_document</i>
                                                                    </a>
                                                                    {/* <a
                                    href="/#"
                                    className="text-secondary d-flex ml-4"
                                    onClick={(e) =>
                                      this.handleDeleteRekening(e, index)
                                    }
                                  >
                                    <i className="material-icons">delete</i>
                                  </a> */}
                                                                </>
                                                            ) : val.status === "Approve" && (val.verifikasi_bank === true || !val.verifikasi_bank) ? (
                                                                ""
                                                            ) : (
                                                                <a
                                                                    href="/#"
                                                                    className="text-secondary d-flex ml-4"
                                                                    onClick={(e) => {
                                                                        this.handleEditRekening(e, val, index);
                                                                    }}>
                                                                    <i className="material-icons">edit_document</i>
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex align-items-center justify-content-xl-end mt-2">
                                                        {val.bank_utama === true ? (
                                                            ""
                                                        ) : (
                                                            <>
                                                                {val.verifikasi_bank && val.status !== "Approve" ? (
                                                                    <a
                                                                        href="/#"
                                                                        className="text-secondary d-flex ml-4"
                                                                        onClick={(e) => {
                                                                            this.handleEditRekening(e, val, index);
                                                                        }}>
                                                                        <i className="material-icons">edit_document</i>
                                                                    </a>
                                                                ) : null}
                                                                <a href="/#" className="text-secondary d-flex ml-4">
                                                                    <i className="material-icons" onClick={(e) => this.handleSetAs(e, index)}>
                                                                        edit
                                                                    </i>
                                                                </a>
                                                            </>
                                                        )}
                                                        {val.bank_utama === true || val.verifikasi_bank === true ? (
                                                            ""
                                                        ) : (
                                                            <a
                                                                href="/#"
                                                                className="text-secondary d-flex ml-4"
                                                                onClick={(e) => this.handleDeleteRekening(e, index)}>
                                                                <i className="material-icons">delete</i>
                                                            </a>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mt-4 mt-md-0 col-6 col-md-12">
                                                <div className="d-flex align-items-center justify-content-xl-end mt-2">
                                                    {val.verifikasi_bank && val.status === "Reject" ? (
                                                        <div>
                                                            <div
                                                                className="text-white btn btn-danger"
                                                                style={{ fontSize: "13px", cursor: "default" }}>
                                                                Nama/Nomor Rekening Tidak Sesuai
                                                            </div>
                                                        </div>
                                                    ) : val.verifikasi_bank === false ? (
                                                        <div className="text-white btn btn-danger" style={{ fontSize: "13px", cursor: "default" }}>
                                                            Belum verifikasi
                                                        </div>
                                                    ) : (
                                                        <div className="text-white btn btn-success" style={{ fontSize: "13px", cursor: "default" }}>
                                                            Terverifikasi
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                });
                return hasil;
            }
        };
        // console.log(this.props.userBank)
        return (
            <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                    <p className="mb-0 text-secondary mr-3">Rekening Bank digunakan untuk proses penarikan Saldo Indofund.id</p>
                    {this.props.userBank.length === 3 ? (
                        ""
                    ) : (
                        <Button
                            data-toggle="modal"
                            data-target="#rekeningModal"
                            color="outline-primary"
                            className="btn ml-auto px-4"
                            onClick={this.togglemodal.bind(this)}>
                            Tambah Rekening
                        </Button>
                    )}
                </div>

                {this.props.userBank.length !== 0 ? (
                    <div>{listBank()}</div>
                ) : (
                    <NoData symbol={<i className="material-icons">credit_card</i>} keterangan={"Belum ada Rekening"} />
                )}
                {/* Modal add/edit rekening */}
                <Modal isOpen={this.state.modal} toggle={this.togglemodal.bind(this)} className="modal-dialog modal-dialog-centered" tabIndex="-1">
                    <ModalHeader toggle={this.togglemodal.bind(this)}>
                        <div className="text-center flex-fill">
                            <h5 className="modal-title font-weight-bold">Tambah Rekening</h5>
                            <p className="mb-0">Pastikan Informasi yang dimasukkan benar</p>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group mb-4">
                            <label htmlFor="nama_pemilik_rekening">Nama Pemilik Rekening</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="nama_pemilik_rekening"
                                onChange={(e) => this.handleChange(e, "string", "errNama")}
                                placeholder="Masukkan Nama Pemilik Rekening"
                            />
                            <FormText color="danger">{this.state.errNama === 0 ? null : this.state.errNama}</FormText>
                        </div>
                        <div className="form-group">
                            <FormGroup>
                                <Label htmlFor="nama bank">Nama Bank</Label>
                                <Select onChange={this.handleBank} options={bank()} />
                            </FormGroup>
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="nomor_rekening">Nomor Rekening</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="nomor_akun_bank"
                                onChange={(e) => this.handleChange(e, "number", "errNumber")}
                                placeholder="Masukkan Nomor Rekening"
                            />
                            <FormText color="danger">{this.state.errNumber === 0 ? null : this.state.errNumber}</FormText>
                        </div>
                        {this.props.user.isLoading ? (
                            <Button disabled type="submit" color="primary" className="btn btn-block btn-lg" onClick={this.handleSaveRekening}>
                                <span className="loader"></span>
                            </Button>
                        ) : (
                            <Button type="submit" color="primary" className="btn btn-block btn-lg" onClick={this.handleSaveRekening}>
                                Simpan
                            </Button>
                        )}
                    </ModalBody>
                </Modal>
                {/* modal edit rekening */}
                <Modal
                    isOpen={this.state.modalEdit}
                    toggle={this.togglemodalEdit.bind(this)}
                    className="modal-dialog modal-dialog-centered"
                    tabIndex="-1">
                    <ModalHeader toggle={this.togglemodalEdit.bind(this)}>
                        <div className="text-center flex-fill">
                            <h5 className="modal-title font-weight-bold">Edit Rekening</h5>
                            <p className="mb-0">Pastikan Informasi yang dimasukkan benar</p>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group mb-4">
                            <label htmlFor="nama_pemilik_rekening">Nama Pemilik Rekening</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="nama_pemilik_rekening"
                                value={this.state.nama_pemilik_rekening}
                                onChange={(e) => this.handleChange(e, "string", "errNama")}
                                // placeholder="Masukkan Nama Pemilik Rekening"
                            />
                            <FormText color="danger">{this.state.errNama === 0 ? null : this.state.errNama}</FormText>
                        </div>
                        <div className="form-group">
                            <FormGroup>
                                <Label htmlFor="nama bank">Nama Bank</Label>
                                <Select onChange={this.handleBank} options={bank()} />
                            </FormGroup>
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="nomor_rekening">Nomor Rekening</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="nomor_akun_bank"
                                value={this.state.nomor_akun_bank}
                                onChange={(e) => this.handleChange(e, "number", "errNumber")}
                                // placeholder="Masukkan Nomor Rekening"
                            />
                            <FormText color="danger">{this.state.errNumber === 0 ? null : this.state.errNumber}</FormText>
                        </div>
                        {this.props.user.isLoading ? (
                            <Button disabled type="submit" color="primary" className="btn btn-block btn-lg" onClick={this.fetchHandleEditRekening}>
                                <span className="loader"></span>
                            </Button>
                        ) : (
                            <Button type="submit" color="primary" className="btn btn-block btn-lg" onClick={this.fetchHandleEditRekening}>
                                Simpan
                            </Button>
                        )}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps)(TambahRekening);
