import React, { Component } from "react";
import { TabContent, TabPane, Col, Table } from "reactstrap";
import Accordion from "./Accordion";

class FaqContent extends Component {
    state = {
        isOpen: false,
        setIsOpen: false
    };

    toggle() {
        this.setState({
            setIsOpen: !this.state.setIsOpen,
            isOpen: !this.state.isOpen
        });
    }
    render() {
        return (
            <Col lg="8">
                <TabContent activeTab={this.props.activeTab}>
                    <TabPane tabId="1">
                        <Accordion
                            panel="panel1"
                            title="Apa itu Indofund.id ?"
                            body={
                                <div>
                                    <p>
                                        Indofund.id adalah sebuah portal pendanaan bersama berbasis teknologi (P2P Lending) dengan menggunakan website
                                        bernama Indofund.id
                                    </p>
                                    <p>
                                        Untuk mengetahui secara lengkap mengenai Indofund.id silahkan kunjungi halaman{" "}
                                        <a href="/tentang-profil">Tentang Kami</a>
                                    </p>
                                </div>
                            }
                        />
                    </TabPane>
                    <TabPane tabId="2">
                        <Accordion
                            panel="panel1"
                            title="Produk apa saja di Indofund?"
                            body={
                                <div>
                                    <ol>
                                        <li>
                                            <p>
                                                <i>Invoice Financing</i>
                                            </p>
                                            <p>Pendanaan berbasis tagihan yang dibuktikan dengan document tagihan / invoice itu sendiri</p>
                                        </li>
                                        <li>
                                            <p>Project Financing</p>
                                            <p>
                                                Pendanaan berbasis project / pekerjaan yang dibuktikan dengan document yang berkaitan dengan project
                                                seperti kontrak / SPK.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Pendanaan Multiguna
                                            </p>
                                            <p>Pendanaan multiguna kepada personal/ perorangan dengan analisa pembayaran berbasis pendapatan atau usaha.</p>
                                        </li>
                                        <li>
                                            <p>
                                                <i>Working Capital Financing</i>
                                            </p>
                                            <p>
                                                Pendanaan berbasis kebutuhan modal kerja yang dibuktikan dengan perputaran dana di rekening koran
                                                dengan berbasis pada analisa arus kas penerima dana.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <i>Employee Loan</i>
                                            </p>
                                            <p>Pendanaan yang diberikan kepada karyawan perusahaan yang telah menjadi partner dari Indofund.id.</p>
                                        </li>
                                    </ol>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel2"
                            title="Bagaimana cara mengajukan Pendanaan?"
                            body={
                                <div>
                                    <div className="row">
                                        <div className="col-md-4" style={{ textAlign: "center" }}>
                                            <img
                                                className="img-fluid mb-5 mb-lg-1"
                                                src={process.env.PUBLIC_URL + "images/faq/kerja-borrower-1.png"}
                                                alt="gambar"
                                            />
                                            <p>
                                                Calon penerima dana mengajukan pendanaan melalui website Indofund.id dengan melengkapi form dan data
                                            </p>
                                        </div>
                                        <div className="col-md-4" style={{ textAlign: "center" }}>
                                            <img
                                                className="img-fluid mb-5 mb-lg-1"
                                                src={process.env.PUBLIC_URL + "images/faq/kerja-borrower-2.png"}
                                                alt="gambar"
                                            />
                                            <p>
                                                Pendanaan disetujui oleh Indofund.id dan pendanaan ditawarkan kepada pemberi dana di website
                                                Indofund.id
                                            </p>
                                        </div>
                                        <div className="col-md-4" style={{ textAlign: "center" }}>
                                            <img
                                                className="img-fluid mb-5 mb-lg-1"
                                                src={process.env.PUBLIC_URL + "images/faq/kerja-borrower-3.png"}
                                                alt="gambar"
                                            />
                                            <p>
                                                Penerima dana melunasi pendanaan terhadap pemberi dana sesuai dengan tenggat waktu yang diberikan
                                                beserta dengan bunga pendanaan
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            }
                        />

                        <Accordion
                            panel="panel3"
                            title="Siapa yang berhak mengajukan pendanaan di Indofund?"
                            body={
                                <div>
                                    <ol>
                                        <li>
                                            <p>Badan Usaha berbentuk PT atau CV</p>
                                            <p>Dikhususkan untuk produk Invoice Financing, Project Financing dan Working Capital Financing</p>
                                        </li>
                                        <li>
                                            <p>Personal</p>
                                            <p>Dikhususkan untuk produk Pendanaan Multiguna & Employee Loan</p>
                                        </li>
                                    </ol>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel4"
                            title="Persyaratan umum untuk mengajukan pendanaan "
                            body={
                                <div>
                                    <ol>
                                        <li>
                                            <p>Invoice Financing, Project Financing dan Working Capital Financing</p>
                                            <ul>
                                                <li>
                                                    <p>Badan usaha berbentuk PT / CV.</p>
                                                </li>
                                                <li>
                                                    <p>Usaha minimal 2 tahun</p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Menyerahkan fotocopy legalitas Usaha ( Akte pendirian beserta perubahan terakhir , SIUP, TDP,
                                                        NPWP )
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>Menyerahkan fotocopy Laporan Keuangan 2 tahun terakhir</p>
                                                </li>
                                                <li>
                                                    <p>Menyerahkan fotocopy rekening Koran 3 bulan terakhir</p>
                                                </li>
                                                <li>
                                                    <p>Dapat memberikan jaminan berupa : Giro dan personal guarantee</p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <p>Pendanaan Multiguna</p>
                                            <ul>
                                                <li>
                                                    <p>Memiliki usaha yang sudah berjalan minimal 1 tahun</p>
                                                </li>
                                                <li>
                                                    <p>Memiliki surat keterangan usaha</p>
                                                </li>
                                                <li>
                                                    <p>Menyerahkan fotocopy tabungan 3 bulan terakhir.</p>
                                                </li>
                                                <li>
                                                    <p>Menyerahkan fotocopy KTP dan Kartu Keluarga</p>
                                                </li>
                                                <li>
                                                    <p>Menyerahkan foto tempat usaha </p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <p>Employee Loan</p>
                                            <ul>
                                                <li>
                                                    <p>Merupakan karyawan tetap dari partner / mitra Indofund.</p>
                                                </li>
                                                <li>
                                                    <p>Minimal telah bekerja selama 1 tahun di perusahaan partner</p>
                                                </li>
                                                <li>
                                                    <p>Memberikan surat keterangan dari HRD tempat bekerja.</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel5"
                            title="Plafon pendanaan yang dapat di mohon ?"
                            body={
                                <div>
                                    <p>Plafon Pendanaan</p>
                                    <ol>
                                        <li>
                                            <p>Untuk Invoice Financing, Project Financing dan Working Capital Financing</p>
                                            <p>Minimal Rp. 10.000.000 (sepuluh juta rupiah), Maksimal Rp. 2.000.000.000 (dua milyar rupiah)</p>
                                        </li>
                                        <li>
                                            <p>Pendanaan Multiguna</p>
                                            <p>Minimal Rp. 10.000.000 (sepuluh juta rupiah), Maksimal Rp. 25.000.000 (dua puluh lima juta rupiah)</p>
                                        </li>
                                        <li>
                                            <p>Employee Loan</p>
                                            <p>Maksimal 3,5 bulan x Gaji bulanan Penerima dana</p>
                                        </li>
                                    </ol>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel6"
                            title="Berapa tenor pendanaan yang dapat diberikan Indofund.id"
                            body={
                                <div>
                                    <p>Lama pendanaan</p>
                                    <p>1 – 12 bulan.</p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel7"
                            title="Berapa besaran bunga pendanaan yang dapat diberikan di indofund?"
                            body={
                                <div>
                                    <p>Bunga dikenakan berdasarkan hasil scoring kredit penerima dana dengan range bunga antara 12% - 24%</p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel8"
                            title="Berapa biaya yang dikenakan Indofund kepada penerima dana?"
                            body={
                                <div>
                                    <p>Indofund mengenakan Biaya admin sebesar 3 - 5 %. </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel9"
                            title="Apakah ada fee diluar Biaya admin ?"
                            body={
                                <div>
                                    <p>Indofund.id tidak mengenakan fee apapun selain Biaya Admin. </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel10"
                            title="Bagaimana cara mendaftarkan diri sebagai penerima dana pada Indofund.id ?"
                            body={
                                <div>
                                    <ol>
                                        <li>
                                            <p>Calon penerima dana dapat mendaftarkan diri pada https://indofund.id</p>
                                        </li>
                                        <li>
                                            <p>Melakukan registrasi dengan memasukkan alamat email dan kata sandi</p>
                                        </li>
                                        <li>
                                            <p>Isi dan lengkapi semua informasi yang dibutuhkan pada formulir pendaftaran penerima dana</p>
                                        </li>
                                        <li>
                                            <p>Unggah semua dokumen yang dipersyaratkan pada formulir pendaftaran</p>
                                        </li>
                                        <li>
                                            <p>
                                                Periksa kembali kebenaran dan kelengkapan semua informasi / data yang telah dimasukkan sebelum dikirim
                                                kepada Indofund.id.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Tunggu proses verifikasi dari admin Indofund. Dalam Proses verifikasi juga dilakukan kunjungan ke
                                                tempat usaha dimana jadwal kunjungan akan diinformasikan oleh tim indofund.id kepada calon penerima
                                                dana secara langsung.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Apabila semua persyaratan sudah terpenuhi, maka anda sudah resmi terdaftar sebagai penerima dana di
                                                Indofund.id dan dapat mengajukan pendanaan.
                                            </p>
                                        </li>
                                    </ol>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel11"
                            title="Bagaimana cara mengajukan pendanaan di Indofund ?"
                            body={
                                <div>
                                    <p>Bagaimana cara mengajukan pendanaan di Indofund ? </p>
                                    <ol>
                                        <li>
                                            <p>Pastikan anda sudah terdaftar sebagai penerima dana di Indofund</p>
                                        </li>
                                        <li>
                                            <p>
                                                Masuk ke menu <i>dashboard</i> penerima dana
                                            </p>
                                        </li>
                                        <li>
                                            <p>Isi formulir pengajuan pendanaan dan lengkapi dokumen pendukung yang dipersyaratkan.</p>
                                        </li>
                                        <li>
                                            <p>Pastikan data yang diisi telah lengkap dan benar.</p>
                                        </li>
                                        <li>
                                            <p>Tunggu proses verifikasi dari admin Indofund.id</p>
                                        </li>
                                        <li>
                                            <p>
                                                Apabila disetujui, anda akan dikirimkan <i>term sheet</i> pendanaan.{" "}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Tandatangani <i>term sheet</i> secara digital maka project pendanaan anda akan otomatis terdaftar di
                                                platform Indofund.id.
                                            </p>
                                        </li>
                                    </ol>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel12"
                            title="Kapan saya dapat menerima dana atas pencairan dana ?"
                            body={
                                <div>
                                    <p>
                                        Pencairan dana atas pendanaan akan dikirimkan ke rekening anda maksimal 2x24 jam setelah dana terkumpul dan
                                        jaminan yang dipersyaratkan telah diserahkan kepada Indofund.id.{" "}
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel13"
                            title="Bagaimana saya melakukan pembayaran angsuran pendanaan ?"
                            body={
                                <div>
                                    <p>
                                        Pembayaran angsuran pendanaan dapat dikirimkan ke rekening VA yang tertera pada <i>dashboard</i> penerima
                                        dana.{" "}
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel14"
                            title="Berapa batas minimum dan maksimal pendanaan yang dapat diajukan?"
                            body={
                                <div>
                                    <p>
                                        Batas minimum nominal pendanaan adalah sebesar Rp 5.000.000,- ( Lima juta rupiah), sedangkan batas maksimal
                                        pendanaan adalah sebesar Rp 2.000.000.000,- ( Dua milyar rupiah )
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel15"
                            title="Apakah perusahaan yang baru berdiri (Start up / Scratch / Concept) bisa mengajukan pendanaan?"
                            body={
                                <div>
                                    <p>
                                        Untuk perusahaan yang baru berdiri dan belum memiliki pendapatan yang dapat dijadikan rekam jejak riwayat
                                        perusahaan , Anda tidak dapat langsung mengajukan pendanaan ke Indofund.id. Namun Anda dapat menghubungi kami
                                        untuk berdiskusi dan mendapatkan informasi mengenai hal tersebut.
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel16"
                            title="Berapa besaran suku bunga yang dikenakan di Indofund.id?"
                            body={
                                <div>
                                    <p>
                                        Suku bunga yang dikenakan akan diperhitungkan oleh tim analis Indofund.id melalui sistem penilaian kredit.
                                        Suku bunga yang dikenakan diberikan berdasarkan rating kredit Anda.
                                    </p>
                                    <p>Rating kredit di Indofund.id :</p>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Rating Kredit</th>
                                                <th>Suku Bunga (efektif per tahun)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>A</td>
                                                <td>&gt;= 12%</td>
                                            </tr>
                                            <tr>
                                                <td>B</td>
                                                <td>12% - 16%</td>
                                            </tr>
                                            <tr>
                                                <td>C</td>
                                                <td>16% - 24%</td>
                                            </tr>
                                            <tr>
                                                <td>D</td>
                                                <td>24% - 30%</td>
                                            </tr>
                                            <tr>
                                                <td>E</td>
                                                <td>30% - 36%</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel17"
                            title="Apa itu bunga flat"
                            body={
                                <div>
                                    <p>Bunga flat adalah sistem perhitungan bunga yang besaranya dihitung berdasarkan jumlah pokok utang awal.</p>

                                    <p>
                                        Bunga flat dihitung dari jumlah awal pokok pendanaan yang kemudian dibagikan secara proporsional sesuai tenor
                                        (jangka waktu) pendanaan.{" "}
                                    </p>

                                    <p>
                                        <b>Contoh kasus:</b>
                                        <br />
                                        Jumlah pendanaan = Rp. 120.000.000 (seratus dua puluh rupiah)
                                        <br />
                                        Bunga = 12% per tahun <br />
                                        Jangka waktu = 12 bulan
                                    </p>

                                    <p>
                                        <b>Perhitungan:</b>
                                        <br />
                                        Angsuran pokok per bulan = Rp. 120.000.000 / 12 bulan = Rp. 10.000.000. <br />
                                        Angsuran bunga per bulan = 1% x Rp. 120.000.000 = Rp. 1.200.000. <br />
                                        Total angsuran per bulan = Rp. 10.000.000 + Rp. 1.200.000 = Rp. 11.200.000.
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel18"
                            title="Apa itu bunga efektif"
                            body={
                                <div>
                                    <p>
                                        Bunga efektif adalah sistem perhitungan bunga dimana porsi bunga setiap bulannya dihitung berdasarkan pokok
                                        utang tersisa.
                                    </p>
                                    <p>
                                        <b>Rumus:</b> <br />
                                        Angsuran = pokok x ((i/12)/(1-1/(1+i/12)^t))) <br />
                                        i = suku bunga per tahun <br />t = jangka waktu pendanaan dalam bulan
                                    </p>
                                    <p>
                                        <b>Contoh perhitungan:</b>
                                        <br />
                                        Jumlah pendanaan = Rp. 10.000.000 <br />
                                        Bunga = 10% per tahun <br />
                                        Jangka waktu = 4 bulan
                                    </p>
                                    <p>
                                        <b>Perhitungan:</b> <br />
                                    </p>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Bulan</th>
                                                <th>Saldo Awal</th>
                                                <th>Angsuran</th>
                                                <th>Pokok</th>
                                                <th>Bunga</th>
                                                <th>Saldo Akhir</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Rp. 10.000.000</td>
                                                <td>Rp. 2.552.299</td>
                                                <td>Rp.2.468.966</td>
                                                <td>Rp. 83.333</td>
                                                <td>Rp. 83.333</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Rp. 7.531.034</td>
                                                <td>Rp. 2.552.299</td>
                                                <td>Rp. 2.489.541</td>
                                                <td>Rp. 62.759</td>
                                                <td>Rp. 5.041.493</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Rp. 5.041.493</td>
                                                <td>Rp. 2.552.299</td>
                                                <td>Rp. 2.510.287</td>
                                                <td>Rp. 42.012</td>
                                                <td>Rp. 2.531.206</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Rp. 2.531.206</td>
                                                <td>Rp. 2.552.299</td>
                                                <td>Rp. 2.531.206</td>
                                                <td>Rp. 21.093</td>
                                                <td>Rp. 0</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel19"
                            title="Apakah pendanaan akan dibayarkan setiap bulan? Apakah itu pokok dan bunganya saja, atau ada yang lainnya ?"
                            body={
                                <div>
                                    <p>
                                        Skema pembayaran pada umumnya dilakukan setiap bulan sesuai jumlah yang ditentukan setiap bulannya hingga
                                        lunas, dibayarkan pokok cicilan dan bunganya. Namun analis kredit juga berhak mengubah sistem pembayaran baik
                                        cicilan bunganya saja hingga periode tertentu, maupun dengan kombinasi lainnya yang sesuai dengan usaha dan
                                        industri yang Anda miliki dengan motivasi utama yakni tidak memberatkan usaha yang Anda jalani.
                                    </p>
                                    <p>Sehingga dalam hal ini pembayaran pada Indofund.id bisa saja berbeda-beda dalam setiap pengajuan Pendanaan.</p>
                                </div>
                            }
                        />

                        <Accordion
                            panel="panel20"
                            title="Bagaimana mekanisme pembayaran cicilan ?"
                            body={
                                <div>
                                    <p>
                                        Perjanjian pendanaan bersama akan ada ketika hari pertama dana sudah tersedia pada saldo pada akun Anda. Dan
                                        masa pembayaran angsuran untuk bulan pertama adalah di hari tersebut hingga 30 hari kedepan (Untuk lebih jelas
                                        bisa dilihat pada bagan)
                                    </p>
                                    <p>
                                        Pembayaran cicilan dilakukan melalui <i>virtual akun</i> (VA) yang telah diterbitkan dan akan terkonfirmasi
                                        otomatis pada sistem selama besaran nominal yang dibayarkan sesuai dengan besaran yang ada di detail
                                        pendanaan.
                                    </p>
                                    <p>Untuk lebih jelasnya, silahkan membaca ilustrasi berikut :</p>
                                    <ul>
                                        <li>
                                            <p>1 Februari 2018 – Tuan A mengajukan pendanaan di Indofund.id sebesar Rp 12.000.000</p>
                                        </li>
                                        <li>
                                            <p>
                                                3 Februari 2018 – Tuan A mendapatkan konfirmasi bahwa pendanaan disetujui sebesar Rp 12.000.000 dengan
                                                jangka waktu 12 bulan dengan besar angsuran adalah Rp 1.020.000 setiap bulan, dan Tuan A menyetujuinya
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                4 Februari 2018 – Proyek Tuan A muncul di website Indofund.id dengan masa pengumpulan dana hingga 14
                                                Februari 2018
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                10 Februari 2018 – Dana telah terkumpul 100% dan dana telah siap di saldo pada akun Tuan A pada
                                                Indofund.id
                                            </p>
                                        </li>
                                    </ul>
                                    <p>
                                        Pada perjanjian pendanaan, Dana dikeluarkan oleh Indofund.id untuk Tuan A yaitu 10 Februari 2018 – 10 Februari
                                        2019
                                    </p>
                                    <p>Masa pembayaran angsuran adalah sebagai berikut :</p>
                                    <ul>
                                        <li>
                                            <p>10 Feb 18 – 9 Mar 18 : Rp 1.020.000</p>
                                        </li>
                                        <li>
                                            <p>10 Mar 18 – 9 Apr 18 : Rp 1.020.000</p>
                                        </li>
                                        <li>
                                            <p>10 Apr 18 – 10 Mei 18 : Rp 1.020.000</p>
                                        </li>
                                        <li>
                                            <p>dst</p>
                                        </li>
                                    </ul>
                                    <p>
                                        Dalam kurun waktu 10 Feb 18 – 9 Mar 18, Tuan A dapat membayarkan angsuran pertamanya sebesar 1.020.000 melalui
                                        rekening <i>virtual account</i> yang tersedia
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel21"
                            title="Apakah ada biaya keterlambatan bila penerima dana terlambat dalam melakukan pembayaran?"
                            body={
                                <div>
                                    <p>
                                        Anda akan dikenakan denda keterlambatan sebesar 3% per bulan yang dihitung dari cicilan tertunggak. Denda akan
                                        dihitung per hari sejak jatuh tempo pembayaran sampai tanggal anda melakukan pembayaran.
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel22"
                            title="Apakah pelunasan dipercepat diperbolehkan dan apakah ada biaya penalty untuk pembayaran dipercepat ?"
                            body={
                                <div>
                                    <p>Anda dapat melakukan pelunasan dipercepat serta tidak akan dikenakan biaya apapun.</p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel23"
                            title="Apakah mendapatkan pendanaan di Indofund.id membutuhkan jaminan?"
                            body={
                                <div>
                                    <p>
                                        Setiap penerima dana wajib memberikan jaminan namun jaminan yang diberikan tidak harus berupa aset tetap. Anda
                                        dapat memberikan jaminan dalam bentuk lain namun jenis jaminan yang diberikan akan turut mempengaruhi rating
                                        pendanaan Anda dan bunga pendanaan Anda.
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel24"
                            title="Apakah saya perlu menghubungi tim Indofund.id untuk menanyakan status pengajuan pendanaan ?"
                            body={
                                <div>
                                    <p>
                                        Tidak, anda tidak perlu menghubungi Indofund. Status pengajuan pendanaan dapat dilihat pada <i>dashboard</i>{" "}
                                        penerima dana.
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel25"
                            title="Apakah saya dapat mengajukan keberatan atas ditolaknya pengajuan menjadi penerima dana ?"
                            body={
                                <div>
                                    <p>
                                        Tidak, Indofund berhak menolak pengajuan Anda dan tidak mempunyai kewajiban untuk menyampaikan alasan
                                        penolakan pengajuan tersebut. Namun anda tetap diperbolehkan mengajukan permohonan kembali setelah tenggat
                                        waktu 3 bulan.
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel26"
                            title="Selama proses pengumpulan dana di Indofund.id, apakah saya boleh mengajukan pembatalan pengajuan pendanaan ?"
                            body={
                                <div>
                                    <p>
                                        Anda boleh mengajukan pembatalan selama dana belum dikirimkan kepada Anda, namun Anda akan dikenakan biaya
                                        pembatalan sebesar 5% dari nominal pengajuan Anda.
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel27"
                            title="Apakah saya bertanggung jawab penuh atas semua informasi dan dokumen yang diberikan ?"
                            body={
                                <div>
                                    <p>
                                        Anda bertanggung jawab sepenuhnya atas informasi dan dokumen yang diserahkan kepada indofund.id. Bilamana
                                        terdapat informasi dan dokumen yang tidak benar dan menimbulkan kerugian terhadap Indofund.id ataupun pemberi
                                        dana di kemudian hari maka tidak tertutup kemungkinan Indofund.id akan melakukan tindakan hukum kepada anda
                                        sesuai dengan hukum yang berlaku di Indonesia.
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel28"
                            title="Bagaimana jika saya gagal bayar ?"
                            body={
                                <div>
                                    <p>
                                        Indofund.id akan melakukan eksekusi jaminan yang dijaminkan. Indofund.id memiliki hak untuk menuntut penerima
                                        pendanaan di pengadilan perdata sesuai dengan kesepekatan yang telah tercantum di perjanjian pendanaan. Segala
                                        biaya yang dikeluarkan Indofund.id sehubungan proses penagihan pendanaan yang tertunggak akan di beban kan
                                        kepada penerima pendanaan.
                                    </p>
                                </div>
                            }
                        />
                    </TabPane>
                    <TabPane tabId="3">
                        <Accordion
                            panel="panel1"
                            title="Bagaimana menjadi pemberi dana di Indofund.id ?"
                            body={
                                <div>
                                    <div className="row">
                                        <div className="col-md-4" style={{ textAlign: "center" }}>
                                            <img
                                                className="img-fluid mb-5 mb-lg-1"
                                                src={process.env.PUBLIC_URL + "images/faq/kerja-lender-1.png"}
                                                alt="gambar"
                                            />
                                            <p>Pemberi dana mendaftarkan diri melalui formulir pendaftaran Indofund.id</p>
                                        </div>
                                        <div className="col-md-4" style={{ textAlign: "center" }}>
                                            <img
                                                className="img-fluid mb-5 mb-lg-1"
                                                src={process.env.PUBLIC_URL + "images/faq/kerja-lender-2.png"}
                                                alt="gambar"
                                            />
                                            <p>
                                                Pemberi dana mencari pendanaan yang sesuai dengan profil resiko dari list project pendanaan yang
                                                tersedia di indofund.id
                                            </p>
                                        </div>
                                        <div className="col-md-4" style={{ textAlign: "center" }}>
                                            <img
                                                className="img-fluid mb-5 mb-lg-1"
                                                src={process.env.PUBLIC_URL + "images/faq/kerja-lender-3.png"}
                                                alt="gambar"
                                            />
                                            <p>pemberi dana menerima pembayaran atas pendanaan yang diberikan di indofund.id</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel2"
                            title="Berapa bunga yang akan saya terima ?"
                            body={
                                <div>
                                    <p>Berbeda-beda sesuai dengan proyek yang Anda danai. Silahkan Anda cek proyek mengenai ilustrasi pendanaan</p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel3"
                            title="Apakah cicilan setiap bulan akan di transfer ke rekening saya?"
                            body={
                                <div>
                                    <p>
                                        Setiap pembayaran cicilan akan dikirimkan ke saldo pada user akun Anda di Indofund.id dan dapat Anda Tarik{" "}
                                        <i>(withdraw)</i> kapanpun dengan lama penarikan dana adalah 2x24 jam
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel4"
                            title="Berapa penarikan dana minimum dari saldo?"
                            body={
                                <div>
                                    <p>Besaran penarikan dana minimum adalah Rp 50.000</p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel5"
                            title="Apakah ada biaya penarikan?"
                            body={
                                <div>
                                    <p>
                                        Indofund.id mengenakan biaya transfer sebesar Rp. 3.500,- setiap penarikan dana dibawah 100 juta sedangkan
                                        untuk penarikan dana diatas 100 juta dikenakan biaya sebesar Rp. 25.000,-
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel6"
                            title="Apa yang dimaksud dengan TKB90 ?"
                            body={
                                <div>
                                    <p>
                                        adalah ukuran tingkat keberhasilan penyelenggara layanan pendanaan bersama berbasis teknologi informasi dalam
                                        memfasilitasi kewajiban pendanaan bersama mendapatkan pendanaan dalam jangka waktu sampai dengan 90 hari sejak
                                        jatuh tempo.
                                    </p>
                                    <p>TKB90 hari dihitung dari 100% dikurangi nilai TKW90.</p>
                                    <p style={{ fontWeight: "bold", textAlign: "center" }}>TKB90 = 100% - TKW90</p>
                                    <p>
                                        TKW90 ialah tingkat wanprestasi atau kelalaian penyelesaian kewajiban di atas 90 (sembilan puluh) hari sejak
                                        tanggal jatuh tempo. TKW90 dihitung dari outstanding wanprestasi di atas 90 hari dibagi dengan total
                                        outstanding, dikali 100%
                                    </p>
                                    <p style={{ fontWeight: "bold", textAlign: "center" }}>
                                        TKW90 = (Outstanding wanprestasi &gt; 90 hari/ Total Outstanding) x 100%
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel7"
                            title="Apakah antara pemberi dana dan penerima dana memiliki kesepakatan yang mengikat secara hukum?"
                            body={
                                <div>
                                    <p>
                                        Ya. Perjanjian pendanaan antara pemberi dana dan penerima dana akan disahkan melalui persetujuan di antara
                                        kedua belah pihak secara elektronik dan mengikat secara hukum.
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel8"
                            title="Apa yang terjadi bila penerima dana terlambat membayar angsuran dana yang telah diterima?"
                            body={
                                <div>
                                    <p>
                                        Akan terdapat denda harian atas keterlambatan yang dapat Anda periksa di perjanjian pendanaan bersama,
                                        silahkan cek perjanjian tersebut di email Anda pertama kali saat proses pendanaan berhasil.
                                    </p>
                                    <p>Silahkan cek juga poin selanjutnya mengenai gagal bayar.</p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel9"
                            title="Apakah ada potensi gagal bayar dari penerima dana?"
                            body={
                                <div>
                                    <p>
                                        Potensi gagal bayar dari setiap penerima dana tetap ada namun resiko gagal bayar ini berusaha diminimalisir
                                        oleh Indofund.id dengan menerapkan prinsip kehati-hatian dalam menganalisa setiap pendanaan yang diajukan.
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel10"
                            title="Apa yang dilakukan oleh Indofund.id bilamana gagal bayar terjadi ?"
                            body={
                                <div>
                                    <p>
                                        Indofund.id tidak memberikan jaminan atas pengembalian dana dari penerima dana ke pemberi dana. Akan tetapi
                                        bilamana gagal bayar terjadi, Indofund.id akan melakukan salah satu hal di bawah ini :
                                    </p>
                                    <ul>
                                        <li>
                                            <p>
                                                Indofund.id akan melakukan penagihan baik yang dilakukan oleh tim internal Indofund.id ataupun melalui
                                                jasa penagihan pihak ketiga.
                                            </p>
                                        </li>
                                        <li>
                                            <p>Melikuidasi jaminan yang diberikan oleh penerima dana.</p>
                                        </li>
                                        <li>
                                            <p>Merestrukturisasi pendanaan.</p>
                                        </li>
                                    </ul>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel11"
                            title="Apakah ada Jaminan dari Indofund.id atas dana yang diberikan pemberi dana ke penerima dana ?"
                            body={
                                <div>
                                    <p>
                                        Indofund.id menyarankan agar calon pemberi dana untuk mempelajari dan memahami resiko dalam pendanaan kepada
                                        Peer to Peer lending sebelum melakukan pendanaan sehingga calon pemberi dana dapat melakukan pertimbangan dan
                                        membuat keputusan yang tepat.
                                    </p>
                                    <p>
                                        Sebagai penyedia layanan peer to peer lending yang terdaftar dan diawasi oleh OJK, Indofund.id tidak
                                        diperkenankan untuk menanggung atau menjamin pengembalian dana pemberi dana dimana hal ini sesuai dengan POJK
                                        No 77 Tahun 2016.
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel12"
                            title="Bagaimana jika saya ingin memperbarui informasi bank?"
                            body={
                                <div>
                                    <p>
                                        Untuk memperbarui informasi bank Anda, silakan mengirimkan email ke <b>cs@indofund.id</b> dengan subject
                                        "Perbarui Informasi Akun Bank". Pastikan Anda juga menyertakan foto halaman depan buku tabungan agar kami
                                        dapat melakukan verifikasi ulang bahwa informasi yang Anda kirimkan adalah benar.
                                    </p>
                                </div>
                            }
                        />
                        {/* <Accordion
                            panel="panel13"
                            title="Apakah pendanaan kita aman ?"
                            body={
                                <div>
                                    <p>
                                        Pedanaan anda di indofund dilindungi oleh asuransi{" "}
                                        <a href="/dana-proteksi" target="_blank">
                                            lihat selengkapnya
                                        </a>
                                    </p>
                                </div>
                            }
                        /> */}
                        <Accordion
                            panel="panel13"
                            title="Apa yang dimaksud dengan auto-withdraw?"
                            body={
                                <div>
                                    <p>
                                        Untuk memenuhi regulasi T+2 yang ditentukan oleh Otoritas Jasa Keuangan (OJK), maka Indofund akan menerapkan
                                        sistem <i>auto-withdraw</i>. Dimana saldo pada dompet akun Indofund yang mengendap lebih dari dua hari akan
                                        dikenakan proses <i>auto-withdraw</i> (ditarik dan dikembalikan ke rekening bank terdaftar atau Dipay secara
                                        otomatis).
                                    </p>
                                    <p>Pengembalian akan dilakukan setiap hari senin, rabu dan jumat.</p>

                                    <p>
                                        Apabila Pemberi dana sudah melakukan aktivasi akun Dipay maka saldo akan dipindahkan ke Dipay pemberi dana,
                                        dalam hal belum dilakukan aktivasi akun Dipay makan akan dipindahkan ke rekening bank utama pemberi dana
                                    </p>

                                    <p>
                                        <i>Auto-withdraw</i> ke rekening bank pemberi dana akan dikenakan biaya admin sama seperti tarik saldo pada
                                        umumnya, yaitu Rp 3.500.
                                        <i>Auto-withdraw</i> melalui Dipay tidak dikenakan biaya admin.
                                    </p>

                                    <p>
                                        Untuk kemudahan mendanai di proyek selanjutnya, kami menganjurkan agar setiap pemberi dana mempunyai akun
                                        Dipay.
                                    </p>
                                </div>
                            }
                        />
                    </TabPane>
                    <TabPane tabId="4">
                        <Accordion
                            panel="panel1"
                            title="Keuntungan Pemberi dana & Penerima dana hanya di Indofund.id"
                            body={
                                <div>
                                    <p>
                                        Setiap member terdaftar di Indofund.id bisa mendapatkan akses program akselerasi yang bermanfaat bagi setiap
                                        pribadi, maupun bisnis dan usahanya.
                                    </p>
                                    <p>
                                        Program akselerasi ini diberikan GRATIS dari Indofund.id melibatkan banyak partner professional agar bisa
                                        mengembangkan perekonomian di Indonesia.
                                    </p>
                                    <p>Setiap member terdaftar dapat mengakses semua program akselerasi tersebut melalui login area.</p>
                                </div>
                            }
                        />
                    </TabPane>
                    <TabPane tabId="5">
                        <Accordion
                            panel="panel1"
                            title="Tentang DIPAY "
                            body={
                                <div>
                                    <p>
                                        Indofund telah bekerja sama dengan <b>DIPAY</b> dalam mengembangkan fitur <b>DIPAY</b> di Indofund.{" "}
                                        <b>DIPAY</b> adalah layanan uang elektronik <i>(e-money)</i> di Indofund yang dapat digunakan sebagai salah
                                        satu metode atau pilihan pembayaran untuk mendanai di Indofund.
                                    </p>
                                    <p>
                                        <b>DIPAY</b> dioperasikan oleh <b>PT Mareco Prima Mandiri (Surya Fajar Capital Group)</b> yang dapat digunakan
                                        untuk transaksi pendanaan proyek di platform Indofund. Penyelenggaraan Uang Elektronik <b>DIPAY</b> telah
                                        terdaftar dan diawasi oleh Bank Indonesia.
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel2"
                            title="Keuntungan apa yang saya terima jika sudah melakukan aktivasi/mengaktifkan DIPAY di Indofund ?"
                            body={
                                <div>
                                    <ul>
                                        <li>Proses transaksi menjadi lebih praktis tanpa harus berpindah tempat.</li>
                                        <li>Dapat menikmati berbagai promo menarik di Indofund</li>
                                    </ul>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel3"
                            title="Bagaimana cara saya mengAktivasi Akun DIPAY di Indofund ?"
                            body={
                                <div>
                                    <p>
                                        Pengguna Indofund yang ingin menggunakan saldo uang elektronik DIPAY, terlebih dahulu harus mengaktivasi akun
                                        DIPAY. Proses aktif akun DIPAY selalu membutuhkan nomor telepon selular <i>(handphone)</i> yang sedang aktif.
                                        Berikut langkah dalam melakukan aktivasi akun DIPAY di Indofund, sebagai berikut :{" "}
                                    </p>
                                    <ol>
                                        <li>
                                            Pastikan anda sudah memiliki akun DIPAY. Bila anda belum memilikinya, anda akan diarahkan sistem DIPAY.
                                        </li>
                                        <div className="text-center">
                                            <img
                                                className="pt-5 pb-5 w-50"
                                                style={{ borderRadius: "60px" }}
                                                src={process.env.PUBLIC_URL + "images/faq/dipay-1.png"}
                                                alt="gambar"
                                            />
                                        </div>
                                        <li>
                                            Pastikan nomor telepon selular <i>(handphone)</i> yang terdaftar di DIPAY dan Indofund merupakan nomor
                                            yang sama. Apabila nomor telepon selular <i>(handphone)</i> berbeda, maka aktivasi akun DIPAY tidak dapat
                                            dilakukan.{" "}
                                        </li>
                                        <div className="text-center">
                                            <img
                                                className="pt-5 pb-5 w-50"
                                                style={{ borderRadius: "60px" }}
                                                src={process.env.PUBLIC_URL + "images/faq/dipay-2.png"}
                                                alt="gambar"
                                            />
                                        </div>
                                        <li>
                                            Masukkan nomor telepon selular <i>(handphone)</i> Anda pada kolom yang muncul ketika Anda menekan tombol "
                                            <b>aktivasi</b>" di halaman utama <i>(dashboard)</i> Indofund.
                                        </li>
                                        <div className="text-center">
                                            <img
                                                className="pt-5 pb-5 w-50"
                                                style={{ borderRadius: "60px" }}
                                                src={process.env.PUBLIC_URL + "images/faq/dipay-3.jpg"}
                                                alt="gambar"
                                            />
                                        </div>
                                        <li>
                                            Masukkan OTP <i>(One-Time Password)</i> DIPAY Anda
                                        </li>
                                        <div className="text-center">
                                            <img
                                                className="pt-5 pb-5 w-50"
                                                style={{ borderRadius: "60px" }}
                                                src={process.env.PUBLIC_URL + "images/faq/dipay-4.jpg"}
                                                alt="gambar"
                                            />
                                        </div>
                                        <li>Apabila verifikasi PIN berhasil, akun DIPAY dan Indofund anda sudah terhubung.</li>
                                        <div className="text-center">
                                            <img
                                                className="pt-5 pb-5 w-50"
                                                style={{ borderRadius: "60px" }}
                                                src={process.env.PUBLIC_URL + "images/faq/dipay-5.png"}
                                                alt="gambar"
                                            />
                                        </div>
                                    </ol>
                                    <p>
                                        Setelah aktivasi akun DIPAY berhasil, Anda dapat secara langsung mendanai proyek-proyek di Indofund dengan
                                        menggunakan saldo DIPAY.
                                    </p>
                                </div>
                            }
                        />

                        <Accordion
                            panel="panel4"
                            title="Bagaimana keamanan dan kerahasiaan data dan informasi saya ?"
                            body={
                                <div>
                                    <p>
                                        Indofund menjamin keamanan dan kerahasiaan data Anda. Indofund tidak berbagi informasi kepada DIPAY. DIPAY
                                        hanya digunakan sebagai pilihan pembayaran untuk bertransaksi dalam proses pendanaan proyek di Indofund.
                                    </p>
                                </div>
                            }
                        />
                        <Accordion
                            panel="panel5"
                            title="Pendanaan proyek di Indofund menggunakan saldo Dipay namun proyek dibatalkan, bagaimana pengembalian saldo saya ?"
                            body={
                                <div>
                                    <p>Saldo pendanaan akan kami transfer ke saldo "Dompet Indofund" .</p>
                                </div>
                            }
                        />
                    </TabPane>
                </TabContent>
            </Col>
        );
    }
}
export default FaqContent;
