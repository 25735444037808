import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, FormGroup, Input } from "reactstrap";
import { LaporanPPH } from "../../../redux/action/Lender";
import "../../../sass/loader.css";
import Swal from "sweetalert2";


class LaporanBunga extends Component {
    constructor(props) {
        super(props);
        this.state = {
            year: "2022",
            years: []
        };
    }

    generateYears = () => {
        const yearNow = new Date().getFullYear();
        const yearsArr = []
        for (let i = yearNow; i >= 2022; i--) {
            yearsArr.push(i)
        }
        this.setState({ years: yearsArr.map(item => ({ name: item, value: item })) })

    }

    componentDidMount() {
        this. generateYears()

    }

    handleChange = (e) => {
        this.setState({
            year: e.target.value
        });
    };

    handleSend = async () => {
        await this.props
            .dispatch(LaporanPPH(this.state.year))
            .then(async (res) => {
                Swal.fire({
                    title: "Berhasil",
                    text: "Laporan berhasil terkirim ke email",
                    icon: "success",
                    button: "ok"
                });
            })
            .catch((err) => {
                Swal.fire({
                    title: "Gagal",
                    text: "Gagal kirim email",
                    icon: "warning",
                    button: "ok"
                });
                console.log(err);
            });
    };
    render() {
        return (
            <div>
                <div className="row pt-4">
                    <div className="col col-md-10">
                        <div className="font-weight-bold">Periode Tahun Laporan PPH23</div>
                    </div>
                    <div className="col col-md-2">
                        <FormGroup>
                            <Input type="select" name="tahun" id="exampleSelect" value={this.state.year} onChange={(e) => this.handleChange(e)}>
                                {this.state.years.map((item) => (
                                    <option value={item.name}>{item.value}</option>

                                ))}
                            </Input>
                        </FormGroup>
                    </div>
                </div>
                <div className="card border-0 shadow-sm mt-2">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6">Laporan Tahun {this.state.year}</div>
                            <div className="col-12 col-md-6">
                                {this.props.lender.isLoadingLaporan ? (
                                    <Button color="primary" disabled onClick={this.handleSend} className="float-md-right">
                                        <span className="loader"></span>
                                    </Button>
                                ) : (
                                    <Button color="primary" className="float-md-right" onClick={this.handleSend}>
                                        <i className="fas fa-paper-plane pr-3"></i>Kirim Email
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lender: state.lender
    };
};

export default connect(mapStateToProps)(LaporanBunga);
