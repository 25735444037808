



import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";


const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
            fontSize: 12,
            padding: "1rem",
            lineHeight: "1.2rem",
        },
    })
);
export default function CustomAccordion({ children, title, detail, withBorder = true, space }) {
    const [expanded, setExpanded] = useState(false);
    return (
        <div>
            <Accordion
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
                sx={
                    withBorder
                        ? { padding: 0, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px" }
                        : { padding: 0, paddingLeft: "0", boxShadow: "none", backgroundColor: "transparent" }
                }
            >
                <AccordionSummary
                    sx={withBorder ? { padding: 0, paddingLeft: "10px", } : { padding: 0, paddingLeft: `${space}px`, boxShadow: "none", backgroundColor: expanded ? "transparent" : "white" }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography
                        sx={{ fontSize: "14px", display: "flex", gap: "10px", fontWeight: withBorder ? "bold" : "normal" }}
                    >
                        {!withBorder && (
                            <span style={{ height: "8px", width: "8px", backgroundColor: "#E4E4E7", borderRadius: "50%" }}></span>
                        )}
                        {title}
                    </Typography>
                    {!withBorder && (
                        <LightTooltip title={detail}>
                            <span id="Popovers3" data-html="true" style={{ marginLeft: "0.5rem" }}>
                                <i className="material-icons-outlined" style={{ fontSize: "15px", color: 'black' }}>
                                    info
                                </i>
                            </span>
                        </LightTooltip>
                    )}
                </AccordionSummary>
                <AccordionDetails
                    sx={
                        withBorder
                            ? { backgroundColor: expanded ? "#1212120D" : "white", padding: 0 }
                            : { padding: 0, backgroundColor: "transparent", boxShadow: "none" }
                    }
                >
                    {children}
                </AccordionDetails>
            </Accordion>
        </div >
    );
}
