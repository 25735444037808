import React, { Component } from "react";
import NoData from "../../assets/no data/NoData";
import Pagination from "react-js-pagination";
import { percentageConvert, sukuBunga } from "../../../library/helper/helper";
import "./portfolio.scss";
import moment from "moment";
import { connect } from "react-redux";
import PortfolioListLayout from "./PortfolioListLayout";
import { LendApp } from "../../../redux/action/Lender";

class CDibayar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {

            },
            query: {
                page: 1,
                limit: 15,
                status: "Selesai",
                search: "",
                totalData: 0,
                totalPage: 0,
            },
            filter: "",
        };
        this.debounceTimeout = null;
        this.handleClickSelesai = this.handleClickSelesai.bind(this);
    }
    handleClickSelesai(pageNumber) {
        window.scrollTo(0, 0);
        this.setState(
            (prevState) => ({
                query: { ...prevState.query, page: pageNumber }, // Update the page number in the query
            }),
            () => {
                this.fetchData(pageNumber); // Fetch data after state update
            }
        );
    }
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        this.fetchData(this.state.page);
    };
    fetchData = async (page) => {
        await this.props.dispatch(LendApp({ ...this.state.query, page }));
        console.log(this.props.lender.lenderLendSelesai)
        this.setState({
            data: this.props.lender.lenderLendSelesai
        });
    }
    handleChangeSearch = (event) => {
        this.setState({ query: { ...this.state.query, search: event.target.value } }, () => {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                this.fetchData(1);
            }, 500);
        });
    };

    Dibayar = () => {
        if (this.props.data === undefined) {
            return <div></div>;
        } else {

            const currentTodos = this.props.lender.lenderLendSelesai

            if (currentTodos?.length !== 0) {
                const hasil = currentTodos.map((val) => {

                    return (
                        <a href={"/detail-porto/" + val.loanId} className="card py-4 mt-3 border-0 card-item" key={val.loanId}>
                            <div className="transaction ">
                                <div className="card border-0">
                                    <div className="card-body ">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-xl-12 mb-3 mb-xl-0">
                                                <div className="row">
                                                    <div className="col-6 col-md-2 mb-3">
                                                        <p className="fs-n2 text-secondary mb-1">Nama Proyek</p>
                                                        <p className="mb-0 fs-n1">{val.loanName} </p>
                                                    </div>
                                                    <div className="col-6 col-md-2 mb-3">
                                                        <p className="fs-n2 text-secondary mb-1">Nominal Pendanaan</p>
                                                        <p className="mb-0 fs-n1">Rp {val.lendingAmount.toLocaleString("IN")}</p>
                                                    </div>
                                                    <div className="col-6 col-md-1 mb-3">
                                                        <p className="fs-n2 text-secondary mb-1">Bunga</p>
                                                        <p className="mb-0 fs-n1 text-darkgreen">
                                                            {percentageConvert(val.loanInterst)}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 col-md-2 mb-3">
                                                        <p className="fs-n2 text-secondary mb-1">Imbal Hasil</p>
                                                        <p className="mb-0 text-darkgreen fs-n1">Rp {val.interestEstimation ? val.interestEstimation.toLocaleString("IN") : 0}</p>

                                                    </div>

                                                    <div className="col-6 col-md-3 mb-3 mb-xl-0">
                                                        <p className="fs-n2 text-secondary mb-1">Total Pengembalian</p>
                                                        <p className="mb-0 fs-n1">Rp { val.repaymentAmount ? val.repaymentAmount.toLocaleString("IN") : 0}</p>
                                                    </div>
                                                    <div className="col-12 col-md-2 mb-1 mb-xl-0">
                                                        <p className="fs-n2 text-secondary mb-1">Periode Pendanaan</p>
                                                        <div key={val.loanId}>
                                                            <p className="mb-0 fs-n1">{moment(new Date(val.disbursementDate)).format('DD MMMM YYYY ')} - {moment(new Date(val.lastPaymentDate)).format('DD MMMM YYYY')}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    );
                });
                return hasil;
            } else {
                return (
                    <div className="transactions-wrapper m-n3">
                        <NoData
                            symbol={<i className="material-icons-outlined">assignment</i>}
                            keterangan={this.props.data.length !== 0 ? "Proyek yg anda danai belum selesai" : "Belum ada Transaksi"}
                        />
                        <div className="text-center">
                            {this.props.data.length !== 0 ? (
                                ""
                            ) : (
                                <a href="/proyek" className="btn btn-outline-primary btn-mulai-dana">
                                    Mulai Danai Sekarang
                                </a>
                            )}
                        </div>
                    </div>

                );
            }
        }
    };
    render() {
        const { currentPageSelesai, todosPerPageSelesai } = this.state;
        const { filter } = this.state;

        //pagination Selesai
        const lengthSelesai = this.props.data.filter((item) =>
            this.state.filter === ""
                ? item.id_pinjaman && item.id_pinjaman.status_proyek === "Proyek selesai"
                : item.id_pinjaman.nama_pinjaman.toLowerCase().includes(this.state.filter.toLowerCase())
        ).length;

        return (
            // <div>
            //     <div className="d-flex align-items-center justify-content-between my-3">
            //         {/* <div>
            //         <label className="mb-0 mr-2 d-none d-md-inline-block" for="urutkan">Urutkan</label>
            //         <select id="urutkan" className="custom-select w-auto mr-2">
            //             <option value="">Suku Bunga</option>
            //         </select>
            //     </div> */}
            //         <div>
            //             <form>
            //                 <div className="input-group">
            //                     <input
            //                         className="form-control"
            //                         type="text"
            //                         id="keyword"
            //                         name="keyword"
            //                         placeholder="Cari Transaksi"
            //                         value={filter}
            //                         onChange={this.handleChangeSearch}
            //                     />
            //                     <div className="input-group-append">
            //                         <div className="input-group-text">
            //                             <i className="material-icons-outlined">search</i>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </form>
            //         </div>
            //     </div>

            //     {this.Dibayar(currentPageSelesai, todosPerPageSelesai, lengthSelesai)}

            //     <div className="d-flex justify-content-center mt-3">
            //         <Pagination
            //             hideDisabled
            //             itemClass="page-item"
            //             linkClass="page-link"
            //             activePage={this.state.currentPageSelesai}
            //             itemsCountPerPage={this.state.todosPerPageSelesai}
            //             totalItemsCount={parseInt(lengthSelesai)}
            //             onChange={this.handleClickSelesai.bind(this)}
            //         />
            //     </div>
            // </div>
            <PortfolioListLayout filter={this.state.query.search} isLoading={this.props.lender.isLoadingLendAppSelesai} limit={this.props.lender.lenderLendSelesaiPagination.limit} page={this.props.lender.lenderLendSelesaiPagination.page} onChangePage={this.handleClickSelesai.bind(this)} renderComponent={this.Dibayar} totalData={this.props.lender.lenderLendSelesaiPagination.totalData} handleChangeSearch={this.handleChangeSearch} />

        );
    }
}

const mapStateToProps = (state) => {
    return {
        lender: state.lender
    };
};

export default connect(mapStateToProps)(CDibayar);
