import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Menu from "../component/template/guest/Menu";
import Footer from "../component/template/guest/footer/Footer";
import Welcome from "../component/guest/welcome/Welcome";
import Tentang from "../component/guest/tentang-kami/TentangKami";
import SukuBunga from "../component/guest/sukubunga/SukuBunga";
import Faq from "../component/guest/faq/Faq";
import Layanan from "../component/guest/layanan/Layanan";
import Proyek from "../component/guest/proyek/AllProyek";
import ProyekDetail from "../component/guest/proyek/ProyekDetail";
import PortoDetail from "../component/lender/portofolio/DetailPorto";
import EmailUserVerifikasi from "../component/email/EmailUserVerifikasi";
import EmailForgotPassword from "../component/email/EmailForgotPassword";
import dasborlender from "../component/lender/Lender";
import dasborborrower from "../component/borrower/Borrower";
import PrivacyCookie from "../component/guest/privacy-cookie/privacyCookie";
import PrivacyPolicy from "../component/guest/privacy-policy/privacyPolicy";
import Lender from "../component/lender/Lender";
import Borrower from "../component/borrower/Borrower";
import SuccessAktivasi from "../component/email/EmailSuccessDaftar";
import NewPassword from "../component/template/guest/FormNewPassword";
import Success from "../component/email/EmailSuccessFP";
import Failed from "../component/email/EmailFailedFP";
import Asuransi from "../component/guest/welcome/Asuransi";
import Event from "../component/guest/welcome/event/Event";
import Blog from "../component/guest/blog/Blog";
import BlogCategory from "../component/guest/blog/BlogCategory";
import BlogDetail from "../component/guest/blog/BlogDetail";
import LaporanKeuangan from "../component/guest/tentang-kami/laporanKeuangan/laporan";
import SuccessDownloadFile from "../component/guest/welcome/Redirect/success/SuccesDownload";
// import Contact from "../component/guest/contactus/Contact";

// forget password
import ForgotPassword from "../component/template/guest/forget password/ForgotPassword";
import Form from "../component/template/guest/forget password/FormForgetPassword";

//login
import LoginAuth from "../component/template/guest/login tunggal/Login";

//daftar
import Daftar from "../component/guest/daftar/DaftarUser";
import PilihBorrower from "../component/guest/daftar/borrower/PilihBorrower";
import PilihUser from "../component/guest/daftar/PilihUser";
import Perorangan from "../component/guest/daftar/borrower/BorrowerPerorang";
import BadanHukum from "../component/guest/daftar/borrower/BorrowerBadanHukum";
import ShowPerorangan from "../component/guest/daftar/borrower/personal/ShowFormPerorangan";

//redirect email
import DigisignSuccess from "../component/guest/welcome/Redirect/DigisignReg";
import RedirectAktivasi from "../component/guest/welcome/Redirect/success/SuccessAktivasi";
import RedirectAktivasiResend from "../component/guest/welcome/Redirect/AktivasiResendEmail";
import AktivasiRe from "../component/guest/welcome/Redirect/AktivasiRe";
import RedirectAktivasiGagal from "../component/guest/welcome/Redirect/failed/FailedAktivasi";
import FailedResend from "../component/guest/welcome/Redirect/failed/FailedAktivasiResend";
import NotFound from "../component/guest/welcome/Redirect/failed/NotFound";
import RedirectDownloadDigisign from "../component/guest/welcome/Redirect/DownloadFileDigisign";

// route lender
import Dasbor from "../component/lender/dashboard/Dasbor";
import Akun from "../component/borrower/akun/Akun";
// tiketing
import TiketingDetailsPage from "../component/tiketing/TiketingDetails";
import { ShowElementContextProvider } from "../context/ShowElementContextProvider";

class Router extends Component {
    render() {
        return (
            <BrowserRouter>
                <ShowElementContextProvider>
                    <Menu />
                    <Switch>
                        {/* home */}
                        <Route exact path="/" component={Welcome} />
                        <Route exact path="/proyek" component={localStorage.jwtToken !== undefined ? Proyek : LoginAuth} />
                        <Route exact path="/proyek/:proyek_id" component={localStorage.jwtToken !== undefined ? ProyekDetail : LoginAuth} />
                        <Route exact path="/detail-porto/:pinjaman_id" component={localStorage.jwtToken !== undefined ? PortoDetail : LoginAuth} />
                        <Route path="/tentang-profil" component={Tentang} />
                        <Route path="/tentang-sukubunga" component={SukuBunga} />
                        <Route path="/tentang-event" component={Event} />
                        <Route path="/faq" component={Faq} />
                        <Route path="/layanan" component={Layanan} />
                        <Route path="/privacy-cookie" component={PrivacyCookie} />
                        <Route path="/privacy-policy" component={PrivacyPolicy} />
                        {/* <Route path="/dana-proteksi" component={Asuransi} /> */}
                        <Route path="/laporan-keuangan" component={LaporanKeuangan} />
                        {/* <Route path="/contact-us" component={Contact} /> ini buat ticketing */}
                        {/* daftar akun */}
                        <Route path="/daftar/:user" component={Daftar} />
                        <Route path="/pilihuser" component={PilihUser} />
                        <Route path="/pilihborrower" component={PilihBorrower} />
                        {/* daftar akun borrower */}
                        <Route path="/regist/personal" component={Perorangan} />
                        <Route path="/regist/badanhukum" component={BadanHukum} />
                        <Route path="/regist-personal/:pilih" component={ShowPerorangan} />
                        {/* blog */}
                        <Route path="/blog" component={Blog} />
                        <Route path="/blog-category/:category" component={BlogCategory} />
                        <Route path="/blog-detail/:blog_id" component={BlogDetail} />
                        {/* email */}
                        <Route path="/emailverif/:user" component={EmailUserVerifikasi} />
                        <Route path="/email-forgotpassword" component={EmailForgotPassword} />
                        {/* berhasil gagal ubah password */}
                        <Route path="/success-password" component={Success} />
                        <Route path="/failed" component={Failed} />
                        {/* redirect dari email forget password*/}
                        <Route path="/forgot-password" component={ForgotPassword} />
                        <Route path="/ForgetPassword" component={Form} />
                        {/* berhasil gagal aktivasi & resend aktivasi */}
                        <Route path="/success-email" component={RedirectAktivasi} />
                        <Route path="/failed-email" component={RedirectAktivasiGagal} />
                        <Route path="/failed-resend" component={FailedResend} />
                        {/* kosong redirect   */}
                        <Route path="/success/redirect" component={DigisignSuccess} />
                        <Route path="/download-digisign" component={RedirectDownloadDigisign} />
                        <Route path="/succes-download" component={SuccessDownloadFile} />
                        <Route path="/user/verification" component={AktivasiRe} />
                        <Route path="/user/resend-email" component={RedirectAktivasiResend} />
                        {/* login */}
                        <Route path="/lender/dasbor" component={localStorage.jwtToken !== undefined ? dasborlender : LoginAuth} />
                        <Route path="/borrower/dasbor" component={localStorage.jwtToken !== undefined ? dasborborrower : LoginAuth} />
                        <Route path="/login" component={LoginAuth} />
                        <Route path="/success-aktivasi" component={SuccessAktivasi} />
                        <Route path="/new-password" component={NewPassword} />
                        <Route path="/detail-tiketing/:id" component={TiketingDetailsPage} />
                        {localStorage.masuk === undefined ? <Route path="*" component={NotFound} /> : null}
                        {/* lender & borrower  */}
                        {localStorage.masuk === "1" ? <Lender /> : <Borrower />}
                    </Switch>
                    <Footer />
                </ShowElementContextProvider>
            </BrowserRouter>
        );
    }
}

export default Router;
