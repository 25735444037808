import React, { Component } from "react";
import "./CalculatorBunga.scss";
import NumberFormat from "react-number-format";
import { Form, Button } from "reactstrap"; //select option search
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import { validate } from "../../../library/validation/validate";
import { Link } from "react-router-dom";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";


class CalculatorBungaPenerimaDana extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      tenor: "",
      grade: "",
      nominal: "",
      activeNumeric: false,
      disabled_suku_bunga: true,
      disabled_button: true,
      suku_bunga: ["12% p.a", "13% p.a", "14% p.a", "15% p.a","16% p.a", "17% p.a", "18% p.a", "19% p.a","20% p.a", "22% p.a", "23% p.a", "24% p.a"],
      text_durasi: "-- Pilih Tenor --",
      text_suku_bunga: "-- Pilih Suku Bunga --",
      text_jenis_pinjaman: "-- Pilih Jenis Pinjaman --",
      filter_durasi: ["1 Bulan", "2 Bulan", "3 Bulan", "4 Bulan","5 Bulan", "6 Bulan", "7 Bulan", "8 Bulan","9 Bulan", "10 Bulan", "11 Bulan", "12 Bulan"],
      filter_grade: ["Invoice financing", "Project financing", "Working capital financing"],
      error: false,
      total: 0
    };
  }

  handleChange = (e, type, changeError) => {
    let value = e.target.value;
    let values = value.replace(/\./g, '');
    let numberValue = parseInt(values);
    if (numberValue < 5000000 || numberValue <= 0) {
      this.setState({
        error: true,
      })
    } else {
      this.setState({
        error: false,
      })
    }
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setState({ [changeError]: validate(type, value) });
    });
  };

  onHandleChange(type, value) {
    switch (type) {
      case "durasi":
        this.setState({
          text_durasi: value
        });
        break;
      case "jenis_pinjaman":
        this.setState({
          text_jenis_pinjaman: value
        });
        this.setState({
          disabled_suku_bunga: false
        });
        break;
      case "suku_bunga":
        this.setState({
          text_suku_bunga: value
        });
        this.checkSukuBunga();
        break;
      default:
        break;
    }
  }

  checkSukuBunga() {
    this.setState({
      disabled_button: false
    });
    // switch (grade) {
    //     case "Invoice financing":
    //         this.setState({
    //             suku_bunga: "15%"
    //         });
    //         break;
    //     case "Project financing":
    //         this.setState({
    //             suku_bunga: "18%"
    //         });
    //         break;
    //     case "Working capital financing":
    //         this.setState({
    //             suku_bunga: "24%"
    //         });
    //         break;
    //     case "Bullet Reguler":
    //         this.setState({
    //             suku_bunga: "24%"
    //         });
    //         break;
    //     default:
    //         break;
    // }
  }

  handleUpdate = () => {
    const { text_durasi, nominal, text_suku_bunga } = this.state;
    const nilai_nominal = nominal.replace(/\D/g, "").replace(/^0+/, "");
    const bunga = parseFloat(text_suku_bunga)
    const pesentage = parseInt(bunga) / 100;
    const totalBungaPerAnnual = pesentage / 12

    let price = 0;

    switch (text_durasi) {
      case "1 Bulan":
        price = (+nilai_nominal + (+nilai_nominal * 1 * totalBungaPerAnnual))/ 1;
        break;
      case "2 Bulan":
        price = (+nilai_nominal + (+nilai_nominal * 2 * totalBungaPerAnnual))/ 2;
        break;
      case "3 Bulan":
        price = (+nilai_nominal + (+nilai_nominal * 3 * totalBungaPerAnnual))/ 3;
        break;
      case "4 Bulan":
        price = (+nilai_nominal + (+nilai_nominal * 4 * totalBungaPerAnnual))/ 4;
        break;
      case "5 Bulan":
        price = (+nilai_nominal + (+nilai_nominal * 5 * totalBungaPerAnnual))/ 5;
        break;
      case "6 Bulan":
        price = (+nilai_nominal + (+nilai_nominal * 6 * totalBungaPerAnnual))/ 6;
        break;
      case "7 Bulan":
        price = (+nilai_nominal + (+nilai_nominal * 7 * totalBungaPerAnnual))/ 7;
        break;
      case "8 Bulan":
        price = (+nilai_nominal + (+nilai_nominal * 8 * totalBungaPerAnnual))/ 8;
        break;
      case "9 Bulan":
        price = (+nilai_nominal + (+nilai_nominal * 9 * totalBungaPerAnnual))/ 9;
        break;
      case "10 Bulan":
        price = (+nilai_nominal + (+nilai_nominal * 10 * totalBungaPerAnnual))/ 10;
        break;
      case "11 Bulan":
        price = (+nilai_nominal + (+nilai_nominal * 11 * totalBungaPerAnnual))/ 11;
        break;
      case "12 Bulan":
        price = (+nilai_nominal + (+nilai_nominal * 12 * totalBungaPerAnnual))/ 12;
        break;
      default:
        break;
    }
    this.setState({
      total: Math.floor(price).toLocaleString('id-ID')
    });
  };

  onHandleRedirect() {
    localStorage.jwtToken === undefined ? this.props.history.push("/daftar/1") : this.props.history.push("/proyek");
  }

  render() {
    const { filter_durasi, suku_bunga, filter_grade, disabled_suku_bunga, total, error, disabled_button } = this.state;
    return (
      <section id="calculator">
        <div>
          <h4 className="text-title">Simulasi Penerima Dana</h4>
          <p className="text">Hitung pengajuan dana Anda dengan mudah menggunakan kalkulator simulasi pengajuan pendanaan kami.</p>
        </div>
        <div className="d-flex justify-content-center">
          <div className="box-container">
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <p className="text-label mb-2">Nominal yang akan di ajukan</p>
                  <div className="form-group ">
                    <div className="input-group ">
                      <div className="input-group-prepend">
                        <div className={`input-group-text ${this.state.activeNumeric ? "active-border" : ""}`}>
                          <span className="font-weight-semi-bold">Rp</span>
                        </div>
                      </div>
                      <NumberFormat
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.nominal}
                        // onKeyDown={this.onKeyDown}
                        autoComplete="off"
                        placeholder="0"
                        className="form-control text-right input-cal"
                        name="nominal"
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        allowNegative={false}
                        decimalScale={0}
                        onFocus={(e) =>
                          this.setState({
                            activeNumeric: true
                          })
                        }
                        onBlur={(e) =>
                          this.setState({
                            activeNumeric: false
                          })
                        }
                      // disabled={this.authDisabled(project)}
                      />
                    </div>
                    {
                      error ?
                        <p className="text-error">Minimal Nominal Rp 5.000.000</p> : null
                    }
                  </div>
                </div>
                <div className="col-md-6">
                  <p className="text-label mb-2">Tenor</p>
                  <UncontrolledDropdown className="dropdown-container-cal">
                    <DropdownToggle color="#fff" outline={false} className="text-filter-cal">
                      {this.state.text_durasi}{" "}
                    </DropdownToggle>
                    <DropdownMenu className="menu-dropdown-cal">
                      {filter_durasi.map((text, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => this.onHandleChange("durasi", text)}
                            className={`${text === this.state.text_durasi ? "active-item" : ""}`}>
                            {text}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <p className="text-label mb-2">Jenis Pinjaman</p>
                  <UncontrolledDropdown className="dropdown-container-cal">
                    <DropdownToggle color="#fff" outline={false} className="text-filter-cal">
                      {this.state.text_jenis_pinjaman}{" "}
                    </DropdownToggle>
                    <DropdownMenu className="menu-dropdown-cal">
                      {filter_grade.map((text, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => this.onHandleChange("jenis_pinjaman", text)}
                            className={`${text === this.state.text_grade ? "active-item" : ""}`}>
                            {text}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <div className="col-md-6">
                  <p className="text-label mb-2">Suku Bunga</p>
                  <UncontrolledDropdown className="dropdown-container-cal" disabled={disabled_suku_bunga}>
                    <DropdownToggle color="#fff" outline={false} className="text-filter-cal">
                      {this.state.text_suku_bunga}{" "}
                    </DropdownToggle>
                    <DropdownMenu className="menu-dropdown-cal">
                      {suku_bunga.map((text, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => this.onHandleChange("suku_bunga", text)}
                            className={`${text === this.state.text_grade ? "active-item" : ""}`}>
                            {text}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <p className="text-note">*Suku Bunga mulai dari 12% - 24% p.a</p>
                </div>
                {/* <FormGroup>
                    <p className="text-label mb-2">Suku Bunga</p>
                    <div className="input-container-cal">{this.state.suku_bunga}</div>
                  </FormGroup> */}
              </div>
              <div className="row mt-4">
                <div className="col-md-6">
                  <Link
                    // onClick={() => this.onHandleRedirect()}
                    color="white"
                    // to={`${localStorage.jwtToken === undefined ? "/daftar/1" : "/proyek"}`}
                    className="btn btn-outline-primary btn-block btn-lg mr-md-2">
                    Ajukan Sekarang
                  </Link>
                </div>
                <div className="col-md-6">
                  <Button
                    onClick={this.handleUpdate}
                    color="primary"
                    disabled={error || disabled_button}
                    className="btn btn-block btn-lg  mt-3 mt-md-0">
                    Hitung
                  </Button>
                </div>
              </div>

              <div className="box-container-total mt-3">
                <img className="img-fluid" src={process.env.PUBLIC_URL + "../../images/icon/logoCalculator.svg"} alt="logo-send" />
                <div>
                  <p className="text-note" style={{ fontSize: 12 }}>
                    Proyeksi Estimasi Pembayaran Cicilan Bulanan
                  </p>
                  <p className="text-total" >Rp {total}</p>
                  <p className="text-note" >
                    *Biaya layanan 3% - 5%
                  </p>
                </div>
                <img
                  className="img-fluid image-logo-cal"
                  src={process.env.PUBLIC_URL + "../../images/icon/logoIndofund.svg"}
                  alt="logo-send"
                />
              </div>
              {/* <Accordion style={{ borderRadius: '10px', background: "#FFF9F2" }}>
                <div className="box-container-total-borrower mt-4">
                  <AccordionSummary
                    style={{ justifyContent: "end", display: "contents" }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <img className="img-fluid" src={process.env.PUBLIC_URL + "../../images/icon/logoCalculator.svg"} alt="logo-send" />
                    <div className="ml-4">
                      <p className="text-note" style={{ fontSize: 12 }}>
                        Proyeksi Hasil Penerima Dana *(Sebelum Pajak)
                      </p>
                      <p className="text-total">Cicilan Bulanan</p>
                    </div>

                  </AccordionSummary>
                </div>
                <AccordionDetails>
                  <div className="result">
                  <Typography className="d-flex justify-content-between w-100">
                      <div>Cicilan 1 :</div>
                      <div>Rp.10.000</div>
                  </Typography>
                  <Typography className="d-flex justify-content-between">
                      <div>Cicilan 1 :</div>
                      <div>Rp.10.000</div>
                  </Typography>
                  <Typography className="d-flex justify-content-between">
                      <div>Cicilan 1 :</div>
                      <div>Rp.10.000</div>
                  </Typography>
                  </div>
                </AccordionDetails>
              </Accordion> */}

              {/* <p className="text-note mt-3">
                                {
                                    "*) Simulasi bersifat proyeksi. Besar total pengembalian pendanaan mengacu dengan parameter grade dan rate yang dipilih."
                                }
                            </p> */}
            </Form>
          </div>
        </div>
      </section>
    );
  }
}

// Rp {total}

export default CalculatorBungaPenerimaDana;
