import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import Loginform from "./LoginForm";
import "./Login.scss";

class Login extends Component {
    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="modal-dialog modal-dialog-centered" tabIndex="-1">
                <ModalHeader toggle={this.props.toggle}>
                    <div className="text-center flex-fill">
                        <h5 className="modal-title font-weight-bold">Masuk Akun Indofund.id</h5>
                        <p className="mb-0">
                            Belum memiliki akun? Yuk daftar{" "}
                            <Link to="/pilihuser" onClick={this.props.toggle}>
                                disini
                            </Link>
                        </p>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Loginform toggle={this.props.toggle} />
                </ModalBody>
            </Modal>
        );
    }
}
export default Login;
