import React, { Component } from "react";
import { EmployeeProject } from "../../../../redux/action/Project";
import { connect } from "react-redux";
import NoDataProyekWelcome from "../../../assets/no data/NoProyekWelcome";
import { Link } from "react-router-dom";
import { progressBarProject } from "../../../../library/helper/helper";
import NumberFormat from "react-number-format";
import { NavLink } from "react-router-dom";

class ProyekEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: []
        };
    }

    componentDidMount = async () => {
        await this.props.dispatch(EmployeeProject());
        this.setState({
            project: this.props.project.projectEmployee
        });
    };

    fetchSukuBunga = (project) => {
        const { jenis_bunga, satuan_suku_bunga_pinjaman } = project

        const bunga = jenis_bunga === 1 ? "Flat" : "Efektif";
        const satuan =
            satuan_suku_bunga_pinjaman === 1
                ? "Hari"
                : satuan_suku_bunga_pinjaman === 2
                    ? "Minggu"
                    : satuan_suku_bunga_pinjaman === 3
                        ? "Bulan"
                        : "Tahun";

        return `${bunga} /  ${satuan}`
    }
    ProjectEmployee = () => {
        //sorting descending
        function compare(a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
        }

        const projectList = this.state.project
            .sort(compare)
            .slice(0, 5)
            .map((project) => {
                const progressBar = (project.nilai_total_peminjam / project.nilai_pengajuan_pinjaman) * 100;
                return (
                    <Link to={"/proyek/" + project._id} key={project._id} className="link-text">
                        <div className="transaction p-3">
                            <div className="card border-0 shadow-sm">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-xl-8 mb-3 mb-xl-0">
                                            <div className="row">
                                                <div className="col-6 col-md-2 mb-3">
                                                    <p className="fs-n1 text-secondary mb-1">Nama Proyek</p>
                                                    <p className="mb-0">{project.nama_pinjaman}</p>
                                                </div>
                                                <div className="col-6 col-md-4 mb-3">
                                                    <p className="fs-n1 text-secondary mb-1">Avalis</p>
                                                    <p className="mb-0">{project.avalis_perusahaan}</p>
                                                </div>
                                                <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                                    <p className="fs-n1 text-secondary mb-1">Tujuan</p>
                                                    <p className="mb-0">{project.tujuan_permohonan_pinjaman}</p>
                                                </div>
                                                <div className="col-6 col-md-2 mb-3">
                                                    <p className="fs-n1 text-secondary mb-1">Tenor</p>
                                                    <p className="mb-0">{project.jangka_waktu_pinjaman} bulan</p>
                                                </div>
                                                <div className="col-6 col-md-2 mb-3">
                                                    <p className="fs-n1 text-secondary mb-1">Bunga</p>
                                                    <p className="mb-0">{project.suku_bunga_konsumtif * 100} % {this.fetchSukuBunga(project)}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-4 progress-divider">
                                            <div className="progress mb-3 mt-0">
                                                <div
                                                    className="progress-bar bg-primary"
                                                    role="progressbar"
                                                    style={{ width: `${parseInt(progressBar)}%` }}>
                                                    <div className={progressBar !== 100 ? "text-progress-bar" : "text-progress-bar employee"}>
                                                        {progressBarProject(progressBar)}%
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row text-information">
                                                <div className="col-6">
                                                    <p className="fs-n1 text-secondary mb-1">Terkumpul</p>
                                                    <p className="mb-0 font-weight-semi-bold text-primary">
                                                        Rp. {project.nilai_total_peminjam.toLocaleString("IN")}
                                                    </p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="fs-n1 text-secondary mb-1">Plafon</p>
                                                    <p className="mb-0 font-weight-semi-bold">
                                                        Rp.{" "}
                                                        <NumberFormat
                                                            value={project.nilai_pengajuan_pinjaman}
                                                            displayType={"text"}
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            decimalScale={0}
                                                            renderText={(value) => <span>{value}</span>}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                );
            });

        return projectList;
    };
    render() {
        return (
            <div>
                {this.state.project.length !== 0 ? (
                    <div>
                        {this.ProjectEmployee()}
                        <div className="text-center mt-4">
                            <NavLink to="/proyek" className="btn btn-outline-primary btn-lg px-5">
                                Lihat semua
                            </NavLink>
                        </div>
                    </div>
                ) : (
                    <NoDataProyekWelcome />
                )}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        project: state.project
    };
};
export default connect(mapStateToProps)(ProyekEmployee);
