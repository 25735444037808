import React, { Component } from "react";
import "./kebijakan.scss";

class privacyCookie extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <section id="kebijakan" className="with-pad">
                <div className="container">
                    <div className="col-12">
                        <div className="jarak">
                            <p className="text">
                                Kebijakan Cookie ini menjelaskan mengapa kami menggunakan cookie, beacon, Flash cookie dan teknologi yang serupa
                                (“Cookie”) dan cara untuk menonaktifkannya. Kebijakan Cookie kami merupakan bagian dari Kebijakan Privasi kami. Dengan
                                mengakses dan/atau menggunakan Situs kami, Anda setuju dengan penggunaan Cookie sebagaimana yang dijelaskan dalam
                                Kebijakan ini.
                            </p>
                            <p className="text">
                                Apabila Anda memilih untuk menggunakan Situs tanpa memblokir atau menonaktifkan Cookie atau memilih teknologi lainnya,
                                anda setuju terhadap penggunaan Cookie ini dan juga penggunaan kami (sesuai dengan kebijakan ini dan kebijakan privasi
                                kami lainnya) terhadap data pribadi yang kami kumpulkan menggunakan Cookie. Jika Anda tidak setuju dengan penggunaan
                                Cookie ini, pastikan Anda memblokir atau menonaktifkan Cookie dengan menggunakan pengaturan browser Anda.
                            </p>
                        </div>

                        <div className="jarak">
                            <h3 className="text-title">Apa Yang Dimaksud Dengan Cookie?</h3>
                            <ol className="text">
                                <li>
                                    <p className="text">
                                        Cookie adalah potongan kecil informasi (termasuk tetapi tidak terbatas pada file-file teks kecil) yang dapat
                                        di-transfer oleh web server dan/atau disimpan dalam web browser, hard drive komputer, ponsel, tablet,
                                        perangkat komunikasi pribadi lainnya, aplikasi atau web tool (“Perangkat” Anda). Gambar grafis kecil seperti
                                        “pixel tags” atau “clear GIFs”, atau file-file komputer yang kecil, program-program komputer, dan
                                        teknologi-teknologi serupa yang menyimpan informasi dalam Perangkat Anda atau mengumpulkan informasi atau
                                        membantu kami mengidentifikasi Anda, akun atau Perangkat Anda dapat juga dimasukkan pada Situs kami (contohnya
                                        www.indofund.id, termasuk semua domain dan sub-domain yang terkait daripadanya).
                                    </p>
                                </li>
                                <li>
                                    <p className="text">
                                        Ketika pengguna web menelusuri web, beberapa Cookie disimpan dalam Perangkat memori dalam file Cookie atau
                                        dikombinasikan dengan Cookie lain. Cookie menyediakan berbagai fungsi dan memfasilitasi fitur-fitur tertentu
                                        yang dapat membuat pengalaman berselancar di internet lebih nyaman dan bernilai/berguna bagi para pengguna web
                                        dengan memiliki Perangkat yang menyimpan informasi tertentu yang bisa didapatkan kembali oleh web server.
                                    </p>
                                </li>
                                <li>
                                    <p className="text">
                                        Informasi dalam Cookie berkaitan dengan kombinasi akun pengguna dan Perangkat. Dengan demikian, siapapun yang
                                        menggunakan beberapa akun atau Perangkat (termasuk komputer dengan browser yang berbeda-beda) memiliki
                                        beberapa set Cookie. Selain itu, Cookie tidak membedakan antara beberapa pengguna yang berbagi akun pengguna
                                        atau Perangkat yang sama. Apabila lebih dari satu browser digunakan pada komputer, masing-masing biasanya
                                        memiliki tempat penyimpanan cookie yang terpisah. Cookie dapat memiliki tanggal kadaluarsa yang panjang, atau
                                        tidak sama sekali, dan dapat tetap tersimpan dalam Perangkat Anda selama berbulan-bulan.
                                    </p>
                                </li>
                                <li>
                                    <p className="text">
                                        Cookie tidak bisa digunakan untuk mendapatkan data dari hard drive Anda, mengambil alamat e-mail atau mencuri
                                        informasi sensitif atau pribadi tentang Anda. Satu-satunya cara yang bisa membuat informasi pribadi apapun
                                        menjadi bagian dari file cookie Anda adalah jika Perangkat Anda menyediakan informasi tersebut untuk Web
                                        server.
                                    </p>
                                </li>
                                <li>
                                    <p className="text">
                                        Untuk informasi lebih lanjut tentang cookie, lihat www.aboutcookies.org, atau www.allaboutcookies.org. Anda
                                        juga bisa merujuk ke Kebijakan Privasi kami yang menjelaskan bagaimana kami melindungi privasi Anda dalam
                                        penggunaan informasi dan data tentang Anda.
                                    </p>
                                </li>
                            </ol>
                        </div>

                        <div className="jarak">
                            <h3 className="text-title">Mengapa Kami Menggunakan Cookie?</h3>
                            <ol className="text">
                                <li>
                                    <p className="text">
                                        Indofund.id menggunakan Cookie untuk meningkatkan kinerja Situs, Konten dan Layanan Situs, dan meningkatkan
                                        pengalaman browsing Anda. Kami dapat berbagi sejumah informasi Cookie yang kami kumpulkan dalam Grup
                                        Indofund.id dan analisis terhadap informasi tersebut dengan Pihak Ketiga Yang Dipilih sesuai dengan dan
                                        sebagaimana didefinisikan dalam Kebijakan Privasi kami. Secara umum, Indofund menggunakan Cookie untuk tujuan
                                        berikut ini:
                                    </p>
                                    <p className="text">
                                        <ol>
                                            <li>
                                                <p className="text">
                                                    memungkinkan Situs kami bekerja dengan benar dan memastikan agar Situs beroperasi dengan baik;
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text">
                                                    membantu kami melindungi keamanan akun Anda dengan mendeteksi aktivitas berbahaya dan pelanggaran
                                                    terhadap Ketentuan Penggunaan kami;
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text">menyediakan Layanan untuk Anda melalui administrasi Situs kami;</p>
                                            </li>
                                            <li>
                                                <p className="text">
                                                    membedakan Anda dari Para Pengguna lain (sebagaimana didefinisikan berdasarkan Kebijakan Privasi
                                                    kami) ketika Anda kembali ke Situs kami dan memungkinkan Anda untuk menggunakan Situs kami dengan
                                                    pengalaman browsing Anda lebih nyaman. Misalnya, cookie dapat memberitahu kami preferensi bahasa
                                                    dan komunikasi Anda;
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text">
                                                    meningkatkan cara kerja situs kami, contohnya, dengan memastikan bahwa Para Pengguna menemukan hal
                                                    yang mereka cari dengan mudah; dan
                                                </p>
                                            </li>
                                            <li>
                                                <p className="text">
                                                    memastikan dan meningkatkan kinerja Situs kami, dalam hal navigasi dan kegunaan, serta untuk
                                                    mengevaluasi efektivitas upaya pemasaran kami.
                                                </p>
                                            </li>
                                        </ol>
                                    </p>
                                </li>
                                <li>
                                    <p className="text">
                                        Apabila Anda ingin tahu lebih banyak tentang cookie tertentu yang kami gunakan, silahkan merujuk pada
                                        informasi di bawah ini:
                                    </p>
                                    <p className="text">
                                        <ol>
                                            <li>
                                                <p className="text">Cookie Yang Benar-Benar Diperlukan (Strictly Necessary Cookies)</p>
                                            </li>
                                            <p className="text">
                                                Ini adalah Cookie yang penting untuk kelayakan pengoperasian website kami. Cookie ini memungkinkan
                                                Anda untuk bergerak di sekitar situs kami dan menggunakan fitur-fitur kami, seperti mengingat tindakan
                                                sebelumnya ketika menavigasi kembali ke suatu laman dalam sesi yang sama. Cookie memungkinkan Anda
                                                untuk mengakses bagian-bagian Situs kami yang dibatasi hanya untuk para pengguna yang terdaftar. Tanpa
                                                cookie ini, Anda tidak dapat masuk atau kembali ke Situs atau bergerak melalui berbagai bagian tanpa
                                                harus terus mengidentifikasi diri Anda dengan mengirimkan kembali data Anda (contohnya, username atau
                                                nomor identifikasi pelanggan yang terenkripsi). Cookie ini juga membantu kami untuk melindungi
                                                keamanan akun Anda dengan memberitahu kami ketika beberapa orang melakukan login dari Perangkat atau
                                                akun yang sama.
                                            </p>
                                            <li>
                                                <p className="text">Cookie Analitis/ Kinerja (Analytical / Performance Cookies)</p>
                                            </li>
                                            <p className="text">
                                                Cookie ini memungkinkan kami mengumpulkan data statistik untuk analisis untuk memastikan Situs kami
                                                berfungsi dengan baik (seperti memungkinkan kami untuk mengidentifikasi kesalahan atau kinerja yang
                                                buruk dari Situs), dalam hal navigasi dan kegunaan, juga mengevaluasi efektivitas upaya pemasaran
                                                kami. Informasi yang kami susun termasuk tetapi tidak terbatas pada:
                                            </p>
                                            <ol>
                                                <li>
                                                    <p className="text">nama domain dan negara asal Penyedia (Provider) Layanan Internet;</p>
                                                </li>
                                                <li>
                                                    <p className="text">volume lalu lintas ke situs kami atau halaman web yang dilihat;</p>
                                                </li>
                                                <li>
                                                    <p className="text">pola penggunaan Situs kami;</p>
                                                </li>
                                                <li>
                                                    <p className="text">
                                                        alamat situs-situs yang dikunjungi sebelum dan sesudah mengunjungi Situs kami;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text">
                                                        Informasi halaman rujukan untuk melacak keberhasilan upaya pemasaran kami dalam menarik orang
                                                        ke Situs kami, melacak respon pengguna terhadap berbagai kampanye iklan dan aktivitas
                                                        pengguna;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text">
                                                        aktivitas “click stream” (yang berarti, jalur-jalur yang diambil oleh para pengunjung ke situs
                                                        kami sebagaimana mereka menavigasi dari satu halaman ke halaman lain), bagaimana Para Pengguna
                                                        bergerak di sekitar situs website kami ketika mereka menggunakannya dan hal apa yang diunduh;
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text">
                                                        atribut-atribut transaksional sesuai dengan informasi yang Anda berikan secara sukarela selama
                                                        menggunakan Situs kami; dan
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="text">kinerja Situs secara keseluruhan.</p>
                                                </li>
                                            </ol>
                                        </ol>
                                    </p>
                                </li>
                            </ol>
                        </div>

                        <div className="jarak">
                            <h4 className="text-title">Cookie Pihak Ketiga</h4>
                            <ol className="text">
                                <li>
                                    <p className="text">
                                        Selain Cookie kami sendiri, kami juga bekerja sama dengan perusahaan-perusahaan lain dalam menempatkan Cookie
                                        pada Situs kami untuk mengumpulkan informasi bagi kami. Perusahaan-perusahaan tersebut tidak dapat menggunakan
                                        Cookie ini untuk kepentingan internal mereka atau membagikan informasi yang dikumpulkan kepada siapapun selain
                                        Indofund, sesuai dengan ketentuan kontrak yang mereka telah tandatangani dengan kami.
                                    </p>
                                </li>
                                <li>
                                    <p className="text">
                                        Cookie iklan dan analitis dapat ditempatkan oleh atau atas nama para pengiklan independen yang memasang iklan
                                        di Situs kami. Sebagai contoh, dengan mengklik Konten Pihak Ketiga, para pengiklan dapat diberikan informasi
                                        tentang kunjungan Anda ke Situs kami sehingga mereka bisa menyajikan iklan-iklan yang mungkin menarik bagi
                                        Anda. Ketika Anda mengklik tombol ‘Like’ atau tombol ‘Share’ untuk layanan jejaring sosial, situs jejaring
                                        sosial tersebut dapat selanjutnya menggunakan informasi tentang kunjungan Anda untuk menargetkan iklan kepada
                                        Anda pada situs-situs lain. Cookie tersebut bersifat anonim dan tidak dapat mengidentifikasi individu. Kami
                                        tidak memiliki akses atau kontrol atas cookie pihak ketiga ini, dan pihak ketiga tersebut memiliki kebijakan
                                        cookie dan privasi mereka sendiri.
                                    </p>
                                </li>
                            </ol>
                        </div>

                        <div className="jarak">
                            <h4 className="text-title">Bagaimana Cara Menonaktifkan Cookie?</h4>
                            <ol className="text">
                                <li>
                                    <p className="text">
                                        Anda dapat memutuskan apakah dan bagaimana Perangkat Anda akan menerima, membatasi atau memblokir Cookie
                                        dengan mengkonfigurasikan preferensi atau pilihan Anda melalui pengaturan web browser. Selain itu, Anda juga
                                        dapat mengkonfigurasikan beberapa Perangkat untuk memberitahu Anda ketika sebuah Cookie sedang ditempatkan
                                        sehingga Anda memiliki kesempatan untuk memutuskan apakah akan menerima Cookie tersebut. Anda juga dapat
                                        meminta mengaktifkan “private browsing”, sebuah fungsi yang ditawarkan oleh sebagian besar web browser.
                                        Meskipun private browsing tidak memblokir Cookie, hal ini akan memungkinkan Anda untuk menghapus semua Cookie
                                        khusus untuk browser dan perangkat setiap kali Anda menutup browser atau mengaktifkan private browsing pada
                                        perangkat khusus tersebut. Beberapa aplikasi atau web tools (contohnya Flash Cookie) memungkinkan Anda untuk
                                        membatasi atau memblokir Cookie dengan cara yang berbeda atau melalui proses yang berbeda.
                                    </p>
                                </li>
                                <li>
                                    <p className="text">
                                        Perangkat, aplikasi atau web tool Anda dapat atau tidak dapat termasuk salah satu atau semua fungsi di atas
                                        untuk memungkinkan Anda untuk mengatur Cookie. Untuk informasi lebih lanjut tentang apakah fungsi tersebut
                                        tersedia dan bagaimana fungsionalitas itu bekerja, kunjungi menu bantuan pada Perangkat, aplikasi atau web
                                        tools Anda. Fungsionalitas pada Perangkat, aplikasi atau web tools Anda dapat diatur melalui antarmuka yang
                                        berbeda selain yang disediakan oleh web browser Anda.
                                    </p>
                                </li>
                                <li>
                                    <p className="text">
                                        Apabila Anda hanya melakukan browsing di Situs kami dan Anda bukan Pengguna terdaftar, Anda mungkin masih
                                        dapat memanfaatkan Situs tanpa menyetujui Cookie kami. Namun, membatasi atau memblokir Cookie dapat
                                        mempengaruhi kegunaan dari Situs. Sebagai contoh, Anda mungkin tidak diperbolehkan menonton video atau konten
                                        lain pada Situs kami.
                                    </p>
                                </li>
                                <li>
                                    <p className="text">
                                        Jika Anda menggunakan pengaturan browser untuk memblok atau menolak semua Cookie, Anda mungkin tidak dapat
                                        mengakses semua atau bagian dari Situs kami, dan kami mungkin tidak memiliki pilihan kecuali berhenti
                                        menawarkan Konten Situs, Layanan, Layanan Interaktif, aplikasi-aplikasi dan tools kepada Anda karena Cookie
                                        adalah hal yang penting untuk administrasi dan keamanan Situs kami.
                                    </p>
                                </li>
                            </ol>
                        </div>

                        <div className="jarak">
                            <h4 className="text-title">Bagaimana Cara Menonaktifkan Cookie?</h4>
                            <p className="text">
                                Anda dapat menghindari pengumpulan Data Pribadi dengan menggunakan teknologi-teknologi lain sebagaimana tercantum di
                                bawah ini:
                            </p>
                            <ol className="text">
                                <li>
                                    <p className="text">
                                        Untuk menghindari analisis oleh Google Analytics pada situs kami atau situs lainnya, kunjungi{" "}
                                        <a href="http://tools.google.com/dlpage/gaoptout">halaman ini.</a>
                                    </p>
                                </li>
                                <li>
                                    <p className="text">
                                        Untuk menghindari penanyangan iklan pribadi pada jaringan konten Google, kunjungi Google Ads Preferences
                                        Manager atau jika Anda ingin menghindara secara permanen bahkan saat semua cookie dihapus dari browser Anda,
                                        Anda dapat menginstal plugin mereka.
                                    </p>
                                </li>
                            </ol>
                        </div>

                        <div className="jarak">
                            <h4 className="text-title">Pembaharuan Kebijakan</h4>
                            <p className="text">
                                Setiap perubahan terhadap kebijakan ini akan di posting di <a href="http://indofund.id/">Situs ini</a>. Kami berhak
                                untuk mengubah Kebijakan Cookie ini dari waktu ke waktu dan perubahan-perubahan tersebut berlaku segera setelah
                                diposting. Jika Anda terus menggunakan Situs, Konten Situs atau Layanan, hal itu mengindikasikan persetujuan Anda
                                terhadap semua perubahan.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default privacyCookie;
