const initialState = {
    response: [],
    responseLender: [],
    isLoading: false,
    isError: false,
    isLoadingDownload: false
};

const Digisign = (state = initialState, action) => {
    switch (action.type) {
        case "SEND_EMAIL_PENDING":
            return {
                ...state,
                isLoading: true
            };

        case "SEND_EMAIL_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case "SEND_EMAIL_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoading: false,
                response: action.payload.data
            };
        case "SIGN_DOCUMENT_PENDING":
            return {
                ...state,
                isLoading: true
            };

        case "SIGN_DOCUMENT_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case "SIGN_DOCUMENT_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoading: false,
                response: action.payload.data
            };
        case "DOWNLOAD_DOCUMENT_PENDING":
            return {
                ...state,
                isLoadingDownload: true
            };

        case "DOWNLOAD_DOCUMENT_REJECTED":
            return {
                ...state,
                isLoadingDownload: false,
                isError: true
            };

        case "DOWNLOAD_DOCUMENT_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoadingDownload: false,
                response: action.payload.data
            };
        case "SEND_EMAIL_LENDER_PENDING":
            return {
                ...state,
                isLoading: true
            };

        case "SEND_EMAIL_LENDER_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case "SEND_EMAIL_LENDER_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoading: false,
                responseLender: action.payload.data
            };
        case "SIGN_DOCUMENT_LENDER_PENDING":
            return {
                ...state,
                isLoading: true
            };

        case "SIGN_DOCUMENT_LENDER_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case "SIGN_DOCUMENT_LENDER_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoading: false,
                responseLender: action.payload.data
            };
        case "DOWNLOAD_DOCUMENT_LENDER_PENDING":
            return {
                ...state,
                isLoading: true
            };

        case "DOWNLOAD_DOCUMENT_LENDER_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case "DOWNLOAD_DOCUMENT_LENDER_FULFILLED":
            return {
                ...state,
                isLoading: false,
                responseLender: action.payload.data
            };
        default:
            return state;
    }
};
export default Digisign;
