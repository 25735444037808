import React, { Component } from "react";
import { Button, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { EmailVerifLender } from "../../../redux/action/Digisign";
import { LendAppSign, LendAppSigned } from "../../../redux/action/Lender";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import classnames from "classnames";
import "../../../sass/loader.css";
import SignProject from "./SignProject";
import Download from "./DownloadDoc";
import Loader from "react-loader-spinner";
import NoData from "../../assets/no data/NoData";
const axios = require("axios").default;
const url = process.env.REACT_APP_BASSE_API;
const queryString = require("query-string");


class Signing extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      disable: true,
      activeTab: "1",
      loading: true,
      query: {
        limit: 6,
        sort: "desc",
        sorting: 'createdAt',
        page: 1,
      }
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  async fetchLendAppSignData(query) {
    const queryParams = { ...query, sign: "Not sign" }
    await this.props.dispatch(LendAppSign(queryParams))

  }
  async fetchLendAppSignedData(query) {
    const queryParams = { ...query, sign: "Done sign" }

    await this.props.dispatch(LendAppSigned(queryParams)).then(res => console.log(res));

  }

  async componentDidMount() {
    await this.fetchLendAppSignData(this.state.query);
    await this.fetchLendAppSignedData(this.state.query);
  }
  //aktivasi
  handleClick = async (id) => {
    // console.log(id)
    const data = await this.props.dispatch(EmailVerifLender(id));
    if (data.value.data.JSONFile.result === "05") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.notif === "General Error") {
      Swal.fire({
        title: "Gagal",
        text: `Terjadi Kesalahan General Error Harap Hubungi Customer Service Indofund`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "30") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "12") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "28") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "06") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "14") {
      if (data.value.data.JSONFile.notif === "Email sudah melakukan aktivasi") {
        await axios.post(`${url}/digisign/aktivasi`, { email_user: this.props.email, document_id: "" }, {});
        Swal.fire({
          title: "Berhasil",
          text: "Akun Telah Berhasil Diaktivasi",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Kembali"
        }).then(async (result) => {
          if (result.value) {
            window.location.reload();
          }
        });
      } else {
        Swal.fire({
          title: "Gagal",
          text: `${data.value.data.JSONFile.notif}`,
          icon: "warning",
          button: "ok"
        });
      }
    } else if (data.value.data.JSONFile.result === "28") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "06") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "14") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else if (data.value.data.JSONFile.result === "12") {
      Swal.fire({
        title: "Gagal",
        text: `${data.value.data.JSONFile.notif}`,
        icon: "warning",
        button: "ok"
      });
    } else {
      window.location = this.props.digisign.responseLender.JSONFile.link;
    }
  };

  authTandatangan = (id_lender) => {
    if (this.props.digisign_kyc === true && this.props.userDigisign === false) {
      return (
        <div className="row mb-4 d-flex justify-content-center min-vh-100">
          <div className="col-12 col-md-10 mb-3 mb-md-0 mt-4 ">
            <h4 className=" font-weight-semi-bold">Aktivasi email</h4>
            <p className="text-secondary mb-4 mt-4">
              Untuk melakukan tanda tangan digital Anda diharuskan melakukan pendaftaran Ke Digisign. Tanda tangan ini digunakan untuk
              pihak <b className="text-primary">Indofund.id</b> mengirim dokumen berisi perjanjian pinjaman kepada pihak{" "}
              <b className="text-primary">Lender</b>. Klik tombol berwarna oranye untuk daftar ke halaman Digisign
            </p>
            {this.props.digisign_kyc !== true ? (
              <Button color="primary" disabled>
                {" "}
                Konfirmasi email
              </Button>
            ) : (
              <Button color="primary" onClick={() => this.handleClick(id_lender)}>
                {" "}
                Konfirmasi email
              </Button>
            )}
          </div>
        </div>
      );
    } else if (this.props.digisign_kyc === true && this.props.userDigisign === true) {
      return (
        <div className="row mb-4 d-flex justify-content-center">
          <div className="col-12 col-md-10 mb-3 mt-3">
            <p className="text-secondary text-center">Saat ini fitur tanda tangan sudah aktif.</p>
            {this.props.lender.isLoadingLendSign ? (
              <Loader type="ThreeDots" color="#FF7A00" height={100} width={100} className="loading-dots" />
            ) : (
              <SignProject query={this.state.query} reFetchData={this.fetchLendAppSignData.bind(this)} pagination={this.props.lender.pagination.sign} lending={this.props.lender.lenderLend} email={this.props.email} />
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="row mb-4 d-flex justify-content-center">
          <div className="col-12 col-md-10 mb-3 mt-3">
            <p className="text-secondary text-center">
              Saat ini fitur tanda tangan <b>belum aktif</b>
            </p>
            <NoData
              symbol={<i className="material-icons-outlined">assignment</i>}
              keterangan={"Data anda sedang di verifikasi oleh admin."}
            />
          </div>
        </div>
      );
    }
  };
  render() {
    return (
      <div>
        <nav>
          <Nav tabs className="over-hidden">
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
                style={{ cursor: "pointer" }}>
                Tanda Tangan Digital
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
                style={{ cursor: "pointer" }}>
                Dokumen yang Telah Di Tanda Tangan
              </NavLink>
            </NavItem>
          </Nav>
        </nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="mt-5">
              <h3 className="text-center font-weight-bold">Tanda Tangan Digital</h3>
              {this.authTandatangan(this.props.id)}
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="mt-5">
              <h3 className="text-center font-weight-bold">Download Dokumen</h3>
              {this.props.lender.isLoadingLendSigned ? (
                <Loader type="ThreeDots" color="#FF7A00" height={100} width={100} className="loading-dots" />
              ) : (
                <Download query={this.state.query} reFetchData={this.fetchLendAppSignedData.bind(this)} lending={this.props.lender.lenderLendSigned} pagination={this.props.lender.pagination.signed} />
              )}
            </div>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    digisign: state.digisign,
    lender: state.lender
  };
};
export default connect(mapStateToProps)(Signing);
