import React, { Component } from "react";
import { getProject } from "../../../redux/action/Project";
import { PaymentLoan, PaymentPenalty } from "../../../redux/action/Borrower";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { LoadingDetailCicil } from "../../assets/loading data/LoadingBorrower";
import Swal from "sweetalert2";
class DetailCicilan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectdet: [],
            nominal: "",
            totalPinjaman: "",
            borrower: "",
            cicilan: ""
        };
    }

    async componentDidMount() {
        await this.props.dispatch(getProject(this.props.match.params.id));
        this.setState({
            projectdet: this.props.project.projectDetail,
            nominal: this.props.project.projectDetail.nilai_pengajuan_pinjaman.toLocaleString("IN"),
            totalPinjaman: this.props.project.projectDetail.nilai_total_peminjam.toLocaleString("IN"),
            borrower: this.props.project.projectDetail.borrower
        });
    }

    //pembayaran selain micro
    CicilanList = () => {
        // console.log(this.props.project.projectDetail)
        const micro = this.state.borrower.micro_financing;
        const employee = this.state.borrower.employee_loan;
        const cicilan = this.state.projectdet.rincian_pinjaman;
        // console.log(this.state.borrower)
        if (cicilan === undefined) {
        } else {
            const hasil = cicilan.map((val, i) => {
                // console.log(val)
                const bayarCicilan = () => {
                    const now = new Date();
                    // const now = new Date("2020-02-05")
                    const borrower = this.state.projectdet.borrower._id;
                    const projectId = this.props.match.params.id;

                    if (i === 0) {
                        if (cicilan[0].status_bayar === false) {
                            if (
                                now.getTime() <= new Date(cicilan[0].jatuh_tempo).getTime() ||
                                now.getTime() > new Date(cicilan[0].jatuh_tempo).getTime()
                            ) {
                                // if(micro === true || employee === true){
                                if (employee === true) {
                                    return <b className="text-danger">Belum dibayar</b>;
                                } else {
                                    // return (
                                    //   <Button
                                    //     color="primary"
                                    //     className="btn btn-block"
                                    //     onClick={() => this.PaymentLoan(borrower, projectId, i)}
                                    //   >
                                    //     Bayar Cicilan{" "}
                                    //   </Button>
                                    // );
                                }
                            } else {
                                // if(micro === true || employee === true){
                                if (employee === true) {
                                    return <b className="text-danger">Sudah dibayar</b>;
                                } else {
                                    return (
                                        <Button disabled color="secondary" className="btn btn-block">
                                            Bayar Cicilan
                                        </Button>
                                    );
                                }
                            }
                        } else {
                            // if(micro === true || employee === true){
                            if (employee === true) {
                                return <b className="text-danger">Belum dibayar</b>;
                            } else {
                                return (
                                    <Button disabled color="secondary" className="btn btn-block">
                                        Bayar Cicilan
                                    </Button>
                                );
                            }
                        }
                    } else if (i > 0) {
                        //cek pembayaran cicilan sebelum nya sudah dibayar atau belum
                        if (cicilan[i - 1].status_bayar === true) {
                            if (cicilan[i].status_bayar === true) {
                                // if(micro === true || employee === true){
                                if (employee === true) {
                                    return <b className="text-green">Sudah dibayar</b>;
                                } else {
                                    return (
                                        <Button disabled color="secondary" className="btn btn-block">
                                            Bayar Cicilan{" "}
                                        </Button>
                                    );
                                }
                                //cek berdasarkan tgl jatuh tempo dan tgl sekarang
                            } else if (
                                now.getTime() <= new Date(cicilan[i].jatuh_tempo).getTime() ||
                                now.getTime() > new Date(cicilan[i].jatuh_tempo).getTime()
                            ) {
                                // if(micro === true || employee === true){
                                if (employee === true) {
                                    return <b className="text-danger">Belum dibayar</b>;
                                } else {
                                    return (
                                        <Button color="primary" className="btn btn-block" onClick={() => this.PaymentLoan(borrower, projectId, i)}>
                                            Bayar Cicilan
                                        </Button>
                                    );
                                }
                            }
                        } else {
                            // if(micro === true || employee === true){
                            if (employee === true) {
                                return <b className="text-danger">Belum dibayar</b>;
                            } else {
                                return (
                                    <Button disabled color="secondary" className="btn btn-block">
                                        Bayar Cicilan{" "}
                                    </Button>
                                );
                            }
                        }
                    }
                };

                const bayarDenda = () => {
                    const now = new Date();
                    // const now = new Date("2020-02-05")
                    const borrower = this.state.projectdet.borrower._id;
                    const projectId = this.props.match.params.id;
                    const denda = val.denda;

                    if (i === 0) {
                        if (cicilan[0].status_bayar === true) {
                            if (
                                now.getTime() > new Date(cicilan[0].jatuh_tempo).getTime() &&
                                cicilan[0].denda !== 0 &&
                                cicilan[0].status_bayar_denda === false
                            ) {
                                return (
                                    <Button
                                        color="primary"
                                        className="btn btn-block"
                                        onClick={() => this.PaymentPenalty(borrower, projectId, i, denda)}>
                                        Bayar Denda
                                    </Button>
                                );
                            } else if (cicilan[0].status_denda === true) {
                                return <p className="text-center">sudah bayar denda</p>;
                            } else {
                                return "";
                            }
                        } else {
                            return "";
                        }
                    } else if (i > 0) {
                        if (cicilan[i].status_bayar === true) {
                            if (
                                now.getTime() > new Date(cicilan[i].jatuh_tempo).getTime() &&
                                cicilan[i].denda !== 0 &&
                                cicilan[i].status_bayar_denda === false
                            ) {
                                return (
                                    <Button
                                        color="primary"
                                        className="btn btn-block"
                                        onClick={() => this.PaymentPenalty(borrower, projectId, i, denda)}>
                                        Bayar Denda{" "}
                                    </Button>
                                );
                            } else if (cicilan[i].status_bayar_denda === true) {
                                return (
                                    <div className="text-center pt-2">
                                        {" "}
                                        <b>Sudah bayar denda</b>
                                    </div>
                                );
                            } else {
                                return "";
                            }
                        } else {
                            return "";
                        }
                    }
                };

                const showDenda = () => {
                    if (val.status_denda === true && val.status_bayar_denda === false) {
                        return (
                            <div className="col-6 col-md-3 mb-3 mb-xl-0">
                                <p className="fs-n1 text-secondary mb-1">Nominal Denda</p>
                                <p className="mb-0">Rp. {val.denda.toLocaleString("IN")} </p>
                            </div>
                        );
                    } else if (val.status_bayar_denda === true) {
                        return (
                            <div className="col-6 col-md-3 mb-3 mb-xl-0">
                                <p className="fs-n1 text-secondary mb-1">Tgl Bayar Denda</p>
                                <p className="mb-0">{new Date(val.tgl_pembayaran_denda).toLocaleDateString("IN")}</p>
                            </div>
                        );
                    }
                };

                const showButtonBayar = () => {
                    if (val.status_bayar === false) {
                        return bayarCicilan();
                    } else if (val.status_bayar === true && val.status_bayar_denda === false) {
                        return bayarDenda();
                    } else {
                        return <b className="text-green">Sudah Bayar</b>;
                    }
                };
                // console.log(val)
                return (
                    <div className="transaction p-3" key={val._id}>
                        <div className="card border-0 shadow-sm">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col-12 col-xl-9 mb-3 mb-xl-0">
                                        <div className="row">
                                            <div className="col-12 mb-3 bg-dark-gray">
                                                <h5 className="font-weight-bold">Bulan {i + 1}</h5>
                                            </div>
                                            <div className="col-6 col-md-3 mb-3">
                                                <p className="fs-n1 text-secondary mb-1">Tgl Jatuh Tempo</p>
                                                <p className="mb-0">{new Date(val.jatuh_tempo).toLocaleDateString("IN")}</p>
                                            </div>
                                            <div className="col-6 col-md-3 mb-3">
                                                <p className="fs-n1 text-secondary mb-1">Nominal Tagihan</p>
                                                <p className="mb-0">Rp {val.borrower_bayar.toLocaleString("IN")}</p>
                                            </div>
                                            <div className="col-6 col-md-3 mb-3">
                                                <p className="fs-n1 text-secondary mb-1">Tgl Bayar Cicilan</p>
                                                <p className="mb-0">
                                                    {!val.tgl_pembayaran_pinjaman
                                                        ? "Belum dibayar"
                                                        : new Date(val.tgl_pembayaran_pinjaman).toLocaleDateString("IN")}
                                                </p>
                                            </div>
                                            {showDenda()}
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-3 text-center">{showButtonBayar()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
            return hasil;
        }
    };

    //cicilan micro
    CicilanListMicro = () => {
        const cicilan = this.state.projectdet.rincian_pinjaman_mikro;
        // const cicilan = this.state.projectdet.rincian_pinjaman;

        if (cicilan === undefined) {
        } else {
            const hasil = cicilan.map((val, i) => {
                // console.log(val)

                const showDenda = () => {
                    if (val.status_denda === true) {
                        return (
                            <div className="col-6 col-md-3 mb-3 mb-xl-0">
                                <p className="fs-n1 text-secondary mb-1">Nominal Denda</p>
                                <p className="mb-0">Rp. {val.denda.toLocaleString("IN")} </p>
                            </div>
                        );
                    } else {
                        return (
                            <div className="col-6 col-md-3 mb-3 mb-xl-0">
                                <p className="fs-n1 text-secondary mb-1">Nominal Denda</p>
                                <p className="mb-0">-</p>
                            </div>
                        );
                    }
                };

                const bayarCicilan = () => {
                    const now = new Date();
                    // const now = new Date("2020-02-05")
                    if (i === 0) {
                        if (cicilan[0].status_bayar === false) {
                            if (
                                now.getTime() <= new Date(cicilan[0].jatuh_tempo).getTime() ||
                                now.getTime() > new Date(cicilan[0].jatuh_tempo).getTime()
                            ) {
                                return <b className="text-danger">Belum dibayar</b>;
                            } else {
                                return <b className="text-green">Sudah dibayar</b>;
                            }
                        } else {
                            return <b className="text-green">Sudah dibayar</b>;
                        }
                    } else if (i > 0) {
                        // console.log(cicilan[0].status_bayar)
                        // console.log(now.getTime() > new Date(cicilan[i].jatuh_tempo).getTime())
                        if (cicilan[i - 1].status_bayar === true) {
                            if (cicilan[i].status_bayar === true) {
                                return <b className="text-green">Sudah dibayar</b>;
                            } else if (
                                now.getTime() <= new Date(cicilan[i].jatuh_tempo).getTime() ||
                                now.getTime() > new Date(cicilan[i].jatuh_tempo).getTime()
                            ) {
                                return <b className="text-danger">Belum dibayar</b>;
                            }
                        } else {
                            return <b className="text-danger">Belum dibayar</b>;
                        }
                    }
                };

                const showButtonBayar = () => {
                    if (val.status_bayar === false) {
                        return bayarCicilan();
                    } else {
                        return <b className="text-green">Sudah Bayar</b>;
                    }
                };
                // console.log(val)
                return (
                    <div className="transaction p-3" key={val._id}>
                        <div className="card border-0 shadow-sm">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col-12 col-xl-9 mb-3 mb-xl-0">
                                        <div className="row">
                                            <div className="col-12 mb-4 bg-dark-gray">
                                                <h5 className="font-weight-bold">Hari {i + 1}</h5>
                                                {/* <h5 className="font-weight-bold">Bulan {i + 1}</h5> */}
                                            </div>
                                            <div className="col-6 col-md-3 mb-3">
                                                <p className="fs-n1 text-secondary mb-1">Tgl Jatuh Tempo</p>
                                                <p className="mb-0">{new Date(val.jatuh_tempo).toLocaleDateString("IN")}</p>
                                            </div>
                                            <div className="col-6 col-md-3 mb-3">
                                                <p className="fs-n1 text-secondary mb-1">Nominal Tagihan</p>
                                                <p className="mb-0">Rp {val.borrower_bayar.toLocaleString("IN")}</p>
                                            </div>
                                            <div className="col-6 col-md-3 mb-3">
                                                <p className="fs-n1 text-secondary mb-1">Tgl Bayar Cicilan</p>
                                                <p className="mb-0">
                                                    {!val.tgl_pembayaran_pinjaman
                                                        ? "Belum dibayar"
                                                        : new Date(val.tgl_pembayaran_pinjaman).toLocaleDateString("IN")}
                                                </p>
                                            </div>
                                            {showDenda()}
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-3 text-center">{showButtonBayar()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
            return hasil;
        }
    };

    PaymentLoan = async (borrowerId, projectId, index) => {
        Swal.fire({
            title: "Bayar cicilan",
            text: "Anda yakin membayar cicilan?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ya bayar sekarang"
        })
            .then(async (result) => {
                if (result.value) {
                    await this.props.dispatch(PaymentLoan(borrowerId, projectId, index));
                    // console.log(this.props.borrower.isLoadingPaymentLoan === true? 'LOADING' : 'ga load')
                    Swal.fire({
                        title: "Berhasil",
                        text: "Anda berhasil bayar",
                        icon: "success",
                        buttons: "ok"
                    }).then(() => {
                        window.location.reload();
                    });
                }
            })
            .catch((err) => {
                // console.log(err.response)
                if (err.response.status === 400) {
                    Swal.fire({
                        title: "Tidak Berhasil",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (err.response.status === 404) {
                    Swal.fire({
                        title: "",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (err.response.status === 500) {
                    Swal.fire({
                        title: "",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (err.response.status === 401) {
                    Swal.fire({
                        title: "",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                }
            });
    };

    //pembayaran denda
    PaymentPenalty = async (borrowerId, projectId, index, denda) => {
        Swal.fire({
            title: " Anda yakin membayar denda?",
            html: ` Denda ada sebesar <b>Rp. ${denda.toLocaleString("IN")}</b> `,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ya bayar sekarang"
        })
            .then(async (result) => {
                if (result.value) {
                    await this.props.dispatch(PaymentPenalty(borrowerId, projectId, index));
                    Swal.fire({
                        title: "Berhasil",
                        text: "Anda berhasil bayar",
                        icon: "success",
                        buttons: "ok"
                    });
                }
            })
            .catch((err) => {
                // console.log(err.response)
                if (err.response.status === 400) {
                    Swal.fire({
                        title: "Tidak Berhasil",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (err.response.status === 404) {
                    Swal.fire({
                        title: "",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (err.response.status === 500) {
                    Swal.fire({
                        title: "",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                }
            });
    };

    ShowCicilan() {
        if (this.state.projectdet.tipe_pinjaman === "Micro Financing") {
            return this.CicilanListMicro();
        } else {
            return this.CicilanList();
        }
    }

    render() {
        return (
            <div>
                <h4 className="text-center font-weight-semi-bold mb-5 mt-3">
                    <b>Detail Cicilan</b>
                </h4>
                {this.props.project.isLoadingIdProject ? (
                    <div>
                        <LoadingDetailCicil />
                        <LoadingDetailCicil />
                    </div>
                ) : (
                    this.ShowCicilan()
                )}
                {/* {this.CicilanList()} */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
        borrower: state.borrower
    };
};
export default connect(mapStateToProps)(DetailCicilan);
