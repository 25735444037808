import React from "react";
import { Button } from "reactstrap";

const DownloadDocumentSuperlender = (props) => {
    return (
        <div style={{ marginBottom: "22px" }}>
            <label className="card border-0 shadow-sm payment-method">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between ">
                        <div className="d-flex align-items-center">
                            <div>
                                <p className="fs-1 mb-1 font-weight-semi-bold">Download Dokumen</p>
                            </div>
                        </div>
                        <Button disabled={props.isLoading} color="primary" onClick={() => props.onClick()}>
                            {`${props.isLoading ? "Mohon Tunggu" : "Download"}`}
                        </Button>
                    </div>
                </div>
            </label>
        </div>
    );
};

export default DownloadDocumentSuperlender;
