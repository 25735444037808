import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import './modal-asset.scss';
import NumberFormat from "react-number-format";

function ModalAsset({ isOpen, toggle, balanceAmount, outstandingAmount, berjalanAmount, totalAmount }) {
    console.log(berjalanAmount)
    return (
        <Modal size="md" isOpen={isOpen} toggle={toggle} className="modal-dialog modal-dialog-centered" tabIndex="-1">
            <ModalHeader toggle={toggle} className="modal-tkb-wrapper">
                <div className="text-center flex-fill mb-4">
                    <h6 className="modal__title text-center mb-2">Nilai Asset</h6>
                </div>
            </ModalHeader>
            <ModalBody className="modal-tkb-body">
                <div className="d-flex flex-column modal__content-wrapper">
                    <div className="flex-1 d-flex justify-content-between modal__content">
                        <p className="font-weight-normal ">Saldo Dompet</p>
                        <p className="text-right modal__content__value">  <NumberFormat
                            value={balanceAmount}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={0}
                            prefix={"Rp "}
                            renderText={(value) => <span>{value}</span>}
                        /></p>
                    </div>
                    <div className="flex-1 d-flex justify-content-between modal__content">
                        <p className="font-weight-normal ">Pendanaan Berjalan </p>
                        <p className="text-right modal__content__value">
                            <NumberFormat
                            value={berjalanAmount}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={0}
                            prefix={"Rp "}
                            renderText={(value) => <span>{value}</span>}
                        /></p>
                    </div>
                    <div className="flex-1 d-flex justify-content-between modal__content">
                        <p className="font-weight-normal ">Pendanaan Outstanding </p>
                        <p className="text-right modal__content__value">  <NumberFormat
                            value={outstandingAmount}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={0}
                            prefix={"Rp "}
                            renderText={(value) => <span>{value}</span>}
                        /></p>
                    </div>
                    <div />
                    <div className="flex-1 d-flex justify-content-between modal__content">
                        <p className="modal__content__summary-desc">Total Asset </p>
                        <p className="text-right modal__content__summary-value">  <NumberFormat
                            value={totalAmount}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={0}
                            prefix={"Rp "}
                            renderText={(value) => <span>{value}</span>}
                        /></p>
                    </div>
                </div>
            </ModalBody>

            <Button color="primary" className="btn btn-primary btn-md my-4 mx-4" onClick={toggle}>
                Mengerti
            </Button>

        </Modal>
    );

}
export default ModalAsset;
