import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import "./PengkinianData.scss";
import ContentModalEntity from "./entity/ContentModal";
import ContentModalPersonal from "./personal/ContentModal";

function PengkinianData(props) {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className="modal-dialog modal-dialog-centered modal-size" tabIndex="-1">
            <ModalHeader toggle={props.toggle} className="p-4">
                <div className="text-left flex-fill">
                    <h5 className="modal-title font-weight-bold mb-6">Pengkinian Data</h5>
                    <hr className="my-4" />
                    <p className="mb-1 f-14">
                        Harap lakukan pengkinian data terlebih dahulu.
                    </p>
                </div>

            </ModalHeader>
            <ModalBody className="p-4" style={{ overflowX: 'hidden' }}>
                {props.jenis_pengguna === 1 ?
                    <ContentModalPersonal toggle={props.toggle} loading={props.loading} onSubmit={props.onSubmit} master={props.master} />
                    :
                    <ContentModalEntity toggle={props.toggle} loading={props.loading} onSubmit={props.onSubmit} master={props.master} />
                }
            </ModalBody>
        </Modal>
    );
}
export default PengkinianData;
