import React, { Component } from "react";
import { DownloadDocumentLender } from "../../../redux/action/Digisign";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Button } from "reactstrap";
import NoData from "../../assets/no data/NoData";
import Pagination from "react-js-pagination";
import NumberFormat from "react-number-format";
import moment from "moment";

class DownloadDoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      todosPerPage: 15
    };
    this.handleClick = this.handleClick.bind(this);
  }
  //pagination
  handleClick(pageNumber) {
    if(pageNumber==this.props.pagination.page) return
    window.scrollTo(0, 0);
    this.setState({
      currentPage: pageNumber
    }, async () => {
      await this.props.reFetchData({ ...this.props.query, page: pageNumber, sort: this.state.sort })

    });

  }

  handleDownload = async (id) => {
    // console.log(id)
    await this.props
      .dispatch(DownloadDocumentLender(id))
      .then((res) => {
        if (res.value.data.JSONFile.result === "05") {
          Swal.fire({
            title: "Gagal",
            text: `${res.value.data.JSONFile.notif}`,
            icon: "warning",
            button: "ok"
          });
        } else if (res.value.data.JSONFile.result === "12") {
          Swal.fire({
            title: "Gagal",
            text: `${res.value.data.JSONFile.notif}`,
            icon: "warning",
            button: "ok"
          });
        } else {
          const pdf = res.value.data.JSONFile.file;
          const linkSource = `data:application/pdf;base64,${pdf}`;
          const downloadLink = document.createElement("a");
          const fileName = "Perjanjian layanan pinjam meminjam.pdf";

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  };
  downloadDoc = (proyekDownload, currentPage, todosPerPage, length) => {
    // const indexOfLastTodo = currentPage * todosPerPage;
    // const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    // function compare(a, b) {
    //   return new Date(b.createdAt) - new Date(a.createdAt);
    // }
    if (length !== 0) {
      //&& val.document_id !== undefined
      const hasil = proyekDownload
        // .filter(
        //     (item) => item.userTtd === true

        //     // item.id_pinjaman &&
        //     // item.id_pinjaman.status_proyek === "Menggalang dana"
        // )
        // .sort(compare)
        // .slice(indexOfFirstTodo, indexOfLastTodo)
        .map((val) => {
          //   console.log(val);
          if (
            // val.id_pinjaman.status_proyek === "Menggalang dana" &&
            val.userTtd === true
          ) {
            return (
              <div className="transaction p-3" key={val._id}>
                <div className="card border-0 shadow-sm">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-12 mb-3 mb-xl-0">
                        <div className="row">
                     
                          <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">
                            <p className="fs-n1 text-secondary mb-1 title-sign-card">Tanggal</p>
                            <p className="fs-n1 mb-0">{moment(new Date(val.createdAt)).format('LL')}</p>
                          </div>
                          <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">

                            <p className="fs-n1 text-secondary mb-1 title-sign-card">Nama Pinjaman</p>
                            <p className="mb-0">{val.id_pinjaman.nama_pinjaman}</p>
                          </div>
                          <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">

                            <p className="fs-n1 text-secondary mb-1 title-sign-card">Bunga</p>
                            <p className="mb-0">
                              {val.id_pinjaman.tipe_pinjaman === "Employee Loan"
                                ? (val.id_pinjaman.suku_bunga_konsumtif * 100).toPrecision(3)
                                : (val.id_pinjaman.suku_bunga_pinjaman * 100).toPrecision(3)}
                              %
                            </p>
                          </div>
                          <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">

                            <p className="fs-n1 text-secondary mb-1 title-sign-card">Nominal</p>
                            <NumberFormat
                              value={val.nilai_penawaran_disetujui}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"Rp "}
                              renderText={(value) => <p className="mb-0">{value}</p>}
                            />
                          </div>
                          <div className="col-6 col-md-3 mb-3 d-flex flex-column flex-1">

                            <p className="fs-n1 text-secondary mb-1 title-sign-card">Tenor</p>
                            <p className="mb-0">{val.id_pinjaman.jangka_waktu_pinjaman} Bulan</p>
                          </div>
                          <div className="col-12 col-xl-2 text-center">
                            {val.sendDocument === false ? (
                              <Button color="primary" disabled onClick={() => this.handleDownload(val._id)}>
                                Download PDF
                              </Button>
                            ) : (
                              <Button color="primary" onClick={() => this.handleDownload(val._id)}>
                                Download PDF
                              </Button>
                            )}
                            <a href="data:application/pdf;base64,[base64]" download="file.pdf"></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        });
      return hasil;
    } else {
      return (
        <NoData
          symbol={<i className="material-icons-outlined">assignment</i>}
          keterangan={"Anda belum mendanai proyek atau perjanjian pinjaman belum di tanda tangani"}
        />
      );
    }
  };
  render() {
    const { currentPage, todosPerPage } = this.state;

    return (
      <div>
        {this.downloadDoc(this.props.lending, this.props.pagination?.page, this.props.pagination?.limit, this.props.pagination?.totalData)}

        <div className="d-flex justify-content-center mt-3">
        {this.props.pagination && this.props.pagination.totalData>0 &&
            <Pagination
              hideDisabled
              itemClass="page-item"
              linkClass="page-link"
              activePage={parseInt(this.props.pagination?.page) || 1}
              itemsCountPerPage={this.props.pagination?.limit || 6}
              totalItemsCount={parseInt(this.props.pagination?.totalData)}
              onChange={this.handleClick.bind(this)}
            />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    digisign: state.digisign
  };
};
export default connect(mapStateToProps)(DownloadDoc);
