import React, { Component } from "react";
import "./Banner.scss";
import OwlCarousel from "react-owl-carousel2";
import "../../../../../node_modules/react-owl-carousel2/lib/styles.css";
import ItemBanner from "./ItemBanner";

class Banner extends Component {
    render() {
        const options = {
            dots: true,
            dotsEach: true,
            dotData: true,
            items: 1,
            dotsClass: "owl-dots no-lg",
            loop: true,
            autoplay: true,
            autoplayTimeout: 4000,
            autoplayHoverPause: true,
            nav: true,
            navText: ["<i class='fa fa-chevron-left icons' ></i>", "<i class='fa fa-chevron-right icons' ></i>"]
        };

        const imgCarousel = [
            {
                backgroundImage:
                    window.innerWidth <= 480
                        ? "url(/images/home-carousel/min-1.png)"
                        : "url(https://frontend-picture.s3-ap-southeast-1.amazonaws.com/1.png)",
                heading1: "Mari membangun usaha dan potensi di Indonesia langsung dari tangan kita bersama",
                heading2: "Ajukan pendanaan dengan bunga bersahabat dan berikan pendanaan dengan return yang menarik."
            },
            {
                backgroundImage:
                    window.innerWidth <= 480
                        ? "url(/images/home-carousel/min-2.png)"
                        : "url(https://frontend-picture.s3-ap-southeast-1.amazonaws.com/2.png)",
                heading1: "Kini Indofund hadirkan layanan Pendanaan Multiguna hingga Rp 25 juta!",
                heading2: "Yuk, kembangkan usaha Anda bersama kami."
            },
            {
                backgroundImage:
                    window.innerWidth <= 480
                        ? "url(/images/home-carousel/min-3.png)"
                        : "url(https://frontend-picture.s3-ap-southeast-1.amazonaws.com/3.png)",
                heading1: "Pembiayaan tagihan atau invoice financing hingga Rp 300 juta!",
                heading2: "Solusi pembayaran yang simpel, cepat, dan efektif untuk memperoleh tambahan modal kerja."
            },
            {
                backgroundImage:
                    window.innerWidth <= 480
                        ? "url(/images/home-carousel/min-4.png)"
                        : "url(https://frontend-picture.s3-ap-southeast-1.amazonaws.com/4.png)",
                heading1: "Kredit modal kerja atau working capital financing hingga Rp 200 juta!",
                heading2:
                    "Pendanaan berbasis kebutuhan modal kerja yang dibuktikan dengan perputaran dana di rekening koran dengan berbasis analisa arus kas."
            },
            {
                backgroundImage:
                    window.innerWidth <= 480
                        ? "url(/images/home-carousel/min-5.png)"
                        : "url(https://frontend-picture.s3-ap-southeast-1.amazonaws.com/5.png)",
                heading1: "Pembiayaan proyek atau project financing hingga Rp 500 juta!",
                heading2: "Pendanaan berbasis proyek atau pekerjaan yang dibuktikan dengan dokumen proyek seperti kontrak/SPK."
            },
            {
                backgroundImage:
                    window.innerWidth <= 480
                        ? "url(/images/home-carousel/min-6.png)"
                        : "url(https://frontend-picture.s3-ap-southeast-1.amazonaws.com/6.png)",
                heading1: "Penyediaan dana untuk pendanaan karyawan atau employee loan",
                heading2: "Kami siap menjadi mitra untuk menyediakan pembiayaan karyawan atau employee loan di perusahaan anda."
            }
        ];

        return (
            <section id="home-carousel">
                <OwlCarousel options={options}>
                    {imgCarousel.map((items, id) => {
                        return (
                            <div key={id}>
                                <ItemBanner rightBg={items.backgroundImage} heading1={items.heading1} heading2={items.heading2} />
                            </div>
                        );
                    })}
                </OwlCarousel>
            </section>
        );
    }
}

export default Banner;
