import React from "react";

const DetailBayar = (props) => {
    return (
        <div className="row mb-3">
            <div className="col-md-6 col-6">
                {props.total ? (
                    <b style={{ color: "black" }}>{props.nameTransaction}</b>
                ) : (
                    <p className="text-secondary mb-1">{props.nameTransaction}</p>
                )}
            </div>
            <div className="col-md-6 col-6 text-right">
                {props.total ? (
                    <b> Rp. {props.nominal.toLocaleString("IN")}</b>
                ) : (
                    <p className="text-secondary mb-1">Rp. {props.nominal.toLocaleString("IN")} </p>
                )}
            </div>
        </div>
    );
};

export default DetailBayar;
