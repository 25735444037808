import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";

class Loading extends Component {
    render() {
        return (
            <div className="transaction p-3">
                <div className="card border-0 shadow-sm">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                <p className="fs-n1 text-secondary mb-1">Tanggal Transaksi</p>
                                <Skeleton />
                            </div>
                            <div className="col-6 col-md-4 mb-3 mb-xl-0">
                                <p className="fs-n1 text-secondary mb-1">Deskripsi</p>
                                <Skeleton />
                            </div>
                            <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                <p className="fs-n1 text-secondary mb-1">Status</p>
                                <Skeleton />
                            </div>
                            <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                <p className="fs-n1 text-secondary mb-1">Nominal</p>
                                <Skeleton />
                            </div>
                            <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                <p className="fs-n1 text-secondary mb-1">Saldo</p>
                                <Skeleton />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Loading;
