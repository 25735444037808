import React, { Component } from "react";
import "./Article.scss";
import { ListBlog } from "../../../redux/action/Blog";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NoData from "../../assets/no data/NoData";

class Article extends Component {
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        await this.props.dispatch(ListBlog());
    };

    mainBlog() {
        if (this.props.blog.listBlog.length !== 0) {
            function compare(a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }
            const hasil = this.props.blog.listBlog
                .sort(compare)
                .slice(0, 3)
                .map((val) => {
                    return (
                        <div className="col-12 col-lg-4 p-3" key={val._id}>
                            <Link to={"/blog-detail/" + val._id} className="card article-card">
                                <img src={val.gambar_blog} alt="article-1" className="card-img-top" style={{ height: "182px" }} />
                                <div className="card-body">
                                    <div className="poster">
                                        <p>{val.penulis.nama_admin}</p>
                                        <span className="dot">•</span>
                                        <p>
                                            {new Date(val.tgl_publikasi).toLocaleDateString("IN", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric"
                                            })}
                                        </p>
                                    </div>
                                    <p className="card-title">{val.judul}</p>
                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: val.konten }}></p>
                                </div>
                            </Link>
                        </div>
                    );
                });
            return hasil;
        } else {
            return <NoData symbol={<i className="material-icons">book</i>} keterangan={"Maaf saat ini belum ada artikel"} />;
        }
    }
    render() {
        return (
            <section id="article" className="welcome pt-5 pb-5 bg-grey">
                <div className="container">
                    <div className="sec-header text-center">
                        <h4 className="sec-title">Artikel & Berita Proyek Terbaru</h4>
                        <p className="sec-desc">Dapatkan informasi serta tips pendanaan khusus untuk Anda</p>
                    </div>
                    <div className="row m-n3">{this.mainBlog()}</div>
                    <div className="text-center mt-5">
                        <Link to="/blog" className="btn btn-outline-primary btn-lg px-5">
                            Lihat Semua Berita
                        </Link>
                    </div>
                </div>
            </section>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        blog: state.blog
    };
};
export default connect(mapStateToProps)(Article);
