import React, { Component } from "react";
import { Form, Button } from "reactstrap";
import { OTPVerif, OTPResend } from "../../redux/action/User";
import "./email.scss";
import Swal from "sweetalert2";
import { connect } from "react-redux";

class EmailUserVerifikasi extends Component {
    //email harus masuk state jangan localstorage
    constructor(props) {
        super(props);
        this.state = {
            values: [],
            OTPnumber: "",
            errNumber: "",
            minutes: 0,
            seconds: 10,
            sumRow: 6,
            email: localStorage.email,
            loading: true
        };
        this.inputs = [];
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("noHP");
        localStorage.removeItem("nama");

        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state;
            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }));
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval);
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }));
                }
            }
        }, 1000);
        this.inputs[0] && this.inputs[0].focus();

        //call kirim email ulang
        if (localStorage.verif === "false") {
            // console.log('jalanin handle')
            this.handleKirimUlang();
        }
    }
    componentWillUnmount() {
        clearInterval(this.myInterval);
    }

    handleChange = (i, e) => {
        // console.log(e.target.value)
        const number = /^\d*$/;
        if (!number.test(e.target.value)) {
            this.setState({
                errNumber: "Masukkan Angka !"
            });
            return false;
        } else {
            if (e.keyCode === 8) {
                this.setState({
                    values: { ...this.state.values, [i]: "" }
                });
                if (i > 0) {
                    this.inputs[i - 1] && this.inputs[i - 1].focus();
                }
            } else {
                this.setState({
                    values: { ...this.state.values, [i]: e.target.value }
                });
                if (i + 1 < this.state.sumRow) {
                    this.inputs[i + 1] && this.inputs[i + 1].focus();
                }
            }

            const arrOTP = Object.values(this.state.values);
            const stringOTP = arrOTP.join("");
            this.setState({
                OTPnumber: stringOTP,
                errNumber: ""
            });
            return true;
        }
    };

    handleVerifEmail = async () => {
        const arrOTP = Object.values(this.state.values);
        const stringOTP = arrOTP.join("");
        // console.log(stringOTP)
        if (this.state.errNumber !== false) {
            await this.props
                .dispatch(
                    OTPVerif({
                        email: this.state.email,
                        otp: stringOTP
                    })
                )
                .then((res) => {
                    localStorage.removeItem("no_hp");
                    localStorage.removeItem("verif");
                    localStorage.removeItem("nama_pengguna");
                    localStorage.removeItem("email");
                    Swal.fire({
                        title: "Berhasil Aktivasi",
                        text: `${res.value.data.result}`,
                        icon: "success",
                        button: "ok"
                    }).then(() => {
                        window.location.href = "/login";
                    });
                })
                .catch((err) => {
                    // console.log(err)
                    // console.log(err.response)
                    if (err.response?.status === 401) {
                        Swal.fire({
                            title: "Tidak Aktivasi Email",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    }
                });
        } else {
            Swal.fire({
                title: "Gagal Aktivasi",
                text: "OTP Yang Dimasukkan Bukan Angka",
                icon: "warning",
                buttons: "ok"
            });
        }
    };

    handleKirimUlang = async () => {
        // console.log('jalan dari didmount')
        // console.log(this.state.email)
        // this.myInterval()
        await this.props
            .dispatch(
                OTPResend({
                    email: this.state.email
                })
            )
            .then((res) => {
                Swal.fire({
                    title: "Berhasil",
                    text: "Berhasil kirim ulang kode OTP",
                    icon: "success",
                    button: "ok"
                });
            })
            .catch((err) => {
                // console.log(err)
                // console.log(err.response)
                if (err.response.status === 401) {
                    Swal.fire({
                        title: "Tidak Aktivasi Email",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                } else if (err.response.status === 404) {
                    Swal.fire({
                        title: "Tidak Aktivasi Email",
                        text: `${err.response.data.message}`,
                        icon: "warning",
                        button: "ok"
                    });
                }
            });
    };

    laodingOTPFalse() {
        if (localStorage.verif === "false" && this.state.loading) {
            if (this.props.user.isLoadingOTPR) {
                this.setState({
                    loading: false
                });
                Swal.fire({
                    html: '<div><span class="loader" style="width: 50px;height: 50px; border: 5px solid #f3f3f3;border-top: 7px solid orange; margin-left: -23px"></span></div>',
                    width: 30,
                    padding: "3em",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    backdrop: `
                    rgba(255,255,255, 0.8)
                    `
                });
            }
        }
    }

    render() {
        const arr = Array.from({ length: this.state.sumRow }, (v, k) => k);
        const arrOTP = Object.values(this.state.values);
        const stringOTP = arrOTP.join("");
        const { minutes, seconds } = this.state;
        const user = this.props.match.params.user;

        this.laodingOTPFalse();
        return (
            <div>
                <section id="email-sent" className="with-pad bg-grey">
                    <div className="container no-lg">
                        <div className="card email-sent-card border-0">
                            <div className="card-body m-md-5">
                                <h4 className="font-weight-bold mb-5 text-center ">Mohon Konfirmasi Email Anda</h4>

                                <div className="row">
                                    <div className="col-md-6 text-center mb-4">
                                        <div className="card border-0 shadow">
                                            <div className="card-body">
                                                <div className="">
                                                    <img
                                                        className="img-fluid mb-5 mt-4 icon-mail"
                                                        src={process.env.PUBLIC_URL + "../../images/icon/mail.svg"}
                                                        alt="logo-send"
                                                    />
                                                    <h4 className="font-weight-bold mb-3">Masukkan Kode OTP</h4>
                                                    <p className="fs-1 mb-1 text-secondary">Kami sudah mengirimkan ke email Anda,</p>
                                                    <p className="font-weight-bold ">{this.state.email}</p>
                                                    <Form
                                                        style={{
                                                            paddingTop: "60px",
                                                            paddingBottom: "30px"
                                                        }}>
                                                        {arr.map((i) => (
                                                            <input
                                                                ref={(ref) => (this.inputs[i] = ref)}
                                                                key={i}
                                                                type="tel"
                                                                maxLength="1"
                                                                // value={console.log(this.state.values[i])}
                                                                name={this.state.values[i]}
                                                                onKeyUp={this.handleChange.bind(this, i)}
                                                                className="input-otp"
                                                            />
                                                        ))}
                                                        <p className="text-danger mt-4">{this.state.errNumber}</p>

                                                        {this.props.user.isLoadingOTPV ? (
                                                            <Button
                                                                disabled
                                                                size="lg"
                                                                color="primary"
                                                                className="mt-5"
                                                                // disabled={stringOTP.length === 6 ? false : true}
                                                                onClick={this.handleVerifEmail}>
                                                                <span className="loader"></span>
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                size="lg"
                                                                color="primary"
                                                                className="pr-4 pl-4 mt-4"
                                                                disabled={stringOTP.length === 6 ? false : true}
                                                                onClick={this.handleVerifEmail}>
                                                                Konfirmasi
                                                            </Button>
                                                        )}
                                                    </Form>
                                                    {minutes === 0 && seconds === 0 ? (
                                                        <p>
                                                            Tidak menerima konfirmasi email ?
                                                            <a
                                                                className="text-primary font-weight-semi-bold"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={this.handleKirimUlang}>
                                                                {this.props.user.isLoadingOTPR ? (
                                                                    <span className="loader ml-4"></span>
                                                                ) : (
                                                                    <b> Kirim ulang email</b>
                                                                )}
                                                            </a>
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            Tunggu: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="text-left container">
                                            <h3 className="font-weight-bold text-secondary">Hanya 3 langkah mudah konfirmasi email anda</h3>
                                            <div className="row mt-5 text-left">
                                                <div className="col-12 col-md-12 mb-4">
                                                    <div className="d-flex mb-4">
                                                        <div className="dot-email">
                                                            <span>1</span>
                                                        </div>
                                                        <div className="ml-5">
                                                            <h5 className=" font-weight-bold title-email">Periksa email anda</h5>
                                                            <p className=" text-secondary text-email">
                                                                Anda mendapat email dari Indofund dengan judul Aktivasi email.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-4">
                                                        <div className="dot-email">
                                                            <span>2</span>
                                                        </div>
                                                        <div className="ml-5">
                                                            {user === "1" ? (
                                                                <div>
                                                                    <h5 className=" font-weight-bold title-email">Temukan kode OTP</h5>
                                                                    <p className=" text-secondary text-email">
                                                                        Temukan kode OTP di dalam email tersebut.
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <h5 className=" font-weight-bold title-email">Temukan &amp; salin kode OTP</h5>
                                                                    <p className=" text-secondary text-email">
                                                                        Temukan kode OTP di dalam email tersebut, lalu salin kode OTP dan kembali ke
                                                                        halaman ini.
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-4">
                                                        <div className="dot-email">
                                                            <span>3</span>
                                                        </div>
                                                        <div className="ml-5">
                                                            {user === "1" ? (
                                                                <div>
                                                                    <h5 className=" font-weight-bold title-email">Salin kode OTP</h5>
                                                                    <p className=" text-secondary text-email">
                                                                        Salin kode OTP kembali ke halaman ini dan masukkan kode OTP tersebut untuk
                                                                        memverifikasi email Anda.
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <h5 className=" font-weight-bold title-email">Proses verifikasi</h5>
                                                                    <p className=" text-secondary text-email">
                                                                        Menunggu proses verifikasi oleh tim Indofund (1x24 jam).
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps)(EmailUserVerifikasi);
