import React, { Component } from "react";
import { Nav, NavItem, NavLink, Col } from "reactstrap";
import classnames from "classnames";

class FaqSidebar extends Component {
    render() {
        return (
            <Col lg="4 pb-5">
                <Nav tabs vertical pills className="over-hidden">
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.props.activeTab === "1" })}
                            onClick={() => {
                                this.props.toggle("1");
                            }}
                            style={{ cursor: "pointer" }}>
                            Frequently Asked Questions
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.props.activeTab === "2" })}
                            onClick={() => {
                                this.props.toggle("2");
                            }}
                            style={{ cursor: "pointer" }}>
                            Pertanyaan terkait dengan Penerima Dana
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.props.activeTab === "3" })}
                            onClick={() => {
                                this.props.toggle("3");
                            }}
                            style={{ cursor: "pointer" }}>
                            Pertanyaan terkait dengan Pemberi Dana
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.props.activeTab === "4" })}
                            onClick={() => {
                                this.props.toggle("4");
                            }}
                            style={{ cursor: "pointer" }}>
                            Keuntungan Menjadi Penerima Dana & Pemberi Dana
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.props.activeTab === "5" })}
                            onClick={() => {
                                this.props.toggle("5");
                            }}
                            style={{ cursor: "pointer" }}>
                            Pembayaran menggunakan Dipay
                        </NavLink>
                    </NavItem>
                </Nav>
            </Col>
        );
    }
}
export default FaqSidebar;
