import React, { Component } from "react";
import Swal from "sweetalert2";
import { createPengajuan } from "../../../../redux/action/Project";
import { connect } from "react-redux";
import { Form, Button } from "reactstrap";
import "../../../../sass/loader.css";
import { InputIcon, InputNominal, InputTextArea, UploadDoc } from "../../../../library/components/Components";
import { validate } from "../../../../library/validation/validate";

class EmployeeL extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nilai_permohonan_pinjaman: "",
            jangka_waktu_pinjaman: "",
            tujuan_permohonan_pinjaman: "",
            errNumber: "",
            errNominal: "",
            errString: "",
            dokumen_pendukung: "",
            file: ""
        };
    }

    handleChange = (e, type, changeError) => {
        const sisa = this.props.plafond_borrower - this.props.plafond_terpakai;

        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value, sisa) });
        });
    };

    handleDokumen = (e) => {
        let file = e.target.files[0];
        this.setState({
            dokumen_pendukung: file
        });
    };

    onChange = (e) => {
        const sisa = this.props.plafond_borrower - this.props.plafond_terpakai;
        if (e.target.value.replace(/\D/g, "") > sisa) {
            this.setState({
                errUang: "Nominal pengajuan melebihi plafond yang ditentukan"
            });
            this.setState([e.target.name]);
            return false;
        } else {
            this.setState({
                [e.target.name]: e.target.value.replace(/\D/g, ""),
                errUang: false
            });
        }
    };
    handleSave = async () => {
        if (this.state.errNominal === 0 || this.state.errNumber === 0) {
            const { nilai_permohonan_pinjaman, jangka_waktu_pinjaman, tujuan_permohonan_pinjaman, dokumen_pendukung } = this.state;
            // console.log(nilai_permohonan_pinjaman)
            if (!nilai_permohonan_pinjaman || !jangka_waktu_pinjaman || !tujuan_permohonan_pinjaman || !dokumen_pendukung) {
                Swal.fire({
                    title: "Data masih kosong",
                    text: "Data Anda belum lengkap mohon di cek kembali",
                    icon: "warning",
                    button: "ok"
                });
            } else {
                var nilaipermohonan = nilai_permohonan_pinjaman.replace(/\D/g, "").replace(/^0+/, "");

                const formData = new FormData();
                formData.append("tipe_pinjaman", "Employee Loan");
                formData.append("nilai_permohonan_pinjaman", parseInt(nilaipermohonan));
                formData.append("jangka_waktu_pinjaman", parseInt(jangka_waktu_pinjaman));
                formData.append("tujuan_permohonan_pinjaman", tujuan_permohonan_pinjaman);
                formData.append("pdf", dokumen_pendukung);
                await this.props
                    .dispatch(createPengajuan(formData))
                    .then(() => {
                        Swal.fire({
                            title: "Anda berhasil mengajukan pinjaman",
                            text: "Mohon tunggu tim terkait memproses pengajuan pinjaman anda",
                            icon: "success",
                            buttons: "ok"
                        }).then(() => {
                            window.location.href = "/borrower/dasbor";
                        });
                    })
                    .catch((err) => {
                        // console.log(err.response)
                        if (err.response.status === 400) {
                            Swal.fire({
                                title: "Gagal Ajukan",
                                text: `${err.response.data.message}`,
                                icon: "warning",
                                button: "ok"
                            });
                        } else if (err.response.status === 404) {
                            Swal.fire({
                                title: "Gagal Ajukan",
                                text: `${err.response.data.message}`,
                                icon: "warning",
                                button: "ok"
                            });
                        } else if (err.response.status === 401) {
                            Swal.fire({
                                title: "Gagal Ajukan",
                                text: `${err.response.data.message}`,
                                icon: "warning",
                                button: "ok"
                            });
                        }
                    });
            }
        } else {
            Swal.fire({
                title: "Gagal Ajukan Pinjaman",
                text: "Periksa kembali data Anda",
                icon: "warning",
                buttons: "ok"
            });
        }
    };
    render() {
        const sisa = this.props.plafond_borrower - this.props.plafond_terpakai;
        return (
            <div>
                <section id="pinjaman">
                    <div className="sec-header">
                        <h4 className="text-center sec-title">Form Pengajuan Pinjaman</h4>
                    </div>
                    <Form>
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <InputNominal
                                    label={"Pinjaman yang diajukan"}
                                    onChange={(e) => this.handleChange(e, "plafondSisa", "errNominal")}
                                    name={"nilai_permohonan_pinjaman"}
                                    info={`Dana plafon anda, sisa Rp. ${sisa.toLocaleString("IN")}`}
                                    error={this.state.errNominal}
                                />
                                <InputIcon
                                    forLabel={"tenor"}
                                    label={"Tenor (Lama Pinjaman)"}
                                    icon={<i className="material-icons-outlined">access_time</i>}
                                    onChange={(e) => this.handleChange(e, "tenor", "errNumber")}
                                    type={"text"}
                                    name="jangka_waktu_pinjaman"
                                    placeholder="Lama pinjaman maksimal 12 bulan"
                                    maxLength="2"
                                    error={this.state.errNumber}
                                />
                                <InputTextArea
                                    label={"Tujuan permohonan pinjaman"}
                                    name="tujuan_permohonan_pinjaman"
                                    onChange={(e) => this.handleChange(e)}
                                    error={this.state.errString}
                                />
                                <UploadDoc label={"Upload dokumen (opsional)"} onChange={this.handleDokumen} name="file" />
                                <div className="text-center mt-5">
                                    {this.props.project.isLoading ? (
                                        <Button color="primary" disabled className="btn btn-lg btn-block card-submit" onClick={this.handleSave}>
                                            <span className="loader"></span>
                                        </Button>
                                    ) : (
                                        <Button color="primary" className="btn btn-lg btn-block card-submit" onClick={this.handleSave}>
                                            Kirim
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Form>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project
    };
};
export default connect(mapStateToProps)(EmployeeL);
