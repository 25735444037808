import React, { Component } from "react";
import TabDetailProyek from "./TabDetailProyek";
import TabInformasiPeminjam from "./TabInformasiPeminjam";
import { NavbarTitle, NavbarContent } from "../../../library/components/NavbarComponent";

class ProyekTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            project: []
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    render() {
        const title = ["Detail Proyek", "Informasi Peminjam"];
        const children = [<TabDetailProyek project={this.props.project} portofolio={false} />, <TabInformasiPeminjam project={this.props.project} />];
        return (
            <div>
                <NavbarTitle title={title} activeTab={this.state.activeTab} toggle={this.toggle} />
                <NavbarContent activeTab={this.state.activeTab} components={children} />
            </div>
        );
    }
}
export default ProyekTab;
