import React, { Component } from "react";
import { TabContent, TabPane } from "reactstrap";
import MenggalangDana from "./CMenggalangDana";
import Outstanding from "./COutstanding";
import Dibayar from "./CDibayar";
import { connect } from "react-redux";
import { LendApp } from "../../../redux/action/Lender";
import { LoadingPortofolio } from "../../assets/loading data/LoadingLender";
class PortoContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: []
        };
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        // await this.props.dispatch(LendApp());
        // this.setState({
        //     info: this.props.lender.lenderLend
        // });
    };
    loading = () => {
        return (
            <div>
                <LoadingPortofolio />
                <LoadingPortofolio />
            </div>
        );
    };
    render() {
        // console.log(this.state.info && this.state.info.id_pinjaman.grade_proyek)
        return (
            <TabContent activeTab={this.props.activeTab}>
                <TabPane tabId={0}> <MenggalangDana data={this.state.info} /></TabPane>
                <TabPane tabId={1}> <Outstanding data={this.state.info} /></TabPane>
                <TabPane tabId={2}><Dibayar data={this.state.info} /></TabPane>
            </TabContent>
        );
    }
}


export default PortoContent;
