import React, { Component } from "react";
import "./TanpaBiaya.scss";

class TanpaBiaya extends Component {
    render() {
        return (
            <section id="tanpa-biaya" className="with-pad">
                <div className="container">
                    <div className="col-12">
                        <h4 className="text-title">Tanpa biaya tambahan apapun </h4>
                        <p className="text">
                            Pendaftaran sebagai pemberi dana maupun sebagai penerima dana tidak dikenakan biaya apapun. <br />
                            <br />
                            Indofund.id hanya mengenakan Biaya admin sebesar 3% - 5% yang hanya berlaku ketika pendanaan Anda berhasil didanai oleh
                            para pemberi dana. <br />
                            <br />
                            Bagi pemberi dana, anda hanya dikenakan biaya sebesar Rp. 3.500 bila anda melakukan penarikkan dana. Dana anda tidak
                            dipotong biaya apapun lagi.
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}
export default TanpaBiaya;
