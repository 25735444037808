import React, { Component } from "react";
import { connect } from "react-redux";
import { LapKeuangan } from "../../../../redux/action/User";
import { convertFileSize } from "../../../../library/helper/helper";
import moment from "moment";
import "moment/locale/id";
import Pagination from "react-js-pagination";
import NoData from "../../../assets/no data/NoData";

class LaporanKeuangan extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            laporan: [],
            currentPage: 1,
            todosPerPage: 15
        };
    }

    handleClick(pageNumber) {
        this.setState({
            currentPage: pageNumber
        });
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        await this.props.dispatch(LapKeuangan());
        this.setState({
            laporan: this.props.user.lapKeuangan.result
        });
    };

    laporan = () => {
        if (this.state.laporan.length === 0) {
            return <NoData symbol={<i className="material-icons-outlined">assignment</i>} keterangan={"Tidak ada laporan"} />;
        } else {
            const lap = this.state.laporan.map((val, i) => {
                return (
                    <div className="container pt-3 pb-4" key={i}>
                        <div className="card border-0 shadow">
                            <div className="card-body">
                                <div>{moment(new Date(val.createdAt)).locale("id").format("LL")}</div>
                                <div className="row">
                                    <div className="col-md-6 d-flex align-items-center">
                                        <div className="font-weight-bold ">Laporan Tahunan {val.tahun}</div>
                                    </div>
                                    <div className="col-md-6 text-md-right">
                                        <div>
                                            PDF | {convertFileSize(val.fileSize)}
                                            <a className="btn btn-primary ml-4" href={val.file_laporan} target="_blank">
                                                <span
                                                    className="material-icons"
                                                    style={{
                                                        top: "6px",
                                                        position: "relative",
                                                        right: "5px"
                                                    }}>
                                                    download
                                                </span>
                                                Unduh
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
            return lap;
        }
    };
    render() {
        return (
            <div className="with-pad">
                <h3 className="font-weight-bold text-center pt-5 pb-5">Laporan Keuangan</h3>
                {this.laporan()}
                <div className="d-flex justify-content-center mt-3 pb-4">
                    <Pagination
                        hideDisabled
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.currentPage}
                        itemsCountPerPage={this.state.todosPerPage}
                        totalItemsCount={this.state.laporan.length}
                        onChange={this.handleClick.bind(this)}
                    />
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps)(LaporanKeuangan);
