import React, { Component } from "react";
import "../privacy-cookie/kebijakan.scss";

class privacyPolicy extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <section id="kebijakan" className="with-pad">
                <div className="container">
                    <div className="col-12">
                        <div className="jarak">
                            {/* <p className="text">
                            Kebijakan Privasi ini berlaku untuk Indofund.id, www.indofund.id dan dioperasikan oleh PT Bursa Akselerasi Indonesia. Kebijakan privasi ini menjelaskan bagaimana Indofund.id mengumpulkan dan menggunakan data pribadi yang telah Anda kirimkan kepada kami. <br /><br />
                            Privasi Anda dan perlindungan terhadap informasi personal Anda yang sensitif sangatlah penting bagi kami. Karena kami mengumpulkan dan atau menyimpan beberapa jenis informasi dari dan tentang Anda, kami perlu menjelaskan kebijakan kami dan syarat dan kondisi seputar pendataan dan penggunaan informasi tersebut.
                            </p> */}
                            <h3 className="text-title">Kebijakan Privasi dan Konfirmasi Pengguna Data</h3>
                            <h4 className="text-section">Kebijakan Privasi</h4>
                            <div className="d-flex flex-row mt-2">
                                <h5 className="text-section">1.</h5>
                                <h5 className="ml-3 text-section">Latar Belakang</h5>
                            </div>
                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p>1.1</p>
                                    <p className="ml-3">
                                        Kebijakan Privasi (“Kebijakan”) ini berlaku dan mengikat seluruh pengunjung portal Indofund baik melalui
                                        website (www.indofund.id) maupun aplikasi mobile (aplikasi Indofund) (“Portal”), khususnya anggota Indofund
                                        yang telah terdaftar, termasuk Anda. Dengan terus mengakses dan/atau menggunakan layanan yang tersedia pada
                                        Portal, maka Anda mengakui bahwa Anda telah membaca dan menyetujui Kebijakan ini.
                                    </p>
                                </div>
                                <div className="d-flex flex-row">
                                    <p>1.2</p>
                                    <p className="ml-3">
                                        Kebijakan ini dibuat agar Anda mengetahui perolehan, pengumpulan, pengolahan, penganalisisan, penyimpanan,
                                        penampilan, penyebarluasan, pembukaan akses dan pemusnahan atas data-data pribadi Anda yang kami lakukan,
                                        sebelum Anda memberikan data pribadi Anda.
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex flex-row text-section mt-3">
                                <h5 className="text-section">2.</h5>
                                <h5 className="ml-3 text-section">Perolehan dan/atau Pengumpulan Informasi</h5>
                            </div>

                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p>2.1</p>
                                    <p className="ml-3">
                                        Kami mengumpulkan informasi dan data pribadi Anda (i) yang Anda berikan secara langsung kepada kami, (ii) yang
                                        terkait dengan penggunaan Portal oleh Anda, dan (iii) yang diberikan oleh pihak ketiga dengan persetujuan
                                        Anda.
                                    </p>
                                </div>
                                <div className="d-flex flex-row">
                                    <p>2.2</p>
                                    <p className="ml-3">
                                        Data pribadi Anda sebagaimana disebut dalam Angka 2.1 di atas termasuk, namun tidak terbatas pada:
                                    </p>
                                </div>
                                <div className="sub-text">
                                    <div className="d-flex flex-row">
                                        <p>(a)</p>
                                        <p className="ml-3">
                                            data identitas dan profil Anda, termasuk KTP, NPWP, nama lengkap, tempat tanggal lahir, jenis kelamin,
                                            alamat, nomor rekening, agama, pekerjaan, tujuan penggunaan, sumber dana, alamat email, nomor telepon,
                                            informasi keuangan, foto, latar belakang pendidikan dan pekerjaan, dan informasi terkait identitas dan
                                            profil lainnya;
                                        </p>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <p>(b)</p>
                                        <p className="ml-3">
                                            pola atau kebiasaan Anda dalam penggunaan Portal, profil risiko Anda, portofolio pemberian pendanaan Anda,
                                            sejarah pencarian atau penggunaan layanan, transaksi yang Anda lakukan di Portal, dan informasi terkait
                                            pola atau kebiasaan lainnya;
                                        </p>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <p>(c)</p>
                                        <p className="ml-3">
                                            alamat IP Anda, informasi log-in Anda, perangkat keras dan perangkat lunak (termasuk tipe dan versi
                                            browser) yang Anda gunakan, serta informasi teknis lainnya terkait penggunaan Portal oleh Anda.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p className="ml-3">
                                        (Informasi sebagaimana disebut dalam Angka 2.2 di atas selanjutnya disebut sebagai “Data Pribadi” Anda)
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex flex-row text-section mt-3">
                                <h5 className="text-section">3.</h5>
                                <h5 className="ml-3 text-section">Pengolahan dan Penganalisisan Informasi</h5>
                            </div>

                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p>3.1</p>
                                    <p className="ml-3">
                                        Kami menggunakan Data Pribadi Anda untuk (i) memverifikasi kelayakan Anda sebagai pengguna, (ii) memberikan
                                        akses layanan yang disediakan di Portal kepada Anda, (iii) menyelesaikan hak dan kewajiban kepada Anda sebagai
                                        pengguna Portal, (iv) mengawasi penggunaan layanan di Portal oleh Anda, (v) memproses dan mengelola akun Anda,
                                        (vi) memberikan otorisasi penggunaan akun Anda di Portal, (vii) mendeteksi, mencegah, dan menyangkal setiap
                                        tindakan melawan hukum terhadap atau oleh Anda, (viii) melakukan audit internal maupun external, (ix)
                                        melakukan pemasaran yang efektif dan relevan, dan (x) untuk hal-hal lainnya yang terkait dengan kegiatan usaha
                                        Indofund sebagai Penyelenggara Layanan Pendanaan Bersama berbasis Teknologi Informasi.
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex flex-row text-section mt-3">
                                <h5 className="text-section">4.</h5>
                                <h5 className="ml-3 text-section">Penyimpanan Data</h5>
                            </div>

                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p>4.1</p>
                                    <p className="ml-3">
                                        Kami melakukan penyimpanan Data Pribadi Anda dengan teknologi enkripsi sesuai standar ISO 27001 untuk
                                        memastikan keamanan Data Pribadi Anda.
                                    </p>
                                </div>
                                <div className="d-flex flex-row">
                                    <p>4.2</p>
                                    <p className="ml-3">
                                        Data Pribadi Anda kami simpan paling tidak sampai dengan 5 tahun sejak Anda berakhir menjadi pengguna Portal
                                        kami, sesuai dengan persyaratan dalam peraturan perundang-undangan. Apabila Anda ingin agar Data Pribadi Anda
                                        dihapus sebelum itu, maka Anda dapat mengirimkan permohonan kepada Customer Service.
                                    </p>
                                </div>
                                <div className="d-flex flex-row">
                                    <p>4.3</p>
                                    <p className="ml-3">
                                        Data Pribadi Anda kami simpan pada pusat data kami serta pusat pemulihan bencana kami yang berlokasi di
                                        Indonesia.
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex flex-row text-section mt-3">
                                <h5 className="text-section">5.</h5>
                                <h5 className="ml-3 text-section">Pengungkapan dan Pembukaan Akses</h5>
                            </div>

                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p>5.1</p>
                                    <p className="ml-3">
                                        Kami akan menjaga kerahasiaan Data Pribadi Anda. Namun demikian, Anda setuju bahwa kami dapat mengungkapkan
                                        Data Pribadi Anda kepada pihak yang dianggap perlu oleh kami yang terkait dengan kegiatan usaha kami sebagai
                                        Penyelenggara Layanan Pendanaan Bersama Berbasis Teknologi Informasi, termasuk namun tidak terbatas kepada:
                                    </p>
                                </div>
                                <div className="sub-text">
                                    <div className="d-flex flex-row">
                                        <p>(a)</p>
                                        <p className="ml-3">direktur, komisaris, atau karyawan Indofund;</p>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <p>(b)</p>
                                        <p className="ml-3">
                                            mitra serta penyedia jasa penunjang atas aktifitas usaha Indofund, termasuk mitra kerjasama channeling,
                                            penyedia payment gateway, penyedia sistem analisis psychometric, bank, penyelenggara sertifikasi
                                            elektronik untuk pembuatan tanda tangan elektronik, konsultan atau penyedia jasa penilaian kelayakan usaha
                                            dan kredit, konsultan hukum, konsultan keuangan, konsultan teknis dan konsultan pajak yang ditunjuk
                                            Indofund untuk memberikan jasa penunjang yang relevan;
                                        </p>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <p>(c)</p>
                                        <p className="ml-3">
                                            Fintech Data Center (FDC) yang didirikan oleh Asosiasi Fintech Pendanaan Bersama Indonesia (AFPI), untuk
                                            keperluan pelaporan data kredit Anda sebagai Penerima pendanaan di Indofund;
                                        </p>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <p>(d)</p>
                                        <p className="ml-3">pihak-pihak lain yang terkait dengan kegiatan usaha Indofund; dan</p>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <p>(e)</p>
                                        <p className="ml-3">pihak yang berwenang sesuai dengan ketentuan peraturan perundang-undangan.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex flex-row text-section mt-3">
                                <h5 className="text-section">6.</h5>
                                <h5 className="ml-3 text-section">Perubahan Data Pribadi</h5>
                            </div>
                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p>6.1</p>
                                    <p className="ml-3">Anda berkewajiban untuk menjaga keakurasian dari Data Pribadi Anda.</p>
                                </div>
                                <div className="d-flex flex-row">
                                    <p>6.2</p>
                                    <p className="ml-3">Anda dapat mengubah Data Pribadi terdaftar Anda melalui Portal.</p>
                                </div>
                                <div className="d-flex flex-row">
                                    <p>6.3</p>
                                    <p className="ml-3">
                                        Kami dapat melakukan tindakan-tindakan yang diperlukan untuk memastikan keakuratan dan kemutakhiran Data
                                        Pribadi Anda, termasuk dengan menghubungi Anda.
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex flex-row text-section mt-3">
                                <h5 className="text-section">7.</h5>
                                <h5 className="ml-3 text-section">Data Pribadi Anak Bawah Umur</h5>
                            </div>

                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p>7.1</p>
                                    <p className="ml-3">
                                        Dalam hal terdapat Data Pribadi dari pengguna yang ternyata masuk dalam kategori anak di bawah 17 tahun, maka
                                        orang tua atau wali dari anak bawah umur tersebut dapat meminta Indofund untuk menghapus Data Pribadi anak
                                        tersebut dengan mengirimkan permohonan kepada Customer Service.
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex flex-row text-section mt-3">
                                <h5 className="text-section">8.</h5>
                                <h5 className="ml-3 text-section">Perubahan Kebijakan Privasi</h5>
                            </div>

                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p className="ml-3">
                                        Setiap perubahan atas Kebijakan ini akan tertera pada halaman ini, dan apabila dipandang perlu akan
                                        diberitahukan kepada seluruh pengguna terdaftar melalui email atau metode lainnya.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* <div className="jarak">
              <h3 className="text-title">Perilaku Pegawai</h3>
              <p className="text">Indofund.id menerapkan komitmen penuh kepada integritas dan nilai-nilai perusahaan dan etika standard serta perilaku bisnis yang juga dituntut dari semua pegawai kami.</p>
              <p className="text">Kode Etik Indofund.id mengatur tata cara mengenai pengamanan informasi rahasia secara spesifik, termasuk di dalamnya informasi pelanggan. Secara umum, pedoman ini membatasi akses pegawai terhadap informasi rahasia dan membatasi penggunaan dan pengungkapan informasi terutama mengenai transaksi dan proses yang telah disetujui dan sedang berjalan. Jika kami mengetahui ada karyawan yang melanggar Kode Etik, tindakan korektif dapat diambil oleh perusahaan, termasuk pemecatan.</p>
            </div>

            <div className="jarak">
              <h3 className="text-title">Verifikasi Praktisi Data</h3>
              <p className="text">Secara berkala, kegiatan dan praktek usaha kami akan ditelaah untuk memastikan bahwa prosedur dan kebijakan yang mengatur kerahasiaan informasi dilakukan sesuai dengan peraturan yang berjalan. Pemeriksaan ini dapat dilakukan oleh staf internal kami sendiri, jasa akuntan dan audit eksternal, dan pemerintah.</p>
            </div>

            <div className="col-xs-12 col-md-10 col-lg-10 jarak">
              <div className="card border-0 shadow reason-card jarak">
                <div className="card-body">
                  <h2 className="card-title">
                    APA YANG INDOFUND.ID LAKUKAN DENGAN INFORMASI PRIBADI ANDA?
                  </h2>
                </div>
              </div>
            </div>

            <div className="jarak">
              <h4 className="text-title">Apa?</h4>
              <p className="text">Kami mengumpulkan informasi finansial dan pribadi sewaktu Anda menggunakan laman kami dan sebagian besar informasi didapatkan sewaktu Anda mendaftar dan mengunggah data Anda.</p>
              <p className="text">Kami memperoleh data ketika Anda berada di laman kami. Data-data ini mencakup namun tidak terbatas pada komputer Anda, informasi geografis, alamat IP, sumber rujukan, lama waktu Anda di laman, periode kunjungan, page views dan klik. Kumpulan informasi ini sebagian besar digunakan untuk meningkatkan kinerja dan keamanan Platform.</p>
            </div>

            <div className="jarak">
              <h4 className="text-title">Alasannya?</h4>
              <p className="text">Kami mewajibkan semua pengguna untuk mengirimkan informasi dasar guna memudahkan proses verifikasi identitas Anda. Proses verifikasi dilakukan untuk memastikan bahwa Anda sudah berhak menggunakan layanan kami. Kami hanya menggunakan informasi pribadi Anda untuk kepentingan pemberian layanan kepada pengguna dan tidak akan berbagi data tersebut kepada pihak ketiga tanpa persetujuan dari Anda.</p>
              <p className="text">Untuk mendapatkan layanan pendanaan, kami akan meminta data tambahan untuk digunakan pada penganalisaan kelayakan mendapatkan pendanaan (credit-scoring).</p>
            </div>

            <div className="col-xs-12 col-md-10 col-lg-10 jarak">
              <div className="card border-0 shadow reason-card jarak">
                <div className="card-body">
                  <h2 className="card-title">
                    APAKAH DATA SAYA AMAN?
                  </h2>
                </div>
              </div>
            </div>

            <div className="jarak">
              <h4 className="text-title">Akses</h4>
              <p className="text">Indofund.id sangat berhati-hati dalam menjaga keamanan data pribadi Anda. Data Anda hanya dapat diakses secara terbatas oleh staff kami dalam penyediaan layanan kepada Anda.</p>
            </div>

            <div className="jarak">
              <h4 className="text-title">Penyimpanan</h4>
              <p className="text">Kami berkonsultasi dengan penyedia jasa keamanan finansial dan memberlakukan teknis perlindungan seperti enkripsi, dan pemantauan berkala untuk memastikan bahwa informasi Anda dilindungi.</p>
            </div>

            <div className="jarak">
              <h4 className="text-title">Ulasan</h4>
              <p className="text">Langkah-langkah ini akan ditinjau dan disesuaikan secara berkala berdasarkan penilaian risiko yang sedang berlangsung. Kami juga merekomendasikan pengguna untuk mengambil langkah-langkah tertentu untuk membantu melindungi informasi mereka.</p>
            </div>

            <div className="jarak">
              <h4 className="text-title">Notifikasi</h4>
              <p className="text">Kami akan mengirimkan notifikasi dari waktu ke waktu untuk memastikan bahwa tidak ada orang lain yang membuat perubahan ke akun Anda atau menjalankan transaksi Anda di platform kami. Kami juga akan mengirimkan update berkala serta informasi mengenai promosi atau perubahan di platform kami.</p>
            </div>

            <div className="jarak">
              <h4 className="text-title">Kasus situasional</h4>
              <p className="text">Kami mungkin akan membagi informasi dan data yang relevan kepada penegak hukum atau badan pemerintah seperti yang disyaratkan oleh hukum bilamana terdapat suatu proses hukum atau perintah pengadilan. Kami juga dapat membagi catatan pendanaan dan peminjam serta segala jenis pembayaran yang terekam bilamana gagal bayar terjadi.</p>
            </div>

            <div className="jarak">
              <h3 className="text-title">Perubahan Kebijakan Privasi</h3>
              <p className="text">Bilamana diperlukan, kami akan menyesuaikan kebijakan privasi sejalan dengan perubahan pada platform kami dalam melayani pengguna. Dalam hal perubahan substantif atau materiil, kami akan memberitahu Anda sebelum perubahan tersebut menjadi efektif.</p>
            </div> */}
                    </div>
                </div>
            </section>
        );
    }
}
export default privacyPolicy;
