import axios from "axios";

// const url = `http://localhost:3000`
const url = process.env.REACT_APP_BASSE_API;

export const LenderId = (id) => {
  return {
    type: "LENDER_ID",
    payload: axios.get(`${url}/lender/find/${id}`, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const LenderUpdateEntity = (id, data) => {
  return {
    type: "LENDER_UPDATE_ENTITY",
    payload: axios.patch(`${url}/lender/update-entity/${id}`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const LenderUpdatePersonal = (id, data) => {
  return {
    type: "LENDER_UPDATE_PERSONAL",
    payload: axios.patch(`${url}/lender/update-personal/${id}`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const LenderEdit = (id, data) => {
  return {
    type: "LENDER_EDIT",
    payload: axios.patch(`${url}/lender/update/${id}`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const LenderUpdateDigisign = (data) => {
  return {
    type: "LENDER_UPDATE_DIGISIGN",
    payload: axios.patch(`${url}/lender/change-lender-data/`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const LenderLend = (id, data) => {
  return {
    type: "LENDING",
    payload: axios.post(`${url}/lender/lend/${id}`, data, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const LendApp = (params) => {
  return {
    type: `LEND_APPLICATION_${params?.status.toUpperCase()}`,
    payload: axios.get(`${url}/lender/lend-application-lender`, {
      params,
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};
export const LendStatistic = () => {
  return {
    type: `LEND_STATISTIC`,
    payload: axios.get(`${url}/lender/lend-statistic`, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const LendAppSign = (query) => {
  return {
    type: "LEND_SIGN",
    payload: axios.get(`${url}/lender/sign`, {
      headers: { "x-auth-token": localStorage.jwtToken },
      params: query
    })
  };
};

export const LendAppSigned = (query) => {
  return {
    type: "LEND_SIGNED",
    payload: axios.get(`${url}/lender/sign`, {
      headers: { "x-auth-token": localStorage.jwtToken },
      params: query
    })
  };
};

export const LendAppNew = (id) => {
  return {
    type: "LEND_APP_DETAIL",
    payload: axios.get(`${url}/lender/lend-details/${id}`, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const LendTotal = () => {
  return {
    type: "LEND_TOTAL",
    payload: axios.get(`${url}/lender/total-lend`, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const LendPort = (params) => {
  return {
    type: "LEND_PORTFOLIO",
    payload: axios.get(`${url}/lender/portfolio-lender`, {
      params,

      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const LaporanPPH = (year) => {
  return {
    type: "LAPORAN_PPH",
    payload: axios.get(`${url}/lender/reporting-pph?year=${year}`, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const checkLenderUpdateYearly = (lenderId) => {
  return {
    type: "CHECK_LENDER_YEARLY",
    payload: axios.get(`${url}/update-data/check/${lenderId}`, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const updateDataLenderYearly = (lenderId, value) => {
  return {
    type: "UPDATE_DATA_YEARLY",
    payload: axios.post(`${url}/update-data/lender/${lenderId}`, value, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};

export const updateDataLenderPengkinianData = (lenderId, value) => {
  return {
    type: "GET_LENDER_PENGKINIAN_DATA",
    payload: axios.get(`${url}/update-data/lender/${lenderId}`, value, {
      headers: { "x-auth-token": localStorage.jwtToken }
    })
  };
};
