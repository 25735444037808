import React, { Component } from "react";
import NoData from "../../assets/no data/NoData";
import Pagination from "react-js-pagination";
import { percentageConvert, sukuBunga } from "../../../library/helper/helper";
import moment from "moment";
import "moment/locale/id";
import "./portfolio.scss";
import CardShimmer from "./Shimmer/CardShimmer";
import PortfolioListLayout from "./PortfolioListLayout";
import { LendApp } from "../../../redux/action/Lender";
import { connect } from "react-redux";

class COutstanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {

            },
            query: {
                page: 1,
                limit: 15,
                status: "Berjalan",
                search: "",
                totalData: 0,
                totalPage: 0,
            },
            filter: "",
        };
        this.debounceTimeout = null;
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(pageNumber) {
        window.scrollTo(0, 0);
        this.setState(
            (prevState) => ({
                query: { ...prevState.query, page: pageNumber }, // Update the page number in the query
            }),
            () => {
                this.fetchData(pageNumber); // Fetch data after state update
            }
        );
    }
    handleChangeSearch = (event) => {
        this.setState({ query: { ...this.state.query, search: event.target.value } }, () => {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                this.fetchData(1);
            }, 500);
        });
    };

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        this.fetchData(this.state.page);
    };
    fetchData = async (page) => {
        await this.props.dispatch(LendApp({ ...this.state.query, page }));
        console.log(this.props.lender.lenderLendBerjalan)
        this.setState({
            data: this.props.lender.lenderLendBerjalan
        });
    }
    dueDateCheck = (value) => {
        return value < 0 ? `Telat ${value * -1} hari` : `${value} hari lagi`

    }


    Outstanding = () => {
        if (this.props.data === undefined) {
            return <div></div>;
        } else {
            const currentTodos = this.props.lender.lenderLendBerjalan

            if (currentTodos.length !== 0) {
                const hasil = currentTodos.map((val) => {

                    return (
                        <a key={val.loanId} className="py-3 mt-3 card border-0 card-item" href={"/detail-porto/" + val.loanId}>
                            <div className="transaction ">
                                <div className="card border-0">
                                    <div className="card-body ">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-xl-12 mb-3 mb-xl-0">
                                                <div className="row">
                                                    <div className="col-6 col-md-2 mb-3">
                                                        <p className="fs-n2 text-secondary mb-1">Nama Proyek</p>
                                                        <p className="mb-0 fs-n1" style={{ display: "flex" }}>
                                                            {val.loanName}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 col-md-2 mb-3">
                                                        <p className="fs-n2 text-secondary mb-1">Nominal Pendanaan</p>
                                                        <p className="mb-0 fs-n1">Rp {val.lendingAmount.toLocaleString("IN")}</p>
                                                    </div>
                                                    <div className="col-6 col-md-1 mb-3">
                                                        <p className="fs-n2 text-secondary mb-1">Bunga</p>
                                                        <p className="mb-0 text-darkgreen">
                                                            {percentageConvert(val.loanInterst)}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 col-md-2 mb-3">
                                                        <p className="fs-n2 text-secondary mb-1">Potensi Imbal Hasil</p>
                                                        <p className="mb-0 text-darkgreen fs-n1">Rp {val.interestEstimation ? val.interestEstimation.toLocaleString("IN") : 0}</p>
                                                    </div>
                                                    <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                                        <p className="fs-n2 text-secondary mb-1">Tanggal Mulai Proyek</p>
                                                        <p className="mb-0 fs-n1">
                                                            {moment(new Date(val.lendingDate)).locale("id").format("LL")}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 col-md-1 mb-3 mb-xl-0">
                                                        <p className="fs-n2 text-secondary mb-1">Jatuh Tempo</p>
                                                        <div className="fs-n1 " style={{color:val.diffDay <0?'red':'black'}} key={val.loanId}>{this.dueDateCheck(val.diffDay)}</div>
                                                    </div>
                                                    <div className="col-6 col-md-2 mb-3 mb-xl-0">
                                                        <p className="fs-n2 text-secondary mb-1" style={{ fontsize: '12px' }}>Pembayaran</p>
                                                        <div className="fs-n1" key={val.loanId}>{val.donePaymentCount}/{val.paymentCount}</div>
                                                    </div>
                                                    {/* <div className="col-12 col-md-1 text-center">
                                                        <a
                                                            href={"/detail-porto/" + val.id_pinjaman._id}
                                                            className="btn btn-outline-primary btn-block">
                                                            <span>Detail</span>
                                                        </a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    );
                });
                return hasil;
            } else {
                return (
                    <div className="transactions-wrapper m-n3">
                        <NoData
                            symbol={<i className="material-icons-outlined">assignment</i>}
                            keterangan={this.props.data.length !== 0 ? "Proyek yg anda danai dalam tahap menggalang dana" : "Belum ada Transaksi"}
                        />
                        <div className="text-center">
                            {this.props.data.length !== 0 ? (
                                ""
                            ) : (
                                <a href="/proyek" className="btn btn-outline-primary btn-mulai-dana">
                                    Mulai Danai Sekarang
                                </a>
                            )}
                        </div>
                    </div>
                );
            }
        }
    };
    render() {
        const { currentPageAktif, todosPerPageAktif } = this.state;
        const { filter } = this.state;

        const lengthAktif = this.props.data.filter((item) =>
            this.state.filter === ""
                ? (item.id_pinjaman && item.id_pinjaman.status_proyek === "Pembayaran cicilan") ||
                (item.id_pinjaman && item.id_pinjaman.status_proyek === "Macet")
                : item.id_pinjaman.nama_pinjaman.toLowerCase().includes(this.state.filter.toLowerCase())
        ).length;

        return (
            // <div>
            //     <div className="d-flex align-items-center justify-content-between my-3">
            //         {/* <div>
            //         <label className="mb-0 mr-2 d-none d-md-inline-block" for="urutkan">Urutkan</label>
            //         <select id="urutkan" className="custom-select w-auto mr-2">
            //             <option value="">Suku Bunga</option>
            //         </select>
            //     </div> */}
            //         <div>
            //             <form>
            //                 <div className="input-group">
            //                     <input
            //                         className="form-control"
            //                         type="text"
            //                         id="keyword"
            //                         name="keyword"
            //                         placeholder="Cari Transaksi"
            //                         value={filter}
            //                         onChange={this.handleChangeSearch}
            //                     />
            //                     <div className="input-group-append">
            //                         <div className="input-group-text">
            //                             <i className="material-icons-outlined">search</i>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </form>
            //         </div>
            //     </div>

            //     {this.Outstanding(currentPageAktif, todosPerPageAktif, lengthAktif)}

            //     <div className="d-flex justify-content-center mt-3">
            //         <Pagination
            //             hideDisabled
            //             itemClass="page-item"
            //             linkClass="page-link"
            //             activePage={this.state.currentPageAktif}
            //             itemsCountPerPage={this.state.todosPerPageAktif}
            //             totalItemsCount={parseInt(lengthAktif)}
            //             onChange={this.handleClickAktif.bind(this)}
            //         />
            //     </div>
            // </div>
            <PortfolioListLayout filter={this.state.query.search} isLoading={this.props.lender.isLoadingLendAppBerjalan} limit={this.props.lender.lenderLendBerjalanPagination.limit} page={this.props.lender.lenderLendBerjalanPagination.page} onChangePage={this.handleClick.bind(this)} renderComponent={this.Outstanding} totalData={this.props.lender.lenderLendBerjalanPagination.totalData} handleChangeSearch={this.handleChangeSearch} />

        );
    }
}

const mapStateToProps = (state) => {
    return {
        lender: state.lender
    };
};

export default connect(mapStateToProps)(COutstanding);
