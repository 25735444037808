import React, { Component } from "react";

class InfoItem extends Component {
    render() {
        return (
            <div className="col-6 p-2">
                <p className="card-text label-info">{this.props.judul}</p>
                <p className="card-text value-info ">{this.props.content}</p>
            </div>
        );
    }
}

export default InfoItem;
