import React, { Component } from "react";
import "./Layanan.scss";
import { validate } from "../../../library/validation/validate";
import { FormGroup, Label, Input, Button, CustomInput, Form } from "reactstrap";
import { selectOption } from "../../../library/components/option";
import { createTiketing, getTopikTiketing } from "../../../redux/action/Tiketing";
import { connect } from "react-redux";
import { encryptedData } from "../../../library/helper/Crypto";
import Swal from "sweetalert2";

function SelectOptions(props) {
    return (
        <FormGroup>
            <Label for={props.forLabel} className="label-select">
                {props.label}
            </Label>
            <Input value={props.value} onChange={props.onChange} type="select" name={props.name} id={props.name} readOnly={props.readOnly}>
                <option value="">-- {props.default} --</option>
                {selectOption(props.data)}
            </Input>
        </FormGroup>
    );
}

class FormTicketing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listTopik: [],
            attachment_satu: "",
            deskripsi: "",
            id_topik: ""
        };
    }

    async componentDidMount() {
        await this.props.dispatch(getTopikTiketing());
        this.setState({ listTopik: this.props.tiketing.listTopik.data });
    }

    handleChange = (e, type, changeError) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value) });
        });
    };

    handleUploadFile = (e) => {
        let file = e.target.files[0];
        this.setState({
            attachment_satu: file
        });
    };

    handleSendTiketing = async () => {
        const { attachment_satu, deskripsi, id_topik } = this.state;
        const formData = new FormData();
        formData.append("id_topik", encryptedData(id_topik));
        formData.append("kode_pengguna", encryptedData(localStorage.kode_pengguna.toString()));
        formData.append("deskripsi", encryptedData(deskripsi));
        formData.append("attachment_satu", attachment_satu);

        await this.props
            .dispatch(createTiketing(formData))
            .then((res) => {
                Swal.fire({
                    title: "Berhasil ",
                    text: "Berhasil Membuat Pengaduan, Silakan Cek Email Anda",
                    icon: "success",
                    button: "ok"
                }).then(() => {
                    window.location.reload();
                });
            })
            .catch((err) => {
                Swal.fire({
                    title: "Gagal, Terjadi Kesalahan",
                    text: `${err.response.data.message}`,
                    icon: "warning",
                    button: "ok"
                });
            });
    };

    renderListTopik(data) {
        if (data === undefined) {
            return;
        } else {
            const hasil = data.map((el) => {
                return {
                    kode: el._id,
                    keterangan: el.nama_topik
                };
            });
            return hasil;
        }
    }

    render() {
        console.log(this.props.tiketing.isLoadingCreateTiketing);

        return (
            <section id="tiketing" className="container">
                <div className="border-container">
                    <div className="container-form">
                        <h3>Formulir Pengaduan</h3>
                        <p className="text-form mt-4">
                            Untuk menyampaikan pertanyaan, keluhan, atau saran dengan mengisi form di bawah ini atau hubungi kami di alamat atau nomor
                            telepon di atas.
                        </p>
                    </div>
                    <div className="container-tiketing mt-5">
                        <Form>
                            <div className="row">
                                <div className="col-md-6">
                                    <SelectOptions
                                        forLabel={"id_topik"}
                                        label={"Topik/Keluhan"}
                                        name={"id_topik"}
                                        onChange={(e) => this.handleChange(e)}
                                        default={"Pilih Topik"}
                                        data={this.renderListTopik(this.state.listTopik)}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <FormGroup>
                                        <div className="custom-file">
                                            <Label className="label-select">Lampiran (optional) </Label>
                                            <CustomInput
                                                type="file"
                                                onChange={this.handleUploadFile}
                                                name="attachment_satu"
                                                id="exampleFile"
                                                label={"Maksimal 2MB"}
                                            />
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <FormGroup>
                                        <Label className="label-select">Deskripsi Keluhan</Label>
                                        <Input
                                            value={this.state.deskripsi}
                                            onChange={(e) => this.handleChange(e)}
                                            type="textarea"
                                            placeholder="Masukkan deskripsi keluhan secara detail"
                                            name={"deskripsi"}
                                        />
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center mt-3">
                                <Button
                                    color="primary"
                                    className="btn btn-lg col-12 col-md-6"
                                    onClick={this.handleSendTiketing}
                                    disabled={this.props.tiketing.isLoadingCreateTiketing}>
                                    {this.props.tiketing.isLoadingCreateTiketing ? <span className="loader"></span> : "Simpan"}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tiketing: state.tiketing,
        user: state.user
    };
};
export default connect(mapStateToProps)(FormTicketing);
