import React, { Component } from "react";
import { Form, Button } from "reactstrap";
import { Inputs } from "../../../../../library/components/Components";

class KontakDarurat extends Component {
    render() {
        return (
            <Form>
                <h4 className="text-center mb-4 d-block d-sm-none font-weight-bold">Kontak Darurat (Keluarga yang Tidak Satu Rumah)</h4>
                <div className="row">
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"nama_kontak_darurat"}
                            label={"Nama Lengkap"}
                            onChange={this.props.onChangeNama}
                            type={"text"}
                            name={"nama_kontak_darurat"}
                            placeholder={"Nama Lengkap darurat"}
                            error={this.props.errNamaD}
                            value={this.props.nama_kontak_darurat}
                        />
                    </div>
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"no_handphone_kontak_darurat"}
                            label={"Nomor Handphone"}
                            onChange={this.props.onChangeNo}
                            type={"text"}
                            name={"no_handphone_kontak_darurat"}
                            placeholder={"No HP darurat"}
                            error={this.props.errNoD}
                            value={this.props.no_handphone_kontak_darurat}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"alamat_kontak_darurat"}
                            label={"Alamat"}
                            onChange={this.props.onChange}
                            type={"text"}
                            name={"alamat_kontak_darurat"}
                            placeholder={"Alamat Kontak"}
                            error={this.props.errAlamatD}
                            value={this.props.alamat_kontak_darurat}
                        />
                    </div>
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"hubungan_kontak_darurat"}
                            label={"Hubungan dengan kontak darurat"}
                            onChange={this.props.onChangeHub}
                            type={"text"}
                            name={"hubungan_kontak_darurat"}
                            placeholder={"Hubungan Kontak"}
                            error={this.props.errHubD}
                            value={this.props.hubungan_kontak_darurat}
                        />
                    </div>
                </div>

                <div className="mt-4 mb-5">
                    <div className="custom-control custom-checkbox filter-input">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="agreement"
                            name="agreement"
                            onChange={this.props.handleCheck}
                            defaultChecked={this.props.checked || ""}
                        />
                        <label className="custom-control-label" htmlFor="agreement">
                            Saya menyatakan bahwa data-data yang telah saya isi diatas adalah valid, saya telah melakukan pengecekan atas kebenaran
                            data-data tersebut, dan saya telah membaca ketentuan privacy dan cookies .
                        </label>
                    </div>
                </div>
                <div className="text-center">
                    {this.props.loading ? (
                        <Button
                            onClick={this.props.handleSaveBorrower}
                            disabled
                            color="primary"
                            className="btn btn-primary btn-lg btn-block card-submit">
                            <span className="loader"></span>
                        </Button>
                    ) : (
                        <Button
                            onClick={this.props.handleSaveBorrower}
                            disabled={this.props.disabled}
                            color="primary"
                            className="btn btn-primary btn-lg btn-block card-submit">
                            Daftar{" "}
                        </Button>
                    )}
                </div>
            </Form>
        );
    }
}
export default KontakDarurat;
