import React from "react";

const Airplane = () => {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.995 511.995">
            <g>
                <g>
                    <path
                        d="M214.312,449.084c-8.256-3.008-16.704-0.192-19.776,8.096c-0.704,0.768-4.576,3.872-12.576,7.744
        c-7.968,3.84-11.296,13.408-7.424,21.376c2.752,5.728,8.48,9.024,14.432,9.024c2.336,0,4.704-0.512,6.944-1.6
        c22.816-11.04,26.08-19.808,27.136-22.72C226.056,462.748,222.6,452.156,214.312,449.084z"
                        fill="#8e8e93"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M122.824,478.492c-15.072,0-28.896-2.848-41.12-8.512c-8-3.648-17.536-0.192-21.248,7.84
        c-3.712,8-0.192,17.504,7.84,21.216c16.448,7.584,34.784,11.424,54.528,11.424h1.152c8.832,0,15.424-7.168,15.424-16
        C139.4,485.628,131.688,478.492,122.824,478.492z"
                        fill="#8e8e93"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M40.328,430.94c-3.424-5.824-6.592-12.288-9.472-19.424c-3.296-8.192-12.736-12.096-20.832-8.832
        c-8.192,3.328-12.16,12.64-8.864,20.832c3.52,8.704,7.424,16.64,11.648,23.744c2.976,5.024,8.32,7.84,13.792,7.84
        c2.752,0,5.568-0.704,8.128-2.208C42.312,448.38,44.84,438.588,40.328,430.94z"
                        fill="#8e8e93"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M506.024,5.052c-4.448-3.584-10.592-4.512-15.968-2.4l-416,192c-5.92,2.368-9.888,8.032-10.048,14.432
        c-0.16,6.4,3.488,12.256,9.312,14.944L192.008,289.5l304-272l-240,305.632V449.5l97.536-57.952l71.744,24.48
        c2.144,0.96,4.448,1.472,6.72,1.472c2.56,0,5.152-0.64,7.456-1.856c4.448-2.336,7.488-6.592,8.32-11.52l64-384
        C512.712,14.46,510.536,8.668,506.024,5.052z"
                        fill="#8e8e93"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Airplane;
