import React, { Component } from "react";
import "./Definisi.scss";

class Definisi extends Component {
    render() {
        const img = {
            backgroundImage: "url(/images/banner/BannerProfilePerusahaan.png)"
        };
        return (
            <div>
                <section id="definisi">
                    <div className="container">
                        <div className="sec-header">
                            <h3 className="sec-title text-center">Tentang Kami</h3>
                        </div>
                        <div>
                            <div className="header-image" style={img}>
                                <p className="title-paragraf">
                                    Indofund merupakan tempat bagi mereka yang membutuhkan pendanaan dan ingin memberikan pendanaan.
                                </p>
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-md-10 col-xl-9">
                                <div className="padding-section row align-items-center">
                                    <div className="col col-md-6">
                                        <h4 className="font-weight-bold pb-2">Visi Perusahaan</h4>
                                        <p className="text-secondary">
                                            Menjadi platform P2P yang sehat dalam upaya mendorong perkembangan UMKM di Indonesia, serta memberikan
                                            nilai tambah kepada para pemberi dana.
                                        </p>
                                    </div>
                                    <div className="col-12 col-md-6 text-center">
                                        <img className="img-svg" src={process.env.PUBLIC_URL + "/images/tentang-kami/client/grafik.svg"} alt="2" />
                                    </div>
                                </div>
                                <div className="padding-section row align-items-center">
                                    <div className="col-12 col-md-6 text-center">
                                        <img className="img-svg" src={process.env.PUBLIC_URL + "/images/tentang-kami/client/target.svg"} alt="2" />
                                    </div>
                                    <div className="col col-md-6 ">
                                        <h4 className="font-weight-bold pb-3">Misi Perusahaan</h4>
                                        <ol className="pl-4">
                                            <li className="text-secondary">
                                                Membantu permodalan serta memberikan edukasi dan bimbingan kepada para pelaku usaha UMKM demi
                                                mendukung perkembangan usaha.
                                            </li>
                                            <li className="text-secondary">
                                                Memberikan pelayanan dan rasa aman kepada para pemberi dana dalam berinvestasi dengan penerapan sistem
                                                seleksi penerima dana yang baik sesuai prinsip kehati - hatian.
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="padding-section row align-items-center">
                                    <div className="col col-md-6">
                                        <h4 className="font-weight-bold pb-3">Apa Itu Indofund?</h4>
                                        <p className="text-secondary">
                                            Indofund adalah sebuah portal pinjam-meminjam (peer to peer lending) yang menjembatani penerima dana atau
                                            pelaku usaha yang membutuhkan dana dengan Pemberi dana yang ingin berinvestasi Pendanaan. Kami adalah
                                            perusahaan yang ingin menjangkau, mengenal, dan mengakselerasi setiap lini bisnis baik besar, sedang,
                                            maupun kecil.
                                        </p>
                                    </div>
                                    <div className="col-12 col-md-6 text-center">
                                        <img className="img-svg" src={process.env.PUBLIC_URL + "/images/tentang-kami/client/question.svg"} alt="2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default Definisi;
