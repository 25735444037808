import axios from "axios";

// const url = `http://localhost:3000`
const url = process.env.REACT_APP_BASSE_API;

export const Master = (masterType = "", lookup = "") => {
    return {
        type: "GET_MASTER",
        payload: axios.get(`${url}/json/all-json?masterType=${masterType}&lookup=${lookup}`)
    };
}; 
export const MasterPusdafil = (masterType = "") => {
    return {
        type: "GET_MASTER_PUSDAFIL",
        payload: axios.get(`${url}/json/pusdafil?masterType=${masterType}`)
    };
};
