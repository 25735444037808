const initialState = {
    borrower: [],
    borrowerList: [],
    borrowerDetail: [],
    borrowerPorto: [],
    borrowerMarket: [],
    isLoading: false,
    isError: false,
    isLoadingGetID: true,
    isLoadingPorto: false,
    isLoadingUpdateE: false,
    isLoadingUpdateP: false,
    isLoadingUpdateDigisign: false,
    isLoadingPaymentLoan: false
};

const borrower = (state = initialState, action) => {
    switch (action.type) {
        case "GET_BORROWER_ID_PENDING":
            return {
                ...state,
                isLoadingGetID: true
            };
        case "GET_BORROWER_ID_REJECTED":
            return {
                ...state,
                isLoadingGetID: false,
                isError: true
            };
        case "GET_BORROWER_ID_FULFILLED":
            // console.log(action.payload.data);
            return {
                ...state,
                isLoadingGetID: false,
                borrowerDetail: action.payload.data.result
            };
        case "BORROWER_UPDATE_ENTITY_PENDING":
            return {
                ...state,
                isLoadingUpdateE: true
            };
        case "BORROWER_UPDATE_ENTITY_REJECTED":
            return {
                ...state,
                isLoadingUpdateE: false,
                isError: true
            };
        case "BORROWER_UPDATE_ENTITY_FULFILLED":
            return {
                ...state,
                isLoadingUpdateE: false,
                borrowerList: [state.borrowerList, action.payload.data]
            };
        case "BORROWER_UPDATE_PERSONAL_PENDING":
            return {
                ...state,
                isLoadingUpdateP: true
            };
        case "BORROWER_UPDATE_PERSONAL_REJECTED":
            return {
                ...state,
                isLoadingUpdateP: false,
                isError: true
            };
        case "BORROWER_UPDATE_PERSONAL_FULFILLED":
            return {
                ...state,
                isLoadingUpdateP: false,
                borrowerList: [state.borrowerList, action.payload.data]
            };
        case "PORTOFOLIO_PENDING":
            return {
                ...state,
                isLoadingPorto: true
            };
        case "PORTOFOLIO_REJECTED":
            return {
                ...state,
                isLoadingPorto: false,
                isError: true
            };
        case "PORTOFOLIO_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoadingPorto: false,
                borrowerPorto: action.payload.data
            };
        case "BORROWER_PAYMENT_LOAN_PENDING":
            return {
                ...state,
                isLoadingPaymentLoan: true,
                isError: false
            };
        case "BORROWER_PAYMENT_LOAN_REJECTED":
            return {
                ...state,
                isLoadingPaymentLoan: false,
                isError: true
            };

        case "BORROWER_PAYMENT_LOAN_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoadingPaymentLoan: false,
                borrowerPayment: action.payload.data
            };
        case "BORROWER_PAYMENT_LOAN_MICRO_PENDING":
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case "BORROWER_PAYMENT_LOAN_MICRO_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case "BORROWER_PAYMENT_LOAN_MICRO_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoading: false,
                borrowerPayment: action.payload.data
            };
        case "BORROWER_PAYMENT_PENALTY_PENDING":
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case "BORROWER_PAYMENT_PENALTY_REJECTED":
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case "BORROWER_PAYMENT_PENALTY_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoading: false,
                borrowerPayment: action.payload.data
            };
        case "UPDATE_DIGISIGIN_BORROWER_PENDING":
            return {
                ...state,
                isLoadingUpdateDigisign: true,
                isError: false
            };
        case "UPDATE_DIGISIGIN_BORROWER_REJECTED":
            return {
                ...state,
                isLoadingUpdateDigisign: false,
                isError: true
            };

        case "UPDATE_DIGISIGIN_BORROWER_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoadingUpdateDigisign: false,
                borrowerList: [state.borrowerList, action.payload.data]
            };
        case "MARKET_LOCATION_MICRO_PENDING":
            return {
                ...state,
                isLoadingPorto: true
            };
        case "MARKET_LOCATION_MICRO_REJECTED":
            return {
                ...state,
                isLoadingPorto: false,
                isError: true
            };
        case "MARKET_LOCATION_MICRO_FULFILLED":
            // console.log(action.payload.data)
            return {
                ...state,
                isLoadingPorto: false,
                borrowerMarket: action.payload.data
            };
        default:
            return state;
    }
};
export default borrower;
