import React, { Component } from "react";
import { MainProject } from "../../../../redux/action/Project";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel2";
import "../../../../../node_modules/react-owl-carousel2/lib/styles.css";
import NoDataProyekWelcome from "../../../assets/no data/NoProyekWelcome";
import { ItemProduktif } from "./ItemProduktif";
import { arrayItemProject } from "../../../../library/helper/helper";

class ProyekProduktif extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: []
        };
    }

    componentDidMount = async () => {
        await this.props.dispatch(MainProject());
        this.setState({
            project: this.props.project.projectList
        });
        // console.log(this.state.project)
    };
    render() {
        const options = {
            dots: true,
            items: 1,
            dotsClass: "owl-dots container no-lg",
            loop: true,
            autoplay: true,
            autoplayTimeout: 7000,
            autoplayHoverPause: true,
            nav: true,
            navText: ["<i class='fa fa-chevron-left icons' ></i>", "<i class='fa fa-chevron-right icons' ></i>"]
        };
        const projects = this.state.project.slice(0, 6);
        const projectList = arrayItemProject(projects)
            .slice(0, 3)
            .map((project) => {
                return ItemProduktif(project);
            });

        const projectListNext = arrayItemProject(projects)
            .slice(3, 6)
            .map((project) => {
                return ItemProduktif(project);
            });

        const projectMobile = () => {
            return (
                <OwlCarousel options={options}>
                    {arrayItemProject(projects).map((project) => {
                        return (
                            <div className="row" key={project._id}>
                                <div className="mobile-owl-project">{ItemProduktif(project)}</div>
                            </div>
                        );
                    })}
                </OwlCarousel>
            );
        };

        const projectWeb = () => {
            return this.state.project.length > 3 ? (
                <OwlCarousel options={options}>
                    <div className="item" style={{ paddingBottom: "10px", paddingRight: "12px" }}>
                        <div className="row">{projectList}</div>
                    </div>
                    <div className="item" style={{ paddingBottom: "10px", paddingRight: "12px" }}>
                        <div className="row">{projectListNext}</div>
                    </div>
                </OwlCarousel>
            ) : (
                <div className="row d-flex justify-content-center">
                    <div className="col-md-10">{projectList}</div>
                </div>
            );
        };

        return (
            <div>
                {this.state.project.length !== 0 ? (
                    <div>
                        <div className="projects-wrapper">{window.innerWidth <= 480 ? projectMobile() : projectWeb()}</div>
                        <div className="text-center mt-4">
                            <NavLink to="/proyek" className="btn btn-outline-primary btn-lg px-5">
                                Lihat semua
                            </NavLink>
                        </div>
                    </div>
                ) : (
                    <NoDataProyekWelcome />
                )}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        project: state.project
    };
};
export default connect(mapStateToProps)(ProyekProduktif);
