import React from "react";

const Whatsapp = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.1597 5.16837C15.2797 0.728369 9.39974 -0.591631 4.83974 2.16837C0.399735 4.92837 -1.04026 10.9284 1.83974 15.3684L2.07974 15.7284L1.11974 19.3284L4.71974 18.3684L5.07974 18.6084C6.63974 19.4484 8.31974 19.9284 9.99974 19.9284C11.7997 19.9284 13.5997 19.4484 15.1597 18.4884C19.5997 15.6084 20.9197 9.72837 18.1597 5.16837ZM15.6397 14.4084C15.1597 15.1284 14.5597 15.6084 13.7197 15.7284C13.2397 15.7284 12.6397 15.9684 10.2397 15.0084C8.19974 14.0484 6.51974 12.4884 5.31974 10.6884C4.59974 9.84837 4.23974 8.76837 4.11974 7.68837C4.11974 6.72837 4.47974 5.88837 5.07974 5.28837C5.31974 5.04837 5.55974 4.92837 5.79974 4.92837H6.39974C6.63974 4.92837 6.87974 4.92837 6.99974 5.40837C7.23974 6.00837 7.83974 7.44837 7.83974 7.56837C7.95974 7.68837 7.95974 7.92837 7.83974 8.04837C7.95974 8.28837 7.83974 8.52837 7.71974 8.64837C7.59974 8.76837 7.47974 9.00837 7.35974 9.12837C7.11974 9.24837 6.99974 9.48837 7.11974 9.72837C7.59974 10.4484 8.19974 11.1684 8.79974 11.7684C9.51974 12.3684 10.2397 12.8484 11.0797 13.2084C11.3197 13.3284 11.5597 13.3284 11.6797 13.0884C11.7997 12.8484 12.3997 12.2484 12.6397 12.0084C12.8797 11.7684 12.9997 11.7684 13.2397 11.8884L15.1597 12.8484C15.3997 12.9684 15.6397 13.0884 15.7597 13.2084C15.8797 13.5684 15.8797 14.0484 15.6397 14.4084Z"
                fill="white"
            />
        </svg>
    );
};

export default Whatsapp;
