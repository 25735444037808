import React, { Component } from "react";
import Step from "./Step";
import PictureStep from "./PictStep";

class AjukanPinjaman extends Component {
    render() {
        return (
            <div>
                <section id="cara-ajukan-pinjaman" className="pt-5 pb-5 bg-gray">
                    <div className="container no-lg">
                        <div className="sec-header" id="two">
                            <h4 className="text-center sec-title">Cara Ajukan Pendanaan</h4>
                            <p className="text-center sec-desc">Langkah mudah memberikan bantuan melalui Indofund</p>
                        </div>
                        <div className="row flex-column-reverse flex-lg-row">
                            <div className="col-12 col-lg-h5 col-lg-6">
                                <Step
                                    title1={"Daftar sebagai Penerima dana"}
                                    body1={"Daftarkan diri anda melalui form pendaftaran."}
                                    title2={"Ajukan Pendanaan Anda"}
                                    body2={"Pendanaan disetujui oleh Indofund.id dan pendanaan ditawarkan kepada pemberi dana di website Indofund.id"}
                                    title3={"Dapatkan Dana dengan bunga kompetitif"}
                                    body3={
                                        "Penerima dana melunasi pendanaan terhadap pemberi dana sesuai dengan tenggat waktu yang diberikan beserta dengan bunga pinjaman"
                                    }
                                    namebutton={"Ajukan pendanaan"}
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <PictureStep name={"/images/cara-ajukan-pinjaman/1.png"} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default AjukanPinjaman;
