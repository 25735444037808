const initialState = {
    isLoadingCreateTiketing: false,
    succesTiketing: "",
    errorCreateTiketing: false,
    listTopik: [],
    isLoadingTopik: false,
    tiketingDetail: {},
    isLoadingDetail: false,
    isLoadingMessage: false,
    isLoadingUpdateStatus: false,
    listTiketing: [],
    page: 0,
    isLoadingListTiketing: false,
    totalData: 0
};

const TiketingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CREATE_TIKETING_PENDING":
            return {
                ...state,
                isLoadingCreateTiketing: true
            };
        case "CREATE_TIKETING_REJECTED":
            return {
                ...state,
                isLoadingCreateTiketing: false,
                errorCreateTiketing: true
            };
        case "CREATE_TIKETING_FULFILLED":
            return {
                ...state,
                succesTiketing: action.payload.data
            };
        case "GET_TOPIK_TIKETING_PENDING":
            return {
                ...state,
                isLoadingTopik: true
            };
        case "GET_TOPIK_TIKETING_FULFILLED":
            return {
                ...state,
                listTopik: action.payload.data
            };
        case "GET_DETAIL_TIKETING_PENDING":
            return {
                ...state,
                isLoadingDetail: true
            };
        case "GET_DETAIL_TIKETING_REJECTED":
            return {
                ...state,
                isLoadingDetail: false
            };
        case "GET_DETAIL_TIKETING_FULFILLED":
            return {
                ...state,
                tiketingDetail: action.payload.data.data,
                isLoadingDetail: false
            };
        case "CREATE_MESSAGE_TIKETING_PENDING":
            return {
                ...state,
                isLoadingMessage: true
            };
        case "CREATE_MESSAGE_TIKETING_REJECTED":
            return {
                ...state,
                isLoadingMessage: false
            };
        case "CREATE_MESSAGE_TIKETING_FULFILLED":
            return {
                ...state,
                isLoadingMessage: false
            };

        case "UPDATE_STATUS_TIKETING_PENDING":
            return {
                ...state,
                isLoadingUpdateStatus: true
            };
        case "UPDATE_STATUS_TIKETING_REJECTED":
            return {
                ...state,
                isLoadingUpdateStatus: false
            };
        case "UPDATE_STATUS_TIKETING_FULFILLED":
            return {
                ...state,
                isLoadingUpdateStatus: false
            };
        case "GET_LIST_TIKETING_PENDING":
            return {
                ...state,
                isLoadingListTiketing: true
            };
        case "GET_LIST_TIKETING_REJECTED":
            return {
                ...state,
                isLoadingListTiketing: false
            };
        case "GET_LIST_TIKETING_FULFILLED":
            return {
                ...state,
                isLoadingListTiketing: false,
                listTiketing: action.payload.data.data,
                page: action.payload.data.page,
                totalData: action.payload.data.totalData
            };
        default:
            return state;
    }
};

export default TiketingReducer;
