import React, { Component } from "react";
import { Table } from "reactstrap";
import moment from "moment";
import "moment/locale/id";

class TabDetailProyek extends Component {
    cicilanProject = () => {
        const project = this.props.project;

        const pinjamanlist = project.rincian_pinjaman;
        const pinjamanSpread = project.rincian_pinjaman_spread;
        const pinjamanKonsumtif = project.rincian_pinjaman_konsumtif;

        if (pinjamanlist === undefined) {
            return (
                <tr>
                    <td className="card-text">Bulan</td>
                    <td className="card-text"> Rp 0</td>
                </tr>
            );
        } else {
            if (project.tipe_pinjaman !== "Employee Loan") {
                if (pinjamanSpread.length !== 0) {
                    const hasil = pinjamanSpread.map((val, index) => {
                        return (
                            <tr key={val._id}>
                                <td className="card-text">
                                    {val.jatuh_tempo === null
                                        ? "Bulan ke " + (index + 1)
                                        : moment(new Date(val.jatuh_tempo)).locale("id").format("LL")}
                                </td>
                                <td className="card-text">Rp {val.borrower_bayar.toLocaleString("IN")}</td>
                            </tr>
                        );
                    });
                    return hasil;
                } else {
                    const hasil = pinjamanlist.map((val, index) => {
                        return (
                            <tr key={val._id}>
                                <td className="card-text">
                                    {val.jatuh_tempo === null
                                        ? "Bulan ke " + (index + 1)
                                        : moment(new Date(val.jatuh_tempo)).locale("id").format("LL")}
                                </td>
                                <td className="card-text">Rp {val.borrower_bayar.toLocaleString("IN")}</td>
                            </tr>
                        );
                    });
                    return hasil;
                }
            } else {
                const hasil = pinjamanKonsumtif.map((val, index) => {
                    return (
                        <tr key={val._id}>
                            <td className="card-text">
                                {val.jatuh_tempo === null ? "Bulan ke " + (index + 1) : moment(new Date(val.jatuh_tempo)).locale("id").format("LL")}
                            </td>
                            <td className="card-text">Rp {val.borrower_bayar.toLocaleString("IN")}</td>
                        </tr>
                    );
                });
                return hasil;
            }
        }
    };

    render() {
        //menghitung periode pendanaan
        const project = this.props.project;
        const periode_awal = project.tgl_mulai_publikasi_pinjaman === undefined ? "" : project.tgl_mulai_publikasi_pinjaman;
        const jangka_waktu = project.rencana_jangka_waktu_publikasi;
        const periode_akhir =
            project.tgl_mulai_publikasi_pinjaman === undefined
                ? ""
                : new Date(new Date(project.tgl_mulai_publikasi_pinjaman).getTime() + jangka_waktu * 24 * 60 * 60 * 1000);
        const awalPendanaan = moment(new Date(periode_awal)).locale("id").format("LL");

        const akhirPendanaan = moment(new Date(periode_akhir)).locale("id").format("LL");

        return (
            <div className={this.props.className}>
                <div className="card container mt-4 mb-4 p-4">
                    <div className="mb-4">
                        {this.props.portofolio !== false ? (
                            <>
                                <p className="font-weight-bold">Status Proyek</p>
                                <p className="mb-5">{this.props.project.status_proyek}</p>
                            </>
                        ) : null}
                        <p className="font-weight-bold">Periode Pendanaan</p>
                        <p className="mb-5">
                            {awalPendanaan} - {akhirPendanaan}
                        </p>
                        <p className="font-weight-bold">Periode Cicilan</p>
                        <div className="card-table col-12 col-lg-9 p-1">
                            <Table borderless responsive style={{ margin: "15px", width: "330px" }}>
                                <thead>
                                    <tr>
                                        <th>Tanggal</th>
                                        <th>Nominal</th>
                                    </tr>
                                </thead>
                                <tbody>{this.cicilanProject()}</tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TabDetailProyek;
