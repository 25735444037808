import React, { Component } from "react";
import "./Akselerasi.scss";

class Akselerasi extends Component {
    render() {
        return (
            <div>
                <section id="akselerasi">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-md-10 col-xl-9 ">
                                <div className="sec-header">
                                    <h4 className="text-center sec-title">Akselerasi Bisnis dan Usaha</h4>
                                    <p className="text-center sec-desc">
                                        Kami bekerja sama dengan mitra-mitra strategis untuk bisa meningkatkan hard skill dan soft skill setiap member
                                        dari Indofund.id
                                    </p>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <img className="img-fluid" src={process.env.PUBLIC_URL + "/images/tentang-kami/potensi/2.png"} alt="ojk" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <p className="text-secondary">
                                            Indofund.id memberikan aneka training dan mentoring kepada penerima dana dan pemberi dana sehingga
                                            penerima dana bisa menjadi pelaku usaha yang semakin berkembang dan maju dan pemberi dana juga bisa
                                            menjadi pemodal yang memiliki profil risiko yang semakin matang serta aset yang mengalami pertumbuhan
                                            dengan maksimal. <br /> <br />
                                            Selain membantu permodalan, Indofund.id juga memberikan edukasi dan bimbingan kepada para pelaku UMKM demi
                                            mendukung perkembangan usaha. Kami memberikan penerapan sistem seleksi Penerima Dana yang baik sesuai
                                            prinsip kehati-hatian demi memberikan pelayanan yang baik dan rasa aman kepada Pemberi Dana
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default Akselerasi;
