import React, { Component } from "react";
import { connect } from "react-redux";
import { LendAppNew } from "../../../redux/action/Lender";
import { LoadingPortofolioDetail } from "../../assets/loading data/LoadingLender";
import Info from "./DetailInfo";
import Tab from "./DetailTab";
import "./detailporto.scss";

class PortoDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pinjaman: [],
            lending: []
        };
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        var id = this.props.match.params.pinjaman_id;
        await this.props.dispatch(LendAppNew(id));
        this.setState({
            pinjaman: this.props.lender.lenderLendNew.id_pinjaman,
            lending: this.props.lender.lenderLendNew
        });
    };

    render() {
        const project = this.state.pinjaman;
        const employee = "/images/proyek/employee.png";
        const background = {
            backgroundImage: `url(${project.tipe_pinjaman !== "Employee Loan" ? project.gambar_proyek : employee})`
        };
        return (
            <div>
                {this.props.lender.isLoadingLendDetail ? (
                    <LoadingPortofolioDetail />
                ) : (
                    <section id="proyek-detail-page" className="with-pad">
                        <div className="container">
                            <div className="project-detail">
                                <div className="row mb-3">
                                    <div className="col-12 col-md-5">
                                        <div className="mb-4">
                                            <div className="gambar-proyek-porto" style={background}></div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-7 d-flex align-items-center">
                                        <Info project={this.state.pinjaman} />
                                    </div>
                                    <div className="col-12">
                                        <Tab lending={this.state.lending} project={this.state.pinjaman} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lender: state.lender
    };
};
export default connect(mapStateToProps)(PortoDetail);
