export function Paginations(project, currentPage, todosPerPage, filter) {
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    //sorting descending
    function compare(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
    }
    const currentTodos = project
        .filter((item) =>
            filter === ""
                ? item.status_proyek === "Pembayaran cicilan"
                : item.nama_pinjaman !== undefined
                ? item.nama_pinjaman.toLowerCase().includes(filter.toLowerCase()) & (item.status_proyek === "Pembayaran cicilan")
                : ""
        )
        .sort(compare)
        .slice(indexOfFirstTodo, indexOfLastTodo);
    return currentTodos;
}

export function ShowIdPinjaman(tipe) {
    const show = () => {
        if (tipe === "Micro Financing" || tipe === "Employee Loan") {
            return true;
        } else {
            return false;
        }
    };
    return show();
}

export function showDataCicilan(data) {
    //   console.log(data.rincian_pinjaman_mikro);
    //   const dataShow = () => {
    if (data.tipe_pinjaman === "Micro Financing") {
        return ".rincian_pinjaman_mikro";
    } else if (data.tipe_pinjaman === "Employee Loan") {
        return ".rincian_pinjaman_konsumtif";
    } else {
        return ".rincian_pinjaman";
    }
    //   console.log(dataTampil());
    //   return dataTampil;
    //   };
}
