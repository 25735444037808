import React, { Component } from "react";

class DisclaimerFooter extends Component {
    render() {
        return (
            <div className="row mb-5d-flex justify-content-center text-justify pt-5" id="contact-us">
                <div className="col col-md-11 col-lg-11">
                    <h5 className="disclaimer-risiko font-weight-bold">Disclaimer risiko</h5>
                    <div className="row mb-5 text-justify">
                        <div className="col-12 col-lg">
                            <ol className="risk-list text-second">
                                <li>
                                    Layanan Pendanaan Bersama Berbasis Teknologi Informasi merupakan kesepakatan perdata antara Pemberi Dana dengan
                                    Penerima Dana, sehingga segala risiko yang timbul dari kesepakatan tersebut ditanggung sepenuhnya oleh
                                    masing-masing pihak.
                                </li>
                                <li>
                                    Risiko kredit atau gagal bayar ditanggung sepenuhnya oleh Pemberi Dana. Tidak ada lembaga atau otoritas negara
                                    yang bertanggung jawab atas risiko gagal bayar ini.
                                </li>
                                <li>
                                    Penyelenggara dengan persetujuan dari masing-masing Pengguna (Pemberi Dana dan/atau Penerima Dana) mengakses,
                                    memperoleh, menyimpan, mengelola dan/atau menggunakan data pribadi Pengguna Pemanfaatan atau di dalam benda,
                                    perangkat elektronik (termasuk smartphone atau telepon seluler), perangkat keras (hardware) maupun lunak
                                    (software), dokumen elektronik, aplikasi atau sistem elektronik milik Pengguna atau yang dikuasai Pengguna, dengan
                                    memberitahukan tujuan, batasan dan mekanisme Pemanfaatan Data tersebut kepada Pengguna yang bersangkutan sebelum
                                    memperoleh persetujuan yang dimaksud.
                                </li>
                                <li>
                                    Pemberi Dana yang belum memiliki pengetahuan dan pengalaman pendanaan bersama, disarankan untuk tidak menggunakan
                                    layanan ini.
                                </li>
                                <li>
                                    Penerima Dana harus mempertimbangkan tingkat bunga pendanaan dan biaya lainnya sesuai dengan kemampuan dalam
                                    melunasi pendanaan.
                                </li>
                                <li>Setiap kecurangan tercatat secara digital di dunia maya dan dapat diketahui masyarakat luas di media sosial.</li>
                            </ol>
                        </div>
                        <div className="col-12 col-lg">
                            <ol className="risk-list text-second" start="7">
                                <li>
                                    Pengguna harus membaca dan memahami informasi ini sebelum membuat keputusan menjadi Pemberi Dana atau Penerima
                                    Dana.
                                </li>
                                <li>
                                    Pemerintah yaitu dalam hal ini Otoritas Jasa Keuangan, tidak bertanggung jawab atas setiap pelanggaran atau
                                    ketidakpatuhan oleh Pengguna, baik Pemberi Dana maupun Penerima Dana (baik karena kesengajaan atau kelalaian
                                    Pengguna) terhadap ketentuan peraturan perundang-undangan maupun kesepakatan atau perikatan antara Penyelenggara
                                    dengan Pemberi Dana dan/atau Penerima Dana.
                                </li>
                                <li>
                                    Setiap transaksi dan kegiatan pendanaan atau pelaksanaan kesepakatan mengenai pendanaan antara atau yang
                                    melibatkan Penyelenggara, Pemberi Dana dan/atau Penerima Dana wajib dilakukan melalui escrow account dan virtual
                                    account sebagaimana yang diwajibkan berdasarkan Peraturan Otoritas Jasa 10/POJK.05/2022 tentang Layanan Pendanaan
                                    Bersama Berbasis Teknologi Informasi dan pelanggaran atau ketidakpatuhan terhadap ketentuan tersebut merupakan
                                    bukti telah terjadinya pelanggaran hukum oleh Penyelenggara sehingga Penyelenggara wajib menanggung ganti rugi
                                    yang diderita oleh masing-masing Pengguna sebagai akibat langsung dari pelanggaran hukum tersebut di atas tanpa
                                    mengurangi hak Pengguna yang menderita kerugian menurut Kitab Undang-Undang Hukum Perdata.
                                </li>
                            </ol>
                        </div>
                    </div>
                    <hr style={{ border: "0.5px solid #acacac" }} />
                </div>
            </div>
        );
    }
}

export default DisclaimerFooter;
